<div class="aapp-title-container">
  <div class="container">
    <div class="row">
      <div class="col-12"><h1 class="aapp-title">Login</h1></div>
    </div>
  </div>
</div>
<div class="aapp-login-container">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="aapp-login-title">
          Please enter your login details to access Ensight
        </h3>
      </div>
      <div class="offset-sm-2 col-sm-8 offset-md-3 col-md-6">
        <um-login-form
          [orgUuid]="route.snapshot.queryParams.orgUuid"
          [managePasswordNavigateTo]="managePasswordNavigateTo"
          [payloadToken]="payloadToken"
          (submitForm)="onLogin($event)"
          [isCaptchaEnabled]="true"
        ></um-login-form>
        <div class="offset-sm-4 col-sm-8">
          <a class="link" [routerLink]="['/forgot-password']">
            Can't remember your password?
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
