import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonSeModule, FormControlsModule } from '@se/common';
import { CustomSummaryTableComponent } from './app-custom-summary-table.component';

@NgModule({
  declarations: [CustomSummaryTableComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormControlsModule, CommonSeModule],
  exports: [CustomSummaryTableComponent],
})
export class CustomSummaryTableModule {}
