import { Inject, Injectable } from '@angular/core';
import { BROWSER_SESSION_STORAGE } from '@core/constant';

@Injectable()
export class BrowserSessionStorage {
  constructor(@Inject(BROWSER_SESSION_STORAGE) private readonly storage: BrowserSessionStorage) {}

  get length(): number {
    return this.storage.length;
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }
}
