<div class="dropdown-container" #dropdown>
  <button class="btn-dropdown" [ngClass]="{'dropdown-open': dropdownOpen }" type="button" (click)="toggleDropdown()">
    <span class="btn-text">Add Placeholder</span>
    <span class="btn-arrow"></span>
  </button>

  <div class="dropdown-list" [ngClass]="{'dropdown-open': dropdownOpen }">
    <div class="dropdown-option" *ngFor="let item of menuItems;">
      <span class="dropdown-option-arrow"></span>
      <span class="dropdown-option-text">{{ item.text }}</span>

      <div class="dropdown-list sub-menu">
        <div class="dropdown-option" *ngFor="let subMenuItem of item.subMenu" (click)="select(subMenuItem)">
          <span class="dropdown-option-text">{{ subMenuItem.text }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
