import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FormSharedService } from '@se/dynamic-form';

import { AppState } from '../../../../../reducers';
import {
  chartMetricsForms,
  configurationForm,
  dynamicFormValue,
  placeholderById,
  placeholdersMetadata,
} from '../../../redux/placeholders-wizard.selectors';
import * as Actions from '../../../redux/placeholders-wizard.actions';
import { FIELDS_BY_STATE_ID, LABELS_BY_STATE_ID } from '../../../constants';
import { CHART_METRICS_STATE } from '../../../constants';
import { ChartPlaceholder, PlaceholdersDynamicFormValue, PlanConfigData, TableColumn } from '@core/model';
import {
  ButtonConfiguration,
  ConfigurationData,
  FormValue,
  MultipleConfigurationFiled,
  MultipleConfigurationLabel,
} from '../../../models';
import { FormConfigurations } from '@shared/models';
import { ChartTypes, ChartDataSource } from '@core/enums';

@UntilDestroy()
@Component({
  selector: 'ep-multiple-configurations',
  templateUrl: './multiple-configurations.component.html',
  styleUrls: ['./multiple-configurations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleConfigurationsComponent implements OnInit {
  @Input() configurationStateId: string;
  @Input() id: string;
  @Input() plansConfigData: PlanConfigData[];
  @Input() plansConfigMeta: PlanConfigData[];

  questionsData$: Observable<any[]>; // Observable<Observable<ConfigurationData>[]>
  activeConfigurationKey = 0;
  configurationButtons = [];
  labels: MultipleConfigurationLabel;
  isPieDonutBarChart = false;
  isDynamicMetrics = false;

  private form: FormGroup;
  private dynamicFormValue: PlaceholdersDynamicFormValue;
  private configurationsData: TableColumn[];
  private configurationChanged = false;
  private fields: MultipleConfigurationFiled;
  private chartMetrics = false;
  private tabsNumber: number;
  private placeholdersMetadata: any[];

  constructor(private store: Store<AppState>, private formSharedService: FormSharedService) {}

  ngOnInit(): void {
    this.fields = FIELDS_BY_STATE_ID[this.configurationStateId];
    this.labels = LABELS_BY_STATE_ID[this.configurationStateId];
    this.chartMetrics = CHART_METRICS_STATE.id === this.configurationStateId;
    this.getPlaceholdersMetadata();
    this.watchForFormShared();
    this.watchForDynamicFormValue();

    this.questionsData$ = this.chartMetrics ? this.getQuestionsInCaseOfChartMetrics() : this.setQuestions();

    if (this.id) {
      this.watchForPlaceholderId();
    }
  }

  onSelectConfiguration(event: ButtonConfiguration): void {
    const previousConfigurationKey = this.configurationButtons.find(button => button.active).key;

    this.setActiveButton(event.key, true);

    this.activeConfigurationKey = this.configurationButtons.find(button => button.active).key;

    if (this.chartMetrics && this.form.invalid && this.configurationsData) {
      this.updateValidationAndFieldsOfForm(previousConfigurationKey);
    }

    this.configurationChanged = true;
  }

  addNewMetric(): void {
    const index = this.configurationButtons.length;
    const metric = {
      label: `Metric ${index + 1}`,
      key: index,
      classes: 'secondary',
      active: true,
    };

    this.tabsNumber += 1;
    this.configurationButtons.push(metric);
    this.activeConfigurationKey = index;
    this.setActiveButton(index);
    this.questionsData$ = this.getQuestionsInCaseOfChartMetrics();
  }

  deleteMetric(): void {
    this.tabsNumber -= 1;
    this.configurationButtons.splice(this.activeConfigurationKey, 1);

    const colors = this.configurationsData.map(item => item?.color);

    this.configurationsData = this.configurationsData
      .filter(item => {
        return item.order !== this.activeConfigurationKey;
      })
      .map((item, index) => ({
        ...item,
        color: colors[index],
        order: index,
      }));

    this.updateDynamicForm(this.configurationsData);
    this.removeFormValidation(this.activeConfigurationKey);

    !this.activeConfigurationKey ? (this.activeConfigurationKey = 0) : (this.activeConfigurationKey -= 1);

    this.setActiveButton(this.activeConfigurationKey);
    this.questionsData$ = this.getQuestionsInCaseOfChartMetrics(true);
  }

  private setFormValues(form: FormGroup, formId: number) {
    const indexField = this.chartMetrics ? 'order' : 'index';

    const tab = this.configurationsData?.find(configuration => configuration[indexField] === formId);

    if (tab) {
      this.fields.innerFields.forEach(innerField => {
        const value =
          innerField === 'productIndex' && !isNaN(+tab[innerField])
            ? +tab[innerField] + 1
            : tab[innerField] ?? form.controls?.[innerField]?.value;

        if (this.fields.optionalDatalistFields?.includes(innerField) && !tab[innerField]) return;

        form.controls?.[innerField]?.setValue(value ?? '', {
          emitEvent: false,
        });
      });
    } else if (!this.chartMetrics && this.id && formId >= this.configurationsData?.length) {
      this.fields.innerFields.forEach(innerField =>
        form.controls?.[innerField]?.setValue('', {
          emitEvent: false,
        })
      );
    }

    this.configurationChanged = false;
  }

  private watchForFormShared(): void {
    (this.formSharedService.valueChangePure$ as Observable<FormValue>)
      .pipe(untilDestroyed(this))
      .subscribe((res: FormValue) => {
        this.form = res.form;

        if (this.configurationChanged) {
          this.setFormValues(this.form, res.formId);
        }

        this.store.dispatch(
          Actions.setFormValidation({
            formId: res.formId,
            isValid: res.form.valid,
          })
        );
      });
  }

  private deleteConfigurations(): void {
    const updatedColumns = this.configurationsData.filter(configuration => configuration.index < this.tabsNumber);

    this.store.dispatch(
      Actions.updateDynamicFormFields({
        fields: { [this.fields.allTabsField]: [...updatedColumns] },
      })
    );
  }

  private isConfigurationValid(tabIndex: number): boolean {
    const tab = this.configurationsData?.find(configuration => configuration.index === tabIndex);

    return tab && this.fields.innerFields.every(innerField => tab[innerField]);
  }

  private watchForPlaceholderId(): void {
    this.store
      .pipe(select(placeholderById(this.id)), first())
      .pipe(untilDestroyed(this))
      .subscribe(placeholder => this.updateDynamicForm([...placeholder[this.fields.allTabsField]]));
  }

  private watchForDynamicFormValue(): void {
    this.store
      .pipe(
        select(dynamicFormValue),
        filter((formValue: PlaceholdersDynamicFormValue) => !!formValue),
        untilDestroyed(this)
      )
      .subscribe((formValue: PlaceholdersDynamicFormValue) => {
        const chartType = (formValue as ChartPlaceholder).chartType;

        this.dynamicFormValue = formValue;
        this.configurationsData = formValue[this.fields.allTabsField];

        this.isPieDonutBarChart =
          chartType === ChartTypes.donut || chartType === ChartTypes.pie || chartType === ChartTypes.clusteredBarChart;
        this.isDynamicMetrics = (formValue as any).chartDataSource !== ChartDataSource.staticMetrics;

        if (this.dynamicFormValue && !this.configurationButtons.length) {
          this.setTabsNumber();
          this.setButtons();

          this.configurationChanged = true;
        }
      });
  }

  private getQuestionsInCaseOfChartMetrics(isDeleteMetric?: boolean): Observable<Observable<ConfigurationData>[]> {
    return this.store.pipe(
      select(
        chartMetricsForms({
          plansConfigData: this.plansConfigData,
          plansConfigMeta: this.plansConfigMeta,
          id: this.id,
          metricsNumber: this.tabsNumber,
          isDeleteMetric,
          configurationData: this.configurationsData,
        })
      ),
      map((data: FormConfigurations[]) => data.map((form: FormConfigurations) => of({ data: form }))),
      first()
    );
  }

  private setQuestions(): Observable<Observable<ConfigurationData>[]> {
    return this.store.pipe(
      select(
        configurationForm({
          id: this.id,
          stateId: this.configurationStateId,
          valuesField: this.fields.allTabsField,
        })
      ),
      filter(item => !!item),
      map(item => Array(this.tabsNumber).fill(of(item))),
      first()
    );
  }

  private setButtons(): void {
    for (let i = 0; i < this.tabsNumber; i++) {
      this.configurationButtons = [
        ...this.configurationButtons,
        {
          label: this.chartMetrics ? `Metric ${i + 1}` : `${i + 1}`,
          key: i,
          classes: 'secondary',
          active: i === 0,
        },
      ];

      if (this.configurationsData && this.configurationsData.length > this.tabsNumber) {
        this.deleteConfigurations();
      }

      if (this.chartMetrics && i !== 0) continue;

      if (this.id && !this.configurationsData) continue;

      this.store.dispatch(Actions.setFormValidation({ formId: i, isValid: !!this.isConfigurationValid(i) }));
    }
  }

  private setTabsNumber(): void {
    const tabsAmountByConfig = this.fields.tabsNumber ?? this.dynamicFormValue[this.fields.tabsNumberField];
    const tabsAmountByCondition = this.id
      ? this.getPlaceholderMetricsLength()
      : this.dynamicFormValue['metrics']?.length || 1;

    this.tabsNumber = this.isPieDonutBarChart ? tabsAmountByCondition : tabsAmountByConfig;
  }

  private getPlaceholdersMetadata(): void {
    this.store
      .select(placeholdersMetadata)
      .pipe()
      .subscribe(data => (this.placeholdersMetadata = data));
  }

  private getPlaceholderMetricsLength(): number {
    const metadata = this.placeholdersMetadata.find(metadata => metadata.id === this.id);

    return metadata?.metrics?.length;
  }

  private setActiveButton(index: number, compareKay?: boolean): void {
    this.configurationButtons = this.configurationButtons.map((item, i) => {
      return {
        label: this.chartMetrics ? `Metric ${i + 1}` : `${i + 1}`,
        key: i,
        classes: 'secondary',
        active: this.configurationButtons.length === 1 ? true : index === (compareKay ? item.key : i),
      };
    });
  }

  private updateValidationAndFieldsOfForm(activeKey: number): void {
    const data = this.configurationsData.filter(configuration => configuration.order !== activeKey);

    this.updateDynamicForm(data);
    this.removeFormValidation(activeKey);
  }

  private updateDynamicForm(data?: TableColumn[]): void {
    this.store.dispatch(
      Actions.updateDynamicFormFields({
        fields: {
          [this.fields.allTabsField]: data,
        },
      })
    );
  }

  private removeFormValidation(activeKey: number): void {
    this.store.dispatch(
      Actions.removeFormValidation({
        formId: activeKey,
      })
    );
  }
}
