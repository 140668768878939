import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash-es';

@Pipe({ name: 'planPersentage' })
export class PlanPersentage implements PipeTransform {
  public transform(
    amount: string | number,
    decimals: string | number,
    noValueLabel: string,
    isCustomMetric?: boolean
  ): string {
    if (!amount || !_.isFinite(Number(amount))) {
      return noValueLabel;
    }

    const decimalFormat = isCustomMetric ? `1.2-2` : `1.0-${decimals}`;

    return new DecimalPipe('en-US').transform(amount, decimalFormat) + '%';
  }
}
