import { AfterViewInit, Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { SelectedData } from '@core/model';

@Component({
  selector: 'ep-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss'],
})
export class ProductSelectorComponent implements AfterViewInit {
  @Output() currentValueChange = new EventEmitter<SelectedData>();

  @ViewChild('container') container: ElementRef;

  template: string;
  id: string;
  selectedValue: number;
  title: string;

  private cards: HTMLElementTagNameMap[];

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.cards = this.container.nativeElement.querySelectorAll('.product-selector-card') || [];
    this.setListener();
  }

  private select(index: number): void {
    this.selectedValue = index;
    this.currentValueChange.emit({ value: { uiId: this.id, value: index }, name: this.title });

    this.cards.forEach((card: HTMLElementTagNameMap, i: number) => {
      if (index == i) {
        this.renderer.addClass(card, 'selected');
      } else {
        this.renderer.removeClass(card, 'selected');
      }
    });
  }

  private setListener(): void {
    const selectedValue = this.cards[this.selectedValue] ? this.selectedValue : 0;

    this.cards.forEach((card: HTMLElementTagNameMap, index: number) => {
      this.renderer.listen(card, 'click', () => this.select(index));

      if (index === selectedValue) {
        this.renderer.addClass(card, 'selected');
      }
    });
  }
}
