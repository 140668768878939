import { SortDirections, TableHeaderColumn } from '@shared/models';

export const TableColumns: TableHeaderColumn[] = [
  {
    sortable: false,
    key: '',
    title: '',
  },
  {
    sortable: true,
    key: 'presentation_name',
    title: 'Name',
  },
  {
    sortable: true,
    key: 'client',
    title: 'Client',
  },
  {
    sortable: true,
    key: 'created_by',
    title: 'Created By',
  },
  {
    sortable: true,
    key: 'update_date',
    title: 'Updated',
  },
];

export const DefaultSortState = {
  ORDER_BY: 'update_date',
  DIRECTION: SortDirections.DESC,
};

export const DefaultPaginationState = {
  itemsPerPage: 5,
  currentPage: 1,
  isManyType: false,
  hasNextPage: true,
  totalElements: null,
};
