export const CUSTOM_PAGE_CONFIGS_SESSION_STORAGE_KEY = 'custom-page-configs';

export const COMPILE_SETTINGS = [
  'metadata.placeholderProductSelectorHtml',
  'metadata.placeholderSelectedProduct',
  'metadata.placeholderSelectedValue',
  'metadata.placeholderDefaultValue',
  'metadata.metrics.[?].metricTitle',
  'metadata.metrics.[?].color',
  'metadata.metrics.[?].metricTitleForPieOrDonutChart',
  'metadata.xAxisSourceStart',
  'metadata.xAxisSourceEnd',
  'metadata.linesStartFrom',
  'metadata.linesEndsOn',
  'metadata.columns.[?].header',
  'metadata.columns.[?].body',
  'metadata.columns.[?].hideFormula',
  'metadata.apiUrl',
  'metadata.apiCallBody',
  'metadata.placeholderText',
  'customFields.htmlBody',
  'metadata.metrics.[?].productIndex',
  'metadata.productIndex',
  'metadata.tabs.[?].HTMLForTabs',
  'metadata.placeholderSelectedTab',
  'metadata.dropdownOptions.[?].value',
  'metadata.defaultOption',
  // 'metadata.defaultProductSelector',
  'metadata.selectedProductIndex',
  'metadata.highlightedRowNumber',
  'metadata.xAxisYearHighlight',
  'metadata.defaultPinValue',
  'metadata.xAxisName',
  'metadata.yAxisName',
  'metadata.additionalYAxisName',
];

export const CALCULATED_SETTINGS = [
  {
    path: 'metadata.formula',
  },
  {
    path: 'metadata.description',
  },
  {
    path: 'metadata.columns.[?].body',
  },
];

export const ADDITIONAL_DATA_INSERT_TYPES = ['productsDescription'];
export const READONLY_PRODUCT_FIELDS = ['id', 'order'];
export const ANCHOR_CLASS = 'route-link-to-other-page';
export const LINK_INTERPOLATION = '{{presentation.presentationInitPage}}/{{presentation.id}}/{{presentation.viewType}}';
export const PrecompilePaths = [
  'selectedDropdownIndex',
  'selectedProductIndex',
  'dropdowns',
  'productSelector',
  'dropdownOptions',
];
