import { findIndex } from 'lodash-es';

import { ActionTypes, disclosureText } from './setup.constant';
import { ActionType } from '../../../reducers';
import { CustomPagesConfig, SetupState } from '@shared/models';

export const initialState: SetupState = {
  disclosureText,
  error: false,
  loading: false,
  plans: {
    selectedPlans: [],
    loading: false,
    loaded: false,
  },
  selectedPageId: null,
  selectedPage: null,
  colorScheme: [],
  singleViewTabs: [],
  endPages: {
    loading: false,
    loaded: false,
    data: [],
  },
  salesConcepts: {
    pendingRequests: false,
    loading: false,
    loaded: false,
    modalAvailable: false,
    count: null,
    data: [],
  },
  dependentPages: {
    loading: false,
    loaded: false,
    salesConceptDependentLoaded: false,
    data: [],
  },
  dependentPagesConfig: {
    loading: false,
    loaded: false,
    data: <{ presentationId: number; configs: CustomPagesConfig[] }>{},
  },
  navBarCustomPages: {
    loading: false,
    loaded: false,
    data: { fullData: [], salesConcepts: [], dependentPages: [], endPages: [] },
  },
};

export function reducer(state = initialState, action: ActionType): SetupState {
  switch (action.type) {
    case ActionTypes.SetupDisclosureTextUpdateSuccess: {
      return {
        ...state,
        disclosureText: action.payload,
      };
    }

    case ActionTypes.ResetSetupState: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.ChangeSelectedPlansValues: {
      if (action.payload && Array.isArray(action.payload)) {
        return {
          ...state,
          plans: {
            ...state.plans,
            selectedPlans: action.payload.map(item => ({ ...item })),
          },
        };
      }

      return state;
    }

    case ActionTypes.ChangeSelectedPage: {
      return {
        ...state,
        selectedPage: action.payload,
      };
    }

    case ActionTypes.SetSetupLoading: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case ActionTypes.ChangeSelectedPageId: {
      return {
        ...state,
        selectedPageId: action.payload,
      };
    }

    case ActionTypes.AddSelectedPlanValues: {
      if (action.payload && Array.isArray(action.payload) && action.payload.length) {
        const statePlans = state.plans.selectedPlans.map(item => ({
          ...item,
        }));
        const plans = action.payload.map(item => ({ ...item }));

        for (let index = 0; index < plans.length; index++) {
          const addedPlan = plans[index];
          const statePlanIndex = statePlans.findIndex(
            plan => plan.planId === addedPlan.planId && plan.visualizationUiId === addedPlan.visualizationUiId
          );

          if (statePlanIndex > -1) {
            statePlans[statePlanIndex] = { ...addedPlan };
          } else {
            statePlans.push(addedPlan);
          }
        }

        return {
          ...state,
          plans: {
            ...state.plans,
            selectedPlans: statePlans,
          },
        };
      }

      return state;
    }

    case ActionTypes.ResetSelectedPlansValues: {
      return {
        ...state,
        plans: {
          ...state.plans,
          selectedPlans: [],
        },
      };
    }

    case ActionTypes.ShowSelectedPlan: {
      const { planId, show, configKey } = action.payload;
      const selectedPlans = state.plans.selectedPlans.map(item => ({
        ...item,
      }));
      const plan = selectedPlans.find(item => item.planId === planId && item.visualizationUiId === configKey);

      if (plan) {
        plan.show = show;
      }

      return {
        ...state,
        plans: {
          ...state.plans,
          selectedPlans,
        },
      };
    }

    case ActionTypes.InfoShowSelectedPlan: {
      const { planId, infoShow } = action.payload;
      const selectedPlans = state.plans.selectedPlans.map(item => ({
        ...item,
      }));
      const plan = selectedPlans.find(item => item.planId === planId);

      if (plan) {
        plan.isShownInfo = infoShow;
      }

      return {
        ...state,
        plans: {
          ...state.plans,
          selectedPlans,
        },
      };
    }

    case ActionTypes.SetPlanLoadingPending: {
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: true,
          loaded: false,
        },
      };
    }

    case ActionTypes.SetPlanLoadingSuccess: {
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          loaded: true,
        },
      };
    }

    case ActionTypes.SetPlanLoadingFailure: {
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          loaded: false,
        },
      };
    }

    case ActionTypes.ColorSchemeLoadSuccess: {
      return {
        ...state,
        colorScheme: action.payload,
      };
    }

    case ActionTypes.ColorSchemeLoadFailure: {
      return {
        ...state,
        colorScheme: state.colorScheme,
        error: action.error,
      };
    }

    case ActionTypes.ColorSchemeLoadPending: {
      return {
        ...state,
        colorScheme: state.colorScheme,
      };
    }

    case ActionTypes.SetSingleViewTabs: {
      return {
        ...state,
        singleViewTabs: action.payload,
      };
    }

    case ActionTypes.GetEndPagesLoadingPending: {
      return {
        ...state,
        endPages: {
          ...state.endPages,
          loading: true,
          loaded: false,
        },
      };
    }

    case ActionTypes.GetSalesConceptsLoadingPending: {
      return {
        ...state,
        salesConcepts: {
          ...state.salesConcepts,
          loading: true,
          loaded: false,
        },
      };
    }

    case ActionTypes.GetSalesConceptsLoadingSuccess: {
      const pages = [...state.salesConcepts.data];
      action.payload.forEach(item => {
        const index = findIndex(pages, { config: { uiId: item.config.uiId } });
        index === -1 ? pages.push(item) : (pages[index] = item);
      });

      return {
        ...state,
        salesConcepts: {
          data: [...pages],
          salesConceptDependentLoaded: false,
          loading: false,
          loaded: true,
          count: state.salesConcepts.count,
        },
      };
    }

    case ActionTypes.GetSalesConceptsCountLoadingSuccess: {
      return {
        ...state,
        salesConcepts: {
          ...state.salesConcepts,
          count: action.payload,
        },
      };
    }

    case ActionTypes.ResetSalesConcepts: {
      return {
        ...state,
        salesConcepts: {
          ...initialState.salesConcepts,
        },
      };
    }

    case ActionTypes.GetSalesConceptLoadingSuccess: {
      return {
        ...state,
        salesConcepts: {
          data: [
            ...state.salesConcepts.data.filter(
              salesConcept => salesConcept.config.uiId !== action.payload[0]?.config.uiId
            ),
            ...action.payload,
          ],
          loading: false,
          loaded: true,
          count: state.salesConcepts.count,
          pendingRequests: state.salesConcepts.pendingRequests,
        },
      };
    }

    case ActionTypes.SalesConceptModalRequestsPending: {
      return {
        ...state,
        salesConcepts: {
          ...state.salesConcepts,
          pendingRequests: true,
        },
      };
    }

    case ActionTypes.SalesConceptModalRequestsSuccess: {
      return {
        ...state,
        salesConcepts: {
          ...state.salesConcepts,
          pendingRequests: false,
        },
      };
    }

    case ActionTypes.GetEndPageLoadingSuccess: {
      return {
        ...state,
        endPages: {
          data: [
            ...state.endPages.data.filter(endPage => endPage.config.uiId !== action.payload[0]?.config.uiId),
            ...action.payload,
          ],
          loading: false,
          loaded: true,
        },
      };
    }

    case ActionTypes.GetSalesConceptsLoadingFailure: {
      return {
        ...state,
        salesConcepts: {
          ...state.salesConcepts,
          loading: false,
          loaded: false,
        },
      };
    }

    case ActionTypes.SalesConceptsModalAvailable: {
      return {
        ...state,
        salesConcepts: {
          ...state.salesConcepts,
          modalAvailable: action.payload,
        },
      };
    }

    case ActionTypes.GetEndPagesLoadingSuccess: {
      return {
        ...state,
        endPages: {
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
    }

    case ActionTypes.GetEndPagesLoadingFailure: {
      return {
        ...state,
        endPages: {
          ...state.endPages,
          loading: false,
          loaded: false,
        },
      };
    }

    case ActionTypes.ResetEndPages: {
      return {
        ...state,
        endPages: {
          ...initialState.endPages,
        },
      };
    }

    case ActionTypes.ResetDisclosure: {
      return {
        ...state,
        disclosureText: initialState.disclosureText,
      };
    }

    case ActionTypes.GetDependentPagesLoadingPending: {
      return {
        ...state,
        dependentPages: {
          ...state.dependentPages,
          salesConceptDependentLoaded: false,
          loading: true,
          loaded: false,
        },
      };
    }

    case ActionTypes.GetDependentPagesLoadingSuccess: {
      return {
        ...state,
        dependentPages: {
          data: action.payload,
          salesConceptDependentLoaded: false,
          loading: false,
          loaded: true,
        },
      };
    }

    case ActionTypes.GetSalesConceptDependentPagesLoadingSuccess: {
      return {
        ...state,
        dependentPages: {
          ...state.dependentPages,
          salesConceptDependentLoaded: true,
        },
      };
    }

    case ActionTypes.GetDependentPageLoadingSuccess: {
      const pages = [...state.dependentPages.data];
      action.payload.forEach(item => {
        const index = findIndex(pages, {
          config: { uiId: item.config.uiId },
          parentUiId: item.parentUiId,
        });
        index === -1 ? pages.push(item) : (pages[index] = item);
      });

      return {
        ...state,
        dependentPages: {
          ...state.dependentPages,
          data: [...pages],
          loading: false,
          loaded: true,
        },
      };
    }

    case ActionTypes.GetDependentPagesLoadingFailure: {
      return {
        ...state,
        dependentPages: {
          ...state.dependentPages,
          salesConceptDependentLoaded: false,
          loading: false,
          loaded: false,
        },
      };
    }

    case ActionTypes.ResetDependentPages: {
      return {
        ...state,
        dependentPages: {
          ...initialState.dependentPages,
        },
      };
    }

    case ActionTypes.GetDependentPagesConfigLoadingPending: {
      return {
        ...state,
        dependentPagesConfig: {
          ...state.dependentPagesConfig,
          loading: true,
          loaded: false,
        },
      };
    }

    case ActionTypes.GetDependentPagesConfigLoadingSuccess: {
      return {
        ...state,
        dependentPagesConfig: {
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
    }

    case ActionTypes.GetDependentPagesConfigLoadingFailure: {
      return {
        ...state,
        dependentPagesConfig: {
          ...state.dependentPagesConfig,
          loading: false,
          loaded: false,
        },
      };
    }

    case ActionTypes.ResetDependentPagesConfig: {
      return {
        ...state,
        dependentPagesConfig: {
          ...initialState.dependentPagesConfig,
        },
      };
    }

    case ActionTypes.UpdatePinValueOnEachChartInsert: {
      return {
        ...state,
        dependentPagesConfig: {
          data: { ...action.payload },
          loading: false,
          loaded: true,
        },
      };
    }

    case ActionTypes.GetNavbarCustomPagesLoadingSuccess: {
      return {
        ...state,
        navBarCustomPages: {
          data: {
            ...state.navBarCustomPages.data,
            ...action.payload,
          },
          loading: false,
          loaded: true,
        },
      };
    }

    case ActionTypes.GetNavbarCustomPagesLoadingFailure: {
      return {
        ...state,
        navBarCustomPages: {
          ...state.navBarCustomPages,
          loading: false,
          loaded: false,
        },
      };
    }

    case ActionTypes.GetNavbarCustomPagesLoadingPending: {
      return {
        ...state,
        navBarCustomPages: {
          ...state.navBarCustomPages,
          loading: true,
          loaded: false,
        },
      };
    }

    case ActionTypes.ResetNavbarCustomPages: {
      return {
        ...state,
        navBarCustomPages: {
          ...initialState.navBarCustomPages,
        },
      };
    }

    default: {
      return state;
    }
  }
}
