import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash-es';

@Pipe({
  name: 'orderObjectBy',
})
export class OrderObjectBy implements PipeTransform {
  public transform(input: any, attribute: string) {
    if (!_.isObject(input)) {
      return input;
    }

    const array = [];

    for (const objectKey in input) {
      if (input.hasOwnProperty(objectKey)) {
        array.push((input as any)[objectKey]);
      }
    }

    array.sort((a, b) => {
      a = parseInt(a[attribute], 10);
      b = parseInt(b[attribute], 10);

      return a - b;
    });

    return array;
  }
}
