import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ep-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() numberOfDependentPages: number;

  skeletons: number[] = [];

  ngOnInit(): void {
    this.skeletons = Array(this.numberOfDependentPages).map(i => i);
  }
}
