<ac-modal-header class="container-header" (close)="close()">
  <p class="title">Choose Presentation</p>
</ac-modal-header>

<ac-modal-body class="container-body">
  <div class="container-search">
    <p>Choose the presentation you want to load data from</p>
    <se-form-input
      class="custom-page-presentation-list-search"
      type="search"
      placeholder="Search Presentation"
      [debounceTime]="1000"
      [handleFocusChange]="false"
      (debounceChange)="search($event)">
    </se-form-input>
  </div>

  <div class="container-table">
    <table *ngIf="!loading && presentations.length > 0">
      <tr class="table-header-row">
        <ng-container *ngFor="let headerColumn of tableHeaderColumn">
          <th
            epSort
            [sortable]="headerColumn.key"
            [currentSortable]="currentOrder.ORDER_BY"
            [currentDirection]="currentOrder.DIRECTION"
            (sortBy)="setOrderByValue($event)"
            class="cell header-cell {{ headerColumn.key }}" [class.sortable]="headerColumn.sortable">
            <div class="wrap-sort">
              <span class="title">{{ headerColumn.title }}</span>
              <span class="sort-icon"></span>
            </div>
          </th>
        </ng-container>
      </tr>
      <tr class="table-body-row"
          *ngFor="let presentation of presentations; trackBy: trackById"
          [ngClass]="{ disable: presentation.carrierPlansCount < minProductOfCustomPage }"
          [title]="presentation.carrierPlansCount < minProductOfCustomPage ? message : ''"
          (click)="select(presentation)">
          <!-- radio button column -->
          <th class="cell body-cell btn-radio">
            <se-radio-button
              [checked]="selectedPresentation?.id === presentation.id"
              [disabled]="presentation.carrierPlansCount < minProductOfCustomPage">
            </se-radio-button>
          </th>
          <!-- name column -->
          <th class="cell body-cell">{{ presentation.name }}</th>
          <!-- client column -->
          <th class="cell body-cell">{{ presentation.clientname }}</th>
          <!-- created by column -->
          <th class="cell body-cell">{{ presentation.createdByName }}</th>
          <!-- updated column -->
          <th class="cell body-cell updated">{{ presentation.update_date | date: 'MM/dd/yyyy' }}</th>
      </tr>
    </table>

    <div *ngIf="loading" class="container-loader">
      <ac-loader></ac-loader>
    </div>

    <se-pager
      *ngIf="!loading && presentations.length > 0"
      [options]="pagination"
      [pagesSliceCount]="5"
      (onPageChange)="onPageChange($event)"
    ></se-pager>

    <h3 class="no-data-message" *ngIf="!loading && presentations.length === 0">No items to display</h3>
  </div>
</ac-modal-body>

<ac-modal-footer class="container-footer">
  <se-button (click)="close()" class="secondary bnt-cancel">Cancel</se-button>
  <se-button class="btn-select-presentation" [disabled]="!selectedPresentation?.id" (click)="selectPresentation()">
    Select Presentation
  </se-button>
</ac-modal-footer>
