import { Component, Output, EventEmitter } from '@angular/core';
import { SelectedData, TabsValue } from '@core/model';

@Component({
  selector: 'ep-tab-insert',
  templateUrl: './tab-insert.component.html',
  styleUrls: ['./tab-insert.component.scss'],
})
export class TabInsertComponent {
  @Output() currentValueChange = new EventEmitter<SelectedData>();

  id: string;
  selectedTab: number;
  tabs: TabsValue[];
  tabTitle: string;

  constructor() {}

  select(index: number): void {
    this.selectedTab = index;
    this.currentValueChange.emit({ value: { uiId: this.id, value: index }, name: this.tabTitle });
  }
}
