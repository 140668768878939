<div class="support-info a-app-company-home-page-icons-color">
  <div>{{ supportInfo?.message }}</div>
  <div>{{ supportInfo?.phone }}</div>
  <div>
    <a href="mailto:{{ supportInfo?.email }}">{{ supportInfo?.email }}</a>
  </div>
  <div>
    <a href="{{ supportInfo?.supportResourcesLink }}" target="_blank">
      Support Resources
    </a>
    <i class="fa fa-question-circle"></i>
  </div>
</div>
