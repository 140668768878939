import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonSeModule } from '@se/common';

import { MetricsListComponent } from './metrics-list.component';

const components = [MetricsListComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, ReactiveFormsModule, CommonSeModule],
})
export class MetricsListModule {}
