<section class="metrics-list">
  <div class="search-container">
    <se-form-input
      [formControl]="searchControl"
      placeholder="Search Metric"
    ></se-form-input>
    <span class="fa fa-search"></span>
  </div>
  <ul class="list" *ngIf="filteredMetrics.length">
    <li
      class="item"
      *ngFor="let metric of filteredMetrics"
      [class.selected]="metric.key === selectedMetric?.key"
      [class.disabled]="metricsSettings[metric.key]?.disabled"
      (click)="onSelect(metric, metricsSettings[metric.key]?.disabled)"
      [title]="metricsSettings[metric.key]?.disabled ? disabledMetricHint : ''"
    >
      {{ metric.title }}
    </li>
  </ul>

  <p class="no-items-message" *ngIf="!filteredMetrics.length">
    No items to display
  </p>
</section>

<footer class="btn-block">
  <se-button class="secondary" (click)="cancel()">Cancel</se-button>
  <se-button [disabled]="!selectedMetric" (click)="addMetric()">
    Add Metric
  </se-button>
</footer>
