import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonSeModule } from '@se/common';

import { ConfirmationModalComponent } from './confirmation-modal.component';

const components = [ConfirmationModalComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, CommonSeModule],
})
export class ConfirmationModalModule {}
