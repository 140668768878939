import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';

import { GAService } from '@shared/services';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ensight-note-input',
  templateUrl: './note-input.component.html',
  styleUrls: ['./note-input.component.scss'],
})
export class NoteInputComponent implements OnDestroy, OnChanges {
  @Input() note: string | number;
  @Input() disabled = false;
  @Input() isNavBar = false;
  @Output() changedNote = new EventEmitter<string>();

  saveMode = false;
  editMode = false;
  private pureNote: string | number;

  @ViewChild('noteInput', { static: false }) private noteInput: ElementRef;

  constructor(private cdr: ChangeDetectorRef, private gaService: GAService) {}

  ngOnChanges(): void {
    this.updatePureNote(this.note);
  }

  updatePureNote(note: string | number): void {
    this.note = note || '';
    this.pureNote = this.note;
    this.saveMode = false;

    if (this.noteInput && this.noteInput.nativeElement) {
      this.noteInput?.nativeElement.blur();
    }

    this.cdr.markForCheck();
  }

  onEditProductNote(edit: boolean, event?: Event): void {
    if (!this.saveMode) {
      event?.stopPropagation();
      this.editMode = this.disabled ? false : edit;

      if (this.editMode) {
        setTimeout(() => {
          this.noteInput?.nativeElement?.focus();
        });
        this.cdr.markForCheck();
      }
    }
  }

  onSaveNote(event: Event): void {
    event.stopPropagation();

    if (this.saveMode) {
      this.saveMode = false;
      this.changedNote.emit((this.note as string).trim());
      this.gaService.sendEditProductNoteEvent();
    }

    this.editMode = false;
  }

  noteChanged(note: string | number): void {
    this.saveMode = this.pureNote !== note;
  }

  onResetChanges(): void {
    this.note = this.pureNote;
    this.saveMode = false;
    this.editMode = false;
  }

  onInputClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  ngOnDestroy() {
    if (this.changedNote) {
      this.changedNote.unsubscribe();
    }
  }
}
