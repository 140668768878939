import { PIN_LINE_HEIGHT_BAR_CHART } from '@shared/components/chart/chart.constants';

export const CHART_OPTIONS = {
  chart: {
    stacked: true,
    height: 510,
    margin: {
      left: 90,
      top: 80,
    },
    barWidth: 90,
    pinLineHeight: PIN_LINE_HEIGHT_BAR_CHART,
  },
};

export const CHART_OPTIONS_PDF = {
  chart: {
    height: 480,
    margin: { top: 50 },
    barWidth: 90,
  },
};

export const AGE_YEAR_RECALCULATED_SHORTFALL_FIELDS = ['retirementAge', 'lastYearOfRetirement', 'ssStartAge'];

export const RETIREMENT_NEED_POST_TAX_KEY = 'retirement_need_post_tax';
export const LIFE_INSURANCE_DISTRIBUTION_TAX_FREE_POLICY_KEY = 'life_insurance_distribution_tax_free_policy';

export const DEFAULT_RETIREMENT_COLORS = [
  { color: '#33c972' },
  { color: '#4472c4' },
  { color: '#B00DC4' },
  { color: '#f85c1d' },
];
