import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ensightImageSetter]',
})
export class ImageSetterDirective implements OnInit {
  // @Input() ensightImageSetter: StyleItem;
  @Input() ensightImageSetter: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  public ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.ensightImageSetter.value);
  }
}
