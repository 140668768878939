<section class="controls-section">
  <div class="control-container">
    <label class="label">Tab Name</label>

    <se-form-input
      [formControl]="tabNameControl"
      placeholder="Untitled tab"
    ></se-form-input>

    <ng-container
      *ngIf="
        tabNameControl.invalid &&
        (tabNameControl.dirty || tabNameControl.touched)
      "
    >
      <span *ngIf="tabNameControl.errors?.required" class="error-message">
        Value is required
      </span>
      <span *ngIf="tabNameControl.errors?.maxlength" class="error-message">
        Please enter up to 50 characters
      </span>
      <span *ngIf="tabNameControl.errors?.tabsQuantity" class="error-message">
        Only {{ maxTabsQuantity }} or less tabs are allowed
      </span>
      <span *ngIf="tabNameControl.errors?.uniqueTabTitle" class="error-message">
        The tab name is not unique
      </span>
    </ng-container>
  </div>
</section>

<footer class="btn-block">
  <se-button class="secondary" (click)="cancel()">Cancel</se-button>

  <se-button
    *ngIf="editMode"
    [disabled]="!tabNameControl?.valid"
    (click)="editTab()"
  >
    Confirm
  </se-button>

  <se-button
    *ngIf="createMode"
    [disabled]="!tabNameControl?.valid"
    (click)="createTab()"
  >
    Add Tab
  </se-button>
</footer>
