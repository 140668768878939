<ac-modal-header (close)="close()">
  <p class="title">Create Custom Page Copy</p>
</ac-modal-header>

<ac-modal-body>
  <div class="form-container">
    <label>Custom Page Name</label>
    <se-form-input type="text" [formControl]="formValue"></se-form-input>
  </div>
  <div class="form-container">
    <label>Reuse inserts instead of duplication</label>
    <se-checkbox [checked]="isInsertReuseEnabled" (onToggle)="onInsertReuseToggle()"></se-checkbox>
  </div>
</ac-modal-body>

<ac-modal-footer>
  <div class="container-btn">
    <se-button
      class="btn-copy"
      type="submit"
      [class]="isLoading ? 'secondary' : 'main'"
      [loading]="isLoading"
      [disabled]="!formValue.valid"
      (click)="onCopyCustomPage()"
    >
      Copy
    </se-button>
    <se-button class="secondary" (click)="close()">Cancel</se-button>
  </div>
</ac-modal-footer>
