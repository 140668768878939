import { Component, EventEmitter, Output, Input, ChangeDetectionStrategy, OnInit, ViewChild } from '@angular/core';

import { css } from 'js-beautify';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

import { StyleModal } from '@shared/models';

@Component({
  selector: 'ep-style-edit',
  templateUrl: './style-edit.component.html',
  styleUrls: ['./style-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyleEditComponent implements OnInit {
  @Input() data: StyleModal = { slyleString: `element {}` };

  @Output() output = new EventEmitter<unknown>();

  @ViewChild('codeMirror') private codeEditorCmp: CodemirrorComponent;

  options = {
    theme: 'default',
    scrollbarStyle: null,
    lineNumbers: true,
    lineWrapping: true,
    autoCloseBrackets: true,
    foldGutter: true,
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
    matchBrackets: true,
    lint: true,
    mode: 'css',
    extraKeys: { 'Ctrl-Space': 'autocomplete' },
  };
  codeModel: string;

  ngOnInit(): void {
    this.codeModel = css(this.data.slyleString, { indent_size: 2 });
    setTimeout(() => this.codeEditorCmp.codeMirror.refresh(), 1000);
  }

  applyStyles(): void {
    const result = this.codeModel && this.codeModel.replace(/(?:\r\n|\r|\n)/g, '');
    this.output.emit({ success: true, result });
  }

  cancel(): void {
    this.output.emit({ success: false });
  }
}
