export const Tabs = [
  { key: 'editInfo', title: 'Insured Person Info' },
  { key: 'editCsvData', title: 'Product Data' },
];
export const NO_INCOMING_PAYLOAD = 'Payload data is missing for the presentation.';
export const INCOMING_PAYLOAD_ERROR = "Payload data can't be retrieved.";

export const ANNUITY_PRODUCT_TYPE = 'ANNUITY';

//TODO: this is hard coded values, will be removed once the DT story is done
//See [comments] section in https://assuranceapp.atlassian.net/browse/DAT-7396
export const PRODUCT_TYPES_BY_INDEX = new Map([
  [4, 'VUL'],
  [16, 'LTC'],
  [1001, 'INFORCE'],
]);

export const ANNUITY_PRODUCT_TYPES_BY_INDEX = new Map([
  [20, ANNUITY_PRODUCT_TYPE],
  [9, ANNUITY_PRODUCT_TYPE],
  [58, ANNUITY_PRODUCT_TYPE],
  [11, ANNUITY_PRODUCT_TYPE],
  [56, ANNUITY_PRODUCT_TYPE],
  [301, ANNUITY_PRODUCT_TYPE],
  [10, ANNUITY_PRODUCT_TYPE],
  [57, ANNUITY_PRODUCT_TYPE],
]);
