import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagePaginationComponent } from './page-pagination.component';
// import { StyleEditorModule } from '../../../style-editor/style.editor.module';

@NgModule({
  declarations: [PagePaginationComponent],
  exports: [PagePaginationComponent],
  imports: [CommonModule],
})
export class PagePaginationModule {}
