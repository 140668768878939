import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Userpilot } from 'userpilot';

import { ga as gaInstance } from './assets/ga';
import { AppModule } from './app/app.module';
import { isPDF } from '@core/utils';
import { CSSLint } from 'csslint';
import { JSHINT } from 'jshint';
import 'codemirror/mode/css/css';
import 'codemirror/addon/hint/css-hint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/lint/css-lint';
import 'codemirror/addon/lint/lint';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/dialog/dialog';
import * as DiffMatchPatch from 'diff-match-patch';

(window as any).CSSLint = CSSLint;
(window as any).JSHINT = JSHINT;

(window as any).diff_match_patch = DiffMatchPatch.diff_match_patch;
(window as any).DIFF_DELETE = DiffMatchPatch.DIFF_DELETE;
(window as any).DIFF_INSERT = DiffMatchPatch.DIFF_INSERT;
(window as any).DIFF_EQUAL = DiffMatchPatch.DIFF_EQUAL;

if (localStorage.getItem('env') === 'production') {
  enableProdMode();
}

const gaV4Code = localStorage.getItem('gaV4Code');
const userPilotCode = localStorage.getItem('userPilotCode');

if (userPilotCode) {
  Userpilot.initialize(userPilotCode);
}

if (gaV4Code && !isPDF) {
  gaInstance.init4v(gaV4Code);
} else {
  (window as any).gtag = () => null;
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.log(err));
