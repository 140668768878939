<!--TODO: need to refact-->

<ng-container *ngIf="!hideHeader">
  <app-header [showUserMenu]="showUserMenu"></app-header>
</ng-container>

<section class="h-100" [ngClass]="{ 'shared-presentation': isSharedPresentation, 'aapp-main-container': !hideHeader }">
  <ep-loader [loading]="loading$ | async"></ep-loader>
  <router-outlet
    (deactivate)="deactivateRouter()"
    (activate)="activeRouter()"
  ></router-outlet>
</section>
