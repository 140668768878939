import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService } from '@se/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ensight-reset-password',
  styleUrls: ['../login.scss'],
  templateUrl: 'reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  public verificationKey: string;

  constructor(private route: ActivatedRoute, private router: Router, private alertService: AlertService) {}

  public ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe(() => {
      this.verificationKey = this.route.snapshot.queryParams.key;
    });
  }
  // TODO: need to change interface
  // public onResetSuccess(response: { success: boolean; data: { message: string; error: { message: string[] } } }): void {
  public onResetSuccess(response: any): void {
    if (response.success) {
      this.alertService.openAlert({
        type: 'neutral',
        body: 'Your password has been successfully reset.',
        autoClose: 5000,
      });
      this.router.navigate(['/login']);
    } else {
      this.alertService.openAlert({
        type: 'error',
        body: response.data?.error?.message?.toString() || response.data.message,
        autoClose: 5000,
      });
    }
  }
}
