export const COLUMNS_HEADERS_CONFIGS = [
  {
    title: 'Name',
    key: 'clientName',
    component: 'link',
    type: 'list',
    format: {
      type: null,
      dateFormat: null,
      alignment: 'LEFT',
      sortable: true,
      sortByDefault: null,
      splitter: null,
      decimalSplitter: null,
      digitInfo: null,
      currencyCode: null,
      symbolDisplay: null,
      customSymbol: null,
      sorted: false,
      direction: null,
      hideable: false,
      groupByColumn: false,
      groupable: false,
      subGroup: null,
      configPanelFormat: null,
      nowrap: true,
    },
    order: 1,
  },
  {
    title: 'Description',
    key: 'description',
    component: null,
    type: null,
    format: {
      type: null,
      dateFormat: null,
      alignment: 'LEFT',
      sortable: false,
      sortByDefault: null,
      splitter: null,
      decimalSplitter: null,
      digitInfo: null,
      currencyCode: null,
      symbolDisplay: null,
      customSymbol: null,
      sorted: false,
      direction: null,
      hideable: false,
      groupByColumn: false,
      groupable: false,
      subGroup: null,
      configPanelFormat: null,
      nowrap: false,
    },
    order: 2,
  },
  {
    title: 'Gender, Age',
    key: 'genderAndAge',
    component: null,
    type: 'list',
    format: {
      type: null,
      dateFormat: null,
      alignment: 'LEFT',
      sortable: false,
      sortByDefault: null,
      splitter: null,
      decimalSplitter: null,
      digitInfo: null,
      currencyCode: null,
      symbolDisplay: null,
      customSymbol: null,
      sorted: false,
      direction: null,
      hideable: false,
      groupByColumn: false,
      groupable: false,
      subGroup: null,
      configPanelFormat: null,
      nowrap: true,
    },
    order: 3,
  },
  {
    title: 'State',
    key: 'state',
    component: null,
    type: null,
    format: {
      type: null,
      dateFormat: null,
      alignment: 'LEFT',
      sortable: false,
      sortByDefault: null,
      splitter: null,
      decimalSplitter: null,
      digitInfo: null,
      currencyCode: null,
      symbolDisplay: null,
      customSymbol: null,
      sorted: false,
      direction: null,
      hideable: false,
      groupByColumn: false,
      groupable: false,
      subGroup: null,
      configPanelFormat: null,
      nowrap: false,
    },
    order: 4,
  },
  {
    title: 'Agent',
    key: 'agentName',
    component: null,
    type: null,
    format: {
      type: null,
      dateFormat: null,
      alignment: 'LEFT',
      sortable: true,
      sortByDefault: null,
      splitter: null,
      decimalSplitter: null,
      digitInfo: null,
      currencyCode: null,
      symbolDisplay: null,
      customSymbol: null,
      sorted: false,
      direction: null,
      hideable: false,
      groupByColumn: false,
      groupable: false,
      subGroup: null,
      configPanelFormat: null,
      nowrap: true,
    },
    order: 5,
  },
  {
    title: 'Created By',
    key: 'createdBy',
    component: null,
    type: null,
    format: {
      type: null,
      dateFormat: null,
      alignment: 'LEFT',
      sortable: true,
      sortByDefault: null,
      splitter: null,
      decimalSplitter: null,
      digitInfo: null,
      currencyCode: null,
      symbolDisplay: null,
      customSymbol: null,
      sorted: false,
      direction: null,
      hideable: false,
      groupByColumn: false,
      groupable: false,
      subGroup: null,
      configPanelFormat: null,
      nowrap: false,
    },
    order: 6,
  },
  {
    title: 'Updated',
    key: 'updatedDate',
    component: null,
    type: null,
    format: {
      type: 'date',
      dateFormat: 'MM/dd/yyyy',
      alignment: 'LEFT',
      sortable: true,
      sortByDefault: 'ASC',
      splitter: null,
      decimalSplitter: null,
      digitInfo: null,
      currencyCode: null,
      symbolDisplay: null,
      customSymbol: null,
      sorted: false,
      direction: null,
      hideable: false,
      groupByColumn: false,
      groupable: false,
      subGroup: null,
      configPanelFormat: null,
      nowrap: false,
    },
    order: 7,
  },
];
