import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import * as apiConstant from '../constant';
import { HttpErrorHandlerService, LocalStorageService } from '@core/service';

const UM_PATH = 'um/';
const PATHS_TO_SKIP = [
  UM_PATH,
  apiConstant.downloadPDF,
  apiConstant.csv,
  apiConstant.support,
  apiConstant.PolicyInForceRecords,
];

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let url = req.url;

    let headers = req.headers;

    if (sessionStorage.getItem('shared-token')) {
      headers = headers.set('shared-token', sessionStorage.getItem('shared-token'));
    }

    headers = headers
      .set('cacheSeconds', '0')
      .set('useExpiresHeader', 'true')
      .set('useCacheControlHeader', 'true')
      .set('useCacheControlNoStore', 'true')
      .set('Cache-Control', 'no-cache, no-store, must-revalidate')
      .set('Pragma', 'no-cache')
      .set('Expires', '0');

    try {
      new URL(req.url);
    } catch {
      url = `${this.localStorageService.getNotJSONData('apiHost')}${req.url}`;
    }

    const request = req.clone({
      url,
      headers: headers,
      withCredentials: true,
    });

    return next.handle(request).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse && !PATHS_TO_SKIP.some(path => new URL(event.url).pathname.includes(path))) {
          // TODO: refact, do not need to do this
          // https://assuranceapp.atlassian.net/browse/DAT-7140
          event = event.clone({
            body: {
              data: event.body,
              dataText: event.statusText,
              headers: event.headers,
              status: event.status,
              url: event.url,
            },
          });
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => this.httpErrorHandlerService.handleError(error))
    );
  }
}
