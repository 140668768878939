import { createAction, props } from '@ngrx/store';

import { CustomPage, Insert, ImageInfo } from '@shared/models';
import { InsertType, Placeholder } from '@core/model';

export const setDynamicFormOpen = createAction(
  '[Modals - Placeholders Wizzard] Set Dynamic Form Open',
  props<{ payload: boolean }>()
);

export const updateDynamicFormFields = createAction(
  '[Modals - Placeholders Wizard] Update Dynamic Form',
  props<{ fields: Record<string, unknown>; valid?: boolean }>()
);

export const resetDynamicFormFields = createAction('[Modals - Placeholders Wizard] Reset Dynamic Form');

export const resetInsertTypeStates = createAction('[Modals - Placeholders Wizard] Reset Insert Types State');

export const setInsertTypeStates = createAction(
  '[Modals - Placeholders Wizard] Set Insert Type States',
  props<{
    id: string;
    insertType: InsertType;
    activePageId?: string;
  }>()
);

export const nextState = createAction('[Modals - Placeholders Wizard] Next State', props<{ stepsToSkip: string[] }>());
export const backState = createAction('[Modals - Placeholders Wizard] Back State', props<{ stepsToSkip: string[] }>());

export const setPlaceholdersDataSuccess = createAction(
  '[Modals - Placeholders Wizard] Set Placeholders Data',
  props<{ data?: Insert[]; metadata: Placeholder[] }>()
);

export const setFormValidation = createAction(
  '[Modals - Placeholders Wizard] Set Form Valid',
  props<{ formId: number; isValid: boolean }>()
);

export const removeFormValidation = createAction(
  '[Modals - Placeholders Wizard] Remove Form Validation',
  props<{ formId: number }>()
);

export const resetFormValidation = createAction('[Modals - Placeholders Wizard] Reset Form Valid');

export const resetPlaceholderWizard = createAction('[Modals - Placeholders Wizard] Reset');

// image insert
export const setImage = createAction('[Modals - Placeholders Wizard] Set Image', props<{ imageData: ImageInfo }>());

export const selectImage = createAction('[Modals - Placeholders Wizard] Select Image', props<{ name: string }>());

export const deleteImage = createAction('[Modals - Placeholders Wizard] Delete Image', props<{ name: string }>());

export const loadedImage = createAction('[Modals - Placeholders Wizard] Loaded Image', props<{ name: string }>());

export const setImagesList = createAction(
  '[Modals - Placeholders Wizard] Set Images List',
  props<{ imagesData: ImageInfo[] }>()
);

export const resetImagesList = createAction('[Modals - Placeholders Wizard] Reset Images List');

export const persistImagesList = createAction(
  '[Modals - Placeholders Wizard] Persist Images List',
  props<{ id: string }>()
);

export const setCurrentCustomPage = createAction(
  '[Modals - Placeholders Wizard] Set Current Custom Page',
  props<{ currentCustomPage: CustomPage }>()
);

export const loadChooseCustomPagesList = createAction(
  '[Modals - Placeholders Wizard] Get Custom Pages list',
  props<{ queryParams: Record<string, string | string[] | boolean> }>()
);

export const loadChooseCustomPagesListSuccess = createAction(
  '[Modals - Placeholders Wizard] Set Custom Page',
  props<{ customPagesList: CustomPage[]; customPagesCount: number }>()
);

export const setCustomPage = createAction(
  '[Modals - Placeholders Wizard] Set Custom Page',
  props<{ customPage: CustomPage }>()
);

export const setInserts = createAction(
  '[Modals - Placeholders Wizard] Set selected inserts',
  props<{ inserts: Insert[] }>()
);
