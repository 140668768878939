import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AssuranceGridComponent } from './assurance-grid.component';
import { KeysPipe } from './forKeys.pipe';

@NgModule({
  declarations: [AssuranceGridComponent, KeysPipe],
  exports: [AssuranceGridComponent],
  imports: [CommonModule, FormsModule, BsDropdownModule.forRoot()],
})
// TODO: Temporary solutions
// TODO: Once assurance/grid will be migrated and all errors related to AOT compilations will be fixed this module will be removed
export class AssuranceGridModule {}
