import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableComponent } from './table.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';

@NgModule({
  declarations: [TableComponent],
  imports: [CommonModule, DirectivesModule, AssurencePipesModule],
  exports: [TableComponent],
})
export class TableModule {}
