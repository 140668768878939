import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { MetricType } from '@shared/constants/metric-type.enum';
import * as _ from 'lodash-es';

@Pipe({
  name: 'ifEmpty',
})
export class IfEmpty implements PipeTransform {
  public transform(input: any, type?: string, returnValue = false) {
    if (_.isUndefined(input) || input === null || (input.trim && input.trim() === '') || input === 'N/A') {
      return returnValue ? `<span class="table-item opacity-0">.</span>` : `<span class="table-item">N/A</span>`;
    }

    if (type === MetricType.currency && this.isNumeric(input)) {
      input = new CurrencyPipe('en-US').transform(input, 'USD', 'symbol', '1.0-0');
    }

    return returnValue ? input : `<span class="table-item">${input}</span>`;
  }

  private isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
}
