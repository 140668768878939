import { Inject, Injectable } from '@angular/core';

import { fromEvent, Observable, combineLatest } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';

import { DOCUMENT_TOKEN, WINDOW_TOKEN } from '@core/constant';
import { MappedSelection } from '../../models';

@Injectable()
export class SelectionService {
  private blockSelection = false;
  private selection: Selection;
  private range: Range;

  constructor(@Inject(WINDOW_TOKEN) private window: Window, @Inject(DOCUMENT_TOKEN) private document: Document) {}

  watchForEvents(element: Element): Observable<[Event, MouseEvent]> {
    return combineLatest([this.watchForSelectionReset(), this.watchForMouseDown(element)]);
  }

  watchForSelection(element: Element): Observable<MappedSelection> {
    return this.watchForEvent<MouseEvent>(element, 'mouseup').pipe(
      map(() => this.getSelection()),
      filter((selection: Selection) => selection.type === 'Range' && selection.toString() !== ''),
      delay(300),
      map((selection: Selection) => this.getMappedSelection(selection)),
      tap(() => (this.blockSelection = true))
    );
  }

  resetSelection(selection: Selection = this.selection): void {
    this.addRange(selection, this.document.createRange());

    this.selection = null;
    this.range = null;
    this.blockSelection = false;
  }

  addRange(selection: Selection = this.selection, range?: Range): void {
    selection.removeAllRanges();
    selection.addRange(range || this.range);
  }

  setEditedContent(content: string | HTMLElement, isTextNode?: boolean): void {
    const range = this.selection.getRangeAt(0);
    const text = isTextNode ? this.document.createTextNode(content as string) : content;

    range.deleteContents();
    range.insertNode(text as Node);
  }

  private watchForSelectionReset(): Observable<Event> {
    return this.watchForEvent(this.document, 'selectionchange').pipe(
      tap(() => {
        const selection = this.getSelection();

        if (!this.isSelectionFromSingleElement(selection) || this.isTextIncludeCompileString(selection)) {
          this.resetSelection(selection);
        }
      })
    );
  }

  private watchForMouseDown(element: Element): Observable<MouseEvent> {
    return this.watchForEvent<MouseEvent>(element, 'mousedown').pipe(
      tap((event: MouseEvent) => {
        if (event.detail > 1) {
          event.preventDefault();
        }
      })
    );
  }

  private getMappedSelection(selection: Selection): MappedSelection {
    const range = selection.getRangeAt(0);
    const domRect = range.getBoundingClientRect();
    const modalOffset = 8;

    this.selection = selection;
    this.range = range;

    return {
      modalPosition: { bottom: domRect.bottom + this.window.scrollY + modalOffset, left: domRect.left },
      text: selection.toString(),
    };
  }

  private watchForEvent<T = Event>(element: Element | Document, eventName: string): Observable<T> {
    return fromEvent(element, eventName).pipe(
      // @ts-ignore
      tap((event: T) => {
        if (this.blockSelection) {
          // @ts-ignore
          event.preventDefault();
        }
      }),
      filter(() => !this.blockSelection)
    );
  }

  private getSelection(): Selection {
    return this.window.getSelection();
  }

  private isSelectionFromSingleElement(selection: Selection): boolean {
    if (selection.rangeCount === 0 && selection.type !== 'Range') {
      return false;
    }

    const startElement = selection.getRangeAt(0).startContainer.parentNode;
    const endElement = selection.getRangeAt(0).endContainer.parentNode;

    return startElement === endElement;
  }

  private isTextIncludeCompileString(selection: Selection): boolean {
    const regExp = new RegExp(/{{(.*?)}}/, 'gi');
    let text = selection.toString();

    const startElement = selection.getRangeAt(0).startContainer.parentNode;

    if (startElement && startElement.textContent) {
      text = startElement.textContent.replace('$', '');
    }

    return regExp.test(text);
  }
}
