import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { AppState } from 'src/app/reducers';
import { getDependentPermissions } from '@ngrx-app/global.selectors';
import { getPresentationConfigs } from 'src/app/components/presentation/redux/configs/selectors';
import { getDependentPagesConfig } from 'src/app/components/presentation/setup/setup.selectors';
import { NavbarPagesService } from '@shared/services';
import { CustomPageService } from '../../../../components/presentation/setup/custom-page/custom-page.service';
import { CustomPageValues, NavbarCustomPagesData } from '@shared/models';

@Injectable()
export class UpdateNavbarPagesService {
  constructor(
    private customPageService: CustomPageService,
    private navbarPagesService: NavbarPagesService,
    private store: Store<AppState>
  ) {}

  fetchNavbarCustomPages(): Observable<[NavbarCustomPagesData, CustomPageValues[]]> {
    return combineLatest([
      this.store.select(getDependentPermissions),
      this.store.select(getPresentationConfigs),
      this.store.select(getDependentPagesConfig),
    ]).pipe(
      switchMap(([dependentPermissions, presentationConfigs, dependentPagesConfig]) => {
        const customPages = {
          endPages: dependentPermissions?.requiredEndPages || [],
          salesConcepts: dependentPermissions?.salesConcepts || [],
        };
        const commonUiIds = this.customPageService.getCommonUiIds(
          { uiId: customPages.salesConcepts },
          presentationConfigs
        );

        return this.navbarPagesService.getNavbarCustomPages(
          presentationConfigs,
          dependentPagesConfig.configs,
          customPages.endPages,
          commonUiIds
        );
      })
    );
  }
}
