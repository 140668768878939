<span *ngIf="type === 'simple'" [class.disabled]="disable" class="toggle-group toggle-container">
  <label *ngFor="let value of values">
    <span *ngIf="value.checked" [class]="value.checkedClass" aria-hidden="true"></span>
    <span *ngIf="!value.checked" [class]="value.uncheckedClass" aria-hidden="true"
    ></span>
    <input
      id="simple-toggle"
      [value]="value.key"
      type="radio"
      #checkElem
      [name]="name"
      [checked]="value.checked"
      (click)="toggleElement($event)"
    />
    <a class="label-value btn btn-link pp-action-button-link" *ngIf="value.label">
      {{ value.label }}
    </a>
  </label>
</span>
<span *ngIf="type === 'replace'" class="toggle-group">
  <se-toggler
    class="epv-switch"
    [class.loading]="loading"
    [readonly]="disable || readonly"
    #checkElem
    [checked]="checked"
    (onChange)="switchElement($event)"
    (click)="readonlyClick()"
  ></se-toggler>
</span>
