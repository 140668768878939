import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { DragulaModule, DragulaService } from 'ng2-dragula';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { DraggableListComponent } from './draggable-list/draggable-list.component';
import { DraggableTableFieldsComponent } from './draggable-table-fields/draggable-table-fields.component';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';

@NgModule({
  imports: [CommonModule, DragulaModule, TooltipModule, FormsModule, AssurencePipesModule],
  declarations: [DraggableListComponent, DraggableTableFieldsComponent],
  exports: [DraggableListComponent, DraggableTableFieldsComponent],
  providers: [DragulaService],
})
export class DraggableComponentsModule {}
