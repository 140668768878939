import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, ModalModule, InputModule, LoaderModule } from '@assurance/bootstrap';

// import { StyleEditorModule } from '../../../style-editor/style.editor.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { DropTicketModalComponent } from './drop-ticket-modal.component';
import { DropTicketModalService } from './drop-ticket-modal.service';
import { DropTicketResponseModalComponent } from './drop-ticket-response-modal.component';

@NgModule({
  exports: [DropTicketModalComponent, DropTicketResponseModalComponent],
  declarations: [DropTicketModalComponent, DropTicketResponseModalComponent],
  imports: [
    CommonModule,
    // StyleEditorModule,
    DirectivesModule,
    ButtonModule,
    ModalModule,
    InputModule,
    LoaderModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [DropTicketModalService],
})
export class DropTicketModalModule {}
