import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormControlsModule } from '@se/common';

import { SimpleToggleComponent } from './simple-toggle/simple-toggle.component';
// import { StyleEditorModule } from '../../../../components/style-editor/style.editor.module';

@NgModule({
  declarations: [SimpleToggleComponent],
  exports: [SimpleToggleComponent],
  imports: [CommonModule, FormControlsModule],
})
export class TogglesModule {}
