export enum KeyboardKey {
  Enter = 'Enter',
  ESC = 'Escape',
  LeftArrow = 'ArrowLeft',
  RightArrow = 'ArrowRight',
}

export enum OperatorKey {
  AND = 'AND',
  OR = 'OR',
}
