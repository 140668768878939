import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { SharedSocketService } from '@shared/services';

@Component({
  selector: 'ep-pdf-loading-message',
  templateUrl: './pdf-loading-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfLoadingMessageComponent {
  @Input() message: string;

  constructor(public sharedSocketService: SharedSocketService) {}
}
