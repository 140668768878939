<ac-modal-header (close)="close()">
  <p class="title">{{ data.name }}</p>
</ac-modal-header>

<ac-modal-body>
  <p *ngIf="data.subTitle" class="title-list">{{ data.subTitle }}</p>
  <ul class="item-list">
    <li *ngFor="let item of data.itemList; let i = index" class="item">
      <a
        href="{{ item.link }}"
        target="_blank"
        class="link"
      >
        <span>{{ i + 1 }}. {{ item.name }}</span>
        <ng-container *ngIf="item.versionName">
          <span > - Version: "{{ item.versionName }}"</span>
          <span class="open-icon"></span>
        </ng-container>
      </a>
    </li>
  </ul>
</ac-modal-body>

<ac-modal-footer>
  <se-button class="secondary" size="medium" (click)="close()">Close</se-button>
</ac-modal-footer>
