import { Injectable } from '@angular/core';

import { Global } from '@shared/services';
import { PRODUCT_TABLE_UIID } from '@shared/constants';

@Injectable()
export class PermissionHandlerService {
  constructor(private global: Global) {}

  filterPermittedConfigs<T>(configs: T[]): T[] {
    if (!(this.global.isSharedPresentation() && configs)) {
      return configs;
    }

    return configs.filter((item: T) => {
      if (item['config'].uiId === PRODUCT_TABLE_UIID) {
        return item['config'].isPermitted;
      }

      return true;
    });
  }
}
