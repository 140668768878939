<ac-modal-header (close)="onCancel()">
  <h2 class="title">Manage Dependent Pages</h2>
</ac-modal-header>

<ac-modal-body>
  <div class="row body-header">
    <div class="col-lg-6">
      <se-search class="search" [placeholder]="'Search for Dependent Page'" (onSearch)="searchData($event)"></se-search>
    </div>
    <div class="col-lg-6">
      <h4>Selected Pages</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="drag-window-container">
        <div class="drag-window">
          <ul class="unselected-dependent-pages-list">
            <ng-container *ngFor="let dependentPage of dependentPages">
              <li class="dragged-li" *ngIf="!dependentPage.selected">
                <span class="label">{{ dependentPage.label }}</span>
                <span class="controls">
                  <se-button class="secondary float-right" (click)="onAction(dependentPage, true)">
                    Add
                  </se-button>
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="drag-window-container">
        <div class="drag-window">
          <div *ngIf="!hasSelectedPage()" class="no-data-wrapper">
            <span>
              No pages selected
              <br />
              Use search on the left
              <br />
              to select a dependent page
            </span>
          </div>
          <ul
            [dragula]="pagesDragModel.bag"
            [dragulaModel]="pagesDragModel.data"
            class="selected-dependent-pages-list"
            *ngIf="pagesDragModel.data"
          >
            <ng-container *ngFor="let dependentPage of pagesDragModel.data">
              <li class="dragged-li" *ngIf="dependentPage.selected">
                <span class="label">{{ dependentPage.label }}</span>
                <span class="drag-icon float-right">
                  <span class="fa fa-ellipsis-v fa-lg" aria-hidden="true"></span>
                  <span class="fa fa-ellipsis-v fa-lg" aria-hidden="true"></span>
                </span>
                <span class="controls">
                  <se-button class="delete float-right" (click)="onAction(dependentPage, false)">
                    Remove
                  </se-button>
                </span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ac-modal-body>

<ac-modal-footer>
  <ac-button (click)="onCancel()" class="secondary" size="sm">Cancel</ac-button>
  <ac-button type="submit" size="sm" (click)="apply()">Apply</ac-button>
</ac-modal-footer>
