import { createAction, props } from '@ngrx/store';

import { ActionTypes } from './setup.constant';
import {
  ColorScheme,
  CustomPagesConfig,
  CustomPageValues,
  NavbarCustomPagesData,
  SelectedPlansValues,
  SingleViewTab,
} from '@shared/models';

export const setupDisclosureTextUpdateSuccess = createAction(
  ActionTypes.SetupDisclosureTextUpdateSuccess,
  props<{ payload: any }>()
);

export const resetSetupState = createAction(ActionTypes.ResetSetupState);

export const changeSelectedPlansValues = createAction(
  ActionTypes.ChangeSelectedPlansValues,
  props<{ payload: SelectedPlansValues[] }>()
);

export const setSingleViewTabs = createAction(ActionTypes.SetSingleViewTabs, props<{ payload: SingleViewTab[] }>());

export const changeSelectedPageId = createAction(ActionTypes.ChangeSelectedPageId, props<{ payload: string }>());

export const changeSelectedPage = createAction(ActionTypes.ChangeSelectedPage, props<{ payload: CustomPageValues }>());

export const showSelectedPlan = createAction(
  ActionTypes.ShowSelectedPlan,
  props<{
    payload: { planId: number | string; show: boolean; configKey: string };
  }>()
);

export const infoShowSelectedPlan = createAction(
  ActionTypes.InfoShowSelectedPlan,
  props<{ payload: { planId: number | string; infoShow: boolean } }>()
);

/**
 * Add only unique selected plans objects where visualization and plan are unique.
 *
 */
export const addSelectedPlanValues = createAction(
  ActionTypes.AddSelectedPlanValues,
  props<{ payload: SelectedPlansValues[] }>()
);

export const resetSelectedPlansValues = createAction(ActionTypes.ResetSelectedPlansValues);

export const setPlanLoadingPending = createAction(ActionTypes.SetPlanLoadingPending);

export const setPlanLoadingSuccess = createAction(ActionTypes.SetPlanLoadingSuccess);

export const setPlanLoadingFailure = createAction(ActionTypes.SetPlanLoadingFailure);

export const colorSchemeLoadSuccess = createAction(
  ActionTypes.ColorSchemeLoadSuccess,
  props<{ payload: ColorScheme[] }>()
);

export const colorSchemeLoadPending = createAction(ActionTypes.ColorSchemeLoadPending);

export const colorSchemeLoadFailure = createAction(ActionTypes.ColorSchemeLoadFailure, props<{ error: any }>());

export const colorSchemeReset = createAction(ActionTypes.ColorSchemeReset);

export const getEndPagesLoadingPending = createAction(ActionTypes.GetEndPagesLoadingPending);

export const getEndPagesLoadingFailure = createAction(ActionTypes.GetEndPagesLoadingFailure);

export const getEndPagesLoadingSuccess = createAction(
  ActionTypes.GetEndPagesLoadingSuccess,
  props<{ payload: CustomPageValues[] }>()
);

export const getSalesConceptsLoadingPending = createAction(ActionTypes.GetSalesConceptsLoadingPending);

export const salesConceptModalRequestsPending = createAction(ActionTypes.SalesConceptModalRequestsPending);

export const salesConceptModalRequestsSuccess = createAction(ActionTypes.SalesConceptModalRequestsSuccess);

export const getSalesConceptsLoadingSuccess = createAction(
  ActionTypes.GetSalesConceptsLoadingSuccess,
  props<{ payload: CustomPageValues[] }>()
);

export const getSalesConceptsCountLoadingSuccess = createAction(
  ActionTypes.GetSalesConceptsCountLoadingSuccess,
  props<{ payload: number }>()
);

export const getSalesConceptLoadingSuccess = createAction(
  ActionTypes.GetSalesConceptLoadingSuccess,
  props<{ payload: CustomPageValues[] }>()
);

export const getSalesConceptsLoadingFailure = createAction(ActionTypes.GetSalesConceptsLoadingFailure);

export const salesConceptsModalAvailable = createAction(
  ActionTypes.SalesConceptsModalAvailable,
  props<{ payload: boolean }>()
);

export const getEndPageLoadingSuccess = createAction(
  ActionTypes.GetEndPageLoadingSuccess,
  props<{ payload: CustomPageValues[] }>()
);

export const resetEndPages = createAction(ActionTypes.ResetEndPages);

export const resetDisclosure = createAction(ActionTypes.ResetDisclosure);

export const resetSalesConcepts = createAction(ActionTypes.ResetSalesConcepts);

export const setSetupLoading = createAction(ActionTypes.SetSetupLoading, props<{ payload: boolean }>());

export const getDependentPagesLoadingPending = createAction(ActionTypes.GetDependentPagesLoadingPending);

export const getDependentPagesLoadingFailure = createAction(ActionTypes.GetDependentPagesLoadingFailure);

export const getDependentPagesLoadingSuccess = createAction(
  ActionTypes.GetDependentPagesLoadingSuccess,
  props<{ payload: CustomPageValues[] }>()
);

export const getSalesConceptDependentPagesLoadingSuccess = createAction(
  ActionTypes.GetSalesConceptDependentPagesLoadingSuccess
);

export const getDependentPageLoadingSuccess = createAction(
  ActionTypes.GetDependentPageLoadingSuccess,
  props<{ payload: CustomPageValues[] }>()
);

export const resetDependentPages = createAction(ActionTypes.ResetDependentPages);

export const getDependentPagesConfigLoadingPending = createAction(ActionTypes.GetDependentPagesConfigLoadingPending);

export const getDependentPagesConfigLoadingFailure = createAction(ActionTypes.GetDependentPagesConfigLoadingFailure);

export const getDependentPagesConfigLoadingSuccess = createAction(
  ActionTypes.GetDependentPagesConfigLoadingSuccess,
  props<{
    payload: CustomPagesConfig;
  }>()
);

export const resetDependentPagesConfig = createAction(ActionTypes.ResetDependentPagesConfig);

export const setChartPinValueForEachChartInsert = createAction(
  ActionTypes.SetPinValueOnEachChartInsert,
  props<{
    payload: {
      pinValue: number;
    };
  }>()
);

export const updateChartPinValueForEachChartInsert = createAction(
  ActionTypes.UpdatePinValueOnEachChartInsert,
  props<{
    payload: {
      presentationId: number;
      configs: CustomPagesConfig[];
    };
  }>()
);

export const setNavbarCustomPages = createAction(
  ActionTypes.GetNavbarCustomPagesLoadingSuccess,
  props<{
    payload: NavbarCustomPagesData;
  }>()
);

export const resetNavbarCustomPages = createAction(ActionTypes.ResetNavbarCustomPages);
