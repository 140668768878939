import { Component, OnInit, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'ensight-confirm-modal',
  styles: [
    `
      [role='dialog'] {
        z-index: 99999;
      }
    `,
  ],
  templateUrl: 'confirm-modal.component.html',
})
export class ConfirmModalComponent implements OnInit {
  @ViewChild('confirmChildModal', { static: false })
  private confirmChildModal: ModalDirective;

  public resolve: any;
  public windowClass: string;
  public modalInitComplete = false;
  private modalState: Subject<any>;

  public openModal({ resolve, windowClass }: any) {
    this.resolve = resolve;
    this.windowClass = windowClass;

    this.modalInitComplete = true;
    setTimeout(() => this.confirmChildModal.show(), 100);

    return new Promise(res => {
      this.modalState.subscribe(data => res(data));
    });
  }

  public ok() {
    this.modalState.next(true);
    this.modalInitComplete = false;
    this.modalState.observers.pop();
  }

  public cancel() {
    this.modalInitComplete = false;
    this.modalState.observers.pop();
  }

  public ngOnInit() {
    this.modalState = new Subject();
  }
}
