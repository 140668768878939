import { Component, OnInit, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'ensight-info-modal',
  templateUrl: 'info-modal.component.html',
})
export class InfoModalComponent implements OnInit {
  @ViewChild('infoChildModal', { static: false })
  private infoChildModal: ModalDirective;

  public resolve: any;
  public windowClass: string;
  public modalInitComplete = false;
  private modalState: Subject<any>;

  public openModal({ resolve, windowClass }: any) {
    this.resolve = resolve;
    this.windowClass = windowClass;

    this.modalInitComplete = true;
    setTimeout(() => this.infoChildModal.show(), 100);

    return new Promise(res => {
      this.modalState.subscribe(data => res(data));
    });
  }

  public close() {
    this.modalInitComplete = false;
    this.modalState.next(true);
    this.modalState.observers.pop();
  }

  public ngOnInit() {
    this.modalState = new Subject();
  }
}
