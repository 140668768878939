import uniqBy from 'lodash-es/uniqBy';
import sortBy from 'lodash-es/sortBy';
import orderBy from 'lodash-es/orderBy';
import map from 'lodash-es/map';
import mapValues from 'lodash-es/mapValues';
import { flatMap, uniq, filter, replace, get, find, flatten, first, last, cloneDeep } from 'lodash-es';

const chainableFunctions = {
  sortBy,
  uniqBy,
  orderBy,
  map,
  flatMap,
  uniq,
  filter,
  replace,
  get,
  find,
  flatten,
  first,
  last,
  cloneDeep,
};

export const chain = <T>(input: T) => {
  let value: any = input;
  const wrapper = {
    ...mapValues(chainableFunctions, (f: any) => (...args: any[]) => {
      value = f(value, ...args);

      return wrapper;
    }),
    value: () => value,
  };

  return wrapper as any;
};
