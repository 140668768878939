import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader.component';
import { FormControlsModule, LoadersModule } from '@se/common';

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, FormControlsModule, LoadersModule],
  exports: [LoaderComponent],
})
export class LoaderModule {}
