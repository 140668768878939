import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { EditorModule } from '@tinymce/tinymce-angular';

import { LoaderModule } from '@assurance/bootstrap';

import { TinyEditorComponent } from './tiny-editor.component';
import { InsertsApiService } from '@shared/services';

@NgModule({
  declarations: [TinyEditorComponent],
  providers: [InsertsApiService],
  imports: [CommonModule, EditorModule, ReactiveFormsModule, LoaderModule],
  exports: [TinyEditorComponent],
})
export class TinyEditorModule {}
