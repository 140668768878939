import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeResource',
})
export class SafeResourcePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(value: string, type: 'resourceUrl' | 'html' | 'url' = 'html'): SafeResourceUrl | SafeHtml {
    switch (type) {
      case 'resourceUrl':
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);

      case 'url':
        return this.domSanitizer.bypassSecurityTrustUrl(value);

      case 'html':
      default:
        return this.domSanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
