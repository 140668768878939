import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { set } from 'lodash-es';

@Component({
  selector: 'ensight-simple-toggle',
  styleUrls: ['simple-toggle.component.scss'],
  templateUrl: 'simple-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleToggleComponent implements OnChanges {
  @Input() type = 'simple';
  @Input() name = 'simple-toggle';
  @Input() values: any = [];
  @Input() checked: boolean;
  @Input() disable = false;
  @Input() loading = false;
  @Input() readonly = false;

  @Output() toggle = new EventEmitter();
  @Output() readonlyEvent = new EventEmitter();

  @ViewChild('checkElem') private checkElem: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable && changes.disable.currentValue && this.checkElem) {
      set(this.checkElem, 'nativeElement.checked', false);
    }
  }

  toggleElement(event: any): void {
    event.stopPropagation();
    this.switchElement(event);
  }

  readonlyClick(): void {
    if (!this.disable && this.readonly) {
      this.readonlyEvent.emit();
    }
  }

  switchElement(event: any): void {
    if (!this.disable && !this.readonly) {
      this.toggle.emit(event);
    }
  }
}
