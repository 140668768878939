import { createAction, props } from '@ngrx/store';

import { Params } from '../import-illustration.models';
import { TableDataItem, PendingExportsData } from '@shared/models';
import { CareerPlan } from '@core/model';

const actionsEntity = 'Import Illustration';

export const setImportParams = createAction(`[${actionsEntity}] Set Import Params`, props<{ params: Params }>());

export const setPendingExportsData = createAction(
  `[${actionsEntity}] Set Pending Exports Data`,
  props<{
    pendingExportsData: PendingExportsData[];
  }>()
);

export const setSelectedPresentation = createAction(
  `[${actionsEntity}] Set Selected Presentation`,
  props<{
    selectedPresentation: TableDataItem;
  }>()
);

export const getSelectedPresentationProducts = createAction(
  `[${actionsEntity}] Get Selected Presentation Products`,
  props<{
    selectedPresentationId: number;
  }>()
);

export const setSelectedPresentationProducts = createAction(
  `[${actionsEntity}] Set Selected Presentation Products`,
  props<{
    selectedPresentationCarrierPlans: CareerPlan[];
  }>()
);
