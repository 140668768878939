import { Injectable } from '@angular/core';

import * as _ from 'lodash-es';

import {
  ChargesMetricsList,
  MetricsNotForModifying,
  ANNUITY_TITLE_FIELD,
  VUL_TITLE_FIELD,
  DEFAULT_TITLE_FIELD,
} from '@shared/constants';
import { Global } from '@shared/services';
import { CareerPlan, Metric } from '@core/model';
import { GlobalConfig, PageConfig } from '@shared/models';
import { MetricType } from '@shared/constants/metric-type.enum';

@Injectable()
export class MetricsService {
  constructor(private global: Global) {}

  getMetric(key: string, disabledMetrics: string[] = [], source = 'data'): Metric {
    const titleField = this.global.getMetricsTitleFieldName();
    const delimiter = ' ';
    const config = this.global.getMetricConfigByKey(key, source);
    const chargesMetric = ChargesMetricsList.find((item: Metric) => item.key === key);

    if (!config) {
      return;
    }

    // TODO:  Need to refact logic of getting metric titles
    // This condition is needed as we changed naming for VUL.
    const isVULSpecialCase =
      titleField === VUL_TITLE_FIELD &&
      (config.db === 'guaranteed_cumulative_premium' || config.db === 'planned_premium');

    // need this in scope of DAT-5186
    // due to current implementation of metrics displaying,
    // we should split metric title in order to get first word as type of metric, and the rest as name of metric

    let type, name;

    if (isVULSpecialCase) {
      const dividedTitle = config[VUL_TITLE_FIELD].split(delimiter);

      type = dividedTitle.splice(0, 4).join(delimiter);
      name = dividedTitle.join(delimiter);
    } else {
      const dividedTitle =
        config[titleField] && titleField !== VUL_TITLE_FIELD
          ? config[titleField].split(delimiter)
          : config[DEFAULT_TITLE_FIELD].split(delimiter);

      const titleMiddle = Math.floor(dividedTitle.length / 2);

      type =
        titleField === ANNUITY_TITLE_FIELD && titleMiddle
          ? dividedTitle.splice(0, titleMiddle).join(delimiter)
          : dividedTitle.splice(0, 1);

      name = dividedTitle.join(delimiter);
    }

    const disabled = disabledMetrics.includes(config.db);

    return {
      title: titleField === ANNUITY_TITLE_FIELD && '',
      type,
      name,
      disabled,
      key: config.db,
      isPercentYAxis: config.type === MetricType.percent,
      yAxisTitle: chargesMetric ? chargesMetric.yAxisTitle : null,
    };
  }

  getMetrics(keys: string[] = [], disabledMetrics: string[] = []): Metric[] {
    return keys
      .map((key: string) => this.getMetric(key, disabledMetrics))
      .filter(item => !MetricsNotForModifying.includes(item.key))
      .filter(item => item);
  }

  getSelectedMetric(config: [GlobalConfig, PageConfig], metricType?: 'Guaranteed' | 'Non-Guaranteed'): Metric {
    const conf = config[1];

    if (!conf) {
      return {} as Metric;
    }

    const selectedPlanKey =
      conf.config.selectedMetricKey === 'cumulative_premium'
        ? _.snakeCase(_.concat((metricType || 'Guaranteed') as any, 'cumulative_premium') as any)
        : conf.config.selectedMetricKey;

    let selectedMetric = this.getMetric(selectedPlanKey);

    if (selectedMetric && !selectedMetric.title) {
      selectedMetric = _.cloneDeep(selectedMetric);
      this.addTitleToMetric(selectedMetric);
    }

    return selectedMetric || ({} as Metric);
  }

  validateMetrics(plan: CareerPlan, metrics: any) {
    const metricsList = _.flatMap(metrics, metric => metric.map((item: Metric) => item.key));

    return _.some(plan.configjson.data, (item: any, i) => {
      return metricsList.includes(i) && item.length;
    });
  }

  validateRetirement(plan: CareerPlan, metrics: any) {
    const metricsList = _.flatMap(metrics, metric => metric.map((item: Metric) => item.key));

    return !_.some(metricsList, (key: string) => !plan.configjson.data[key]);
  }

  getDisabledMetricsFields(activePlans: CareerPlan[]): any[] {
    const existingKeys = [];
    activePlans.forEach(item => existingKeys.push(...Object.keys(item.configjson.data)));

    return _.difference(_.map(ChargesMetricsList, 'key'), _.uniq(existingKeys));
  }

  getAvailableMetric(disabledMetrics: string[], metricsKeys?: string[]): Metric[] | Metric {
    const list = ChargesMetricsList;

    if (metricsKeys) {
      const filteredMetricsList = list.filter(metric => metricsKeys.includes(metric.key));
      const enabledMetricList = filteredMetricsList.filter(item => !disabledMetrics.includes(item.key));

      if (!enabledMetricList.length) {
        return [];
      }

      const firstEnabledMetric = _.cloneDeep(_.first(enabledMetricList));
      this.addTitleToMetric(firstEnabledMetric);

      return firstEnabledMetric;
    }

    const firstEnabledMetric = _.cloneDeep(_.first(list.filter(item => !disabledMetrics.includes(item.key))));
    this.addTitleToMetric(firstEnabledMetric);

    return firstEnabledMetric;
  }

  getMetricTitleByKey(key: string, source = 'data'): string {
    const metricConfig = this.global.getMetricConfigByKey(key, source);

    return metricConfig?.[this.global.getMetricsTitleFieldName()] || metricConfig?.[DEFAULT_TITLE_FIELD];
  }

  addTitleToMetric(metric: Metric, source?: string): void {
    if (metric) {
      metric.title = this.getMetricTitleByKey(metric.key, source);
    }
  }
}
