import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserMessageComponent } from './browser-message.component';

@NgModule({
  declarations: [BrowserMessageComponent],
  imports: [CommonModule],
  exports: [BrowserMessageComponent],
})
export class BrowserMessageModule {}
