import { Component } from '@angular/core';
import { DOMService } from '@shared/services';

@Component({
  selector: 'ensight-router-root-not-found',
  template: `
    <app-header [showUserMenu]="false"></app-header>
    <section class="not-found-main-container">
      <router-outlet></router-outlet>
    </section>
  `,
  styles: [
    `
      .not-found-main-container {
        background-color: white;
        height: 100%;
      }
    `,
  ],
})
export class RouterRootNotFoundComponent {
  public navItems: any;
  public showUserMenu: any;
  constructor(public domService: DOMService) {}
}
