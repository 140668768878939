import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesPreviewComponent } from './images-preview.component';
import { CommonSeModule } from '@se/common';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';

const components = [ImagesPreviewComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, CommonSeModule, AssurencePipesModule],
  exports: components,
})
export class ImagesPreviewModule {}
