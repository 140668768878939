<div
  #textBox
  role="textbox"
  class="form-control"
  contenteditable="true"
  [style]="style"
  (click)="saveRange($event)"
  (input)="watchForText()"
  (keydown)="saveRange($event)"
  (paste)="onPaste($event)">
</div>

