import { Component } from '@angular/core';
import { AlertService } from '@se/common';

@Component({
  selector: 'ensight-forgot-password',
  styleUrls: ['../login.scss'],
  templateUrl: 'forgot-password.component.html',
})
export class ForgotPasswordComponent {
  public resetUrl = `${location.origin}/reset-password`;

  constructor(private alertService: AlertService) {}
  // TODO: need to change interface
  // submitForm(response: { success: boolean; data: { message: string; error: { message: string[] } } }): void {
  submitForm(response: any): void {
    if (response.success) {
      this.alertService.openAlert({
        type: 'neutral',
        body: 'Email was sent. Please follow the instructions to reset your password.',
        autoClose: 5000,
      });
    } else {
      this.alertService.openAlert({
        type: 'error',
        body: response.data?.error?.message?.toString() || response.data.message,
        autoClose: 5000,
      });
    }
  }
}
