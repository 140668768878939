import { MenuItemsType, UserMenuItem } from './user-menu.model';
import { PermissionKeyEnum } from '@core/enums';

// TODO: prop action should be deleted from each object once UserMenuItem model will be updated
// TODO: Need to use enum for permission property
// https://assuranceapp.atlassian.net/browse/DAT-7141
export const MainMenuItems: UserMenuItem[] = [
  {
    action: 'click',
    label: 'Intelligent Quote',
    type: MenuItemsType.eiq,
    groupPermission: PermissionKeyEnum.intelligent_quote_link_button,
  },
  {
    action: 'click',
    label: 'User Management',
    type: MenuItemsType.um,
    route: '/user-management',
    groupWithPortalPermission: PermissionKeyEnum.access_to_eum,
  },
  {
    action: 'click',
    label: 'Custom Pages',
    type: MenuItemsType.customPages,
    route: '/custom-pages',
    userPermission: PermissionKeyEnum.view_custom_pages,
  },
  {
    action: 'click',
    label: 'Revisions',
    type: MenuItemsType.revisions,
    route: '/history',
  },
  {
    action: 'click',
    label: 'Payload Statuses',
    type: MenuItemsType.statuses,
    route: '/xml-statuses',
    isAssuranceAdmin: true,
  },
  {
    action: 'click',
    label: 'Policy Inforce Records',
    type: MenuItemsType.policyInForce,
    route: '/policy-inforce-records',
    userPermission: PermissionKeyEnum.view_policy_inforce_records,
  },
  {
    action: 'click',
    label: 'Data Consumption Configuration',
    type: MenuItemsType.configManagement,
    route: '/config-management',
    userPermission: PermissionKeyEnum.data_consumption_configurations,
  },
];

export const BaseMenuItems: UserMenuItem[] = [
  {
    action: 'click',
    label: 'Manage Password',
    type: MenuItemsType.managePassword,
    route: '/manage-password',
  },
  {
    action: 'click',
    label: 'Change Organization',
    type: MenuItemsType.changeOrganization,
    route: '/organizations-switch',
  },
  {
    action: 'click',
    label: 'Logout',
    type: MenuItemsType.logout,
    icon: 'logout',
    route: '/login',
  },
];
