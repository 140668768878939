import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash-es';

const NOT_CURRENCY_VALUES_PLAN = [
  'policy_year',
  'eoy_age',
  'years_guaranteed',
  'lapse_year',
  'ifl_start_year',
  'guaranteed_interest_rate',
  'current_interest_rate',
  'mec',
  'company_name',
  'product_name',
  'agent',
  'idb_adjustment',
  'minimum_premium',
  'guaranteed_interest_rate',
  'current_interest_rate',
  'product_type',
  'guaranteed_duration',
];

@Pipe({
  name: 'currencyPlan',
})
export class CurrencyPlanPipe implements PipeTransform {
  public transform(amount: any, planAttr: any): any {
    const fieldValue = typeof planAttr === 'object' ? planAttr.id : planAttr;

    if (NOT_CURRENCY_VALUES_PLAN.indexOf(fieldValue) !== -1 || amount === '' || _.isNaN(parseFloat(amount))) {
      return amount;
    }

    return new CurrencyPipe('en-US').transform(amount, 'USD', 'symbol');
  }
}
