import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { SelectedData, DropdownOption } from '@core/model';

@Component({
  selector: 'ep-insert-dropdown',
  templateUrl: './insert-dropdown.component.html',
  styleUrls: ['./insert-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertDropdownComponent implements OnInit {
  @Input() id: string;
  @Input() options: DropdownOption[];
  @Input() title: string;
  @Input() value: string;

  @Output() currentValueChange = new EventEmitter<SelectedData>();

  selectedValue: DropdownOption;
  dropdownOpen = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selectedValue = this.options.find((option: DropdownOption) => {
      const optionValue = typeof option.value === 'string' ? option.value?.trim() : option.value;

      return optionValue === this.value?.trim();
    });
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
    this.cdr.detectChanges();
  }

  select(index: number): void {
    if (this.options[index].value !== this.selectedValue?.value) {
      this.currentValueChange.emit({
        value: { uiId: this.id, index: index, value: this.options[index].value },
        name: this.title,
      });
    }

    this.selectedValue = this.options[index];
    this.closeDropdown();

    this.cdr.detectChanges();
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
    this.cdr.detectChanges();
  }
}
