import { Injectable } from '@angular/core';
import { UmNodeAPIService } from '@assurance/um-services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, tap, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { AppState } from '../index';
import * as GlobalActions from './global.actions';
import { getOrganizationsSwitchByProfile, getOrganizationsSwitchData } from './global.selectors';

@Injectable()
export class GlobalEffects {
  organizationsSwitch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalActions.organizationsSwitch),
      withLatestFrom(
        this.store.pipe(select(getOrganizationsSwitchData)),
        this.store.pipe(select(getOrganizationsSwitchByProfile))
      ),
      filter(([, data, byProfile]) => {
        return !byProfile || !data;
      }),
      switchMap(([action]) => {
        this.store.dispatch(GlobalActions.loadOrganizationsSwitchPending());
        const request = action.userId
          ? this.umNodeAPIService.getOrganizationsSwitch(action.userId)
          : this.umNodeAPIService.getAvailableOrganizations();

        return request.pipe(
          mergeMap(response =>
            action.userId
              ? [
                  GlobalActions.loadOrganizationsSwitchSuccess({
                    payload: response,
                  }),
                ]
              : [
                  GlobalActions.loadOrganizationsSwitchSuccess({
                    payload: response,
                  }),
                  GlobalActions.organizationsSwitchNavigate({
                    payload: response,
                    queryParams: action.queryParams,
                  }),
                ]
          ),
          catchError(err => {
            return of(
              GlobalActions.loadOrganizationsSwitchError({
                payload: err,
              })
            );
          })
        );
      })
    )
  );

  organizationsSwitchNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalActions.organizationsSwitchNavigate),
        tap(action =>
          this.router.navigate(action.payload.data.length > 1 ? ['/organizations-select'] : ['/home'], {
            queryParams: action.queryParams,
          })
        )
      ),
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private umNodeAPIService: UmNodeAPIService,
    private store: Store<AppState>
  ) {}
}
