<div *ngIf="isLoadingVersionOrCompiledPage && !loading" class="spinner-holder">
  <ac-loader></ac-loader>
</div>

<section class="content a-app-main-container-background-color" [ngClass]="{ loader: loading, hidden: !loading }"></section>

<div *ngIf="customPage" class="custom-page-wrapper" data-at-id="custom-page">
  <ensight-custom-page-header
    *ngIf="!PDFPrint"
    [title]="customPage?.name"
    [showSecondary]="true"
    [isNotEndPage]="isNotEndPage"
    [isNotDependentPage]="isNotDependentPage"
    [customPageVersionId]="customPage._id"
    [customPageVersions]="customPageVersions"
    [presentationName]="selectedPresentation?.name"
    (changeVersion)="changeVersion($event)"
    (onPdfDownload)="downloadPDF()"
    (onClose)="leaveCustomPage()"
    (onPageSettings)="openPageSettings()"
    (openManageVersionModal)="openManageVersionModal()"
    (openChoosePresentationModal)="openChoosePresentationModal()"
    (clearPresentation)="clearPresentation()"
    (openPageEditText)="openPageEditText()"
    (openPageHistory)="openPageHistory()"
    (exportCustomPage)="exportCustomPage()"
  ></ensight-custom-page-header>

  <div *ngIf="PDFPrint" class="header-pdf"></div>

  <section
    *ngIf="!compiledCustomPage"
    #templateRef
    [innerHTML]="htmlBody"
    class="custom-page-body"
    data-at-id="custom-page-body"
    [class.custom-page-body-pdf]="PDFPrint">
  </section>

  <div class="custom-page-body custom-page-compiled" *ngIf="compiledCustomPage" [class.custom-page-body-pdf]="PDFPrint">
    <ep-insert-content
      #innerContent
      [inserts]="inserts"
      [isCompiledPagePreview]="true"
      [body]="compiledCustomPage.customFields.htmlBody">
    </ep-insert-content>
  </div>

</div>
