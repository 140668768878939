<ac-modal-header class="container-header" (close)="close()">
  <h2 class="title">Sales Stories Setup</h2>
</ac-modal-header>

<ac-modal-body class="container-body">
  <p class="hint">Review and edit values to fit your client scenario.</p>

  <div class="container-sale-concept" *ngFor="let concept of salesConcepts; let i = index">
    <p class="name">{{ i + 1 }}.{{ concept.label }}</p>

    <div class="container-info">
      <div class="container-preview">
        <p class="description" *ngIf="concept.description">{{ concept.description }}</p>
        <img class="preview" *ngIf="concept.previewFilePath" src="{{ concept.previewFilePath }}">
        <div class="no-preview" *ngIf="!concept.description && !concept.previewFilePath">
          <p>The preview is not available</p>
        </div>
      </div>

      <div class="container-form">
        <form [formGroup]="concept.form">
          <div class="container-control" *ngFor="let insert of concept.inserts">
            <label>{{ insert.metadata.placeholderName }}</label>
            <se-form-input [formControlName]="insert.config.uiId" [inputPattern]="inputPattern"></se-form-input>
            <span *ngIf="concept.form.get(insert.config.uiId).errors?.required" class="error-message">
              This field is required
            </span>
            <span *ngIf="concept.form.get(insert.config.uiId).errors?.pattern" class="error-message">
              Number of decimals should be {{ insert.metadata.placeholderDecimals ? 'up to' : '' }} {{ insert.metadata.placeholderDecimals }}
            </span>
            <span *ngIf="concept.form.get(insert.config.uiId).errors?.max" class="error-message">
              Value should be less than or equal to {{ insert.metadata.placeholderMaxValue }}
            </span>
            <span *ngIf="concept.form.get(insert.config.uiId).errors?.min" class="error-message">
              Value should be greater than or equal to {{ insert.metadata.placeholderMinValue }}
            </span>
          </div>
        </form>
      </div>

    </div>
  </div>
</ac-modal-body>

<ac-modal-footer class="container-footer">
  <ac-button (click)="apply()" class="bnt-apply" size="sm">Apply</ac-button>
  <ac-button (click)="close()" class="secondary" size="sm">Close</ac-button>
</ac-modal-footer>


<div class="container-confirm" *ngIf="isConfirmVisible">
  <div class="content">
    <div class="confirm-header">
      <h2 class="title">Proceed with default values</h2>
      <button type="button" class="btn-confirm-close" (click)="cancel()">
        <span class="btn-text">esc</span>
        <ac-icon class="btn-icon" icon="close" [width]="21" [height]="21" [active]="false"></ac-icon>
      </button>
    </div>
    <div class="confirm-body">
      Default values will be applied to the sales story.
      Select cancel to <br> edit any applicable client scenario changes.
      Values can also be edited later in sales story settings.
    </div>
    <div class="confirm-footer">
      <ac-button (click)="ok()" class="bnt-ok" size="sm">Confirm</ac-button>
      <ac-button (click)="cancel()" class="secondary" size="sm">Cancel</ac-button>
    </div>
  </div>
</div>
