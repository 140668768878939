<div class="mb-4">
  <div class="d-flex justify-content-between align-content-end">
    <se-button-group [buttons]="buttons" size="medium" (onSelectButton)="selectConfiguration($event)"></se-button-group>
    <se-button
      *ngIf="activeTab === customMetricFormulaStateId"
      [disabled]="!selectedPresentation || !customPage || !isValidForm" (click)="compile()">
      Compile
    </se-button>
  </div>

  <div *ngIf="activeTab === customMetricFormulaStateId" class="mt-2 d-flex justify-content-end align-items-center" data-at-id="compile-hint">
    <span *ngIf="!customPage">Save the custom page to use Compile</span>
    <span *ngIf="!selectedPresentation && customPage">Please select presentation on the preview to use Compile</span>
  </div>
</div>

<se-dynamic-form
  [class.d-none]="activeTab === customMetricFormulaStateId"
  *ngIf="questionsData$ | async"
  [questionsData]="questionsData$"
  [formId]="configurationStateId"
  [params]="{}"
></se-dynamic-form>

<div  [class.d-none]="activeTab === configurationStateId">
  <div class="wrap-editors d-flex justify-content-between mb-4" >
    <div class="wrap-editor d-flex flex-column justify-content-start mr-4 overflow-hidden">
      <div class="d-flex justify-content-between align-items-center pb-3">
        <p class="title m-0">Formula</p>
        <div class="d-flex justify-content-between">
          <se-button class="secondary" (click)="enableVersion2()">V1 -> V2</se-button>
          <se-button class="secondary ml-3" *ngIf="isNewVersion" (click)="format()">Format</se-button>
        </div>
      </div>
      <div class="overflow-hidden h-100">
        <ngx-codemirror
          *ngIf="form"
          [formControl]="asFormControl(form.controls.formula)"
          [options]="formulaEditorOptions">
        </ngx-codemirror>
      </div>
    </div>

    <div class="wrap-editor d-flex flex-column justify-content-start overflow-hidden">
      <p class="title m-0 pb-3">Preview</p>
      <div class="overflow-hidden h-100">
        <ngx-codemirror
          class="compilation-result"
          [ngClass]="{ 'compilation-error': isError }"
          [ngModel]="compilation"
          [options]="compilationEditorOptions">
        </ngx-codemirror>
      </div>
    </div>
  </div>

  <se-dynamic-form
    [class.d-none]="activeTab === configurationStateId"
    *ngIf="formulaQuestions$ | async"
    [questionsData]="formulaQuestions$"
    [formId]="customMetricFormulaStateId"
    [params]="{}"
  ></se-dynamic-form>
</div>

