import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartComponent } from './chart.component';
import { NvD3Module } from '@assurance/ng2-nvd3';
import { ChartService } from './chart.service';
import { ChartTooltipComponent } from './chart-tooltip/chart-tooltip.component';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';
import { C3ChartComponent } from './c3-chart/c3-chart.component';
import { C3AdapterService } from './c3-chart/c3-chart.adapter.service';

//Import should be directly here
//https://github.com/krispo/ng2-nvd3#simple-bar-chart
import '@assurance/nvd3';

@NgModule({
  imports: [CommonModule, NvD3Module, AssurencePipesModule],
  declarations: [ChartComponent, ChartTooltipComponent, C3ChartComponent],
  exports: [ChartComponent, C3ChartComponent],
  providers: [ChartService, C3AdapterService],
})
export class ChartModule {}
