import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

import sortBy from 'lodash-es/sortBy';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SetupService } from '../setup/setup.service';
import { Global } from '@shared/services';
import { VisualizationReadyEvent } from '@shared/models';
import { select, Store } from '@ngrx/store';
import { getPresentationLocked } from '../redux/configs/selectors';
import { getPresentationPlans } from '../presentation.selectors';
import { AppState } from '../../../reducers';
import { CareerPlan } from '@core/model';

@UntilDestroy()
@Component({
  selector: 'ensight-target-premium',
  templateUrl: './target-premium.component.html',
  styleUrls: ['./target-premium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetPremiumComponent implements OnInit, AfterViewInit {
  plans: CareerPlan[];
  isPresentationLocked$: Observable<boolean>;
  hasNotes: boolean;

  @Output() removePageEvent = new EventEmitter();
  @Output() visualizationReadyEvent = new EventEmitter<VisualizationReadyEvent>();

  constructor(
    public global: Global,
    public setupService: SetupService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(getPresentationPlans), untilDestroyed(this)).subscribe((plans: CareerPlan[]) => {
      this.plans = sortBy(plans, 'order');
      this.hasNotes = this.plans.some(plan => plan.configjson.metadata.note);
      this.cdr.markForCheck();
    });
    this.isPresentationLocked$ = this.store.pipe(select(getPresentationLocked));
  }

  ngAfterViewInit(): void {
    this.visualizationReadyEvent.emit({ id: 'target_premium' });
  }

  removePage(): void {
    this.removePageEvent.emit();
  }
}
