import { createAction, props } from '@ngrx/store';

import { GlobalConfig, PageConfig, PlanMetric, SingleViewPlanMetric, SpreadsheetConfig } from '@shared/models';
import { ActivePlan } from '@core/model';

export const saveConfigs = createAction(
  '[Configs] Save',
  props<{ alertMessage?: string; saveLockedSettings?: boolean }>()
);

export const configsUpdating = createAction('[Configs] Updating', props<{ value: boolean }>());

export const presentationConfigsReset = createAction('[Configs] Configs State Reset');

export const removeConfig = createAction('[Configs] Remove Config', props<{ uiId: string }>());

export const resetConfig = createAction('[Configs] Reset Config');

export const addConfig = createAction('[Configs] Add Config', props<{ config: GlobalConfig & PageConfig }>());

export const updateShowPreview = createAction(
  '[Configs] Update Show Preview',
  props<{ uiId: string; checkedState: boolean; isParentEnabled?: boolean }>()
);

export const setDisabledPage = createAction(
  '[Configs] Set Disabled Page',
  props<{ uiId: string; checkedState: boolean }>()
);

export const updatePageOrdering = createAction(
  '[Configs] Update Page Ordering',
  props<{ orderedUiIds: string[]; orderedPageOrder: number[] }>()
);

export const updateActivePlansOrdering = createAction(
  '[Configs] Update Active Plans Ordering',
  props<{ orderedPlansIds: number[] }>()
);

export const setActivePlans = createAction(
  '[Configs] Set Active Plans',
  props<{
    configsActivePlans: { [key: string]: ActivePlan[] };
  }>()
);

export const setSpreadsheetSettings = createAction(
  '[Configs] Set Spreadsheet Settings',
  props<{
    spreadsheetDrawConfig: { [key: string]: SpreadsheetConfig };
  }>()
);

export const setPlanMetrics = createAction(
  '[Configs] Set Plan Metrics',
  props<{
    configsPlanMetrics: {
      [key: string]: (PlanMetric & SingleViewPlanMetric)[];
    };
  }>()
);

export const updateActivePlans = createAction(
  '[Configs] Update Active Plans',
  props<{
    uiId: string;
    metricId: number | string;
    fields: Partial<ActivePlan>;
  }>()
);

export const setPageName = createAction(
  '[Configs] Set Page Name',
  props<{
    uiId?: string;
    planId?: string | number;
    pageName: string;
  }>()
);

export const configsPageAreaValue = createAction(
  '[Configs] Presentation configs Page Area Value',
  props<{ area: boolean; uiId: string }>()
);
