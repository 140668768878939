import { Injectable } from '@angular/core';

import { template } from 'lodash';

import { md5 } from '@core/utils';
import { WizardModalResponse } from '@shared/models';

@Injectable()
export class TinyEditorService {
  static extendContent(key: string, content: string, values: WizardModalResponse): string {
    let result: string;
    switch (key) {
      case 'basicInsertButton':
        result = TinyEditorService.extendMetadata(content, values);
        break;
      default:
        result = content;
        break;
    }

    return result;
  }

  static extendMetadata(content: string, values: WizardModalResponse, styleId?: string): string {
    styleId = styleId ?? this.generateId(`${Math.random()}`);
    const data = Object.keys(values).reduce(
      (acc, key) => ({
        ...acc,
        [key]: values[key] && values[key].toString().replace(/^\s/g, ''),
      }),
      {}
    );

    return template(content)({
      ...data,
      styleId,
    });
  }

  static generateId(additionalString = ''): string {
    return `u${md5(new Date().toString() + additionalString)}`;
  }
}
