import { createSelector } from '@ngrx/store';
import { find } from 'lodash-es';

import { AppState } from '../../../reducers';
import { DEFAULT_RETIREMENT_COLORS } from './retirement-shortfall/retirement-shortfall.constants';
import { SetupState } from '@shared/models';

export const getGlobalReducer = (state: AppState): SetupState => state.setup;

export const getDisclosureText = createSelector(getGlobalReducer, (state: SetupState) => state.disclosureText);

export const getSelectedPlansValues = createSelector(
  getGlobalReducer,
  (state: SetupState) => state.plans.selectedPlans
);

export const getEndPages = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.endPages.data;
});

export const getSelectedPageId = createSelector(getGlobalReducer, (state: SetupState) => state.selectedPageId);

export const getSetupLoading = createSelector(getGlobalReducer, (state: SetupState) => state.loading);

export const getSelectedPage = createSelector(getGlobalReducer, (state: SetupState) => state.selectedPage);

export const getSalesConcepts = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.salesConcepts.data;
});

export const getSalesConceptsCount = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.salesConcepts.count;
});

export const getSalesConceptById = (uiId: string) =>
  createSelector(getGlobalReducer, (state: SetupState) => {
    return find(state.salesConcepts.data, { config: { uiId } });
  });

export const getDependentPagesConfig = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.dependentPagesConfig.data;
});

export const getDependentPages = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.dependentPages.data;
});

export const getDependentPagesPayload = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.dependentPages;
});

export const getSalesConceptDependentLoaded = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.dependentPages.salesConceptDependentLoaded;
});

export const getSingleViewTabs = createSelector(getGlobalReducer, (state: SetupState) => state.singleViewTabs);

export const getFullEndPages = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.endPages;
});

export const getEndPagesLoading = createSelector(getGlobalReducer, (state: SetupState) => state.endPages.loading);

export const getSalesConceptsLoading = createSelector(
  getGlobalReducer,
  (state: SetupState) => state.salesConcepts.loading
);

export const getSalesConceptsLoaded = createSelector(
  getGlobalReducer,
  (state: SetupState) => state.salesConcepts.loaded
);

export const salesConceptModalRequestsPending = createSelector(
  getGlobalReducer,
  (state: SetupState) => state.salesConcepts.pendingRequests
);

export const pendingRequests = createSelector(
  getSalesConceptsLoading,
  salesConceptModalRequestsPending,
  (salesConceptsLoading: boolean, requestsPending: boolean) => salesConceptsLoading || requestsPending
);

export const getSalesConceptsModalAvailable = createSelector(
  getGlobalReducer,
  (state: SetupState) => state.salesConcepts.modalAvailable
);

export const getDependentPagesLoading = createSelector(
  getGlobalReducer,
  (state: SetupState) => state.dependentPages.loading
);

export const getDependentPagesPendingRequests = createSelector(
  getDependentPagesLoading,
  pendingRequests,
  (dependentPagesLoading: boolean, requestsPending: boolean) => dependentPagesLoading || requestsPending
);

export const getPlanLoading = createSelector(getGlobalReducer, (state: SetupState) => state.plans.loading);

export const getChartColorScheme = (pageId: string) =>
  createSelector(getGlobalReducer, (state: SetupState) => {
    if (pageId === 'retirement_shortfall') {
      return DEFAULT_RETIREMENT_COLORS;
    }

    return state.colorScheme;
  });

export const getNavbarCustomPages = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.navBarCustomPages.data;
});

export const getNavbarSalesConceptsPages = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.navBarCustomPages.data.salesConcepts;
});

export const getNavbarDependentPages = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.navBarCustomPages.data.dependentPages;
});

export const getNavbarEndPages = createSelector(getGlobalReducer, (state: SetupState) => {
  return state.navBarCustomPages.data.endPages;
});
