import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

import { CoverSheetPlaceholders } from './cover-sheet-placeholder.constant';
import { CoverSheetPlaceholderItem } from '../cover-sheet-modal.interfaces';
import { CoverSheetModalService } from '../cover-sheet-modal.service';

@Component({
  selector: 'ep-cover-sheet-placeholder',
  templateUrl: './cover-sheet-placeholder.component.html',
  styleUrls: ['./cover-sheet-placeholder.component.scss'],
})
export class CoverSheetPlaceholderComponent {
  menuItems = CoverSheetPlaceholders;
  dropdownOpen = false;

  @ViewChild('dropdown') dropdown: ElementRef;

  constructor(private coverSheetModalService: CoverSheetModalService) {}

  @HostListener('document:click', ['$event']) clickOut(event: MouseEvent): void {
    const target = <HTMLElement>event.target;

    if (!this.dropdown.nativeElement.contains(target)) {
      this.dropdownOpen = false;
    }
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  select(item: CoverSheetPlaceholderItem): void {
    this.coverSheetModalService.setPlaceholder(item);
    this.dropdownOpen = false;
  }
}
