import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { TreeInsertsService } from '../../../services';
import { TreeItem } from '@assurance/bootstrap';
import { IResponseSelected } from '@assurance/bootstrap/tree/tree.interface';
import { Insert } from '@shared/models';
import { AppState } from '../../../../../reducers';
import { setCustomPage } from '../../../redux';

@UntilDestroy()
@Component({
  selector: 'ep-tree-inserts',
  templateUrl: './tree-inserts.component.html',
  styleUrls: ['./tree-inserts.component.scss'],
})
export class TreeInsertsComponent implements OnInit, OnDestroy {
  tree: TreeItem[];
  isLoading: boolean;

  constructor(
    private treeInsertService: TreeInsertsService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.watchTreeOfInserts();
  }

  ngOnDestroy(): void {
    this.store.dispatch(setCustomPage({ customPage: null }));
  }

  selectedInsert(event: IResponseSelected): void {
    this.treeInsertService.setSelectedInserts(event.selectedValues as unknown as Insert[]);
  }

  private watchTreeOfInserts(): void {
    this.isLoading = true;
    this.treeInsertService
      .getTreeOfInserts()
      ?.pipe(first(), untilDestroyed(this))
      .subscribe((tree: TreeItem[]) => {
        this.tree = tree;
        this.isLoading = false;
        this.cd.markForCheck();
      });
  }
}
