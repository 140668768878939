export const UPLOAD_CONFIG_VALIDATIONS = [
  {
    type: 'extension',
    value: ['bmp', 'gif', 'jpeg', 'jpg', 'png', 'svg'],
    errorMsg: 'Caution! Please, upload a file with a valid extension (bmp, gif, jpeg, jpg, png, svg)',
  },
  {
    type: 'max-size',
    value: 1000000,
    errorMsg: 'Caution! The uploaded file is too big. Maximum allowed file size is 1 MB',
  },
];
