import { Directive, ElementRef, DoCheck, Input, Renderer2 } from '@angular/core';

import { get, has } from 'lodash-es';

import { Global } from '@shared/services';

@Directive({
  selector: '[ensightTitleFormat]',
})
export class EnsightTitleFormatDirective implements DoCheck {
  @Input() set val(val: string) {
    this.updateHtml(val);
  }
  @Input() set cutVal(val: string) {
    this.cutTitle(val);
  }

  constructor(private el: ElementRef, private global: Global, private renderer: Renderer2) {}

  public ngDoCheck(): void {
    if (this.global.getPresentation && this.global.getPresentation.metadata) {
      this.updateHtml(this.el.nativeElement.innerHTML);
    }
  }

  private updateHtml(innerHTML: string) {
    if (innerHTML.trim()) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.global.getTitleByType(innerHTML));
    }
  }

  private cutTitle(innerHTML: string) {
    if (innerHTML && get(this.global, 'getPresentation.metadata')) {
      const metadata = this.global.getPresentation.metadata;

      innerHTML = innerHTML.replace(new RegExp(metadata.guaranteed.full, 'gi'), `${metadata.guaranteed.short}`);
      innerHTML = innerHTML.replace(new RegExp(metadata.non_guaranteed.full, 'gi'), `${metadata.non_guaranteed.short}`);

      if (has(metadata, 'historical')) {
        innerHTML = innerHTML.replace(new RegExp(metadata.historical.full, 'gi'), `${metadata.historical.short}`);
        innerHTML = innerHTML.replace(
          new RegExp(metadata.hypotheticalRate.full, 'gi'),
          `${metadata.hypotheticalRate.short}`
        );
        innerHTML = innerHTML.replace(
          new RegExp(metadata.hypotheticalGross.full, 'gi'),
          `${metadata.hypotheticalGross.short}`
        );
        innerHTML = innerHTML.replace(
          new RegExp(metadata.hypotheticalNegative.full, 'gi'),
          `${metadata.hypotheticalNegative.short}`
        );
      }

      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', innerHTML);
    }
  }
}
