import { createSelector } from '@ngrx/store';
import { State as PresentationState } from '../../presentation.reducer';
import { getPresentationReducer, getRules, getXAxisSource } from '../../presentation.selectors';
import { DataLimitsSource, GlobalConfig, PageConfig } from '@shared/models';
import { get } from 'lodash-es';
import { PAGE_TYPE_FIELD_BY_PERMISSION, PRODUCT_TABLE_UIID } from '@shared/constants';
import { getProfileGroupPermissions } from '@ngrx-app/global.selectors';
import { XAxisSourceType } from '@core/model';

export const getPresentationConfigs = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.configs.data
);

export const getPresentationInitialLoadingConfigs = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.initialLoadingConfigs.data
);

export const getPresentationConfigsUpdating = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.configs.updating
);

export const getConfigsDataLimits = createSelector(
  getPresentationConfigs,
  getRules,
  getXAxisSource,
  (configs, rules, xAxisSource): DataLimitsSource => {
    return {
      topIrr: get(configs, '[0].config.topIrr'),
      bottomIrr: get(configs, '[0].config.bottomIrr'),
      maxAgeDisplay: get(configs, '[0].config.maxAgeDisplay') || get(rules, 'maxAge') || null,
      minAgeDisplay: get(configs, '[0].config.minAgeDisplay'),
      xAxisSource,
    };
  }
);

export const getGraphValue = createSelector(getPresentationConfigs, (configs): XAxisSourceType => {
  const graphValues = configs.map((item: any) => item?.config?.chartConfig?.xAxisSource).filter((item: any) => item);

  return graphValues.filter((val, index, values) => values.indexOf(val) === index)[0];
});

export const getPresentationPageConfig = (pageId: string, pdfConfig?: PageConfig[]) =>
  createSelector(getPresentationReducer, (state: PresentationState) =>
    (pdfConfig || state.configs.data).find(config => config.config.uiId === pageId)
  );

export const getPresentationSingleViewConfigs = createSelector(getPresentationReducer, (state: PresentationState) =>
  state.configs.data.filter(config => (config as PageConfig).config.isSinglePolicy)
);

export const getPresentationGlobalConfig = createSelector(
  getPresentationReducer,
  (state: PresentationState) => state.configs.data[0] as GlobalConfig
);

export const getPresentationLocked = createSelector(
  getPresentationGlobalConfig,
  (state: GlobalConfig) => state?.config.locked
);

export const getGlobalMaxAgeDisplay = createSelector(
  getPresentationGlobalConfig,
  (state: GlobalConfig) => state.config.maxAgeDisplay
);

export const getGlobalMinAgeDisplay = createSelector(
  getPresentationGlobalConfig,
  (state: GlobalConfig) => state.config.minAgeDisplay
);

export const getGlobalMaxYDisplay = createSelector(
  getPresentationGlobalConfig,
  (state: GlobalConfig) => state.config.topIrr
);

export const getGlobalMinYDisplay = createSelector(
  getPresentationGlobalConfig,
  (state: GlobalConfig) => state.config.bottomIrr
);

export const getPermittedConfigs = createSelector(
  getPresentationConfigs,
  getProfileGroupPermissions,
  (configs, groupPermissions, { isPdf }: { isPdf: boolean } = { isPdf: false }) => {
    const permissions = groupPermissions || [];

    return configs.filter(item => {
      let isValid = item.config.checkPermission
        ? permissions.some(
            permission => item.config.uiId === permission || item.config[PAGE_TYPE_FIELD_BY_PERMISSION.get(permission)]
          )
        : true;

      if (item.config.uiId && item.config.uiId === 'cover') {
        isValid = permissions.includes('add_cover_letter');
      }

      if (isPdf && item.config.uiId === PRODUCT_TABLE_UIID && item.config.showPreview) {
        isValid = true;
      }

      return isValid;
    });
  }
);
