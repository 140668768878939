import { Component, ChangeDetectionStrategy, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../../../../reducers';
import * as Actions from '../../../redux/placeholders-wizard.actions';
import { INSERT_TYPES } from '@shared/constants';
import { InsertType } from '@core/model';
import { setCustomPage, setInserts } from '../../../redux';

@Component({
  selector: 'ep-insert-type',
  templateUrl: './insert-type.component.html',
  styleUrls: ['./insert-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertTypeComponent implements OnInit, OnChanges {
  @Input() selectedInsertType: InsertType;

  insertTypes = INSERT_TYPES;

  constructor(private store: Store<AppState>) {}

  ngOnChanges(): void {
    this.insertTypes.forEach(insertType => (insertType.checked = insertType.type === this.selectedInsertType));
  }

  ngOnInit(): void {
    // need to dispatch this action in order to disable the next button in one case
    this.store.dispatch(setCustomPage({ customPage: null }));
    this.store.dispatch(Actions.resetDynamicFormFields());
  }

  onTypeSelect({ id, type }: any): void {
    this.store.dispatch(setInserts({ inserts: null }));
    this.store.dispatch(setCustomPage({ customPage: null }));
    this.store.dispatch(Actions.setInsertTypeStates({ id, insertType: type }));
  }
}
