import { Inject, Injectable } from '@angular/core';

import { LOCAL_STORAGE_TOKEN } from '@core/constant';

@Injectable()
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE_TOKEN) private localStorage: Storage) {}

  setData<T>(key: string, data: T): void {
    this.localStorage.setItem(key, JSON.stringify(data));
  }

  getData<T>(key: string): T {
    const data = this.localStorage.getItem(key);

    return data ? (JSON.parse(data) as T) : null;
  }

  getNotJSONData(key: string): string {
    return this.localStorage.getItem(key);
  }

  removeItem(key: string): void {
    this.localStorage.removeItem(key);
  }

  clear(): void {
    this.localStorage.clear();
  }
}
