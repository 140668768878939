import { Component, ViewChild } from '@angular/core';
import { ModalEvents } from '@se/common';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { APPLY_MODAL_EVENT } from './confirm-agreement.constants';

@Component({
  selector: 'ensight-confirm-agreement-modal',
  styleUrls: ['./confirm-agreement.component.scss'],
  templateUrl: 'confirm-agreement.component.html',
})
export class ConfirmAgreementModalComponent {
  @ViewChild('confirmAgreementChildModal', { static: false })
  public confirmAgreementChildModal: ModalDirective;

  public windowClass: string;
  public modalInitComplete = false;
  public modalState: Subject<any> = new Subject();

  public openModal({ windowClass }: any) {
    this.windowClass = windowClass;

    this.modalInitComplete = true;
    setTimeout(() => this.confirmAgreementChildModal.show(), 100);

    return new Promise(res => {
      this.modalState.subscribe(data => res(data));
    });
  }

  public onAgreementOutput({ type }: { type: ModalEvents }) {
    if (type === APPLY_MODAL_EVENT) {
      this.ok();
    } else {
      this.cancel();
    }
  }

  public cancel() {
    this.modalState.next(false);
    this.modalInitComplete = false;
    this.modalState.observers.pop();
  }

  private ok() {
    this.modalState.next(true);
    this.modalInitComplete = false;
    this.modalState.observers.pop();
  }
}
