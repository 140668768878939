import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { cloneDeep, orderBy, find, minBy, maxBy, some, isEqual } from 'lodash-es';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService } from '@se/common';
import { ModalRef, ModalConfig } from '@assurance/bootstrap';

import { CareerPlan, ResponseType, TextPlaceholder, VariablePlaceholder } from '@core/model';
import {
  Carrier,
  Carriers,
  CustomPageInsert,
  CustomPageInsertMetadata,
  CustomPageValues,
  DataSourcesConfig,
  PageConfig,
} from '@shared/models';
import { APIService, GAService, Global } from '@shared/services';
import { NUMBER_REGEXP } from '@shared/constants';
import { CustomPageService } from '../../setup/custom-page/custom-page.service';
import {
  getSalesConceptLoadingSuccess,
  getSalesConceptsLoadingFailure,
  getSalesConceptsLoadingPending,
  salesConceptModalRequestsPending,
} from '../../setup/setup.actions';
import { ERROR_MESSAGE } from '../../setup/sale-concept/sale-concept.constants';
import { SalesConceptModalService } from './sales-concept-modal.service';
import { SetupService } from '../../setup/setup.service';
import { minSelectedCheckboxes, maxSelectedCheckboxes } from '../../../../modules/custom-pages/utils';
import { MAX_NAME_LENGTH, BUTTON_LABELS, FIRST_SALES_CONCEPT_INTERNALID } from './sales-concept.const';
import { toggleSettingsModal } from '../../../modals/redux/page-settings.actions';
import { FormErrors, PlansWithCustomFields } from './sales-concept-modal.model';
import { INSERT_TYPE } from '@core/enums';
import { getValidatorsForEditablePlaceholder } from '@shared/utils';

@UntilDestroy()
@Component({
  selector: 'ensight-sales-concept-modal',
  templateUrl: './sales-concept-modal.component.html',
  styleUrls: ['./sales-concept-modal.component.scss', '../shared/sidebar-modals.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesConceptModalComponent implements OnInit {
  activePageContainer: CustomPageValues;
  inserts: CustomPageInsert<VariablePlaceholder>[];
  salesConcepts: CustomPageValues[];
  filteredSalesConcepts: CustomPageValues[] = [];
  plans: PlansWithCustomFields[];
  showSpinner: boolean;
  showSearchSpinner = false;
  selectedView = 0;
  selectedPlans: Array<number> = [];
  salesConceptForm: FormGroup;
  settingsForm: any;
  previewImageLoading: boolean;
  inputPattern: RegExp;
  carriersList: Carrier[] = [];
  searchControl = new FormControl();
  views = [
    {
      title: 'Select Sales Story',
      onSubmit: (): void => this.plansSelectingView(),
    },
    {
      title: 'Select Product',
      onSubmit: (): void => (this.inserts?.length ? this.settingsStep() : this.submitPage()),
    },
    {
      title: 'Sales Story Settings',
      onSubmit: (): void => this.submitPage(),
    },
  ];

  private dataSourcesList: DataSourcesConfig[] = [];
  private previousSelectedPlans: number[] = [];

  get activePage(): CustomPageValues {
    return this.activePageContainer;
  }

  set activePage(page: CustomPageValues) {
    this.activePageContainer = page;
  }

  get formErrors(): FormErrors {
    let errorMessage = '';

    if (this.salesConceptForm) {
      const { title } = this.formControls;

      if (title.errors && title.errors.required) {
        errorMessage += 'Sales story name is required.';
      } else if (title.errors && title.errors.maxlength) {
        errorMessage += `Sales story name must be less then ${MAX_NAME_LENGTH} symbols.`;
      }
    } else if (!this.activePageAvailable && this.salesConcepts) {
      const minOfAll = minBy(this.salesConcepts, 'productsRange[0]');
      errorMessage = `The Sales Story requires at least ${minOfAll.productsRange[0]} plans in the presentation.`;
    }

    const sidebarPage = (index, min: number, carrierUiIds: string[] = []): string => {
      let eligibleProductTypes;

      if (this.salesConcepts[index].eligibleProductTypes) {
        eligibleProductTypes = this.salesConcepts[index].eligibleProductTypes.map(p => p.label).join(', ');
      }

      const countText = `\nThe Sales Story requires ${min} plans in the presentation`;
      const msgCount = (this.isPageDisabledByPlansCount(this.salesConcepts[index]) && countText) || '';
      const msgTypes =
        (!this.isProductTypeValid(this.salesConcepts[index]) &&
          `\nThis sales story is eligible to be used only with the following product types: ${eligibleProductTypes}`) ||
        '';
      const msgCarriers =
        (this.isCarriersDisable(this.salesConcepts[index]) && this.carriersDisableMsg(carrierUiIds)) || '';
      const fullMsg = `${msgCount}${msgTypes}${msgCarriers}`;

      return !msgCount && fullMsg ? `${countText}${fullMsg}` : fullMsg;
    };

    return {
      errorMessage: (errorMessage.length && errorMessage) || null,
      sidebarPage,
    };
  }

  get activePageAvailable(): boolean {
    return Boolean(this.activePage && this.activePage.config);
  }

  get productRange(): string {
    const [min, max] = this.activePage.productsRange;

    if (max === 1) {
      return 'Select a product to be represented';
    } else if (min === max) {
      return `Select ${min} products in order they will be represented`;
    }

    return `Select ${min} to ${max} products in order they will be represented`;
  }

  get formControls(): any {
    return this.salesConceptForm.controls;
  }

  get formAvailable(): boolean {
    const viewValidation = [
      this.activePageAvailable,
      this.salesConceptForm && this.salesConceptForm.valid && !this.salesConceptForm.pristine,
      this.settingsForm && this.settingsForm.valid,
    ];

    return viewValidation[this.selectedView];
  }

  constructor(
    public modal: ModalRef,
    public config: ModalConfig,
    private salesConceptModalService: SalesConceptModalService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private customPageService: CustomPageService,
    private store: Store,
    private global: Global,
    private apiService: APIService,
    private gaService: GAService,
    private alertService: AlertService,
    private setupService: SetupService
  ) {}

  ngOnInit(): void {
    this.inputPattern = new RegExp(NUMBER_REGEXP);

    if (!this.global.isSharedPresentation()) {
      this.getCarriersList().subscribe();
    }

    const salesConcepts$ = this.config.data?.salesConcepts || of([]);

    salesConcepts$
      .pipe(
        switchMap((salesConcepts: CustomPageValues[]) => {
          if (salesConcepts?.some(salesConcept => salesConcept.isEligibleForDataSources)) {
            const dataSources$ = this.config.data?.dataSources || of([]);

            return dataSources$.pipe(
              tap(dataSources => {
                this.dataSourcesList = dataSources as DataSourcesConfig[];
              }),
              map(() => salesConcepts)
            );
          } else {
            return of(salesConcepts);
          }
        }),
        map((salesConcepts: CustomPageValues[]) =>
          salesConcepts?.filter(salesConcept => {
            const plansClone = cloneDeep(this.config.data.plans);
            this.plans = this.salesConceptModalService.addIsShownField(plansClone);

            if (this.config.data.editMode) {
              return true;
            }

            const planDetails = this.plans.map(plan => ({
              productType: plan.productType,
              carrierCode: plan.carrierCode,
            }));

            const productTypeEligibility =
              !salesConcept.eligibleProductTypes.length ||
              salesConcept.eligibleProductTypes.some(productType =>
                planDetails.some(
                  detail => detail.productType === productType.productType || detail.productType === productType.label
                )
              );

            const carrierIdEligibility =
              !salesConcept.carrierUiIds?.length ||
              salesConcept.carrierUiIds.some(carrierUiId =>
                planDetails.some(detail => detail.carrierCode === carrierUiId)
              );
            let dataSourceEligibility = true;

            if (salesConcept.isEligibleForDataSources && salesConcept.eligibleDataSources.length) {
              dataSourceEligibility = this.customPageService.hasEnoughEligibleProducts(salesConcept);
            }

            return productTypeEligibility && carrierIdEligibility && dataSourceEligibility;
          })
        )
      )
      .subscribe(filteredSalesConcepts => {
        this.salesConcepts = filteredSalesConcepts.sort(
          ({ salesConceptLabel: labelA }, { salesConceptLabel: labelB }) => labelA.localeCompare(labelB)
        );
        this.sortSalesConcepts();

        if (this.config.data.editMode) {
          this.choosePage(this.salesConcepts[0]);
          this.initEditMode();
        } else {
          for (let index = 0; index < this.salesConcepts.length; index++) {
            if (!this.isPageDisabled(this.salesConcepts[index])) {
              this.choosePage(this.salesConcepts[index]);
              break;
            }
          }
        }

        this.filteredSalesConcepts = this.salesConcepts;

        this.cdr.markForCheck();
      });

    this.watchForSearch();
  }

  isActivePage(id: string): boolean {
    return this.activePageAvailable && this.activePage.config.uiId === id;
  }

  onSaveNote(plan: PlansWithCustomFields, newNote: string): void {
    const planToUpdate = this.global.getCurrentCarrierPlans.find(
      (carrierPlan: CareerPlan) => carrierPlan.id === plan.id
    );
    this.plans = this.plans.map(p => (p.id === plan.id ? { ...p, productNote: newNote } : p));

    this.setupService
      .updatePlanFromPage(planToUpdate, newNote)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.gaService.sendEditProductNoteEvent();
      });
  }

  private sortSalesConcepts(): void {
    this.salesConcepts.sort((a, b) => {
      const isADisabled = this.isPageDisabled(a);
      const isBDisabled = this.isPageDisabled(b);

      if (isADisabled !== isBDisabled) {
        return isADisabled ? 1 : -1;
      }

      const isAInvalid = !this.isProductTypeValid(a);
      const isBInvalid = !this.isProductTypeValid(b);

      if (isAInvalid !== isBInvalid) {
        return isAInvalid ? 1 : -1;
      }

      return 0;
    });
  }

  getButtonLabel(view: number): string {
    switch (view) {
      case 0:
        return this.activePage && this.productsQuantityIsEqual() && !this.inserts.length
          ? BUTTON_LABELS.add
          : BUTTON_LABELS.next;
      case 1:
        return this.inserts?.length
          ? BUTTON_LABELS.next
          : this.config.data.editMode
          ? BUTTON_LABELS.submit
          : BUTTON_LABELS.add;
      case 2:
        return this.config.data.editMode ? BUTTON_LABELS.submit : BUTTON_LABELS.add;
      default:
        return BUTTON_LABELS.submit;
    }
  }

  isBackBtnShown(): boolean {
    return (
      !(this.config.data.editMode && this.selectedView === 1) &&
      this.selectedView !== 0 &&
      !(this.productsQuantityIsEqual() && this.config.data.editMode) &&
      !this.global.isSharedPresentation()
    );
  }

  getErrorMessages(errors: Record<string, unknown>, insert: CustomPageInsert<VariablePlaceholder>): string[] {
    return this.customPageService.getErrorMessagesForEditablePlaceholder(
      insert.metadata.insertType,
      insert.metadata,
      errors ?? {}
    );
  }

  choosePage(page: CustomPageValues): void {
    if (this.activePage?.config?.uiId === page?.config?.uiId) {
      return;
    }

    this.activePage = page;

    const pageInserts = this.activePage?.inserts as CustomPageInsert<VariablePlaceholder>[];

    this.setFilteredInserts(pageInserts);

    if (this.activePage?.previewFilePath) {
      this.previewImageLoading = true;
    }
  }

  onCancel(): void {
    this.store.dispatch(toggleSettingsModal({ open: false }));
    this.modal.close();
  }

  onBack(): void {
    if (this.productsQuantityIsEqual() && this.selectedView === 2) {
      this.selectedView = 0;
    } else if (this.selectedView === 1) {
      this.selectedView -= 1;
      this.salesConceptForm = null;
      this.selectedPlans = [];
      this.plans = this.plans.map(plan => ({ ...plan, isShown: false }));
    } else {
      this.previousSelectedPlans = this.selectedPlans;
      this.selectedView -= 1;
    }

    this.settingsForm = null;
  }

  isProductTypeValid(salesConcept: CustomPageValues): boolean {
    const productTypes = [];

    if (salesConcept.eligibleProductTypes) {
      salesConcept.eligibleProductTypes.forEach(plan => {
        productTypes.push(plan.label);
        productTypes.push(plan.productType);
      });
    }

    const countOfValidProducts = this.plans.filter(plan => productTypes.includes(plan.productType)).length;

    return !salesConcept.eligibleProductsActive || salesConcept.productsRange[0] <= countOfValidProducts;
  }

  isPageDisabled(page: CustomPageValues): boolean {
    return this.isPageDisabledByPlansCount(page) || this.isCarriersDisable(page);
  }

  findById(carrierPlanId: number): string {
    return String(this.selectedPlans.findIndex(el => el === carrierPlanId) + 1);
  }

  private getTooltipMessage(plan: PlansWithCustomFields): string {
    if (this.isProductDisableByCarriers(this.activePage, [plan])) {
      return this.carriersDisableMsg(this.activePage?.carrierUiIds);
    } else if (this.isProductTypeInvalid(plan)) {
      return this.eligibleProductTypesMessage(this.activePage?.eligibleProductTypes);
    } else if (this.isProductDisableByDataSources(plan)) {
      return this.eligibleDataSourceMessage();
    }

    return '';
  }

  isProductDisabled(index: number): boolean {
    const plan = this.plans[index];
    let isNotEligibleForDataSources = false;
    const isNotEligibleForCarriers =
      this.activePage.isEligibleForCarriers && !this.activePage.carrierUiIds?.includes(plan.carrierCode);

    if (this.activePage.isEligibleForDataSources) {
      const dataSource = this.global.getCurrentCarrierPlans.find(product => product.id === plan.id).app;
      isNotEligibleForDataSources =
        this.activePage.isEligibleForDataSources &&
        !this.activePage.eligibleDataSources?.map(ds => ds.toLowerCase()).includes(dataSource.toLowerCase());
    }

    return isNotEligibleForCarriers || this.isProductTypeInvalid(plan) || isNotEligibleForDataSources;
  }

  onPreviewImageLoad(): void {
    this.previewImageLoading = false;
  }

  checkboxEvent(index: number): void {
    this.setSelectedPlans(index);
  }

  handleRadioButtonEvent(index: number): void {
    const products = this.formControls.products.value.map((_, i) => i === index);
    this.formControls.products.setValue(products, { emitEvent: true });
    this.plans = this.plans.map((plan, i) => ({
      ...plan,
      isShown: i === index,
    }));
    const plan = this.plans[index];
    this.selectedPlans = [plan.id];
  }

  showRadioButtons(): boolean {
    const [min, max] = this.activePage.productsRange;

    return (min === 1 && max === 1) || this.productsQuantityIsEqual();
  }

  private watchForSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap(() => (this.showSearchSpinner = true)),
        debounceTime(500)
      )
      .subscribe((value: string) => {
        this.filterSalesConcepts(value);

        this.showSearchSpinner = false;
        this.cdr.detectChanges();
      });
  }

  private filterSalesConcepts(searchTerm: string): void {
    if (!searchTerm) {
      this.filteredSalesConcepts = this.salesConcepts;
    } else {
      this.filteredSalesConcepts = this.salesConcepts.filter(concept =>
        concept.salesConceptLabel.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  private isPageDisabledByPlansCount(page: CustomPageValues): boolean {
    return this.plans.length < page.productsRange[0];
  }

  private setSelectedPlans(index: number): void {
    const plan = this.plans[index];
    plan.isShown = this.formControls.products.value[index];

    if (plan.isShown) {
      this.selectedPlans.push(plan.id);
    } else {
      this.selectedPlans = this.selectedPlans.filter(el => el !== plan.id);
    }
  }

  private isProductDisableByCarriers(concept: CustomPageValues, plans: PlansWithCustomFields[]): boolean {
    return (
      concept?.isEligibleForCarriers &&
      concept?.carrierUiIds?.length &&
      !some(plans, item => concept.carrierUiIds.includes(item.carrierCode))
    );
  }

  private isCarriersDisable(concept: CustomPageValues): boolean {
    const plans = this.plans.filter(
      plan => !concept.isEligibleForCarriers || concept.carrierUiIds.includes(plan.carrierCode)
    );

    return plans.length < concept.productsRange[0];
  }

  private isProductDisableByDataSources(plan: PlansWithCustomFields): boolean {
    const product = this.global.getCurrentCarrierPlans.find(product => product.id === plan.id).app;

    return (
      this.activePage?.isEligibleForDataSources &&
      !this.activePage?.eligibleDataSources.map(ds => ds.toLowerCase()).includes(product.toLowerCase())
    );
  }

  private isProductTypeInvalid(plan: PlansWithCustomFields): boolean {
    const productTypes = [];

    if (this.activePage.eligibleProductTypes) {
      this.activePage.eligibleProductTypes.forEach(type => {
        productTypes.push(type.label);
        productTypes.push(type.productType);
      });
    }

    return this.activePage.eligibleProductsActive && !productTypes.includes(plan.productType);
  }

  private eligibleProductTypesMessage(productTypes): string {
    const eligibleProductTypes = productTypes.map(type => type.label).join(', ');

    return `This sales story is eligible to be used only with the following product types: ${eligibleProductTypes}`;
  }

  private eligibleDataSourceMessage(): string {
    const dataSourcesLabels = this.activePage.eligibleDataSources
      .map(dataSource => {
        const found = this.dataSourcesList?.find(item => item.key === dataSource);

        return found ? found.label : dataSource;
      })
      .join(', ');

    return `This sales story is eligible to be used only with the following data sources: ${dataSourcesLabels}`;
  }

  private carriersDisableMsg(carrierUiIds: string[]): string {
    if (!carrierUiIds) return '';

    const carriersName = carrierUiIds.map(
      carrier => this.carriersList.find(item => item.code === carrier)?.name || carrier
    );

    return `\nThis sales story is eligible to be used only with the following carriers: ${carriersName.join(', ')}`;
  }

  private productsQuantityIsEqual(): boolean {
    const [min, max] = this.activePage.productsRange;

    return min === 1 && max >= 1 && this.plans.length === 1;
  }

  private setCompiledData(): void {
    let compiledVariables: CustomPageInsert<VariablePlaceholder>[];
    const hasInsertsForCompilation = this.activePage.inserts.some(insert => {
      const defaultValue = insert.metadata.placeholderDefaultValue?.toString()?.trim();

      return (
        insert.metadata.insertType === INSERT_TYPE.variable &&
        defaultValue?.startsWith('{{') &&
        defaultValue?.endsWith('}}')
      );
    });
    const shouldRecompileInsert =
      this.activePage.insertsCompiled && !isEqual(this.previousSelectedPlans, this.selectedPlans);

    if (!this.config.data.editMode && (hasInsertsForCompilation || shouldRecompileInsert)) {
      this.showSpinner = true;
      this.customPageService
        .getCompiledInserts(this.activePage.config.uiId, this.selectedPlans)
        .pipe(untilDestroyed(this))
        .subscribe((data: CustomPageInsert[]) => {
          if (!data) return;

          compiledVariables = data.filter(
            insert => insert.metadata.insertType === INSERT_TYPE.variable
          ) as CustomPageInsert<VariablePlaceholder>[];

          this.processCompiledData(compiledVariables);
        });
    } else {
      this.initSettingsForm();
    }
  }

  private processCompiledData(compiledVariables: CustomPageInsert<VariablePlaceholder>[]): void {
    const compiledInserts = this.activePage.inserts.map(insert => {
      const compiledVariable = compiledVariables.find(
        compiledVariable => compiledVariable.config.uiId === insert.config.uiId
      );

      return compiledVariable || insert;
    });

    this.setFilteredInserts(compiledVariables);

    this.initSettingsForm();
    this.showSpinner = false;
    this.activePage = { ...this.activePage, inserts: compiledInserts, insertsCompiled: true };
    this.store.dispatch(getSalesConceptLoadingSuccess({ payload: [this.activePage] }));
    this.cdr.markForCheck();
  }

  private getCarriersList(): Observable<ResponseType<Carriers>> {
    return this.apiService.getCarriersList().pipe(
      tap((response: ResponseType<Carriers>) => {
        this.carriersList = response && response.data.data;
      }),
      untilDestroyed(this)
    );
  }

  private initEditMode(): void {
    this.selectedPlans = this.config.data.activePlans
      ? orderBy(this.config.data.activePlans, plan => plan.order)
          .map(activePlan => {
            const plan = find(this.plans, { id: activePlan.carrierPlanId });
            const isEligibleForCarriers =
              !this.salesConcepts[0].isEligibleForCarriers ||
              this.salesConcepts[0].carrierUiIds.includes(plan.carrierCode);

            if (plan && isEligibleForCarriers) {
              plan.isShown = activePlan.isShown;
            }

            return isEligibleForCarriers ? activePlan.carrierPlanId : null;
          })
          .filter(item => item)
      : [];
    this.plansSelectingView();

    if (this.inserts?.length && this.selectedPlans.length >= this.activePage.productsRange[0]) {
      this.settingsStep();
    }
  }

  private plansSelectingView(): void {
    this.initForm();

    if (this.productsQuantityIsEqual() && !this.config.data.editMode) {
      this.selectedView = this.inserts?.length ? (this.selectedView += 1) : this.selectedView;
      const products = this.formControls.products.value.map(() => true);
      this.formControls.products.setValue(products, { emitEvent: true });
      products.forEach((_, i) => {
        this.setSelectedPlans(i);
      });

      return this.inserts?.length ? this.settingsStep() : this.submitPage();
    }

    this.selectedView += 1;
  }

  private settingsStep(): void {
    if (this.inserts?.length) {
      this.setCompiledData();
    }

    this.selectedView += 1;
  }

  private submitPage(): void {
    if (!this.formAvailable) return;

    const insertsToUpdate = this.getInsertsToUpdate();
    this.showSpinner = true;

    if (this.global.isSharedPresentation()) {
      this.handleSubmitPageSuccess(null, insertsToUpdate);

      return;
    }

    if (this.config.data.editMode && this.config.data.pageConfig) {
      this.store.dispatch(getSalesConceptsLoadingPending());
      this.store.dispatch(salesConceptModalRequestsPending());
      this.salesConceptModalService
        .editPage(this.addFormFields as PageConfig)
        .pipe(untilDestroyed(this))
        .subscribe(
          res => this.handleSubmitPageSuccess(res, insertsToUpdate),
          () => {
            this.handleSubmitPageError();
            this.store.dispatch(getSalesConceptsLoadingFailure());
          }
        );

      return;
    }

    this.salesConceptModalService
      .addPage(this.addFormFields as CustomPageValues)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.handleSubmitPageSuccess(res, insertsToUpdate);
          this.gaService.sendAddPageEvent(this.addFormFields.label as string);
        },
        () => this.handleSubmitPageError()
      );
  }

  private getInsertsToUpdate(): { uiId: string; value: unknown }[] {
    if (this.settingsForm?.invalid || !this.settingsForm?.value) return;

    return Object.entries(this.settingsForm.value).map(([uiId, insertValue]) => {
      return {
        uiId,
        value: Number(insertValue),
      };
    });
  }

  private get addFormFields(): CustomPageValues | PageConfig {
    const activePlans = this.salesConceptModalService.getFilteredActivePlans(this.plans, this.selectedPlans);

    if (this.config.data.editMode) {
      this.config.data.pageConfig.config.activePlans = activePlans;
      this.config.data.pageConfig.pageName = this.formControls.title.value;

      return this.config.data.pageConfig;
    }

    const activePage = cloneDeep(this.activePage);
    const lastConfig = maxBy(this.config.data.presentationConfig, (item: any) => item.internalId);
    activePage.internalId = lastConfig ? lastConfig?.internalId + 1 : FIRST_SALES_CONCEPT_INTERNALID;
    activePage.config.uiId = this.customPageService.concatSalesConceptIds(
      activePage.config.uiId,
      activePage.internalId
    );
    activePage.config.activePlans = activePlans;
    activePage.label = this.formControls.title.value;

    return activePage;
  }

  private initSettingsForm(): void {
    if (!this.inserts?.length) {
      return;
    }

    this.settingsForm = this.fb.group({
      ...this.inserts.reduce((res, insert) => {
        return {
          ...res,
          [insert.config.uiId]: [
            this.customPageService.getInsertValue(this.config.data.customPagesConfigs, this.activePage, insert) ?? '',
            getValidatorsForEditablePlaceholder(
              insert.metadata as CustomPageInsertMetadata<TextPlaceholder & VariablePlaceholder>
            ),
          ],
        };
      }, {}),
    });
  }

  private initForm(): void {
    const [min, max] = this.activePage.productsRange;
    this.salesConceptForm = this.fb.group({
      title: [
        this.config.data.editMode ? this.config.data.pageConfig.pageName : this.activePage.salesConceptLabel,
        [Validators.required, Validators.maxLength(MAX_NAME_LENGTH)],
      ],
      products: this.fb.array(
        this.plans.map(plan => {
          const control = new FormControl(plan.isShown);
          plan.errorMessage = this.getTooltipMessage(plan);

          return control;
        }),
        [minSelectedCheckboxes(min), maxSelectedCheckboxes(max)]
      ),
    });
  }

  private handleSubmitPageError(): void {
    this.showSpinner = false;
    this.alertService.openAlert({
      type: 'error',
      body: ERROR_MESSAGE,
      autoClose: 5000,
    });
    this.cdr.markForCheck();
  }

  private handleSubmitPageSuccess(pageConfig?, insertsToUpdate?: { uiId: string; value: unknown }[]): void {
    this.modal.close({
      submitted: true,
      uiId: pageConfig?.config?.uiId,
      insertsToUpdate,
      pageConfig,
    });
    this.store.dispatch(toggleSettingsModal({ open: false }));
    this.showSpinner = false;
  }

  private setFilteredInserts(inserts): void {
    const callBack = (insert): boolean => {
      return (
        insert.metadata.insertType === INSERT_TYPE.variable &&
        !insert.metadata.hidden &&
        !insert.metadata.isInlineEditable
      );
    };

    this.inserts = this.global.isSharedPresentationView()
      ? inserts?.filter(insert => callBack(insert) && !insert.metadata.hiddenOnSharedView)
      : inserts?.filter(insert => callBack(insert));
  }
}
