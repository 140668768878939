import { Component } from '@angular/core';
import { DOMService } from '@shared/services';
import { Store, select } from '@ngrx/store';

import { AppState } from '../reducers';
import { getLoading } from '@ngrx-app/global.selectors';

@Component({
  selector: 'ensight-router-root-new',
  template: `
    <section class="h-100">
      <ep-loader [loading]="loading$ | async"></ep-loader>
      <router-outlet></router-outlet>
    </section>
  `,
})
export class RouterRootNewComponent {
  public loading$ = this.store.pipe(select(getLoading));
  public navItems: any;
  public showUserMenu: any;
  constructor(public domService: DOMService, public store: Store<AppState>) {}
}
