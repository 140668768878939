import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserMenuComponent } from './user-menu.component';
import { ButtonsModule, PopupModule } from '@se/common';
import { StyleEditorService } from '@shared/services';

@NgModule({
  declarations: [UserMenuComponent],
  imports: [CommonModule, PopupModule, ButtonsModule],
  exports: [UserMenuComponent],
  providers: [StyleEditorService],
})
export class UserMenuModule {}
