import { DEFAULT_HEADERS_FORMAT } from '@shared/constants';
import { TimeFormat } from '@core/constant';

const dateFormat = {
  useCustomFormat: true,
  dateFormat: TimeFormat.MMddyyyy,
  type: 'date',
  sortable: false,
};

export const CHOOSE_CUSTOM_PAGE_HEADERS = [
  {
    format: { ...DEFAULT_HEADERS_FORMAT, sortable: false },
    key: 'name',
    title: 'Page Name',
    order: 1,
  },
  {
    format: { ...DEFAULT_HEADERS_FORMAT, sortable: false },
    key: 'pageTypeText',
    title: 'Page Type',
    order: 2,
  },
  {
    format: { ...DEFAULT_HEADERS_FORMAT, sortable: false },
    key: 'createdBy',
    title: 'Created By',
    order: 3,
  },
  {
    format: {
      ...DEFAULT_HEADERS_FORMAT,
      ...dateFormat,
    },
    key: 'createdAt',
    title: 'Created',
    order: 4,
  },
  {
    format: {
      ...DEFAULT_HEADERS_FORMAT,
      ...dateFormat,
    },
    key: 'updatedAt',
    title: 'Updated',
    order: 5,
  },
];
