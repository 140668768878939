import { Injectable } from '@angular/core';

import { Client } from './client-section.model';
import { CareerPlan } from '@core/model';

@Injectable()
export class ClientService {
  getClientsData(plan: CareerPlan): Client[] {
    const clients = [];

    clients.push({
      age: plan.configjson.metadata.age,
      fullName: plan.configjson.metadata.client_name,
      sex: plan.configjson.metadata.sex,
    });

    if (plan.configjson.metadata.client_name_2) {
      clients.push({
        age: plan.configjson.metadata.age_2 || null,
        fullName: plan.configjson.metadata.client_name_2,
        sex: plan.configjson.metadata.sex_2,
      });
    }

    return clients;
  }
}
