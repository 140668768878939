import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ResponseType } from '@core/model';
import * as apiConstant from '@core/constant';
import { Insert } from '@shared/models';

// TODO: need to check if this service is needed in the folder shared

@Injectable()
export class InsertsApiService {
  constructor(private http: HttpClient) {}

  createInsert(insert: FormData): Observable<ResponseType<Insert>> {
    return this.http
      .post<ResponseType<Insert>>(`${apiConstant.contentService}/insert`, insert)
      .pipe(catchError(() => of({ data: {} } as ResponseType<Insert>)));
  }

  updateInsert(insert: FormData, id: string): Observable<ResponseType<Insert>> {
    return this.http
      .patch<ResponseType<Insert>>(`${apiConstant.contentService}/insert/${id}`, insert)
      .pipe(catchError(() => of({ data: {} } as ResponseType<Insert>)));
  }

  deleteInsert(insertUUID: string, customPageUIID: string): Observable<void> {
    return this.http.delete<void>(`${apiConstant.contentService}/insert/`, {
      params: new HttpParams({ fromObject: { insertUUID, customPageUIID } }),
    });
  }
}
