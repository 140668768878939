import { Injectable } from '@angular/core';

import { merge } from 'lodash-es';
import { isPDF } from '../utils';
import { ChartOptions, ModifiedChartOptions } from '@core/model';

@Injectable()
export class ChartOptionsService {
  private xAxisLabels = { eoy_age: 'Age', policy_year: 'Policy year' };

  public getOptions(data: ModifiedChartOptions): ChartOptions {
    const result = {
      chart: {
        duration: 0,
        type: data.type,
        xAxis: {
          axisLabel: data.xAxisMetricLabel ? data.xAxisMetricLabel : this.xAxisLabels[data.xAxisSource],
          axisLabelDistance: 20,
          tickFormat: this.tickFormatXAxis,
        },
        yAxis: {
          axisLabel: data.yKey,
          tickFormat: this.tickFormatYAxis.bind(this, data.isPercentYAxis),
        },
        margin: {
          left: 110,
        },
      },
      id: data.pageID,
      pinValue: data.pinValue,
      isPDF,
    };

    return data.isPdf ? merge(result, { chart: { margin: { left: 160 } } }) : result;
  }

  public tickFormatYAxis(isPercentYAxis: boolean, d: number): string {
    if (isPercentYAxis) {
      return `${d3.format(',.2f')(d)}%`;
    }

    return `$${d3.format(',')(d)}`;
  }

  public tickFormatXAxis(d: number): string {
    return d3.format('g')(d);
  }
}
