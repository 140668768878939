import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'planCurrency',
})
export class PlanCurrency implements PipeTransform {
  public transform(
    amount: string | number,
    currencySymbol: string,
    decimals: string | number,
    noValueLabel: string
  ): string {
    if (!amount || amount === 'N/A' || amount === 'NaN') {
      return noValueLabel;
    }

    return new CurrencyPipe('en-US').transform(amount, currencySymbol, 'symbol', '1.0-' + decimals);
  }
}
