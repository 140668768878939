import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonSeModule } from '@se/common';

import { TabEditingModalComponent } from './tab-editing-modal.component';

const components = [TabEditingModalComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, ReactiveFormsModule, CommonSeModule],
})
export class TabEditingModalModule {}
