import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { select, Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Editor } from 'tinymce';
import { DragulaService } from 'ng2-dragula';

import * as PlaceholdersWizardSelectors from '../../redux/placeholders-wizard.selectors';
import { AppState } from '../../../../reducers';
import { ACTIVE_PAGE_ON_EDIT, CUSTOM_PAGES_LIST_URL, KeyForCopy, UsedInPagesTitle } from '../../constants';
import {
  ChartPlaceholder,
  ExtendedPlaceholderMetadata,
  ImagePlaceholder,
  InsertType,
  Placeholder,
  VariablePlaceholder,
} from '@core/model';
import { INSERT_TYPE } from '@core/enums';
import { ClipboardService } from '@shared/services';
import { CustomElementConfig, UsedInItemList, WizardModalResponse } from '@shared/models';
import { ModalProviderService, PlaceholdersOrderService, PlaceholdersWizardService } from '../../services';
import { INSERT_TYPES } from '@shared/constants';
import * as PlaceholdersWizardActions from '../../redux/placeholders-wizard.actions';

@UntilDestroy()
@Component({
  selector: 'ep-custom-page-inserts',
  templateUrl: './custom-page-inserts.component.html',
  styleUrls: ['./custom-page-inserts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPageInsertsComponent implements OnInit, OnDestroy {
  @Input() editor: Editor;

  @Output() openPlaceholdersWizardModal = new EventEmitter<Placeholder>();
  @Output() createPlaceholder = new EventEmitter<{ data: WizardModalResponse; config: CustomElementConfig }>();
  @Output() deletePlaceholder = new EventEmitter<Placeholder>();
  @Output() isSaveDisabled = new EventEmitter<boolean>();

  getPlaceholders$ = this.store.pipe(
    select(PlaceholdersWizardSelectors.placeholdersMetadata),
    map(placeholders => placeholders.filter(placeholder => !placeholder.delete))
  );
  dragModelBag = 'insert-sections-bag';
  copiedIndex: number;
  keyForCopy = KeyForCopy;

  constructor(
    private store: Store<AppState>,
    private clipboardService: ClipboardService,
    private cdr: ChangeDetectorRef,
    private modalProvider: ModalProviderService,
    private placeholdersWizardService: PlaceholdersWizardService,
    private dragulaService: DragulaService,
    private placeholderOrderService: PlaceholdersOrderService
  ) {}

  ngOnInit(): void {
    this.initDragula();
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy(this.dragModelBag);
  }

  copyToClipboard(type: InsertType, key: string, index: number): void {
    const value = type === INSERT_TYPE.customMetric ? key : `${this.keyForCopy[type]}.${key}`;

    this.copiedIndex = index;
    this.clipboardService.copyExecCommand(value);
    this.cdr.markForCheck();
    this.closePopover();
  }

  openUsedInSalesConceptsModal(placeholder: Placeholder): void {
    if (placeholder.usedInPages.length) {
      const insertName = placeholder.placeholderName || placeholder.chartName || 'Product Description';

      this.modalProvider.openUsedInModal({
        name: `${insertName} ${UsedInPagesTitle}`,
        itemList: this.addLinkUsedInSalesConcepts(placeholder.usedInPages),
      });
    }
  }

  addPlaceholder(placeholder: ExtendedPlaceholderMetadata): void {
    const data: WizardModalResponse = {
      id: placeholder.id,
      type: INSERT_TYPES.find(item => item.type === placeholder.insertType).label,
      name: (placeholder as ChartPlaceholder).chartName || placeholder.placeholderName || 'Product description',
      height: (placeholder as ImagePlaceholder).height,
      width: (placeholder as ImagePlaceholder).width,
      hidden: (placeholder as VariablePlaceholder).hidden,
      hiddenOnSharedView: (placeholder as VariablePlaceholder).hiddenOnSharedView,
    };

    const config = {
      type: 'basicInsertButton',
      openWizard: true,
      config: {
        text: 'Insert...',
        tooltip: 'Inserts',
      },
    };

    this.createPlaceholder.emit({ data, config });
  }

  editPlaceholder(placeholder: Placeholder): void {
    this.store.dispatch(
      PlaceholdersWizardActions.setInsertTypeStates({
        id: placeholder.id,
        insertType: placeholder.insertType,
        activePageId: ACTIVE_PAGE_ON_EDIT[placeholder.insertType],
      })
    );

    this.openPlaceholdersWizardModal.emit(placeholder);
  }

  removePlaceholder(placeholder: Placeholder): void {
    this.placeholdersWizardService.deletePlaceholderStateById(placeholder).pipe(untilDestroyed(this)).subscribe();
    this.deletePlaceholder.emit(placeholder);
  }

  handlePlaceholdersOrder(direction: string, placeholders: Placeholder[], id: string): void {
    // todo: fix issue with non accessible properties to avoid use of parse/stringify
    placeholders = JSON.parse(JSON.stringify(placeholders.filter(pl => !pl.delete)));
    const selectedPlaceholder = placeholders.find(placeholder => placeholder.id === id);
    const index = placeholders.indexOf(selectedPlaceholder);
    const swapIndex = direction === 'up' ? index - 1 : index + 1;

    const swapIndexOrder = placeholders[swapIndex].order;
    placeholders[index] = placeholders[swapIndex];
    placeholders[index].order = selectedPlaceholder.order;
    placeholders[swapIndex] = selectedPlaceholder;
    placeholders[swapIndex].order = swapIndexOrder;
    this.store.dispatch(
      PlaceholdersWizardActions.setPlaceholdersDataSuccess({
        metadata: this.placeholderOrderService.handleOrdering(placeholders),
      })
    );
    this.isSaveDisabled.emit(false);
  }

  private closePopover(): void {
    timer(3000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.copiedIndex = null;
        this.cdr.markForCheck();
      });
  }

  private addLinkUsedInSalesConcepts(pages: UsedInItemList[]): UsedInItemList[] {
    return pages.map(customPage => ({
      ...customPage,
      link: `${CUSTOM_PAGES_LIST_URL}/${customPage._id}/edit`,
      versionName: `${customPage.versionName}`,
    }));
  }

  private initDragula(): void {
    const options = {
      moves: (el: Element) => {
        return el.classList.contains('draggable-item');
      },
    };
    this.dragulaService.setOptions(this.dragModelBag, options);

    this.dragulaService.dropModel
      .pipe(
        untilDestroyed(this),
        filter(([bagName]) => bagName === this.dragModelBag),
        switchMap(([, , target]) =>
          this.getPlaceholders$.pipe(
            take(1),
            map(placeholders => this.updatePlaceholderOrder(placeholders, target))
          )
        )
      )
      .subscribe(updatedPlaceholders => {
        this.store.dispatch(
          PlaceholdersWizardActions.setPlaceholdersDataSuccess({
            metadata: this.placeholderOrderService.handleOrdering(updatedPlaceholders),
          })
        );

        this.isSaveDisabled.emit(false);
      });
  }

  private updatePlaceholderOrder(placeholders: Placeholder[], target: any): Placeholder[] {
    return Array.from(target.children)
      .map((child, index) => {
        const placeholderId = (child as HTMLElement).getAttribute('data-placeholder-id');
        const placeholder = placeholders.find(p => p.id === placeholderId);

        return placeholder ? { ...placeholder, order: index } : null;
      })
      .filter(p => p !== null)
      .map(p => ({ ...p, order: p.order || 0 }));
  }
}
