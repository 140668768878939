import { Component, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ep-header',
  templateUrl: './presentation-header.component.html',
  styleUrls: ['./presentation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresentationHeaderComponent {
  @Input() public title: string;
  @Input() public hideCloseButton: boolean;
  @Input() public showSidebarToggler: boolean;
  @Input() public showPdfDownloadBtn: boolean;
  @Input() public pdfDownload: boolean;
  @Output() public sidebarEvent = new EventEmitter();
  @Output() public closeEvent = new EventEmitter();
  @Output() public pdfEvent = new EventEmitter();

  public toggleSidebar(): void {
    this.sidebarEvent.emit();
  }

  public toggleClose(): void {
    this.closeEvent.emit();
  }

  public togglePdf(): void {
    this.pdfEvent.emit();
  }
}
