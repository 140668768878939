import { Pipe, PipeTransform } from '@angular/core';

import { isDefined } from '@core/utils';

@Pipe({
  name: 'default',
})
export class DefaultPipe implements PipeTransform {
  transform(value: string | number, defaultValue: unknown): unknown {
    return isDefined(value) && value !== '' ? value : defaultValue;
  }
}
