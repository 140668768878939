<section class="content-section">
  {{ content }}
</section>

<footer class="btn-block">
  <se-button class="secondary" (click)="output.emit()">Cancel</se-button>
  <se-button (click)="confirm()">
    {{ confirmBtnLabel }}
  </se-button>
</footer>
