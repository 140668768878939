import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { StyleEditorComponent } from './style-editor.component';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';
import { ImageSetterDirective } from './style-image-setter.directive';
import { StyleEditorService, Utils } from '@shared/services';

@NgModule({
  declarations: [StyleEditorComponent, ImageSetterDirective],
  exports: [StyleEditorComponent, ImageSetterDirective],
  imports: [
    FormsModule,
    CommonModule,
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    ColorPickerModule,
    AssurencePipesModule,
    ModalModule.forRoot(),
  ],
  providers: [ColorPickerService, Utils, StyleEditorService],
})
export class StyleEditorModule {}
