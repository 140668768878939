import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'ep-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  @Input() confirmBtnLabel = 'Confirm';
  @Input() content = '';
  @Output() output = new EventEmitter<{ confirmed?: boolean }>();

  confirm(): void {
    this.output.emit({ confirmed: true });
  }
}
