import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { first, map, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ModalService as ModalServiceEP } from '@assurance/bootstrap';

import { getDependentPagesConfig, getNavbarCustomPages } from '../../setup/setup.selectors';
import { SalesConceptModalComponent } from './sales-concept-modal.component';
import { coverSheetModalSize } from '../cover-sheet-modal/cover-sheet-modal.constants';
import {
  salesConceptModalRequestsPending,
  salesConceptModalRequestsSuccess,
  salesConceptsModalAvailable,
} from '../../setup/setup.actions';
import { AppState } from '../../../../reducers';
import { CustomPageService } from '../../setup/custom-page/custom-page.service';
import { SetupService } from '../../setup/setup.service';
import { GAService, Global } from '@shared/services';
import { CustomPageValues, DataSourcesConfig, NavbarCustomPagesData, PageConfig } from '@shared/models';

//TODO: refact this service
@UntilDestroy()
@Injectable()
export class OpenSalesConceptModalService {
  constructor(
    public store: Store<AppState>,
    public router: Router,
    public customPageService: CustomPageService,
    public setupService: SetupService,
    private modal: ModalServiceEP,
    public global: Global,
    public gaService: GAService
  ) {}

  editPage(page: CustomPageValues, configs: PageConfig[], dataSourcesConfig: Observable<DataSourcesConfig[]>): void {
    if (!page || !page.config.uiId) {
      return;
    }

    this.openSalesConceptModal(page, configs, dataSourcesConfig);
    this.gaService.sendOpenPageSettingsEvent();
  }

  private openSalesConceptModal(
    selectedCustomPage: CustomPageValues,
    configs: PageConfig[],
    dataSourcesConfig: Observable<DataSourcesConfig[]>
  ): void {
    this.store
      .pipe(
        select(getDependentPagesConfig),
        map(conf => conf.configs),
        first()
      )
      .subscribe(customPagesConfig => {
        const pageConfig = this.setupService.getPageConfigById(configs, selectedCustomPage?.config?.uiId);
        const config = cloneDeep(pageConfig);
        const data = {
          salesConcepts: of(cloneDeep([selectedCustomPage])),
          plans: this.setupService.buildPlansNav(this.global.getCurrentCarrierPlans),
          pageConfig: config,
          activePlans: config.config.activePlans,
          customPagesConfigs: customPagesConfig,
          dataSources: dataSourcesConfig,
          editMode: true,
          shared: this.router.url.indexOf('shared-presentation') > -1,
        };

        const modal = this.modal.open(SalesConceptModalComponent, {
          data,
          width: coverSheetModalSize.width,
          panelClass: 'sale-story-settings',
        });

        modal.afterClosed.pipe(first()).subscribe(res => {
          if (!res?.submitted) return;

          this.customPageService.setCompilePage(true);
          this.store.dispatch(salesConceptModalRequestsPending());
          (res.insertsToUpdate
            ? this.customPageService.updateInsertsConfigsDirectPage(
                res.insertsToUpdate,
                customPagesConfig,
                selectedCustomPage.config.uiId
              )
            : of(null)
          )
            .pipe(
              switchMap(() => this.store.select(getNavbarCustomPages).pipe(first())),
              switchMap((data: NavbarCustomPagesData) => {
                const uiId = config.config.uiId;
                const found = data.salesConcepts.find(item => item.config.uiId === uiId);

                const params = found ? { uiId, _id: found?._id, tookByFormula: found?.tookByFormula } : { uiId };

                return this.customPageService.getSalesConcepts([params]);
              }),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.store.dispatch(salesConceptModalRequestsSuccess());
              this.store.dispatch(salesConceptsModalAvailable({ payload: true }));
              this.customPageService.setCompilePage(false);
            });
        });
      });
  }
}
