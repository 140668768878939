import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseType } from '@core/model';
import {
  CompilePageOptions,
  CopyCustomPage,
  CustomPage,
  CustomPageVersion,
  CustomPageVersions,
  DataSourcesConfig,
  ExportedCustomPage,
  SalesConceptForDependentPage,
  TableRequestParams,
} from '@shared/models';
import * as apiConstant from '@core/constant';
import { CarrierPlanOptional, CustomPagesLists } from '../../models';
import { APIService } from '@shared/services';
import { getSearchQuery } from '@core/utils';

@Injectable()
export class CustomPageApiService {
  constructor(private http: HttpClient, private apiService: APIService) {}

  getPagesList(params: TableRequestParams): Observable<CustomPagesLists> {
    return this.http
      .get<ResponseType<CustomPagesLists>>(apiConstant.customPagesContentV2, {
        params: getSearchQuery(params),
      })
      .pipe(map((res: ResponseType<CustomPagesLists>) => res.data));
  }

  getDependentPagesOfPage(id: string): Observable<CustomPage[]> {
    return this.http
      .get<ResponseType<{ data: CustomPage[] }>>(`${apiConstant.contentServiceContent}/${id}/dependent-pages`)
      .pipe(map((res: ResponseType<{ data: CustomPage[] }>) => res.data.data));
  }

  // TODO: need to add one method to get data of response
  getCustomPage(id: string): Observable<CustomPage> {
    return this.http
      .get<ResponseType<{ data: CustomPage }>>(`${apiConstant.contentServiceContent}/${id}`)
      .pipe(map((res: ResponseType<{ data: CustomPage }>) => res.data.data));
  }

  createCustomPage(data: FormData): Observable<CustomPage> {
    return this.http
      .post<ResponseType<{ data: CustomPage }>>(apiConstant.contentServiceContent, data)
      .pipe(map((res: ResponseType<{ data: CustomPage }>) => (res.data && res.data.data ? res.data.data : {})));
  }

  updateCustomPage(data: FormData, _id: string): Observable<CustomPage> {
    return this.http
      .put<ResponseType<{ data: CustomPage }>>(`${apiConstant.contentServiceContent}/${_id}`, data)
      .pipe(map((res: ResponseType<{ data: CustomPage }>) => (res.data && res.data.data ? res.data.data : {})));
  }

  copyCustomPage(data: CopyCustomPage): Observable<CustomPage> {
    return this.http
      .post<ResponseType<{ data: CustomPage }>>(apiConstant.copyCustomPage, data)
      .pipe(map((res: ResponseType<{ data: CustomPage }>) => (res.data && res.data.data ? res.data.data : {})));
  }

  getCarrierPlansConfigData(): Observable<CarrierPlanOptional[]> {
    return this.http
      .get<ResponseType<CarrierPlanOptional[]>>(apiConstant.carrierPlansFullConfigData)
      .pipe(map((res: ResponseType<CarrierPlanOptional[]>) => res.data));
  }

  getCarrierPlansConfigMeta(): Observable<CarrierPlanOptional[]> {
    return this.http
      .get<ResponseType<CarrierPlanOptional[]>>(apiConstant.carrierPlansConfigMeta)
      .pipe(map((res: ResponseType<CarrierPlanOptional[]>) => res.data));
  }

  getCustomPageVersions(uuId: string): Observable<CustomPageVersions> {
    return this.http
      .get<ResponseType<{ data: CustomPageVersions }>>(`${apiConstant.versions}/${uuId}`)
      .pipe(map((response: ResponseType<{ data: CustomPageVersions }>) => response.data.data));
  }

  getDependentPages(addedDate: string): Observable<CustomPage[]> {
    return this.apiService
      .getPagesListByLabel({ labels: 'dependentpage', addedDate })
      .pipe(map((res: ResponseType<{ data: CustomPage[] }>) => res.data.data));
  }

  uploadCustomPageImage(data: FormData): Observable<ResponseType<any>> {
    return this.http.post<ResponseType<any>>(apiConstant.customImage, data);
  }

  createNewVersion(uuId: string, versionName: string): Observable<CustomPageVersion> {
    return this.http
      .post<ResponseType<CustomPageVersion>>(`${apiConstant.versions}/add-new-version/${uuId}`, { versionName })
      .pipe(map((response: ResponseType<CustomPageVersion>) => response.data));
  }

  updateVersions(
    uuId: string,
    versions: CustomPageVersion[],
    pickVersionFormula: string
  ): Observable<CustomPageVersions> {
    return this.http
      .patch<ResponseType<{ data: CustomPageVersions }>>(`${apiConstant.versions}/${uuId}`, {
        versions,
        pickVersionFormula,
      })
      .pipe(map((response: ResponseType<{ data: CustomPageVersions }>) => response.data.data));
  }

  getCompiledPreviewPage(pageId: string, options: CompilePageOptions, presentationId: number): Observable<CustomPage> {
    const url = `${apiConstant.contentServiceContent}/${apiConstant.compile}/preview/${pageId}`;

    return this.http
      .post<ResponseType<{ data: CustomPage }>>(url, options, {
        headers: new HttpHeaders({
          PresentationId: presentationId.toString(),
        }),
      })
      .pipe(map((data: ResponseType<{ data: CustomPage }>) => data.data.data));
  }

  exportCustomPage(customPageUUID: string): Observable<ResponseType<{ data: ExportedCustomPage }>> {
    return this.http.get<ResponseType<{ data: ExportedCustomPage }>>(
      `${apiConstant.exportCustomPage}/${customPageUUID}`
    );
  }

  importCustomPage(fileContents: ExportedCustomPage): Observable<ResponseType<{ success: boolean }>> {
    return this.http.post<ResponseType<{ success: boolean }>>(`${apiConstant.importCustomPage}`, fileContents);
  }

  updateCustomPageHtmlBody(id: string, data: Record<string, string>): Observable<void> {
    return this.http.patch<void>(`${apiConstant.contentServiceContent}/${id}/htmlBody `, data);
  }

  getSalesConceptsForDependentPage(id: string): Observable<SalesConceptForDependentPage[]> {
    return this.http
      .get<ResponseType<{ data: SalesConceptForDependentPage[] }>>(
        `${apiConstant.contentServiceContent}/${id}/salesconcepts`
      )
      .pipe(map((res: ResponseType<{ data: SalesConceptForDependentPage[] }>) => res.data.data));
  }

  public getDataSourcesConfig(): Observable<DataSourcesConfig[]> {
    return this.http
      .get<ResponseType<DataSourcesConfig[]>>(apiConstant.dataSourcesConfig)
      .pipe(map((res: ResponseType<DataSourcesConfig[]>) => res.data));
  }
}
