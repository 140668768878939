import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnsightTitleFormatDirective } from './title-formater/ensight-title-format.directive';
import { EnsightInputPrefixerDirective } from './input-prefixer/ensight-input-prefixer.directive';
import { SortDirective } from './sort/sort.directive';
import { AccessControlDirective } from './access-control/access-control.directive';
import { AccessControlClassDirective } from './access-control/access-control-class.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { CustomTableColumnDirective } from './custom-table-column/custom-table-column.directive';
import { FocusControlDirective } from '@shared/directives/focus-control/focus-control.directive';
import { InputPatternDirective } from '@shared/directives/input-pattern/input-pattern.directive';
import { DynamicPositionOfMenuDirective } from './dynamic-position-of-menu/dynamic-position-of-menu.directive';

const directives = [
  EnsightTitleFormatDirective,
  EnsightInputPrefixerDirective,
  SortDirective,
  AccessControlDirective,
  AccessControlClassDirective,
  ClickOutsideDirective,
  CustomTableColumnDirective,
  FocusControlDirective,
  InputPatternDirective,
  DynamicPositionOfMenuDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {}
