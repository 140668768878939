import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[epClickOutside]',
})
export class ClickOutsideDirective {
  @Input() trigger: string[] = [];
  @Output() close = new EventEmitter<void>();

  @HostListener('document:mousedown', ['$event']) public onClick(event) {
    const target = event.target as HTMLElement;
    const isExcluded = this.isExcluded(target);

    if (!isExcluded) {
      this.close.emit();
    }
  }

  private isTriggeredClass(element: HTMLElement, className: string): boolean {
    return element.classList.contains(className.trim());
  }

  private isExcluded(target: HTMLElement): boolean {
    return this.trigger.some(excludedClass => this.isTriggeredClass(target, excludedClass));
  }
}
