import { FormConfigurations } from '@shared/models';
import {
  CALCULATED_DATA_TARGET,
  ChartDataSource,
  ChartTypes,
  INSERT_TYPE,
  LegendType,
  PinTotalTypes,
} from '@core/enums';
import { MetricType } from '@shared/constants/metric-type.enum';

export const NO_API_CALL_INSERTS_MSG = 'There are no API call inserts created';
export const CHART_CSS_PATTERN =
  '/^(-?(\\d*\\.)?\\d+)((px)|(em)|(%)|(ex)|(ch)|(rem)|(vw)|(vh)|(vmin)|(vmax)|(cm)|(mm)|(in)|(pt)|(pc))$/';
export const CHART_CSS_UNIT_VALIDATION_MSG = 'The value should be a number followed by a unit, such as 10px, 2em, etc.';

export const defaultXAxisSourceTypeStart = `{{#if (and (gt globalConfig.config.minAgeDisplay 1) (eq globalConfig.config.xAxisSource 'eoy_age'))}} {{globalConfig.config.minAgeDisplay}} {{else}} {{#if (and (gt globalConfig.config.minAgeDisplay 1) (eq globalConfig.config.xAxisSource 'policy_year'))}} {{globalConfig.config.minAgeDisplay}} {{else}} {{globalConfig.config.minAgeDisplay}} {{/if}} {{/if}}`;

export const defaultXAxisSourceTypeEnd = `{{#if (and (gt globalConfig.config.maxAgeDisplay 1) (eq globalConfig.config.xAxisSource 'eoy_age'))}} {{globalConfig.config.maxAgeDisplay}} {{else}} {{#if (and (gt globalConfig.config.maxAgeDisplay 1) (eq globalConfig.config.xAxisSource 'policy_year'))}} {{globalConfig.config.maxAgeDisplay}} {{else}} {{globalConfig.config.maxAgeDisplay}} {{/if}} {{/if}}`;

export const INSERT_TYPE_STATE = {
  title: 'Select Insert Type',
  id: 'insertType',
  active: false,
};

export const CHART_CONFIGURATION_STATE = {
  title: 'Set Chart Configuration',
  id: 'chartConfiguration',
  active: false,
};

export const CHART_STYLE_CONFIGURATION_STATE = {
  title: 'Chart Style Configuration',
  id: 'chartStyleConfiguration',
  active: false,
};

export const CHART_METRICS_STATE = {
  title: 'Select Chart Metrics',
  id: 'chartMetrics',
  active: false,
};

export const IMAGE_STATE = {
  title: 'Set Image',
  id: 'setImage',
  active: false,
};

export const CUSTOM_PAGE = {
  title: 'Choose Custom Page',
  id: 'customPage',
  active: false,
};

export const INSERTS_FROM_OTHER_PAGE = {
  title: 'Choose Insert',
  id: 'insertsFromOtherPage',
  active: false,
};

export const TEXT_CONFIGURATION_STATE = {
  title: 'Set Text Configuration',
  id: 'textConfiguration',
  active: false,
};

export const VARIABLES_STATE = {
  title: 'Variable',
  id: 'variableConfiguration',
  active: false,
};

export const CUSTOM_METRIC_STATE = {
  title: 'Custom Metric',
  id: 'customMetric',
  active: false,
};

export const CUSTOM_METRIC_FORMULA_STATE = {
  title: '',
  id: 'customMetricFormula',
  active: false,
};

export const PRODUCTS_DESCRIPTION_STATE = {
  title: 'Products Description',
  id: 'productsDescription',
  active: false,
};

export const CUSTOM_TABLE_STATE = {
  title: 'Custom Table',
  id: 'customTable',
  active: false,
};

export const CUSTOM_TABLE_COLUMNS_STATE = {
  title: 'Custom Table',
  id: 'customTableColumns',
  active: false,
};

export const BUTTON_STATE = {
  title: 'Button',
  id: 'button',
  active: false,
};

export const API_CALL_CONFIGURATION_STATE = {
  title: 'API Call - Request',
  id: 'apiCallConfiguration',
  active: false,
};

export const POST_API_CALL_STATE = {
  title: 'API Call - Request Body',
  id: 'postApiCall',
  active: false,
};

export const API_CALL_RESPONSE_STATE = {
  title: 'API Call - Response',
  id: 'apiCallResponse',
  active: false,
};

export const DROPDOWN_STATE = {
  title: 'Dropdown',
  id: 'dropdownVariable',
  active: false,
};

export const PRODUCT_SELECTOR_STATE = {
  title: 'Product Selector',
  id: 'productSelector',
  active: false,
};

export const TAB_STATE = {
  title: 'Tab',
  id: 'tab',
  active: false,
};

export const TAB_AREA_STATE = {
  title: 'Tabs',
  id: 'tabs',
  active: false,
};

export const METRIC_BASED_CHART_BACKGROUND = 'metricBased';
export const DEFAULT_METRIC_VALUE = 'none';
export const DEFAULT_DASH_METRIC_VALUE = '5px';

const DYNAMIC_FORM_CHART_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: CHART_CONFIGURATION_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'chartName',
          label: 'Chart Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          pattern: '/^.{0,100}$/',
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'chartType',
          label: 'Chart Type',
          type: 'select',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            { name: ChartTypes.lineChart, label: 'Line', orderNum: 1, hint: null },
            {
              name: ChartTypes.multiBarChart,
              label: 'Stacked Column',
              orderNum: 2,
              hint: null,
            },
            {
              name: ChartTypes.stackedAreaChart,
              label: 'Stacked Area',
              orderNum: 3,
              hint: null,
            },
            {
              name: ChartTypes.lineChartStepAfter,
              label: 'Step Line Chart',
              orderNum: 3,
              hint: null,
            },
            {
              name: ChartTypes.comboChart,
              label: 'Combo Chart (c3js)',
              orderNum: 4,
              hint: null,
            },
            {
              name: ChartTypes.pie,
              label: 'Pie Chart (c3js)',
              orderNum: 5,
              hint: null,
            },
            {
              name: ChartTypes.donut,
              label: 'Donut Chart (c3js)',
              orderNum: 6,
              hint: null,
            },
            {
              name: ChartTypes.clusteredBarChart,
              label: 'Clustered Bar Chart',
              hint: null,
            },
          ],
          value: ChartTypes.lineChart,
          optional: true,
        },
        {
          name: 'chartBackground',
          label: 'Chart Background',
          type: 'select',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            { name: 'line', label: 'Line', orderNum: 1, hint: null },
            {
              name: 'solid',
              label: 'Solid',
              orderNum: 2,
              hint: null,
            },
            {
              name: METRIC_BASED_CHART_BACKGROUND,
              label: 'Metric Based',
              orderNum: 3,
              hint: null,
            },
          ],
          condition: {
            action: 'show',
            formula: 'a1 || a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.lineChart,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.lineChartStepAfter,
                op: 'eq',
              },
            ],
          },
          value: 'line',
          optional: true,
        },
        {
          name: 'pinType',
          label: 'Pin Type',
          type: 'select',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            {
              name: 'noPin',
              label: 'No Pin',
              orderNum: 1,
              hint: null,
            },
            {
              name: 'productComparePin',
              label: 'Product Compare',
              orderNum: 2,
              hint: null,
            },
            {
              name: 'metricComparePin',
              label: 'Metric Compare',
              orderNum: 3,
              hint: null,
            },
            {
              name: 'carrierComparePin',
              label: 'Carrier Compare',
              orderNum: 4,
              hint: null,
            },
            {
              name: 'customPin',
              label: 'Custom Pin',
              orderNum: 5,
              hint: null,
            },
          ],
          value: 'noPin',
          optional: true,
          condition: {
            action: 'show',
            formula: '!a1 && !a2 && !a3',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'defaultPinValue',
          label: 'Pin Position',
          type: 'input',
          row: 5,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          optional: true,
          condition: {
            action: 'show',
            formula: '!a1 && a2',
            source: [
              {
                name: 'pinType',
                value: 'noPin',
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'optimalVerticalPosition',
          label: 'Find the Optimal Vertical Position of the Pin Tool-tip',
          row: 6,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          condition: {
            action: 'show',
            formula: '!a1 && a2',
            source: [
              {
                name: 'pinType',
                value: 'noPin',
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'pinTotalType',
          label: 'Total on the Pin',
          type: 'select',
          row: 7,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            {
              name: PinTotalTypes.noTotal,
              label: 'No Total',
              orderNum: 1,
              hint: null,
            },
            {
              name: PinTotalTypes.topTotal,
              label: 'Total at the Top',
              orderNum: 2,
              hint: null,
            },
            {
              name: PinTotalTypes.bottomTotal,
              label: 'Total at the Bottom',
              orderNum: 3,
              hint: null,
            },
          ],
          condition: {
            action: 'show',
            formula: '!a1 && !a2 && !a3 && !a4 && !a5 && !a6',
            source: [
              {
                name: 'pinType',
                value: 'noPin',
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.lineChart,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.lineChartStepAfter,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
          value: PinTotalTypes.noTotal,
          optional: true,
        },
        {
          name: 'pinTotalLabel',
          label: 'Total Label',
          type: 'input',
          row: 8,
          col: 1,
          width: 12,
          labelBold: false,
          validationRules: [
            {
              message: 'Please enter up to 100 characters',
              rule: 'maxLength',
              value: '100',
            },
          ],
          condition: {
            action: 'show',
            formula: '!a1 && !a2 && !a3 && !a4 && !a5 && !a6 && !a7',
            source: [
              {
                name: 'pinType',
                value: 'noPin',
                op: 'eq',
              },
              {
                name: 'pinTotalType',
                value: PinTotalTypes.noTotal,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.lineChart,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.lineChartStepAfter,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
          value: 'Total',
          optional: true,
        },
        {
          name: 'pinTotalColor',
          label: 'Total Number Color',
          type: 'input',
          row: 9,
          col: 1,
          width: 12,
          labelBold: false,
          validationRules: [
            {
              message: 'Please enter up to 10 characters',
              rule: 'maxLength',
              value: '10',
            },
          ],
          condition: {
            action: 'show',
            formula: '!a1 && !a2 && !a3 && !a4 && !a5 && !a6 && !a7',
            source: [
              {
                name: 'pinType',
                value: 'noPin',
                op: 'eq',
              },
              {
                name: 'pinTotalType',
                value: PinTotalTypes.noTotal,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.lineChart,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.lineChartStepAfter,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
          value: '#000000',
          optional: true,
        },
        {
          name: 'xAxisSource',
          label: 'X-Axis Source',
          type: 'select',
          row: 10,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            {
              name: 'presentationBased',
              label: 'Presentation Based',
              orderNum: 1,
              hint: null,
            },
            {
              name: 'eoy_age',
              label: 'Age',
              orderNum: 2,
              hint: null,
            },
            {
              name: 'policy_year',
              label: 'Policy Year',
              orderNum: 3,
              hint: null,
            },
            {
              name: 'metric',
              label: 'Metric',
              orderNum: 4,
              hint: null,
            },
          ],
          value: 'presentationBased',
          optional: true,
          condition: {
            action: 'show',
            formula: '!a1 && !a2 && !a3',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'xAxisMetric',
          label: 'X-Axis Metric',
          type: 'datalist',
          row: 9,
          col: 1,
          width: 12,
          labelBold: false,
          renderingOptions: {
            customConfigs: {
              searchAnyPosition: true,
            },
          },
          options: [
            {
              name: 'none',
              label: 'None',
              hint: null,
            },
          ],
          value: DEFAULT_METRIC_VALUE,
          condition: {
            action: 'show',
            formula: 'a1 && a2 && !a3 && !a4 && !a5',
            source: [
              {
                name: 'xAxisSource',
                value: 'metric',
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
          validationRules: [
            {
              rule: 'unionRegex',
              value: '/^$/',
              message: 'The entered value is not valid. Enter a valid value or select an option from the dropdown menu',
              type: null,
            },
            {
              rule: 'regex',
              value: '/^(?!.*none).*$/',
              message: 'Metric is required',
              type: null,
            },
          ],
        },
        {
          name: 'yAxisMaxValueMetric',
          label: 'Y-Axis Max Value Metric (metadata)',
          type: 'datalist',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          renderingOptions: {
            customConfigs: {
              searchAnyPosition: true,
            },
          },
          options: [
            {
              name: 'none',
              label: 'None',
              hint: null,
            },
          ],
          value: DEFAULT_METRIC_VALUE,
          condition: {
            action: 'show',
            formula: 'a1 || a2 && !a3',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
              {
                name: 'rotatedAxes',
                value: true,
                op: 'eq',
              },
            ],
          },
          validationRules: [
            {
              rule: 'unionRegex',
              value: '/^$/',
              message: 'The entered value is not valid. Enter a valid value or select an option from the dropdown menu',
              type: null,
            },
          ],
        },
        {
          name: 'xAxisMaxValueMetric',
          label: 'X-Axis Max Value Metric (metadata)',
          type: 'datalist',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          renderingOptions: {
            customConfigs: {
              searchAnyPosition: true,
            },
          },
          options: [
            {
              name: 'none',
              label: 'None',
              hint: null,
            },
          ],
          value: DEFAULT_METRIC_VALUE,
          condition: {
            action: 'show',
            formula: 'a1 && a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
              {
                name: 'rotatedAxes',
                value: true,
                op: 'eq',
              },
            ],
          },
          validationRules: [
            {
              rule: 'unionRegex',
              value: '/^$/',
              message: 'The entered value is not valid. Enter a valid value or select an option from the dropdown menu',
              type: null,
            },
          ],
        },
        {
          name: 'xAxisName',
          label: 'X-Axis Name',
          type: 'input',
          row: 9,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          condition: {
            action: 'show',
            formula: 'a1 && a2 && !a3 && !a4 && !a5',
            source: [
              {
                name: 'xAxisSource',
                value: 'metric',
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
          value: '',
        },
        {
          name: 'xAxisSourceStart',
          label: 'X-Axis Source Start',
          type: 'input',
          value: defaultXAxisSourceTypeStart,
          row: 10,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          condition: {
            action: 'show',
            formula: '!a1 && !a2 && !a3',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'xAxisSourceEnd',
          label: 'X-Axis Source End',
          type: 'input',
          value: defaultXAxisSourceTypeEnd,
          row: 10,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          condition: {
            action: 'show',
            formula: '!a1 && !a2 && !a3',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'xAxisYearHighlight',
          label: 'X-Axis Tick Highlight',
          type: 'input',
          value: '',
          row: 11,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'xAxisYearHighlightColor',
          label: 'X-Axis Tick Highlight Color',
          type: 'input',
          value: '#148C53',
          row: 12,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'yAxisName',
          label: 'Y-Axis Name',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          optional: true,
          condition: {
            action: 'show',
            formula: '!a1 && !a2 && !a3',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'chartDataSource',
          label: 'Chart Data Source',
          type: 'select',
          row: 15,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            {
              name: ChartDataSource.staticMetrics,
              label: 'One Metric for Labels and One Metric for Data',
              orderNum: 1,
              hint: null,
            },
            {
              name: ChartDataSource.dynamicMetrics,
              label: 'Multiple Metrics',
              orderNum: 2,
              hint: null,
            },
          ],
          condition: {
            action: 'show',
            formula: 'a1 || a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
            ],
          },
          value: ChartDataSource.dynamicMetrics,
          optional: true,
        },
        {
          name: 'metricDataSource',
          label: 'Metric Source',
          type: 'select',
          row: 16,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            {
              name: CALCULATED_DATA_TARGET.data,
              label: 'Data',
              orderNum: 1,
              hint: null,
            },
            {
              name: CALCULATED_DATA_TARGET.metadata,
              label: 'Metadata',
              orderNum: 2,
              hint: null,
            },
          ],
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'chartDataSource',
                value: ChartDataSource.staticMetrics,
                op: 'eq',
              },
            ],
          },
          value: 'data',
          optional: true,
        },
        {
          name: 'legendType',
          label: 'Legend Type',
          type: 'select',
          row: 17,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            { name: 'hide', label: 'Not Displayed', orderNum: 1, hint: null },
            { name: 'labels', label: 'Labels', orderNum: 2, hint: null },
            { name: 'values', label: 'Values', orderNum: 3, hint: null },
            { name: 'labels-values', label: 'Labels and Values', orderNum: 4, hint: null },
            { name: 'labels-values-in-two-lines', label: 'Labels and Values in 2 Lines', orderNum: 5, hint: null },
          ],
          condition: {
            action: 'show',
            formula: 'a1 || a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
            ],
          },
          value: 'hide',
          optional: true,
        },
        {
          name: 'legendPosition',
          label: 'Legend Location',
          type: 'select',
          row: 18,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            { name: 'bottom', label: 'Bottom', orderNum: 1, hint: null },
            { name: 'right', label: 'Right', orderNum: 2, hint: null },
          ],
          condition: {
            action: 'show',
            formula: '(a1 || a2) && (a3 || a4 || a5 || a6)',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labels,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.values,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labelsValues,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labelsValuesInTwoLines,
                op: 'eq',
              },
            ],
          },
          value: 'bottom',
          optional: true,
        },
        {
          name: 'valueDisplay',
          label: 'Value Display',
          type: 'select',
          row: 19,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            { name: 'chart', label: 'Display on the Chart', orderNum: 1, hint: null },
            { name: 'tooltip', label: 'Display on the Tool-tip', orderNum: 2, hint: null },
            { name: 'chart-tooltip', label: 'Display on Chart and Tool-tip', orderNum: 2, hint: null },
            { name: 'hide', label: 'Hide', orderNum: 2, hint: null },
          ],
          condition: {
            action: 'show',
            formula: 'a1 || a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
            ],
          },
          value: 'chart',
          optional: true,
        },
        {
          name: 'valueDisplayOnBarChart',
          label: 'Value Display',
          type: 'select',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            { name: 'displayOnTheChart', label: 'Display on the Chart', orderNum: 1, hint: null },
            { name: 'hide', label: 'Hide', orderNum: 2, hint: null },
          ],
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
          value: 'displayOnTheChart',
          optional: true,
        },
        {
          name: 'rotatedAxes',
          label: 'Rotated Axes',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'hideYAxisOnBarChart',
          label: 'Hide Y-Axis',
          row: 5,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          condition: {
            action: 'show',
            formula: 'a1 && a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
              {
                name: 'rotatedAxes',
                value: false,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'hideXAxisOnBarChart',
          label: 'Hide X-Axis',
          row: 5,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          condition: {
            action: 'show',
            formula: 'a1 && a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
              {
                name: 'rotatedAxes',
                value: true,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'donutWidth',
          label: 'Percentage of Donut Chart Width, %',
          type: 'input',
          row: 20,
          col: 1,
          width: 12,
          labelBold: false,
          pattern: '/^[1-9][0-9]?$|^100$/',
          value: 30,
          optional: true,
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'secondLineDescription',
          label: '2nd Line Description',
          type: 'input',
          row: 18,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          pattern: '/^.{0,100}$/',
          value: '',
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'legendType',
                value: LegendType.labelsValuesInTwoLines,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'legendTitle',
          label: 'Legend Title',
          type: 'input',
          row: 21,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          value: '',
          condition: {
            action: 'show',
            formula: '(a1 || a2) && (a3 || a4 || a5 || a6)',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labels,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.values,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labelsValues,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labelsValuesInTwoLines,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'legendSubtitle',
          label: 'Legend Subtitle',
          type: 'input',
          row: 22,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          value: '',
          condition: {
            action: 'show',
            formula: '(a1 || a2) && (a3 || a4 || a5 || a6)',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labels,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.values,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labelsValues,
                op: 'eq',
              },
              {
                name: 'legendType',
                value: LegendType.labelsValuesInTwoLines,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'axes',
          label: 'Y-Axes',
          type: 'select',
          row: 13,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          value: 'singleYAxis',
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
            ],
          },
          options: [
            { name: 'y', label: 'Single Y-Axis', orderNum: 1, hint: null },
            { name: 'y2', label: 'Additional Y-Axis', orderNum: 2, hint: null },
          ],
        },
        {
          name: 'additionalYAxisName',
          label: 'Additional Y-Axis Name',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          value: '',
          condition: {
            action: 'show',
            formula: 'a1 && a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
              {
                name: 'axes',
                value: 'y2',
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'hideAdditionalYAxis',
          label: 'Hide Additional Y-Axis',
          row: 15,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          condition: {
            action: 'show',
            formula: 'a1 && a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.comboChart,
                op: 'eq',
              },
              {
                name: 'axes',
                value: 'y2',
                op: 'eq',
              },
            ],
          },
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
      ],
    },
  ],
};

const DYNAMIC_FORM_CHART_STYLE_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: CHART_STYLE_CONFIGURATION_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'chartHeight',
          label: 'Chart Height',
          type: 'input',
          row: 12,
          col: 1,
          width: 12,
          labelBold: false,
          value: '20vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [],
        },
        {
          name: 'chartTheme',
          label: 'Style',
          type: 'select',
          row: 13,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          value: 'lightTheme',
          options: [
            {
              name: 'lightTheme',
              label: 'Light Theme',
              orderNum: 1,
              hint: null,
            },
            {
              name: 'darkTheme',
              label: 'Dark Theme',
              orderNum: 2,
              hint: null,
            },
          ],
          disableForChart: [],
        },
        {
          name: 'chartFontSizeAxesLabels',
          label: 'Font Size of Labels on the Axes',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '0.625vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [ChartTypes.pie, ChartTypes.donut, ChartTypes.clusteredBarChart],
        },
        {
          name: 'chartFontSizeXAxesLabels',
          label: 'Font Size of Labels on the X-Axis',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '0.625vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [
            ChartTypes.lineChart,
            ChartTypes.multiBarChart,
            ChartTypes.stackedAreaChart,
            ChartTypes.lineChartStepAfter,
            ChartTypes.comboChart,
            ChartTypes.pie,
            ChartTypes.donut,
          ],
        },
        {
          name: 'chartFontSizeYAxesLabels',
          label: 'Font Size of Labels on the Y-Axis',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '0.625vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [
            ChartTypes.lineChart,
            ChartTypes.multiBarChart,
            ChartTypes.stackedAreaChart,
            ChartTypes.lineChartStepAfter,
            ChartTypes.comboChart,
            ChartTypes.pie,
            ChartTypes.donut,
          ],
        },
        {
          name: 'chartFontSizeAxesNames',
          label: 'Font Size of Axes Names',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '0.625vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [ChartTypes.pie, ChartTypes.donut, ChartTypes.clusteredBarChart],
        },
        {
          name: 'fontSizeValueOnChart',
          label: 'Font Size of Values on the Chart',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '0.625vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [
            ChartTypes.lineChart,
            ChartTypes.multiBarChart,
            ChartTypes.stackedAreaChart,
            ChartTypes.lineChartStepAfter,
            ChartTypes.comboChart,
            ChartTypes.pie,
            ChartTypes.donut,
          ],
        },
        {
          name: 'barWidth',
          label: 'Bar Width, %',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: 50,
          optional: false,
          pattern: '/^[1-9][0-9]?$|^100$/',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
          disableForChart: [
            ChartTypes.lineChart,
            ChartTypes.multiBarChart,
            ChartTypes.stackedAreaChart,
            ChartTypes.lineChartStepAfter,
            ChartTypes.comboChart,
            ChartTypes.pie,
            ChartTypes.donut,
          ],
        },
        {
          name: 'chartFontSizeToolTipLabel',
          label: 'Font Size of Tool-tip Label',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '0.625vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [ChartTypes.pie, ChartTypes.donut, ChartTypes.clusteredBarChart],
        },
        {
          name: 'chartFontSizeToolTipNumber',
          label: 'Font Size of Tool-tip Number',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '1vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [ChartTypes.pie, ChartTypes.donut, ChartTypes.clusteredBarChart],
        },
        {
          name: 'chartFontSizeXAxisValuePin',
          label: 'Font Size of X Axis Value on the Pin',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '2vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [ChartTypes.pie, ChartTypes.donut, ChartTypes.clusteredBarChart],
        },
        {
          name: 'fontSizeOfChartLabels',
          label: 'Font Size of Chart Labels',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '0.625vw',
          optional: true,
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
          ],
          disableForChart: [
            ChartTypes.lineChart,
            ChartTypes.multiBarChart,
            ChartTypes.stackedAreaChart,
            ChartTypes.lineChartStepAfter,
            ChartTypes.comboChart,
            ChartTypes.clusteredBarChart,
          ],
        },
        {
          name: 'defaultColors',
          label: 'Color',
          type: 'input',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: '#006bb7,#00c972,#fc5716,#9c27b0',
          optional: false,
          disableForChart: [
            ChartTypes.lineChart,
            ChartTypes.multiBarChart,
            ChartTypes.stackedAreaChart,
            ChartTypes.lineChartStepAfter,
            ChartTypes.comboChart,
            ChartTypes.clusteredBarChart,
          ],
          disableForDataSource: [ChartDataSource.dynamicMetrics],
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
      ],
    },
  ],
};

const DYNAMIC_FORM_TEXT_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: TEXT_CONFIGURATION_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Placeholder Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          pattern: '/^.{0,50}$/',
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'placeholderText',
          label: 'Placeholder Text',
          type: 'input',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'placeholderMaxLength',
          label: 'Placeholder Max Length',
          type: 'input',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          pattern: '/^\\d+$/',
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
      ],
    },
  ],
};

const DYNAMIC_FORM_IMAGE: FormConfigurations = {
  groups: [
    {
      name: IMAGE_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Image Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          pattern: '/^.{0,100}$/',
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'width',
          label: 'Width',
          type: 'input',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          pattern: '/^\\d+$/',
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'height',
          label: 'Height',
          type: 'input',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          pattern: '/^\\d+$/',
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_VARIABLES_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: VARIABLES_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Variable Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              rule: 'unionRegex',
              value: '[a-zA-Z0-9]+',
              message: 'Variable name is invalid',
            },
          ],
        },
        {
          name: 'placeholderKey',
          label: 'Variable Key',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          readOnly: true,
          validationRules: [
            {
              rule: 'regex',
              value: '/(?:)/',
              message: 'Variable key is not unique',
            },
          ],
        },
        {
          name: 'placeholderDecimals',
          label: 'Decimals',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          value: 0,
          type: 'input',
          pattern: '/^\\d+$/',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              message: 'Decimals value should be between 0 and 5',
              rule: 'min',
              value: 0,
            },
            {
              message: 'Decimals value should be between 0 and 5',
              rule: 'max',
              value: 5,
            },
          ],
          condition: {
            action: 'show',
            formula: '!a1 && !a2',
            source: [
              {
                name: 'placeholderValueType',
                value: 'textField',
                op: 'eq',
              },
              {
                name: 'placeholderValueType',
                value: 'textArea',
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'placeholderMinValue',
          label: 'Minimal Value',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          pattern: '/^\\d+(\\.(\\d{1,5})?)?$/',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
          condition: {
            action: 'show',
            formula: '!a1 && !a2',
            source: [
              {
                name: 'placeholderValueType',
                value: 'textField',
                op: 'eq',
              },
              {
                name: 'placeholderValueType',
                value: 'textArea',
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'placeholderMaxValue',
          label: 'Maximal Value',
          row: 5,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          pattern: '/^\\d+(\\.(\\d{1,5})?)?$/',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
          condition: {
            action: 'show',
            formula: '!a1 && !a2',
            source: [
              {
                name: 'placeholderValueType',
                value: 'textField',
                op: 'eq',
              },
              {
                name: 'placeholderValueType',
                value: 'textArea',
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'placeholderDefaultValue',
          label: 'Default Value',
          row: 6,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          optional: false,
          // validationRules: [
          //   {
          //     rule: 'required',
          //     value: 'true',
          //   },
          // ],
        },
        {
          name: 'isInlineEditable',
          label: 'Inline editable',
          row: 7,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
        },
        {
          name: 'isVariableUpdateTriggersCompilation',
          label: 'Variable update triggers compilation',
          row: 9,
          col: 1,
          width: 12,
          labelBold: false,
          value: true,
          type: 'toggle',
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'isInlineEditable',
                value: true,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'placeholderValueType',
          label: 'Type',
          row: 10,
          col: 1,
          width: 12,
          labelBold: false,
          type: 'select',
          options: [
            {
              name: 'currency',
              label: 'Currency',
              orderNum: 1,
              hint: null,
            },
            {
              name: 'percentage',
              label: 'Percentage',
              orderNum: 2,
              hint: null,
            },
            {
              name: 'number',
              label: 'Number',
              orderNum: 3,
              hint: null,
            },
            {
              name: 'textField',
              label: 'Plain text field',
              orderNum: 4,
              hint: null,
            },
            {
              name: 'textArea',
              label: 'Plain text area',
              orderNum: 5,
              hint: null,
            },
          ],
          value: 'number',
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'isInlineEditable',
                value: true,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'placeholderMaxLength',
          label: 'Max Length',
          row: 11,
          col: 1,
          width: 12,
          labelBold: false,
          value: 100,
          type: 'input',
          pattern: '/^\\d+$/',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
          condition: {
            action: 'show',
            formula: 'a1 || a2',
            source: [
              {
                name: 'placeholderValueType',
                value: 'textField',
                op: 'eq',
              },
              {
                name: 'placeholderValueType',
                value: 'textArea',
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'placeholderHint',
          label: 'Input hint',
          row: 12,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          optional: true,
          condition: {
            action: 'show',
            formula: 'a1',
            source: [
              {
                name: 'isInlineEditable',
                value: true,
                op: 'eq',
              },
            ],
          },
        },
        {
          name: 'hidden',
          label: 'Hidden in Sales Story Settings',
          row: 13,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
        },
        {
          name: 'hiddenOnSharedView',
          label: 'Not editable on Shared View',
          row: 14,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
        },
        {
          name: 'showOnSetup',
          label: 'Prompt when the Presentation is Created from the Template',
          row: 15,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_PRODUCTS_DESCRIPTION: FormConfigurations = {
  groups: [
    {
      name: PRODUCTS_DESCRIPTION_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'description',
          label: 'Products Description',
          placeholder: 'Enter Products Description',
          type: 'textarea',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              message: 'Please enter up to 5000 characters',
              rule: 'maxLength',
              value: '5000',
            },
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'isNewVersion',
          label: 'Version 2 (JavaScript)',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_CUSTOM_TABLE_COLUMNS: FormConfigurations = {
  groups: [
    {
      name: CUSTOM_TABLE_COLUMNS_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'header',
          label: 'Header',
          type: 'textarea',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'body',
          label: 'Body',
          type: 'textarea',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'hideFormula',
          label: 'Column show hide formula (0 to hide)',
          type: 'textarea',
          row: 3,
          col: 1,
          width: 12,
          optional: true,
          labelBold: false,
          value: '',
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_CUSTOM_TABLE: FormConfigurations = {
  groups: [
    {
      name: CUSTOM_TABLE_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Table Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'linesStartFrom',
          label: 'Lines Start from',
          type: 'textarea',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'linesEndsOn',
          label: 'Lines End on',
          type: 'textarea',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'columnsNumber',
          label: 'Number of Columns',
          type: 'input',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          pattern: '/^\\d+?$/',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              message: 'Value should be between 1 and 20',
              rule: 'min',
              value: 1,
            },
            {
              message: 'Value should be between 1 and 20',
              rule: 'max',
              value: 20,
            },
          ],
        },
        {
          name: 'productIndex',
          label: 'Product',
          type: 'input',
          row: 5,
          col: 1,
          width: 12,
          labelBold: false,
          optional: false,
          value: '',
          validationRules: [
            {
              rule: 'min',
              value: 1,
            },
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'highlightedRowNumber',
          label: 'Highlighted Row Number',
          type: 'input',
          row: 6,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          value: '',
        },
        {
          name: 'isNewVersion',
          label: 'Version 2 (JavaScript)',
          row: 7,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_CUSTOM_METRIC: FormConfigurations = {
  groups: [
    {
      name: CUSTOM_METRIC_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Custom Metric Name',
          placeholder: 'Enter Custom Metric Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              rule: 'unionRegex',
              value: '[a-zA-Z0-9]+',
              message: 'Custom metric name is invalid',
            },
          ],
        },
        {
          name: 'placeholderKey',
          label: 'Custom Metric Key',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          readOnly: true,
          validationRules: [
            {
              rule: 'regex',
              value: '/(?:)/',
              message: 'Custom metric key is not unique',
            },
          ],
        },
        {
          name: 'calculatedDataTarget',
          label: 'Store Metric in Data Source',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          type: 'select',
          options: [
            {
              name: CALCULATED_DATA_TARGET.data,
              label: 'Data',
              orderNum: 1,
              hint: null,
            },
            {
              name: CALCULATED_DATA_TARGET.metadata,
              label: 'Metadata',
              orderNum: 2,
              hint: null,
            },
          ],
          value: CALCULATED_DATA_TARGET.data,
        },
        {
          name: 'metricType',
          label: 'Type',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          type: 'select',
          options: [
            {
              name: MetricType.currency,
              label: 'Currency',
              orderNum: 1,
              hint: null,
            },
            {
              name: MetricType.percent,
              label: 'Percentage',
              orderNum: 2,
              hint: null,
            },
          ],
          value: 'Currency',
        },
        {
          name: 'isExportCSVAllowed',
          label: 'Allow Export to the CSV',
          type: 'toggle',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          validationRules: [],
          value: false,
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_CUSTOM_METRIC_FORMULA: FormConfigurations = {
  groups: [
    {
      name: CUSTOM_METRIC_FORMULA_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'formula',
          label: '',
          placeholder: 'Enter Formula',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: 'return +get_.product.configjson.data.key[iterator];',
          type: 'hidden',
          textareaHeight: 180,
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'isNewVersion',
          label: 'Version 2 (JavaScript)',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: true,
          type: 'toggle',
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_POST_API_CALL: FormConfigurations = {
  groups: [
    {
      name: POST_API_CALL_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'apiCallBody',
          label: '',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          config: {
            useTextValue: true,
            editorMode: true,
          },
          value: '',
          type: 'jsoneditor',
          validationRules: [],
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_API_CALL_RESPONSE: FormConfigurations = {
  groups: [
    {
      name: API_CALL_RESPONSE_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'responsePath',
          label: 'XPath or JSONPath',
          type: 'textarea',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'destination',
          label: 'Destination',
          type: 'textarea',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_API_CALL_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: API_CALL_CONFIGURATION_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'API Call Name',
          placeholder: 'Enter API Call Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              message: 'Please enter up to 300 characters',
              rule: 'maxLength',
              value: '300',
            },
          ],
        },
        {
          name: 'apiUrl',
          label: 'API URL',
          placeholder: 'Enter API URL',
          type: 'input',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              message: 'Please enter up to 300 characters',
              rule: 'maxLength',
              value: '300',
            },
          ],
        },
        {
          name: 'apiMethod',
          label: 'API Method',
          type: 'select',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            {
              name: 'get',
              label: 'GET',
              orderNum: 1,
              hint: null,
            },
            {
              name: 'post',
              label: 'POST',
              orderNum: 2,
              hint: null,
            },
          ],
          value: 'get',
        },
        {
          name: 'apiAuthentication',
          label: 'Authentication',
          type: 'select',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          options: [
            {
              orderNum: 1,
              hint: null,
              name: 'none',
              label: 'None',
            },
            {
              name: 'ensightCookies',
              label: 'Ensight Cookies',
              orderNum: 2,
              hint: null,
            },
          ],
          value: 'none',
        },
        {
          name: 'apiHeaders',
          label: 'Headers',
          placeholder: null,
          row: 5,
          col: 1,
          width: 12,
          labelBold: false,
          value: null,
          optional: true,
          type: 'dynamicInputList',
          format: {
            splitter: ',',
          },
          validationRules: [],
          renderingOptions: {
            customConfigs: {
              maxInputQuantity: 15,
              addBtnLabel: 'Add Another Header',
              useStringFormat: true,
            },
          },
        },
        {
          name: 'apiResultsNumber',
          label: 'Number of Results',
          type: 'input',
          row: 6,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          pattern: '/^\\d+?$/',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              message: 'Value should be between 1 and 100',
              rule: 'min',
              value: 1,
            },
            {
              message: 'Value should be between 1 and 100',
              rule: 'max',
              value: 100,
            },
          ],
        },
      ],
    },
  ],
};

export const DEFAULT_METRICS_COLORS = ['#006bb7', '#00c972', '#fc5716', '#9c27b0', '#000000'];
export const CUSTOM_METRIC_TYPE = 'customPin';
export const METRICS_TO_EXCLUDE = ['policy_year', 'eoy_age'];

const getMetricQuestion = (
  name: string,
  label: string,
  disableForDataSource: string[],
  defaultValue: string,
  options: Record<string, string>[]
) => ({
  name,
  label,
  type: 'datalist',
  row: 1,
  col: 1,
  width: 12,
  labelBold: false,
  renderingOptions: {
    customConfigs: {
      searchAnyPosition: true,
    },
  },
  options: [...options],
  value: defaultValue,
  validationRules: [
    {
      rule: 'unionRegex',
      value: '/^$/',
      message: 'The entered value is not valid. Enter a valid value or select an option from the dropdown menu',
      type: null,
    },
  ],
  disableForDataSource,
});

const metricKey = getMetricQuestion('metricKey', 'Metric', [ChartDataSource.staticMetrics], DEFAULT_METRIC_VALUE, [
  {
    name: 'none',
    label: 'None',
    hint: null,
  },
]);

const metricKeyLabels = getMetricQuestion(
  'metricKeyLabels',
  'Metric for Labels',
  [ChartDataSource.dynamicMetrics],
  '',
  []
);

const metricKeyData = getMetricQuestion('metricKeyData', 'Metric for Data', [ChartDataSource.dynamicMetrics], '', []);

export const DYNAMIC_FORM_CHART_METRICS: FormConfigurations = {
  groups: [
    {
      name: CHART_METRICS_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        metricKey,
        metricKeyLabels,
        metricKeyData,
        {
          name: 'metricTitle',
          label: 'Pin Title',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              message: 'Please enter up to 1000 characters',
              rule: 'maxLength',
              value: '1000',
            },
            {
              rule: 'required',
              value: 'true',
            },
          ],
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'productIndex',
          label: 'Product',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              message: 'Product should be between 1 and 40',
              rule: 'min',
              value: 1,
            },
            {
              message: 'Product should be between 1 and 40',
              rule: 'max',
              value: 40,
            },
            {
              rule: 'required',
              value: 'true',
            },
          ],
          disableForDataSource: [],
        },
        {
          name: 'chartType',
          label: 'Chart Type',
          type: 'datalist',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          renderingOptions: {
            customConfigs: {
              searchAnyPosition: true,
            },
          },
          options: [
            {
              name: ChartTypes.line,
              label: 'Line',
              hint: null,
            },
            {
              name: ChartTypes.spline,
              label: 'Spline',
              hint: null,
            },
            {
              name: ChartTypes.step,
              label: 'Step Line',
              hint: null,
            },
            {
              name: ChartTypes.area,
              label: 'Line with Background',
              hint: null,
            },
            {
              name: ChartTypes.areaSpline,
              label: 'Spline with Background',
              hint: null,
            },
            {
              name: ChartTypes.areaStep,
              label: 'Step Line with Background',
              hint: null,
            },
            {
              name: ChartTypes.areaBelow,
              label: 'Line with a Filled Area Below',
              hint: null,
            },
            {
              name: ChartTypes.areaAbove,
              label: 'Line with a Filled Area Above',
              hint: null,
            },
            {
              name: ChartTypes.bar,
              label: 'Bar',
              hint: null,
            },
          ],
          value: ChartTypes.line,
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'stepLineType',
          label: 'Step Line Type',
          type: 'datalist',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          renderingOptions: {
            customConfigs: {
              searchAnyPosition: true,
            },
          },
          options: [
            {
              name: 'step',
              label: 'Step',
              hint: null,
            },
            {
              name: 'step-before',
              label: 'Step-before',
              hint: null,
            },
            {
              name: 'step-after',
              label: 'Step-after',
              hint: null,
            },
          ],
          value: 'step',
          condition: {
            action: 'show',
            formula: 'a1 || a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.step,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.areaStep,
                op: 'eq',
              },
            ],
          },
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'metricType',
          label: '',
          type: 'hidden',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          disableForDataSource: [],
        },
        {
          name: 'metricSource',
          label: '',
          type: 'hidden',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          disableForDataSource: [],
        },
        {
          name: 'color',
          label: 'Color',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          optional: false,
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
          labelBold: false,
          value: '',
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'metricTitleForPieOrDonutChart',
          label: 'Metric Title',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          optional: true,
          labelBold: false,
          value: '',
          condition: {
            action: 'show',
            formula: 'a1 || a2 || a3',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.donut,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.pie,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.clusteredBarChart,
                op: 'eq',
              },
            ],
          },
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'opacity',
          label: 'Opacity, %',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          optional: true,
          labelBold: false,
          value: 20,
          pattern: '/^[1-9][0-9]?$|^100$/',
          condition: {
            action: 'show',
            formula: 'a1 || a2 || a3 || a4 || a5',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.area,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.areaSpline,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.areaStep,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.areaBelow,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.areaAbove,
                op: 'eq',
              },
            ],
          },
          disableForChart: [ChartTypes.pie, ChartTypes.donut, ChartTypes.clusteredBarChart],
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'background',
          label: 'Background',
          type: 'select',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: 'line',
          options: [
            { name: 'line', label: 'Line', orderNum: 1, hint: null },
            {
              name: 'solid',
              label: 'Solid',
              orderNum: 2,
              hint: null,
            },
          ],
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'interpolate',
          label: 'Interpolate Option',
          type: 'select',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: 'line',
          options: [
            { name: 'line', label: 'Line', orderNum: 1, hint: null },
            {
              name: 'step-after',
              label: 'Step Line',
              orderNum: 2,
              hint: null,
            },
          ],
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'hideMetricOnChart',
          label: 'Hide Metric on the Chart',
          row: 7,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'hideMetric',
          label: 'Hide Metric on the Pin Tool-tip',
          row: 7,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'dashedMetric',
          label: 'Dashed',
          row: 7,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          condition: {
            action: 'show',
            formula: '!a1',
            source: [
              {
                name: 'chartType',
                value: 'bar',
                op: 'eq',
              },
            ],
          },
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'dashLength',
          label: 'Dash Length',
          type: 'input',
          row: 8,
          col: 1,
          width: 12,
          labelBold: false,
          optional: false,
          condition: {
            action: 'show',
            formula: 'a1 && !a2',
            source: [
              {
                name: 'dashedMetric',
                value: true,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: 'bar',
                op: 'eq',
              },
            ],
          },
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
            {
              rule: 'required',
              value: 'true',
            },
          ],
          value: DEFAULT_DASH_METRIC_VALUE,
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'dashInterval',
          label: 'Dash Interval',
          type: 'input',
          row: 8,
          col: 1,
          width: 12,
          labelBold: false,
          optional: false,
          condition: {
            action: 'show',
            formula: 'a1 && !a2',
            source: [
              {
                name: 'dashedMetric',
                value: true,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: 'bar',
                op: 'eq',
              },
            ],
          },
          validationRules: [
            {
              rule: 'regex',
              value: CHART_CSS_PATTERN,
              message: CHART_CSS_UNIT_VALIDATION_MSG,
            },
            {
              rule: 'required',
              value: 'true',
            },
          ],
          value: DEFAULT_DASH_METRIC_VALUE,
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'stackedMetric',
          label: 'Stacked',
          row: 12,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
          condition: {
            action: 'show',
            formula: '!a1 && !a2',
            source: [
              {
                name: 'chartType',
                value: ChartTypes.areaAbove,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.areaBelow,
                op: 'eq',
              },
            ],
          },
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'stackedGroup',
          label: 'Stacked Group',
          type: 'input',
          row: 13,
          col: 1,
          width: 12,
          labelBold: false,
          pattern: '/^\\d+$/',
          value: '1',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
          condition: {
            action: 'show',
            formula: 'a1 && !a2 && !a3',
            source: [
              {
                name: 'stackedMetric',
                value: true,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.areaAbove,
                op: 'eq',
              },
              {
                name: 'chartType',
                value: ChartTypes.areaBelow,
                op: 'eq',
              },
            ],
          },
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
        {
          name: 'yAxis',
          label: 'Y-Axis',
          type: 'select',
          row: 5,
          col: 1,
          width: 12,
          labelBold: false,
          optional: true,
          value: 'mainYAxis',
          options: [
            { name: 'y', label: 'Main Y-Axis', orderNum: 1, hint: null },
            { name: 'y2', label: 'Additional Y-Axis', orderNum: 2, hint: null },
          ],
          disableForDataSource: [ChartDataSource.staticMetrics],
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_BUTTONS_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: BUTTON_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Button Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              rule: 'unionRegex',
              value: '[a-zA-Z0-9]+',
              message: 'Button name is invalid',
            },
          ],
        },
        {
          name: 'buttonLabel',
          label: 'Button Label',
          type: 'input',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              rule: 'unionRegex',
              value: '[a-zA-Z0-9]+',
              message: 'Button label is invalid',
            },
          ],
        },
        {
          name: 'buttonType',
          label: 'Button Type',
          type: 'select',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          options: [{ name: 'apiTrigger', label: 'API Trigger' }],
          value: 'apiTrigger',
        },
        {
          name: 'apiCalls',
          label: 'API Calls',
          type: 'checkbox',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          options: [],
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
          value: [],
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_DROPDOWN_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: DROPDOWN_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
              message: 'Name is required',
            },
          ],
        },
        {
          name: 'placeholderKey',
          label: 'Key',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          readOnly: true,
          validationRules: [],
        },
        {
          name: 'dropdownOptions',
          label: 'List of Options',
          placeholder: 'Enter the Dropdown Option',
          type: 'dynamicInputList',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          value: [],
          addBtnLabel: 'Add Dropdown Option',
          format: {
            splitter: ',',
          },
          validationRules: [
            {
              rule: 'required',
              value: 'true',
              message: 'Option is required',
            },
            {
              rule: 'unique',
              value: 'true',
              message: 'Option is not unique',
            },
          ],
        },
        {
          name: 'defaultOption',
          label: 'Default Option',
          type: 'input',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          value: 0,
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'hidden',
          label: 'Hidden on Custom Page',
          row: 5,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
        },
        {
          name: 'hiddenOnSharedView',
          label: 'Hidden on Shareable View Settings',
          row: 6,
          col: 1,
          width: 12,
          labelBold: false,
          value: false,
          type: 'toggle',
        },
        // {
        //   name: 'showOnSetup',
        //   label: 'Prompt when the presentation is created from the template',
        //   row: 6,
        //   col: 1,
        //   width: 12,
        //   labelBold: false,
        //   value: false,
        //   type: 'toggle',
        // },
      ],
    },
  ],
};

export const DYNAMIC_FORM_PRODUCT_SELECTOR_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: PRODUCT_SELECTOR_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              rule: 'unionRegex',
              value: '[a-zA-Z0-9]+',
              message: 'Name is invalid',
            },
          ],
        },
        {
          name: 'placeholderKey',
          label: 'Key',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          readOnly: true,
          validationRules: [
            {
              rule: 'regex',
              value: '/(?:)/',
              message: 'Key is not unique',
            },
          ],
        },
        {
          name: 'placeholderDefaultValue',
          label: 'Default Product',
          type: 'input',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          value: 0,
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
        {
          name: 'placeholderProductSelectorHtml',
          label: 'HTML',
          type: 'textarea',
          row: 4,
          col: 1,
          width: 12,
          labelBold: false,
          value: '{{#each products}} <div class="product-selector-card">Test</div> {{/each}}',
          textareaHeight: 320,
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
          ],
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_TAB_CONFIGURATION: FormConfigurations = {
  groups: [
    {
      name: TAB_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'placeholderName',
          label: 'Name',
          type: 'input',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              rule: 'unionRegex',
              value: '[a-zA-Z0-9]+',
              message: 'Name is invalid',
            },
          ],
        },
        {
          name: 'placeholderKey',
          label: 'Key',
          row: 2,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          readOnly: true,
          validationRules: [
            {
              rule: 'regex',
              value: '/(?:)/',
              message: 'Key is not unique',
            },
          ],
        },
        {
          name: 'tabsNumber',
          label: 'Number of Tabs',
          row: 3,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          type: 'input',
          pattern: '/^\\d+?$/',
          validationRules: [
            {
              rule: 'required',
              value: 'true',
            },
            {
              rule: 'min',
              value: 1,
            },
            {
              rule: 'unionRegex',
              value: '[0-9]+',
              message: 'Value is invalid',
            },
          ],
        },
      ],
    },
  ],
};

export const DYNAMIC_FORM_TAB_AREA: FormConfigurations = {
  groups: [
    {
      name: TAB_AREA_STATE.id,
      row: 1,
      col: 1,
      width: 12,
      questions: [
        {
          name: 'HTMLForTabs',
          label: '',
          type: 'textarea',
          row: 1,
          col: 1,
          width: 12,
          labelBold: false,
          value: '',
          textareaHeight: 320,

          validationRules: [
            {
              rule: 'required',
              message: 'HTML for Tabs is required',
            },
          ],
        },
      ],
    },
  ],
};

export const FORM_BY_STATE_ID = {
  [CHART_CONFIGURATION_STATE.id]: DYNAMIC_FORM_CHART_CONFIGURATION,
  [CHART_STYLE_CONFIGURATION_STATE.id]: DYNAMIC_FORM_CHART_STYLE_CONFIGURATION,
  [TEXT_CONFIGURATION_STATE.id]: DYNAMIC_FORM_TEXT_CONFIGURATION,
  [VARIABLES_STATE.id]: DYNAMIC_FORM_VARIABLES_CONFIGURATION,
  [CUSTOM_METRIC_STATE.id]: DYNAMIC_FORM_CUSTOM_METRIC,
  [IMAGE_STATE.id]: DYNAMIC_FORM_IMAGE,
  [PRODUCTS_DESCRIPTION_STATE.id]: DYNAMIC_FORM_PRODUCTS_DESCRIPTION,
  [CUSTOM_METRIC_FORMULA_STATE.id]: DYNAMIC_FORM_CUSTOM_METRIC_FORMULA,
  [CUSTOM_TABLE_STATE.id]: DYNAMIC_FORM_CUSTOM_TABLE,
  [CUSTOM_TABLE_COLUMNS_STATE.id]: DYNAMIC_FORM_CUSTOM_TABLE_COLUMNS,
  [BUTTON_STATE.id]: DYNAMIC_FORM_BUTTONS_CONFIGURATION,
  [API_CALL_CONFIGURATION_STATE.id]: DYNAMIC_FORM_API_CALL_CONFIGURATION,
  [POST_API_CALL_STATE.id]: DYNAMIC_FORM_POST_API_CALL,
  [API_CALL_RESPONSE_STATE.id]: DYNAMIC_FORM_API_CALL_RESPONSE,
  [CHART_METRICS_STATE.id]: DYNAMIC_FORM_CHART_METRICS,
  [DROPDOWN_STATE.id]: DYNAMIC_FORM_DROPDOWN_CONFIGURATION,
  [PRODUCT_SELECTOR_STATE.id]: DYNAMIC_FORM_PRODUCT_SELECTOR_CONFIGURATION,
  [TAB_STATE.id]: DYNAMIC_FORM_TAB_CONFIGURATION,
  [TAB_AREA_STATE.id]: DYNAMIC_FORM_TAB_AREA,
};

export const INSERT_STEP_IDS = [
  CHART_METRICS_STATE.id,
  TEXT_CONFIGURATION_STATE.id,
  VARIABLES_STATE.id,
  CUSTOM_METRIC_STATE.id,
  IMAGE_STATE.id,
  PRODUCTS_DESCRIPTION_STATE.id,
  CUSTOM_TABLE_COLUMNS_STATE.id,
  BUTTON_STATE.id,
  API_CALL_RESPONSE_STATE.id,
  INSERTS_FROM_OTHER_PAGE.id,
  DROPDOWN_STATE.id,
  PRODUCT_SELECTOR_STATE.id,
  TAB_AREA_STATE.id,
  // POST_API_CALL_STATE.id,
];

// export const INSERT_TYPE_IDS: { [key: string]: Placeholder.InsertType } = {

export const INSERT_SELECTION_STATE = new Map([
  [
    INSERT_TYPE.chart,
    [INSERT_TYPE_STATE, CHART_CONFIGURATION_STATE, CHART_STYLE_CONFIGURATION_STATE, CHART_METRICS_STATE],
  ],
  [INSERT_TYPE.text, [INSERT_TYPE_STATE, TEXT_CONFIGURATION_STATE]],
  [INSERT_TYPE.variable, [INSERT_TYPE_STATE, VARIABLES_STATE]],
  [INSERT_TYPE.customMetric, [INSERT_TYPE_STATE, CUSTOM_METRIC_STATE, CUSTOM_METRIC_FORMULA_STATE]],
  [INSERT_TYPE.image, [INSERT_TYPE_STATE, IMAGE_STATE]],
  [INSERT_TYPE.productsDescription, [INSERT_TYPE_STATE, PRODUCTS_DESCRIPTION_STATE]],
  [INSERT_TYPE.customTable, [INSERT_TYPE_STATE, CUSTOM_TABLE_STATE, CUSTOM_TABLE_COLUMNS_STATE]],
  [INSERT_TYPE.button, [INSERT_TYPE_STATE, BUTTON_STATE]],
  [
    INSERT_TYPE.apiCall,
    [INSERT_TYPE_STATE, API_CALL_CONFIGURATION_STATE, POST_API_CALL_STATE, API_CALL_RESPONSE_STATE],
  ],
  [INSERT_TYPE.fromOtherPage, [INSERT_TYPE_STATE, CUSTOM_PAGE, INSERTS_FROM_OTHER_PAGE]],
  [INSERT_TYPE.dropdown, [INSERT_TYPE_STATE, DROPDOWN_STATE]],
  [INSERT_TYPE.productSelector, [INSERT_TYPE_STATE, PRODUCT_SELECTOR_STATE]],
  [INSERT_TYPE.tab, [INSERT_TYPE_STATE, TAB_STATE, TAB_AREA_STATE]],
]);

export const INSERT_SECTIONS_LABELS = [
  {
    label: 'CHARTS',
    type: INSERT_TYPE.chart,
  },
  {
    label: 'TEXT PLACEHOLDERS',
    type: INSERT_TYPE.text,
  },
  {
    label: 'VARIABLES',
    type: INSERT_TYPE.variable,
  },
  {
    label: 'API CALLS',
    type: INSERT_TYPE.apiCall,
  },
  {
    label: 'CUSTOM METRICS',
    type: INSERT_TYPE.customMetric,
  },
  {
    label: 'IMAGES',
    type: INSERT_TYPE.image,
  },
  {
    label: 'PRODUCTS DESCRIPTIONS',
    type: INSERT_TYPE.productsDescription,
  },
  {
    label: 'CUSTOM TABLE',
    type: INSERT_TYPE.customTable,
  },
  {
    label: 'BUTTONS',
    type: INSERT_TYPE.button,
  },
  {
    label: 'DROPDOWNS',
    type: INSERT_TYPE.dropdown,
  },
  {
    label: 'PRODUCT SELECTOR',
    type: INSERT_TYPE.productSelector,
  },
  {
    label: 'TAB',
    type: INSERT_TYPE.tab,
  },
];

export const ACTIVE_PAGE_ON_EDIT = {
  [INSERT_TYPE.chart]: CHART_CONFIGURATION_STATE.id,
  [INSERT_TYPE.text]: TEXT_CONFIGURATION_STATE.id,
  [INSERT_TYPE.variable]: VARIABLES_STATE.id,
  [INSERT_TYPE.customMetric]: CUSTOM_METRIC_STATE.id,
  [INSERT_TYPE.image]: IMAGE_STATE.id,
  [INSERT_TYPE.productsDescription]: PRODUCTS_DESCRIPTION_STATE.id,
  [INSERT_TYPE.customTable]: CUSTOM_TABLE_STATE.id,
  [INSERT_TYPE.button]: BUTTON_STATE.id,
  [INSERT_TYPE.apiCall]: API_CALL_CONFIGURATION_STATE.id,
  [INSERT_TYPE.fromOtherPage]: CUSTOM_PAGE.id,
  [INSERT_TYPE.dropdown]: DROPDOWN_STATE.id,
  [INSERT_TYPE.productSelector]: PRODUCT_SELECTOR_STATE.id,
  [INSERT_TYPE.tab]: TAB_STATE.id,
};
