import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges } from '@angular/core';

import { Observable } from 'rxjs';
import { ChartOptions, ChartTooltipValues } from '@core/model';

@Component({
  selector: 'ep-chart-tooltip',
  templateUrl: './chart-tooltip.component.html',
  styleUrls: ['./chart-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTooltipComponent implements OnChanges {
  @Input() data: ChartTooltipValues<Observable<string>>[];
  @Input() chartOptions: ChartOptions;

  @Output() onChanges = new EventEmitter();
  @Output() stopPin = new EventEmitter();

  ngOnChanges(): void {
    this.onChanges.emit();
  }
}
