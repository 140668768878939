import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ModalConfig, ModalRef } from '@assurance/bootstrap';
import { IPagerOptions } from '@se/common/app/navigations/components/pager/pager.interface';

import { MappedPresentation, MappedPresentationCount, TableHeaderColumn, TableRequestParams } from '@shared/models';
import { DefaultPaginationState, DefaultSortState, TableColumns } from '../../constants';
import { CustomPageService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'ep-presentation-list-modal',
  templateUrl: './presentation-list-modal.component.html',
  styleUrls: ['./presentation-list-modal.component.scss'],
})
export class PresentationListModalComponent implements OnInit {
  presentations: MappedPresentation[];
  loading = true;
  tableHeaderColumn: TableHeaderColumn[] = TableColumns;
  currentOrder: TableRequestParams = DefaultSortState;
  pagination: IPagerOptions = DefaultPaginationState;
  minProductOfCustomPage: number | string;
  message: string;
  searchValue = '';
  selectedPresentation: MappedPresentation;

  constructor(
    private modal: ModalRef,
    private customPageService: CustomPageService,
    private cd: ChangeDetectorRef,
    private config: ModalConfig<number | string>
  ) {}

  ngOnInit(): void {
    this.minProductOfCustomPage = this.config.data;
    this.message = `The sales story requires ${this.minProductOfCustomPage} plans to be in the presentation`;
    this.getPresentation();
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  trackById(index: number, presentation: MappedPresentation): number {
    return presentation.id;
  }

  close(): void {
    this.modal.close(null);
  }

  onPageChange(options: IPagerOptions): void {
    this.pagination = options;
    this.getPresentation();
  }

  setOrderByValue(event: TableRequestParams): void {
    this.currentOrder = event;
    this.getPresentation();
  }

  search(event: InputEvent): void {
    this.searchValue = event.target['value'];
    this.getPresentation();
  }

  select(presentation: MappedPresentation): void {
    if (presentation.carrierPlansCount < this.minProductOfCustomPage) {
      return;
    }

    this.selectedPresentation = presentation;
  }

  selectPresentation(): void {
    this.modal.close(this.selectedPresentation);
  }

  private getQueryParams(): TableRequestParams {
    const params: TableRequestParams = {
      PAGE_NUMBER: this.pagination.currentPage - 1,
      PAGE_SIZE: 5,
      SEARCH_VALUE: this.searchValue,
      SHOW_PUBLISHED: true,
      TEMPLATE: false,
    };

    if (Object.keys(this.currentOrder).length > 0) {
      params.DIRECTION = this.currentOrder.DIRECTION;
      params.SORT_FIELD = this.currentOrder.ORDER_BY;
    }

    return params;
  }

  private getPresentation(): void {
    this.loading = true;
    this.customPageService
      .getPresentations(this.getQueryParams())
      .pipe(untilDestroyed(this))
      .subscribe((data: MappedPresentationCount) => {
        this.presentations = data.presentations;
        this.pagination.totalElements = data.count;
        this.loading = false;
        this.cd.markForCheck();
      });
  }
}
