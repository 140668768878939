import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { map, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { APIService, Utils, Global } from '@shared/services';
import { setDependentPermissions } from '@ngrx-app/global.actions';
import { AppState } from '../../../reducers';
import { getDependentPermissions } from '@ngrx-app/global.selectors';

@Injectable()
export class SharedDependentPermissions implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private global: Global,
    private apiService: APIService,
    private utils: Utils
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.global.setSharedToken(route);

    return this.apiService.getDependentPermissions().pipe(
      withLatestFrom(this.store.select(getDependentPermissions)),
      map(([response, permissions]) => {
        !permissions &&
          this.store.dispatch(
            setDependentPermissions({
              dependentPermissions: this.utils.extractDependentPermissions(response.data),
            })
          );

        return true;
      })
    );
  }
}
