import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { chain } from '@shared/constants';

@Pipe({
  name: 'formatText',
})
export class FormatTextPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  public transform(value: string): string {
    const newValue: string = this.sanitizeTags(this.handleFontSize(value));

    return this.sanitized.bypassSecurityTrustHtml(newValue) as any;
  }

  private handleFontSize(value: string) {
    const reg = /<size\d*>/g;
    const sizes = value.match(reg);

    if (sizes && sizes.length) {
      sizes.forEach((size: string) => {
        const regExp = new RegExp(size, 'g');
        value = value.replace(regExp, `&span style='font-size: ${size.replace(/\D/g, '')}px;'>`);
      });
    }

    return value;
  }

  private sanitizeTags(value: string) {
    return chain(value)
      .replace(/\s+/g, ' ')
      .replace(/<b>/g, '&b&')
      .replace(/<\/b>/g, '&/b&')
      .replace(/<i>/g, '&i&')
      .replace(/<\/i>/g, '&/i&')
      .replace(/<br>/g, '&br&')
      .replace(/<br \/>/g, '&br/&')
      .replace(/<\/size\d*>/g, '&/span&')
      .replace(/(<\w*>)|(<\/\w*>)|(<\w*)/g, '')
      .replace(/&b&/g, '<b>')
      .replace(/&\/b&/g, '</b>')
      .replace(/&i&/g, '<i>')
      .replace(/&\/i&/g, '</i>')
      .replace(/&br&/g, '<br>')
      .replace(/&br\/&/g, '<br />')
      .replace(/&\/span&/g, '</span>')
      .replace(/&span/g, '<span')
      .value();
  }
}
