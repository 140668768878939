import { Action, createReducer, on } from '@ngrx/store';
import { find } from 'lodash-es';

import { InsertType } from '@core/model';
import * as PlaceholdersWizardActions from './placeholders-wizard.actions';
import { INSERT_SELECTION_STATE, INSERT_TYPE_STATE } from '../constants';
import { PlaceholderWizardState } from '../models';

export const initialState: PlaceholderWizardState = {
  insertTypeStates: {
    id: null,
    selectedType: null,
    typeStates: [],
  },
  placeholders: {
    data: [],
    metadata: [],
    pending: null,
    success: null,
    error: null,
  },
  dynamicForm: {
    content: null,
    valid: null,
  },
  imagesList: [],
  persistImagesList: {},
  validationForm: [],
  open: false,
  currentCustomPage: null,
  customPagesList: null,
  customPagesCount: null,
  selectedCustomPage: null,
  selectedInserts: null,
};

function getUpdatedWizardPageState(state, activeStateId: string) {
  return {
    ...state,
    insertTypeStates: {
      ...state.insertTypeStates,
      typeStates: state.insertTypeStates.typeStates.map(item => ({
        ...item,
        active: item.id === activeStateId,
      })),
    },
  };
}

export const placeholderWizard = createReducer(
  initialState,
  on(PlaceholdersWizardActions.setImagesList, (state, { imagesData }) => ({
    ...state,
    imagesList: imagesData,
  })),
  on(PlaceholdersWizardActions.setImage, (state, { imageData }) => ({
    ...state,
    imagesList: [
      ...state.imagesList,
      {
        ...imageData,
      },
    ],
  })),
  on(PlaceholdersWizardActions.selectImage, (state, { name }) => ({
    ...state,
    imagesList: state.imagesList.map(image => ({
      ...image,
      selected: image.name === name,
    })),
  })),
  on(PlaceholdersWizardActions.deleteImage, (state, { name }) => {
    const image = state.imagesList.find(image => image.name === name);

    if (!image) return state;

    return {
      ...state,
      imagesList: image.upload
        ? state.imagesList.filter(image => image.name !== name)
        : state.imagesList.map(image =>
            image.name === name
              ? {
                  ...image,
                  delete: true,
                }
              : image
          ),
    };
  }),
  on(PlaceholdersWizardActions.loadedImage, (state, { name }) => ({
    ...state,
    imagesList: state.imagesList.map(image =>
      image.name === name
        ? {
            ...image,
            loading: false,
          }
        : image
    ),
  })),
  on(PlaceholdersWizardActions.resetImagesList, state => ({
    ...state,
    imagesList: [],
  })),
  on(PlaceholdersWizardActions.persistImagesList, (state, { id }) => ({
    ...state,
    imagesList: [],
    persistImagesList: {
      ...state.persistImagesList,
      [id]: state.imagesList,
    },
  })),
  on(
    PlaceholdersWizardActions.setInsertTypeStates,
    (state, { id, insertType, activePageId = INSERT_TYPE_STATE.id }) => ({
      ...state,
      insertTypeStates: {
        id,
        selectedType: insertType,
        typeStates: INSERT_SELECTION_STATE.get(insertType as InsertType).map(item => ({
          ...item,
          active: item.id === activePageId,
        })),
      },
    })
  ),
  on(PlaceholdersWizardActions.resetInsertTypeStates, state => ({
    ...state,
    insertTypeStates: {
      id: null,
      selectedType: null,
      typeStates: [],
    },
  })),
  on(PlaceholdersWizardActions.nextState, (state, { stepsToSkip }) => {
    const filteredTypeStates = state.insertTypeStates.typeStates.filter(item => !stepsToSkip.includes(item.id));

    const activeIndex = filteredTypeStates.findIndex(item => item.active);

    return getUpdatedWizardPageState(state, filteredTypeStates[activeIndex + 1].id);
  }),
  on(PlaceholdersWizardActions.backState, (state, { stepsToSkip }) => {
    const filteredTypeStates = state.insertTypeStates.typeStates.filter(item => !stepsToSkip.includes(item.id));

    const activeIndex = filteredTypeStates.findIndex(item => item.active);

    return getUpdatedWizardPageState(state, filteredTypeStates[activeIndex - 1].id);
  }),
  on(PlaceholdersWizardActions.resetDynamicFormFields, state => ({
    ...state,
    dynamicForm: {
      content: null,
      valid: null,
    },
  })),
  on(PlaceholdersWizardActions.updateDynamicFormFields, (state, { fields, valid = state.dynamicForm.valid }) => ({
    ...state,
    dynamicForm: {
      ...state.dynamicForm,
      content: {
        ...state.dynamicForm.content,
        ...fields,
      },
      valid,
    },
  })),
  on(PlaceholdersWizardActions.setPlaceholdersDataSuccess, (state, { data, metadata }) => ({
    ...state,
    placeholders: {
      data: data || state.placeholders.data,
      metadata,
      pending: false,
      success: true,
      error: false,
    },
  })),
  on(PlaceholdersWizardActions.setFormValidation, (state, data) => {
    const validationForm: {
      formId: number;
      isValid: boolean;
    }[] = state.validationForm;
    const form = find(validationForm, {
      formId: data.formId,
    });
    const newForm = form
      ? validationForm.map(item => ({
          ...item,
          isValid: item.formId === data.formId ? data.isValid : item.isValid,
        }))
      : [...validationForm, data];

    return {
      ...state,
      validationForm: newForm,
    };
  }),
  on(PlaceholdersWizardActions.removeFormValidation, (state, { formId }) => ({
    ...state,
    validationForm: state.validationForm.filter(form => form.formId !== formId),
  })),
  on(PlaceholdersWizardActions.resetFormValidation, state => ({
    ...state,
    validationForm: [],
  })),
  on(PlaceholdersWizardActions.resetPlaceholderWizard, state => ({
    ...state,
    ...initialState,
  })),
  on(PlaceholdersWizardActions.setDynamicFormOpen, (state, { payload }) => ({
    ...state,
    open: payload,
  })),
  on(PlaceholdersWizardActions.setCurrentCustomPage, (state, { currentCustomPage }) => {
    return {
      ...state,
      currentCustomPage,
    };
  }),
  on(PlaceholdersWizardActions.loadChooseCustomPagesListSuccess, (state, { customPagesList, customPagesCount }) => {
    return {
      ...state,
      customPagesList,
      customPagesCount,
    };
  }),
  on(PlaceholdersWizardActions.setCustomPage, (state, { customPage }) => {
    return {
      ...state,
      selectedCustomPage: customPage,
    };
  }),
  on(PlaceholdersWizardActions.setInserts, (state, { inserts }) => {
    return {
      ...state,
      selectedInserts: inserts,
    };
  })
);

export function placeholderWizardReducer(
  state: PlaceholderWizardState | undefined,
  action: Action
): PlaceholderWizardState {
  return placeholderWizard(state, action);
}
