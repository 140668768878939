import { Injectable } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as fileSaver from 'file-saver';

import { CustomPageApiService } from '../custom-page-api/custom-page-api.service';
import { ExportedCustomPage } from '@shared/models';
import { ResponseType } from '@core/model';
import { TimeFormat } from '@core/constant';
import { TimeService } from '@shared/services';
import { LocalStorageService } from '@core/service';

@Injectable()
export class ImportExportService {
  constructor(
    private customPageApiService: CustomPageApiService,
    private localStorageService: LocalStorageService,
    private timeService: TimeService
  ) {}

  exportCustomPage(customPageUUID: string, name: string): Observable<void> {
    return this.customPageApiService.exportCustomPage(customPageUUID).pipe(
      map((data: ResponseType<{ data: ExportedCustomPage }>) => {
        const fileName = this.getFormattedFileName(name);
        fileSaver.saveAs(new Blob([JSON.stringify(data.data)]), fileName);
      })
    );
  }

  importCustomPage({ file }: { file: File }): Observable<ResponseType<{ success: boolean }>> {
    const subject = new Subject<ExportedCustomPage>();
    const observable = subject.asObservable();
    const fileReader = new FileReader();

    fileReader.onload = () => {
      let content: ExportedCustomPage;

      try {
        content = JSON.parse(fileReader.result as string);
      } catch (e) {
        content = null;
      }

      subject.next(content);
      subject.complete();
    };

    fileReader.readAsText(file);

    return observable.pipe(
      switchMap((content: ExportedCustomPage) =>
        content ? this.customPageApiService.importCustomPage(content) : of(null)
      )
    );
  }

  private getFormattedFileName(customPageName: string): string {
    const env = this.localStorageService.getNotJSONData('appEnv');
    const timestamp = this.timeService.getFormattedDate(new Date(), TimeFormat.YYYYMMDDhhmmss);

    return `${env} - [${customPageName}] - ${timestamp}.json`;
  }
}
