<div class="aapp-login-container">
  <div class="container">
    <div class="row justify-content-center">
      <h3 class="title col-12">Manage Password</h3>
      <div class="col-lg-8 col-sm-12">
        <um-manage-password-form
          *ngIf="forced"
          [rememberPasswordLinkNavigateTo]="['/forgot-password']"
          (submitForm)="submitForm($event)"
        ></um-manage-password-form>
        <um-manage-password-form
          *ngIf="!forced"
          [rememberPasswordLinkNavigateTo]="['/forgot-password']"
          (cancelForm)="cancelForm()"
          (submitForm)="submitForm($event)"
        ></um-manage-password-form>
      </div>
    </div>
  </div>
</div>
