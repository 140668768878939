import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  AfterViewChecked,
  Output,
} from '@angular/core';

import { SortDirection, TableRequestParams } from '@shared/models';
import { Directions } from '@shared/constants';

@Directive({
  selector: '[epSort]',
})
export class SortDirective implements AfterViewInit, AfterViewChecked {
  @Input() sortable: string;
  @Input() currentSortable: string;
  @Input() currentDirection: SortDirection;

  @Output() sortBy = new EventEmitter<TableRequestParams>();

  private direction: SortDirection | string = '';

  @HostListener('click', ['$event']) sort() {
    const direction = this.sortable === this.currentSortable ? this.currentDirection : this.direction;
    this.direction = Directions[direction];
    this.setClass(this.direction);
    this.sortBy.emit(this.direction ? { DIRECTION: this.direction as SortDirection, ORDER_BY: this.sortable } : {});
  }

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.sortable === this.currentSortable) {
      this.setClass(this.currentDirection);
    }
  }

  ngAfterViewChecked(): void {
    if (this.sortable !== this.currentSortable) {
      this.elementRef.nativeElement.removeAttribute('direction');
    }
  }

  private setClass(direction: SortDirection | string): void {
    if (direction) {
      this.elementRef.nativeElement.setAttribute('direction', direction);
    } else {
      this.elementRef.nativeElement.removeAttribute('direction');
    }
  }
}
