import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TableColumn } from '@core/model';

@Component({
  selector: 'ep-custom-table',
  templateUrl: './custom-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTableComponent {
  @Input() columns: TableColumn[];
  @Input() tableData: number[][];
  @Input() rowHighlighted: number;
}
