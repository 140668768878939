import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';

import { RowClickEvent, TableBodyColumn, TableHeaderColumn, TableRequestParams } from '@shared/models';
import { CustomTableColumnDirective } from '@shared/directives/custom-table-column/custom-table-column.directive';

@Component({
  selector: 'ep-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements AfterViewInit {
  @Input() tableHeaderColumn: TableHeaderColumn[] = [];
  @Input() tableColumn: TableBodyColumn[] = [];
  @Input() expendedDetailColumns: TableBodyColumn[] = [];
  @Input() trackByKey: string;
  @Input() data: T[] = [];
  @Input() orderByParams: TableRequestParams;
  @Input() rowHeaderStyle: Record<string, string>;
  @Input() rowBodyStyle: Record<string, string>;

  @Output() sortBy = new EventEmitter<TableRequestParams>();
  @Output() rowClick = new EventEmitter<RowClickEvent<T>>();

  @ContentChildren(CustomTableColumnDirective) columnDefinitions: QueryList<CustomTableColumnDirective>;

  //TODO: need to find another way to display changes
  constructor(private cdr: ChangeDetectorRef) {}

  isReady = false;
  customColumns: Record<string, TemplateRef<HTMLElement>> = {};

  ngAfterViewInit(): void {
    this.columnDefinitions.toArray().forEach((column: CustomTableColumnDirective) => {
      if (column.columnTemplate) {
        this.customColumns[column.columnName] = column.columnTemplate;
      }
    });
    this.isReady = true;
    this.cdr.detectChanges();
  }

  // @ts-ignore
  trackBy(index: number, item: T): string | number {
    return item[this.trackByKey];
  }

  sort(value: TableRequestParams): void {
    this.sortBy.emit(value);
  }

  rowBodyClick(index: number, record: T): void {
    this.rowClick.emit({ index, record });
  }
}
