import { Directive, ElementRef, Input, Inject, HostListener, AfterViewInit } from '@angular/core';
import { WINDOW_TOKEN } from '@core/constant';

@Directive({
  selector: '[epDynamicPositionOfMenu]',
})
export class DynamicPositionOfMenuDirective implements AfterViewInit {
  @Input() menuBtn: HTMLElement;

  @HostListener('window:scroll')
  onWindowScroll(): void {
    this.positionMenu();
  }

  constructor(@Inject(WINDOW_TOKEN) private window: Window, private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.positionMenu();
  }

  private positionMenu(): void {
    const rect = this.menuBtn.getBoundingClientRect();
    const element = this.elementRef.nativeElement as HTMLElement;

    const spaceBelow = this.window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;
    const menuHeight = element.clientHeight;

    if (spaceBelow < menuHeight && spaceAbove > menuHeight) {
      element.style.top = `${rect.top - menuHeight}px`;
    } else {
      element.style.top = `${rect.bottom}px`;
    }
  }
}
