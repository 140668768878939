import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { throwError, Observable, EMPTY, of, Subject } from 'rxjs';
import { orderBy } from 'lodash-es';
import { first, map, catchError, switchMap } from 'rxjs/operators';

import { Global, APIService } from '@shared/services';
import { AppState } from '../../../../reducers';
import {
  CoverLetterTemplatesLoadSuccess,
  CoverLetterTemplatesLoadFailure,
  CoverLetterTemplatesLoadPending,
  CoverLetterRequiredTemplatesLoadPending,
  CoverLetterRequiredTemplatesLoadSuccess,
  CoverLetterRequiredTemplatesLoadFailure,
} from './cover-sheet-modal.actions';
import { getCoverLetterRequiredTemplatesLoaded, getCoverLetterTemplatesLoaded } from './cover-sheet-modal.selectors';
import { coverSheetTemplatesQueryParams, coverSheetRequiredTemplatesQueryParams } from './cover-sheet-modal.constants';
import { CoverSheetPlaceholderItem, FormField } from './cover-sheet-modal.interfaces';

@Injectable()
export class CoverSheetModalService {
  private placeholder = new Subject<CoverSheetPlaceholderItem>();
  private currentRange: Range;
  private filed: FormField;

  set setRange(range: Range) {
    this.currentRange = range;
  }

  get getRange(): Range {
    return this.currentRange;
  }

  set setFiled(field: FormField) {
    this.filed = field;
  }

  get getFiled(): FormField {
    return this.filed;
  }

  constructor(private apiService: APIService, private store: Store<AppState>, public global: Global) {}

  watchForPlaceholder(): Observable<CoverSheetPlaceholderItem> {
    return this.placeholder.asObservable();
  }

  setPlaceholder(placeholder: CoverSheetPlaceholderItem): void {
    this.placeholder.next(placeholder);
  }

  getCoverSheetTemplates() {
    return this.store.select(getCoverLetterTemplatesLoaded).pipe(
      switchMap(loaded => {
        if (!loaded) {
          return this.getCoverSheetTemplatesHandler(coverSheetTemplatesQueryParams).pipe(first());
        }

        return of(EMPTY);
      }),
      first()
    );
  }

  getCoverSheetRequiredTemplates() {
    return this.store.select(getCoverLetterRequiredTemplatesLoaded).pipe(
      switchMap(loaded => {
        if (!loaded) {
          return this.getCoverSheetTemplatesHandler(coverSheetRequiredTemplatesQueryParams, true).pipe(first());
        }

        return of(EMPTY);
      }),
      first()
    );
  }

  getCoverSheetTemplatesHandler(params: any, required = false): Observable<unknown> {
    this.dispatchPendingEvent(required);

    if (this.global.isSharedPresentation()) {
      return this.apiService.getCoverLetterTemplates(params).pipe(
        map(res => this.handleSuccess(res, required)),
        catchError(err => this.handleError(err, required))
      );
    }

    return this.apiService.getPagesListByLabel(params).pipe(
      map(res => this.handleSuccess(res, required)),
      catchError(err => this.handleError(err, required))
    );
  }

  private dispatchPendingEvent(required: boolean) {
    this.store.dispatch(
      required ? new CoverLetterRequiredTemplatesLoadPending() : new CoverLetterTemplatesLoadPending()
    );
  }

  private handleSuccess(response: any, required = false) {
    this.store.dispatch(
      required
        ? new CoverLetterRequiredTemplatesLoadSuccess(orderBy(response.data.data, ['customFields.order']))
        : new CoverLetterTemplatesLoadSuccess(orderBy(response.data.data, ['customFields.order']))
    );
  }

  private handleError(error: any, required = false) {
    this.store.dispatch(
      required ? new CoverLetterRequiredTemplatesLoadFailure(error) : new CoverLetterTemplatesLoadFailure(error)
    );

    return throwError(`${error.status} - ${error.statusText}`);
  }
}
