import { Observable } from 'rxjs';
import hash from 'hash-it';

export abstract class AbstractCacheService<T> {
  private cache: {
    [id: string]: {
      value: Observable<T>;
      payload: Record<string, unknown>;
    };
  } = {};

  getValue(object: any): Observable<T> {
    const key = hash(object).toString();
    const item = this.cache[key];

    if (!item) {
      return null;
    }

    return item.value;
  }

  setValue(value: Observable<T>, object: any) {
    const key = hash(object).toString();
    this.cache[key] = { value, payload: object };
  }

  clearCache(): void {
    this.cache = {};
  }
}
