import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ModalConfig, ModalRef } from '@assurance/bootstrap';

@Component({
  selector: 'ensight-plan-import-modal',
  templateUrl: './plan-import-modal.component.html',
  styleUrls: ['./plan-import-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanImportModalComponent {
  constructor(public config: ModalConfig, public modal: ModalRef) {}
}
