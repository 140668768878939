// TODO: WTF!!!!! What are these file paths!!!

import { Action } from '@ngrx/store';
import * as global from './global/global.reducer';
import * as setup from './../components/presentation/setup/setup.reducer';
import * as presentation from '../components/presentation/presentation.reducer';
import * as coverLetterTemplates from './../components/presentation/modals/cover-sheet-modal/cover-sheet-modal.reducer';
import * as chart from '@shared/components/chart/chart.reducer';
import * as importIllustration from '../modules/import-illustration/redux/reducer';
import { State as SidebarState } from '@shared/components/sidebar/redux/reducer';
import { State as ModalsState } from '../components/modals/redux/reducer';
import { State as ImportIllustrationState } from '../modules/import-illustration/redux/reducer';
import { SetupState } from '@shared/models';
import { PlaceholderWizardState } from '../modules/custom-pages/models';

export interface AppState {
  global: global.GlobalState;
  setup: SetupState;
  coverLetterTemplates: coverLetterTemplates.CoverSheetModalState;
  presentation: presentation.State;
  chart: chart.ChartState;
  sidebar?: SidebarState;
  modals?: ModalsState;
  importIllustration: ImportIllustrationState;
  placeholdersWizard?: PlaceholderWizardState;
}

export interface ActionType extends Action {
  payload?: any;
  error?: any;
}

// TODO: change interface
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const reducers: any = {
  global: global.reducer,
  setup: setup.reducer,
  coverLetterTemplates: coverLetterTemplates.reducer,
  presentation: presentation.reducer,
  chart: chart.reducer,
  importIllustration: importIllustration.reducer,
};
