import { Injectable } from '@angular/core';

import { ModalRef, ModalService } from '@assurance/bootstrap';

import { ManageVersionComponent, PresentationListModalComponent } from '../../components';
import { CopyModalComponent, DependentPagesModalComponent, UsedInModalComponent } from '../../modals';
import { IFilterBlockDataPages, CustomPageVersions, CopyCustomPage } from '@shared/models';
import { UsedInData } from '../../models';
import { SelectVersionModalComponent } from 'src/app/components/presentation/modals/select-version-modal/select-version-modal.component';
import { SelectVersionModalData } from 'src/app/components/presentation/modals/select-version-modal/select-version-modal.model';

@Injectable()
export class ModalProviderService {
  constructor(private modal: ModalService) {}

  openManageVersionModal(data: CustomPageVersions): ModalRef {
    return this.modal.open(ManageVersionComponent, {
      data,
      width: '1110px',
      height: '592px',
      disableEscapeEvent: true,
    });
  }

  openChoosePresentationModal(data: number | string): ModalRef {
    return this.modal.open(PresentationListModalComponent, {
      data,
      width: '1000px',
    });
  }

  openDependentPagesModal(data: { dependentPages: IFilterBlockDataPages[] }): ModalRef {
    return this.modal.open(DependentPagesModalComponent, { data, width: '60%' });
  }

  openSelectedVersionModal(data: SelectVersionModalData): ModalRef {
    return this.modal.open(SelectVersionModalComponent, { data, width: '436px', maxHeight: 'min-content' });
  }

  openCopyModal(data: CopyCustomPage): ModalRef {
    return this.modal.open(CopyModalComponent, { data, width: '560px' });
  }

  openUsedInModal(data: UsedInData): ModalRef {
    return this.modal.open(UsedInModalComponent, { data, width: '759px' });
  }
}
