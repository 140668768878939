import { remove, cloneDeep, findIndex, isEmpty } from 'lodash-es';
import { Injectable } from '@angular/core';

import { Global } from '@shared/services';

import { SetupService } from './setup/setup.service';
import { AGE_YEAR_RECALCULATED_SHORTFALL_FIELDS } from './setup/retirement-shortfall/retirement-shortfall.constants';
import { ChartDataService } from '@core/service';
import { IAMService } from '@assurance/um-services';
import { GlobalConfig, PageConfig, ShortfallFields } from '@shared/models';
import { XAxisSourceType } from '@core/model';

@Injectable()
export class PresentationService {
  constructor(
    private iamService: IAMService,
    private global: Global,
    private setupService: SetupService,
    private chartDataService: ChartDataService
  ) {}

  /**
   * Returns tuple with cover sheet index and availability status if cover sheet is available or not.
   */
  private hasCoverSheet(coverLetterData: any, menuPagesList: PageConfig[]): [boolean, number] {
    const coverLetterIndex = findIndex(menuPagesList, ['config.uiId', 'cover']);
    const isSharedPresentation = this.global.isSharedPresentation();
    let hasCoverLetter = false;

    if (isSharedPresentation || this.iamService.hasGroupAccess('add_cover_letter')) {
      if (coverLetterIndex !== -1 && !isEmpty(coverLetterData)) {
        hasCoverLetter = true;
      }
    }

    return [hasCoverLetter, coverLetterIndex];
  }

  /**
   * Returns filtered array of configurations by permissions.
   */
  public configByPermissions(coverLetterData: any, configs: (GlobalConfig & PageConfig)[]): any[] {
    this.checkIfHasSelectedPlan(configs);
    this.setupService.filterNavbarStaticPages(configs);
    this.setCoverLetter(configs, coverLetterData);

    return configs;
  }

  /**
   * Set current letter to [this.menuPagesList] and returns if the coversheet is exists.
   */
  public setCoverLetter(menuPagesList: PageConfig[], coverLetterData: any) {
    const [hasCoverSheet, index] = this.hasCoverSheet(coverLetterData, menuPagesList);

    if (index !== -1 && hasCoverSheet) {
      this.moveArray(menuPagesList, index, 0);
    } else if (index !== -1 && Number.isInteger(index)) {
      menuPagesList.splice(index, 1);
    }

    return hasCoverSheet;
  }

  public moveArray(array: any[], from: number, to: number) {
    const [item] = array.splice(from, 1);

    if (item) {
      array.splice(to, 0, item);
    }
  }

  private checkIfHasSelectedPlan(pagesList: (GlobalConfig & PageConfig)[]) {
    remove(
      pagesList,
      (item: any) =>
        (item.config.uiId === 'retirement_shortfall' || item.config.uiId === 'charges') && !item.config.selectedPlanId
    );
  }

  recalculateShortfallPolicyValues(
    shortfallSettings: ShortfallFields | { [key: string]: string },
    graphValue: XAxisSourceType
  ): ShortfallFields | { [key: string]: string } {
    const settings = cloneDeep(shortfallSettings);
    const plans = this.global.getCurrentCarrierPlans;

    for (const key in settings) {
      if (
        settings.hasOwnProperty(key) &&
        AGE_YEAR_RECALCULATED_SHORTFALL_FIELDS.find(field => field === key) &&
        plans &&
        plans.length
      ) {
        const convertToType = typeof settings[key] === 'string' ? String : Number;
        const element = settings[key];
        settings[key] = convertToType(this.chartDataService.agePolicyYearConvert(element, graphValue, plans));
      }
    }

    return settings;
  }
}
