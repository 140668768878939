import { Pipe, PipeTransform } from '@angular/core';

import { INSERT_SECTIONS_LABELS } from '../constants';
import { Placeholder } from '@core/model';

@Pipe({
  name: 'sortInsertLabels',
})
export class SortInsertTypesPipe implements PipeTransform {
  transform(placeholders: Placeholder[], placeholder: Placeholder): string {
    const index = placeholders.indexOf(placeholder);
    let insertType;

    if (placeholders[index - 1]?.insertType !== placeholder.insertType) {
      insertType = placeholder.insertType;
    } else if (index === 0) {
      insertType = placeholder.insertType;
    }

    return INSERT_SECTIONS_LABELS.find(section => section.type === insertType)?.label;
  }
}
