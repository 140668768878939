import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationHeaderComponent } from './presentation-header.component';
import { PdfLoadingMessageModule } from '../pdf-loading-message/pdf-loading-message.module';

const components = [PresentationHeaderComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, PdfLoadingMessageModule],
  exports: components,
})
export class PresentationHeaderModule {}
