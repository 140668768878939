import { createReducer, on, Action } from '@ngrx/store';
import { mouseUpSuccess, mouseUpReset } from './chart.actions';

export interface ChartState {
  mouseUp: boolean;
}

export const initialState: ChartState = {
  mouseUp: false,
};

const chartState = createReducer(
  initialState,
  on(mouseUpSuccess, (state, { mouseUp }) => ({
    ...state,
    mouseUp,
  })),
  on(mouseUpReset, state => ({
    ...state,
    mouseUp: false,
  }))
);

export function reducer(state: ChartState | undefined, action: Action): ChartState {
  return chartState(state, action);
}
