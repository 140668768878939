import { Injectable } from '@angular/core';

import {
  ChangeHistoryEvent,
  CustomPageChanges,
  CustomPageHistory,
  MappedCustomPage,
  MappedHistoryCustomPage,
  ModifiedFieldInfo,
} from '../../models';
import { CUSTOM_PAGES_TYPES, PAGE_TYPES } from '@shared/constants';

@Injectable()
export class CustomPageHistoryAdapterService {
  adaptCustomPageForHistory(data: MappedCustomPage): MappedHistoryCustomPage {
    return {
      name: data.customPage.name,
      id: data.customPage._id,
      uiId: data.customPage.uiId,
      labels: data.customPage.labels,
    };
  }

  adaptHistory(data: CustomPageHistory, labels: string[]): CustomPageHistory {
    const modifiedRecords = data.data.map((item: CustomPageChanges) => {
      const numberOfChanges = this.getRecordNumberOfChanges(item);
      const title = this.getRecordTitle(item.eventName, labels);

      return {
        ...item,
        title,
        numberOfChanges,
      };
    });

    return {
      ...data,
      data: modifiedRecords,
    };
  }

  private getRecordTitle(eventName: ChangeHistoryEvent, labels: string[]): string {
    const type = this.getTypeOfPage(labels);

    return `Custom Page (${type}) ${eventName.toLowerCase()}`;
  }

  private getRecordNumberOfChanges(record: CustomPageChanges): string {
    let numberOfChanges = '';

    Object.values(record.listOfModifiedFields || {}).forEach((info: ModifiedFieldInfo) => {
      const separator = numberOfChanges.length ? ',' : '';

      numberOfChanges = `${numberOfChanges}${separator} ${info.entity}: ${info.affectedLines}`;
    });

    return numberOfChanges;
  }

  private getTypeOfPage(labels: string[]): string {
    if (labels.includes(CUSTOM_PAGES_TYPES.salesconcept)) {
      return PAGE_TYPES.salesconcept;
    } else if (labels.includes(CUSTOM_PAGES_TYPES.dependentpage)) {
      return PAGE_TYPES.dependentpage;
    } else if (labels.includes(CUSTOM_PAGES_TYPES.requiredpage)) {
      return PAGE_TYPES.requiredpage;
    }
  }
}
