import { Injectable } from '@angular/core';

@Injectable()
export class Utils {
  private readonly dependentPermissionsKeys = ['coverPages', 'coverPagesRequired', 'salesConcepts', 'requiredEndPages'];

  public getMatchesFunction(): string {
    let matchesFn: any;
    ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].forEach(fn => {
      if (!matchesFn && typeof (document.body as any)[fn] === 'function') {
        matchesFn = fn;

        return true;
      }

      return false;
    });

    return matchesFn;
  }

  public fireRefreshEventOnWindow() {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  }

  public moveArray(array: any[], from: number, to: number) {
    const [item] = array.splice(from, 1);

    if (item) {
      array.splice(to, 0, item);
    }
  }

  /**
   * Returns the index of the last element in the array where predicate is true, and -1
   * otherwise.
   * @param array The source array to search in
   * @param predicate find calls predicate once for each element of the array, in descending
   * order, until it finds one where predicate returns true. If such an element is found,
   * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
   */
  public findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
      if (predicate(array[l], l, array)) return l;
    }

    return -1;
  }

  public getValueByPath(obj: Record<string, unknown>, path: string): unknown {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return path.split('.').reduce((res, value) => res[value], obj);
  }

  public setValueByPath(obj: unknown, path: string[], value: unknown): unknown {
    let i,
      innerObj = obj;

    for (i = 0; i < path.length - 1; i++) {
      innerObj = innerObj[path[i]];
    }

    innerObj[path[i]] = value;

    return obj;
  }

  public readCookie(name: string): string {
    const foundCookie = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');

    return foundCookie ? foundCookie.pop() : '';
  }

  public deleteCookie(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  public extractDependentPermissions(groupPermissions: string[]): Record<string, string[] | null> {
    let groupPermission = [];

    const permissions = this.dependentPermissionsKeys.reduce((obj, key) => {
      const values = this.getValuesFromPermissions(key, groupPermissions);

      obj[key] = values.length ? values : null;

      return obj;
    }, {});

    groupPermission = groupPermissions.filter(permission => {
      const [id] = permission.split('.');

      return !this.dependentPermissionsKeys.includes(id) && !permission.includes('.');
    });

    return { ...permissions, groupPermission };
  }

  /**
   * getValuesFromPermissions returns values (ex. ["dasq34ds", "asd13c"]) form permissions (ex. ["salesConcepts.dasq34ds", "salesConcepts.asd13c"])
   */
  public getValuesFromPermissions<T extends string | number>(key: T, permissions: string[]): string[] {
    return permissions.reduce((response, permission) => {
      const [id, value] = permission.split('.');

      if (id === key && value) {
        response.push(value);
      }

      return response;
    }, []);
  }
}
