import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { PlanMetric } from '@shared/models';

@UntilDestroy()
@Component({
  selector: 'ep-metrics-list',
  templateUrl: './metrics-list.component.html',
  styleUrls: ['./metrics-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricsListComponent implements OnInit {
  @Input() metrics: PlanMetric[];
  @Input() metricsSettings: { [key: string]: { disabled: boolean } };

  @Output() output = new EventEmitter<{ selectedMetric?: PlanMetric }>();

  filteredMetrics: PlanMetric[];
  selectedMetric: PlanMetric;
  searchControl: FormControl;
  disabledMetricHint = 'A tab must contain values with the same number format (% or $)';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.filteredMetrics = this.metrics;
    this.searchControl = new FormControl('', [Validators.maxLength(100)]);
    this.watchForSearch();
  }

  onSelect(metric: PlanMetric, disabled: boolean): void {
    if (disabled) return;

    this.selectedMetric = metric;
  }

  addMetric(): void {
    if (!this.selectedMetric) return;

    this.output.emit({ selectedMetric: this.selectedMetric });
  }

  cancel(): void {
    this.output.emit();
  }

  private watchForSearch(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(value => {
        this.filteredMetrics = this.metrics.filter(metric => metric.title.toLowerCase().includes(value.toLowerCase()));
        this.cdr.markForCheck();
      });
  }
}
