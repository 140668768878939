export const TABLE_LABELS = {
  noEntitiesFound: {
    title: 'No items to display',
    message: 'Try to modify the search to see more results',
  },
};

export const DEFAULT_HEADERS_FORMAT = {
  direction: 'desc',
  sortable: true,
  sorted: false,
};

export const ILLUSTRATION_HEADER_CONSTANT = [
  'Order',
  'Carrier Name',
  'Product Name (Illustration)',
  'Notes (40 Characters Max)',
];
