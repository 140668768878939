import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, CanActivateChild, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map, tap, withLatestFrom } from 'rxjs/operators';

import { AuthService, UserService, IAMService } from '@assurance/um-services';

import { AppState } from '../reducers';
import { presentationUpdateSharedToken } from '../components/presentation/presentation.actions';
import { Global } from '@shared/services';
import { routeConf } from '@core/constant';
import { PresentationSetupPageComponent } from '../components/presentation/setup/setup.component';
import {
  loadOrganizationsSwitchByProfile,
  organizationsSwitch,
  setDependentPermissions,
  setProfileGroupPermissions,
  setProfileGroupRules,
} from '@ngrx-app/global.actions';
import { Utils } from '@shared/services';
import { getDependentPermissions, getProfileGroupPermissions, getProfileGroupRules } from '@ngrx-app/global.selectors';
import { LoginComponent } from '../components/login/login.component';
import { AUTH_KEYS, userPilotStorageKey } from '@shared/constants';
import { LocalStorageService } from '@core/service';

@Injectable()
export class NewAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private iamService: IAMService,
    private global: Global,
    private utils: Utils,
    private localStorage: LocalStorageService
  ) {}

  private validateAuth(): Observable<boolean> {
    return (this.authService.isLogged && of(this.authService.isLogged)) || this.authService.auth(AUTH_KEYS);
  }

  canLoad(): Observable<boolean> {
    return this.validateAuth();
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | UrlTree {
    if (route.queryParams.case_view_page_url) {
      sessionStorage.setItem('case_view_page_url', route.queryParams.case_view_page_url);
    }

    if (route.queryParams.inforceFilePath) {
      sessionStorage.setItem('inforce_file_path', route.queryParams.inforceFilePath);
    }

    if (!this.utils.readCookie('loggedIn')) {
      return route.component !== LoginComponent ? this.router.parseUrl('/login') : of(true);
    }

    // redirecting to view when presentation edit is not allowed (DAT-5413)
    if (this.shouldRedirectToPresentationView(route)) {
      return this.router.parseUrl(`/presentation/${route.parent.params.id}/view`);
    }

    const isShared = route.queryParamMap.get('shared-presentation');
    const sharedToken = route.paramMap.get('id') || route.parent.paramMap.get('id');

    if (isShared) {
      sessionStorage.setItem('shared-token', sharedToken);
      this.store.dispatch(presentationUpdateSharedToken({ sharedToken }));

      return of(true);
    }

    // @ts-ignore
    return <Observable<UrlTree>>this.validateAuth().pipe(
      tap(() => this.store.dispatch(organizationsSwitch({ userId: this.userService.user.id }))),
      tap(() => this.store.dispatch(loadOrganizationsSwitchByProfile())),
      withLatestFrom(
        this.store.select(getDependentPermissions),
        this.store.select(getProfileGroupPermissions),
        this.store.select(getProfileGroupRules)
      ),
      // TODO: wrong interface
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // map(([permissions, profileGroupPermissions, profileGroupRules]) => {
      map(([, permissions, profileGroupPermissions, profileGroupRules]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !permissions &&
          this.store.dispatch(
            setDependentPermissions({
              dependentPermissions: this.utils.extractDependentPermissions(this.userService.groupPermissions.slice()),
            })
          );
        !profileGroupPermissions &&
          this.store.dispatch(
            setProfileGroupPermissions({
              groupPermissions: this.userService.groupPermissions.slice(),
            })
          );
        !profileGroupRules &&
          this.store.dispatch(
            setProfileGroupRules({
              groupRules: this.userService.groupRules.slice(),
            })
          );

        return this.navigateState(route);
      }),
      catchError(err => {
        if (err?.status === 403) {
          this.localStorage.setData(userPilotStorageKey, false);

          return this.authService.logout();
        }

        return of(err);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | UrlTree {
    return this.canActivate(route);
  }

  private shouldRedirectToPresentationView(route: ActivatedRouteSnapshot): boolean {
    return !!(
      route.queryParams.case_view_page_url &&
      route?.data?.name === routeConf.setup.name &&
      route.component === PresentationSetupPageComponent &&
      !this.iamService.hasUserAccess(routeConf.setup.name) &&
      route.parent?.params?.id &&
      !this.global.getPresentation?.isDistributed
    );
  }

  private navigateState(route: ActivatedRouteSnapshot): boolean | UrlTree {
    this.setActiveComponent(route);

    return this.getActivateState(route) || this.router.parseUrl('/');
  }

  private getActivateState(route: ActivatedRouteSnapshot): boolean {
    if (route && route.data && route.data.name && !this.global.getPresentation?.isDistributed) {
      const name = route.data.name;

      return name === 'isAssuranceAdmin'
        ? this.userService.isAssuranceOrganization
        : this.iamService.hasUserAccess(name);
    } else {
      return true;
    }
  }

  // TODO: will be removed if not needed. (header is dependent on it)
  private setActiveComponent(route: ActivatedRouteSnapshot): void {
    if (route && route.data && route.data.activeElement) {
      this.global.setActiveComponent = route.data.activeElement;
    }
  }
}
