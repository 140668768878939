import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { SPREADHSEET_IGNORE_VALUE_LABEL } from '@shared/constants';
import { MetricType } from '@shared/constants/metric-type.enum';

@Pipe({
  name: 'spreadsheetValue',
})
export class SpreadsheetValuePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: string | number, type?: string): string | number {
    if (value === 'N/A' || value === SPREADHSEET_IGNORE_VALUE_LABEL || value === '') {
      return value;
    }

    switch (type) {
      case MetricType.percent:
        return `${value}%`;

      case MetricType.currency:
        return this.currencyPipe.transform(Number(value), 'USD', 'symbol', '1.0-0');

      case 'integer':
      case 'string':
      default:
        return value;
    }
  }
}
