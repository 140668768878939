import { Component } from '@angular/core';

import { ModalConfig, ModalRef } from '@assurance/bootstrap';

import { UsedInData } from '../../models';

@Component({
  selector: 'ep-used-in-modal',
  templateUrl: './used-in-modal.component.html',
  styleUrls: ['./used-in-modal.component.scss'],
})
export class UsedInModalComponent {
  data: UsedInData;

  constructor(public modal: ModalRef, public config: ModalConfig<UsedInData>) {
    this.data = this.config.data;
  }

  close(): void {
    this.modal.close(null);
  }
}
