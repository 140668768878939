import { Injectable } from '@angular/core';

import { UiTheme } from '@core/model';

@Injectable({
  providedIn: 'root',
})
export class StyleSchemaService {
  private defaultUiTheme: UiTheme;

  set setDefaultUiTheme(theme: UiTheme) {
    this.defaultUiTheme = theme;
  }

  get getDefaultUiTheme(): UiTheme {
    return this.defaultUiTheme;
  }

  setTitleOfDefaultTheme(title: string): void {
    this.defaultUiTheme.title = title;
  }
}
