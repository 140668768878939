import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@assurance/bootstrap';
import { FormsModule } from '@angular/forms';

import { CoverSheetTemplatesComponent } from './cover-sheet-templates.component';

const components = [CoverSheetTemplatesComponent];

@NgModule({
  imports: [CommonModule, IconModule, FormsModule],
  exports: [...components],
  declarations: [...components],
})
export class CoverSheetTemplatesModule {}
