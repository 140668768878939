<div class="search-container">
  <se-form-input
    [formControl]="searchControl"
    placeholder="Search by Case, Agent Name, Description, Created By"
  ></se-form-input>
  <span class="fa fa-search"></span>
</div>
<div *ngIf="!dataLoading" class="summary-table-container">
  <se-summary-table
    *ngIf="tableData[0]?.data.length"
    tableType="compact"
    emptyDataSymbol=""
    [classes]="['no-underline', 'divider']"
    [data]="tableData"
    [tableHeaders]="tableHeadersData"
    [rowHoverEffect]="true"
    (headerClick)="onHeaderClick($event)"
    (onRowClick)="onRowClick($event)"
  ></se-summary-table>
  <se-pager
    [options]="pagination"
    (onPageChange)="onPageChange($event)"
  ></se-pager>
</div>
<se-notification
  *ngIf="!dataLoading && !tableData[0]?.data.length"
  type="no-entities-found"
  [title]="tableLabels.noEntitiesFound.title"
  [message]="tableLabels.noEntitiesFound.message"
></se-notification>
<div *ngIf="dataLoading" class="table-list-loader">
  <se-circle-loader type="circle-scale"></se-circle-loader>
</div>
