import { Injectable } from '@angular/core';

@Injectable()
export class ThousandsSeparatorService {
  private options: Intl.NumberFormatOptions = {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  };

  getFormattedValue(value: number): string {
    const newValue = new Intl.NumberFormat('en-US', this.options).format(value);

    return newValue
      .split('.')
      .map(part => part.split(',').join(','))
      .join('.');
  }
}
