<div class="aapp-login-container">
  <div class="container">
    <div class="row">
      <h3 class="title col-12">Please enter your email address</h3>
      <div class="offset-sm-2 col-md-8 offset-md-3 col-lg-6">
        <um-forgot-password-form
          [resetUrl]="resetUrl"
          (submitForm)="submitForm($event)"
        ></um-forgot-password-form>
      </div>
    </div>
  </div>
</div>
