<section class="content-section" *ngIf="getPlaceholders$ | async as placeholders">
  <ng-container *ngIf="placeholders.length !== 0">
    <label class="label">Inserts</label>
    <div class="insert-sections">
      <div [dragula]="dragModelBag" [dragulaModel]="placeholders" class="insert-sections-container">
        <ng-container *ngFor="let placeholder of placeholders; let i = index;">
          <div class="section-label-container" *ngIf="placeholders | sortInsertLabels: placeholder">
            <label #sectionLabel class="insert-section-label">
              {{ placeholders | sortInsertLabels: placeholder }}
            </label>
            <div *ngIf="sectionLabel.innerText" class="label-underline"></div>
          </div>

          <div class="insert-section-item draggable-item" [attr.data-placeholder-id]="placeholder.id">
            <div class="d-flex justify-content-between flex-grow-1 flex-shrink-1">
              <div class="d-flex align-items-center">
                <div *ngIf="placeholder.errorMessage" class="warning-block">
                  <img src="/assets/icons/insert-warning-icon.svg" alt="Warning icon"/>
                </div>
                <div class="d-flex flex-row align-items-center placeholder-name pr-2 m-0">
                  <p class="placeholder-name pr-2 m-0">
                    {{ placeholder.chartName || placeholder.placeholderName || 'Products Description' }}
                  </p>
                </div>
                <div *ngIf="placeholder.insertType === 'customMetric' || placeholder.insertType === 'customTable'"
                     class="version-badge text-center"
                     [ngClass]="{'version-two':
                        placeholder.create ? (placeholder.reused ? placeholder.version === 2 : placeholder.isNewVersion) :
                        placeholder.edit ? placeholder.isNewVersion :
                        placeholder.version === 2
                    }">
                      <span>
                        v{{
                        placeholder.create ? (placeholder.reused ? (placeholder.version || 1) : (placeholder.isNewVersion ? 2 : 1)) :
                          placeholder.edit ? (placeholder.isNewVersion ? 2 : 1) :
                            (placeholder.version || 1)
                        }}
                      </span>
                </div>
              </div>

              <div class="key-for-copy d-none align-items-center justify-content-between {{ placeholder.insertType }} ml-3">
                <div class="placeholder-key">{{ placeholder.placeholderKey }}</div>
                <button
                  type="button"
                  (click)="copyToClipboard(placeholder.insertType, placeholder.placeholderKey, i)"
                  [popover]="(placeholder.insertType === 'customMetric' ? placeholder.placeholderKey  : keyForCopy[placeholder.insertType] + '.' + placeholder.placeholderKey) + ' copied!'"
                  [triggers]="null"
                  [outsideClick]="true"
                  [isOpen]="copiedIndex === i"
                  [containerClass]="'popover-copied'"
                  class="btn-copy fa fa-regular fa-copy border-0">
                </button>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div class="insert-btn-block d-flex">
                <div class="wrap-badge d-flex align-items-center mw-100 justify-content-end">
                  <span
                    *ngIf="placeholder.usedInPages?.length"
                    class="badge-content"
                    [ngClass]="{ 'no-clickable': !placeholder.usedInPages?.length }"
                    (click)="openUsedInSalesConceptsModal(placeholder)"
                  >
                    {{ placeholder.usedInPages?.length }}
                  </span>
                </div>
                <button class="btn edit-placeholder-btn" (click)="addPlaceholder(placeholder)">
                  Add
                </button>
                <button class="btn edit-placeholder-btn" (mouseup)="editPlaceholder(placeholder)">
                  Edit
                </button>
                <button class="btn delete-placeholder-btn" (click)="removePlaceholder(placeholder)">
                  Delete
                </button>
                <div class="order-btn-container">
                  <button
                    *ngIf="placeholder.order !== placeholders.length - 1"
                    [disabled]="placeholder.order === placeholders.length - 1"
                    class="order-btn-down fa fa-chevron-down h-100 w-100 rounded-circle"
                    (click)="handlePlaceholdersOrder( 'down', placeholders, placeholder.id)"
                  ></button>
                </div>
                <div class="order-btn-container">
                  <button
                    *ngIf="placeholder.order !== 0"
                    [disabled]="placeholder.order === 0"
                    class="order-btn-up fa fa-chevron-up h-100 w-100 rounded-circle"
                    (click)="handlePlaceholdersOrder('up', placeholders, placeholder.id)"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</section>
