import { RouteConf } from '@core/model';

export const routeConf: Record<string, RouteConf> = {
  agencies: {
    activeElement: 'agenciesRouter',
  },
  'agencies/:id/edit': {
    activeElement: 'agenciesRouter',
    name: 'AGENCY_EDIT',
  },
  'agencies/:id/info': {
    activeElement: 'agenciesRouter',
    name: 'AGENCIES_LIST',
  },
  'agencies/create': {
    activeElement: 'agenciesRouter',
    name: 'AGENCY_ADD',
  },
  'agencies/list': {
    name: 'AGENCIES_LIST',
  },
  agency: {
    activeElement: 'agenciesRouter',
  },
  'all-elements': {
    name: 'SET_PRSNT_BRANDING',
  },
  home: {
    activeElement: 'home',
  },
  login: {
    activeElement: 'login',
  },
  plans: {
    name: 'PRSNT_EDIT',
  },
  role: {
    activeElement: 'rolesRouter',
  },
  roles: {
    activeElement: 'rolesRouter',
  },
  'roles/:id/edit': {
    activeElement: 'rolesRouter',
    edit: true,
    name: 'ROLE_EDIT',
  },
  'roles/create': {
    activeElement: 'rolesRouter',
    name: 'ROLE_ADD',
  },
  'roles/list': {
    name: 'ROLES_LIST',
  },
  setup: {
    name: 'PRSNT_EDIT',
  },
  user: {
    activeElement: 'usersRouter',
  },
  users: {
    activeElement: 'usersRouter',
  },
  'users/:id/edit': {
    activeElement: 'usersRouter',
    name: 'USER_EDIT',
  },
  'users/:id/info': {
    activeElement: 'usersRouter',
  },
  'users/create': {
    activeElement: 'usersRouter',
    name: 'USER_ADD',
  },
  'users/list': {
    name: 'USERS_LIST',
  },
  //TODO: need to remove once PresentationView will be removed
  view: {
    name: 'PRSNT_MODE',
    view: true,
  },
  'xml-statuses': {
    name: 'isAssuranceAdmin',
  },
};
