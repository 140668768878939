import { Component, HostListener, OnInit } from '@angular/core';

import { ModalConfig, ModalRef } from '@assurance/bootstrap';

import { SalesConcept, SalesConceptModalData } from '../../shared/models';
import { NUMBER_REGEXP } from '@shared/constants';

@Component({
  selector: 'ep-sales-stories-inputs',
  templateUrl: './sales-stories-inputs.component.html',
  styleUrls: ['./sales-stories-inputs.component.scss'],
})
export class SalesStoriesInputsComponent implements OnInit {
  salesConcepts: SalesConcept[];
  inputPattern = new RegExp(NUMBER_REGEXP);
  isConfirmVisible = false;

  constructor(private modal: ModalRef, private config: ModalConfig<SalesConceptModalData>) {}

  ngOnInit(): void {
    this.salesConcepts = this.config.data.salesConcepts;
  }

  @HostListener('window:keyup.esc') onKeyUp(): void {
    this.isConfirmVisible ? this.cancel() : this.close();
  }

  close(): void {
    this.isConfirmVisible = true;
  }

  apply(): void {
    this.modal.close(true);
  }

  ok(): void {
    this.modal.close(false);
  }

  cancel(): void {
    this.isConfirmVisible = false;
  }
}
