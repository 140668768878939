<ac-modal-header (close)="modal.close(false)">
  <header class="title">
    {{ config.data?.title || 'Product Import' }}
  </header>
</ac-modal-header>

<ac-modal-body>
  <div class="content">
    <div class="plan-header">
      <span *ngIf="!config.data?.disabledJurisdictionStates">
        You are attempting to import the following products that have not been
        approved for usage by the carrier:
      </span>
      <span *ngIf="config.data?.disabledJurisdictionStates">
        You are attempting to import products that are under a restricted
        jurisdiction: {{ config.data?.disabledJurisdictionStates }}
      </span>
    </div>
    <div class="plans-list">
      <div *ngFor="let plan of config.data?.plans" class="plan-list-item">
        <div class="company-name">{{ plan.companyName }}</div>
        <div data-at-id="product-name">{{ plan.productName }}</div>
      </div>
    </div>
    <ng-template [ngIf]="config.data?.availablePlans">
      <div class="plan-header header-content">
        Do you want to continue importing the following products?
      </div>
      <div class="plans-list">
        <div
          *ngFor="let plan of config.data?.availablePlans"
          class="plan-list-item"
        >
          <div class="company-name">{{ plan.companyName }}</div>
          <div data-at-id="product-name">{{ plan.productName }}</div>
        </div>
      </div>
    </ng-template>
  </div>
</ac-modal-body>

<ac-modal-footer>
  <div class="buttons-block">
    <ac-button
      *ngIf="config.data?.activateSubmit"
      type="submit"
      (click)="modal.close(true)"
    >
      OK
    </ac-button>
    <ac-button class="secondary" (click)="modal.close(false)">Cancel</ac-button>
  </div>
</ac-modal-footer>
