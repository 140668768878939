export const RETIREMENT_SHORTFALL_SETTINGS_FIELDS = [
  'retirementAge',
  'lastYearOfRetirement',
  'currentAnnualIncome',
  'percentNeededInRetirement',
  'taxRate',
  'inflation',
  'ssStartAge',
  'ssAnnualAmount',
  'retirementAssetsCurrentBalance',
  'retirementAssetsGrowthRate',
  'retirementAssetsContributionOfIncome',
];
