import { Directive, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

import { toNumber, toString } from 'lodash-es';

@Directive({
  selector: '[ensightInputPrefixer]',
})
export class EnsightInputPrefixerDirective {
  constructor(@Optional() private control: NgControl) {}

  @Input() prefix: string;
  @Input() contentType = 'string';

  @HostListener('input', ['$event'])
  setPrefix(event: Event) {
    const to = this.contentType === 'number' ? toNumber : toString;
    const target = event.target as HTMLInputElement;

    if (!this.isValid(target)) {
      return;
    }

    const value = to(`${this.prefix}${target.value}`) || target.value;

    if (!this.control) {
      target.value = value as string;

      return;
    }

    this.control.control.setValue(value);
  }

  private isValid(target: HTMLInputElement) {
    const [prefix, ...args] = toString(target.value).split('');
    const hasPrefix = prefix === this.prefix;

    if (hasPrefix && !args.length) {
      target.value = null;

      return true;
    }

    return !hasPrefix;
  }
}
