import { Injectable } from '@angular/core';

import { Userpilot } from 'userpilot';
import { UserService } from '@assurance/um-services';

import { Global } from '@shared/services';
import { BrowserSessionStorage, LocalStorageService } from '@core/service';
import { userPilotStorageKey } from '@shared/constants';

@Injectable()
export class UserPilotService {
  constructor(
    private userService: UserService,
    private global: Global,
    private localStorage: LocalStorageService,
    private browserSessionStorage: BrowserSessionStorage
  ) {}

  track(data: Record<string, string>): void {
    if (!this.localStorage.getData(userPilotStorageKey)) {
      this.global.isSharedPresentation() ? this.identifySharedPage() : this.identify();
    }

    Userpilot.track(data.eventAction, { ...data, portal: 'EP' });
  }

  private identify(): void {
    Userpilot.identify(this.userService.user.id.toString(), {
      name: this.userService.user.username,
      email: this.userService.user.emails?.length ? this.userService.user.emails[0] : '',
      company: {
        id: this.userService.organization.id,
        name: this.userService.organization.name,
      },
      organizationId: this.userService.organization.id,
      organizationName: this.userService.organization.name,
      roleId: this.userService.user.role,
      // should be 'EP' or 'EUM' based on the portal user is on,
      portal: 'EP',
      // presentationId for EP, userId/groupId/roleId/organizationId/dataSourceId for EUM
      currentEntityId: this.global.getActivePresentationId || '',
    });

    this.localStorage.setData(userPilotStorageKey, true);
  }

  private identifySharedPage(): void {
    const token = this.browserSessionStorage.getItem('shared-token');

    Userpilot.identify(token, {
      name: 'Shared Page',
      email: '',
      portal: 'EP',
      currentEntityId: token,
    });

    this.localStorage.setData(userPilotStorageKey, true);
  }
}
