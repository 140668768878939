import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ModalModule, ButtonModule, LoaderModule } from '@assurance/bootstrap';
import { FormControlsModule } from '@se/common';

import { SetupService } from '../../setup/setup.service';
import { SalesConceptModalComponent } from './sales-concept-modal.component';
import { SalesConceptModalService } from './sales-concept-modal.service';
import { PlansService } from '../../presentation-plans/plans.service';
import { OpenSalesConceptModalService } from './open-sales-concept-modal.service';
import { MetricsService } from '@shared/services';
import { NoteInputModule } from '@shared/components';

@NgModule({
  declarations: [SalesConceptModalComponent],
  exports: [SalesConceptModalComponent],
  imports: [
    ModalModule,
    ButtonModule,
    ReactiveFormsModule,
    CommonModule,
    LoaderModule,
    FormControlsModule,
    NoteInputModule,
  ],
  providers: [SetupService, SalesConceptModalService, OpenSalesConceptModalService, PlansService, MetricsService],
})
export class SalesConceptModalModule {}
