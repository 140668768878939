import { createAction, props } from '@ngrx/store';
import { XAxisSourceEvent, PageConfig, GlobalConfig } from '@shared/models';
import { CareerPlan, Presentation } from '@core/model';

export const presentationRulesLoadSuccess = createAction('[Presentation] Rules Load Success', props<{ data: any }>());

export const presentationRulesLoadPending = createAction('[Presentation] Rules Load Pending');

export const presentationRulesLoadFailure = createAction('[Presentation] Rules Load Failure', props<{ error: any }>());

export const presentationProductsReset = createAction('[Presentation] Products Reset');

export const presentationStateReset = createAction('[Presentation] State Reset');

export const presentationRulesReset = createAction('[Presentation] Rules Reset');

export const presentationHeaderDisabled = createAction(
  '[Presentation] Header disabled state',
  props<{ disabled: boolean }>()
);

export const presentationXAxisSourceUpdateSuccess = createAction(
  '[Presentation] xAxisSource Update Success',
  props<{ xAxisSource: XAxisSourceEvent }>()
);

export const presentationXAxisSourceReset = createAction('[Presentation] xAxisSource Reset');

export const presentationPlansUpdateSuccess = createAction(
  '[Presentation] Plans Update Success',
  props<{ plans: CareerPlan[] }>()
);

export const presentationPlansReset = createAction('[Presentation] Plans Reset');

export const presentationDataLoadSuccess = createAction(
  '[Presentation] Data Load Success',
  props<{ data: Presentation }>()
);

export const presentationDataLoadPending = createAction('[Presentation] Data Load Pending');

export const presentationDataLoadFailure = createAction('[Presentation] Data Load Failure', props<{ error: any }>());

export const presentationUpdateSharedToken = createAction(
  '[Presentation] Update Shared Token',
  props<{ sharedToken: string }>()
);

export const presentationDataReset = createAction('[Presentation] Data Reset');

export const presentationConfigsSettingSuccess = createAction(
  '[Configs] Presentation Configs Setting Success',
  props<{ data: (GlobalConfig & PageConfig)[] }>()
);

export const presentationConfigsSettingPending = createAction('[Configs] Presentation Configs Setting Pending');

export const presentationConfigsSettingFailure = createAction(
  '[Configs] Presentation Configs Setting Failure',
  props<{ error: any }>()
);
