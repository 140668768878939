<div
  bsModal
  #confirmChildModal="bs-modal"
  (onHide)="cancel()"
  class="{{ windowClass }} modal fade aapp-modal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  *ngIf="modalInitComplete"
>
  <div class="modal-container modal-md a-app-confirm-info-modal-color">
    <div class="modal-content">
      <div class="modal-header aapp-modal-header">
        <h3 class="modal-title">Confirm</h3>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="cancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="text-center">{{ resolve.data.message }}</h5>
      </div>
      <div class="modal-footer">
        <button
          class="
            aapp-btn
            a-app-confirm-info-modal-cancel-button-color
            aapp-btn-default
            btn btn-secondary btn-lg
            text-uppercase
          "
          type="button"
          (click)="cancel()"
          [innerHTML]="resolve.data.cancelBtnTitle || 'cancel'"
        ></button>
        <button
          class="
            aapp-btn
            a-app-confirm-info-modal-confirm-button-color
            btn btn-primary btn-lg
            text-uppercase
          "
          type="button"
          (click)="ok()"
        >
          yes
        </button>
      </div>
    </div>
  </div>
</div>
