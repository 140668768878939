// we use these constants to save\remove this selected presentation and related data to local storage on the custom page
// the selected presentation and related data is used for displaying a compiled custom page
export const PresentationPreviewKey = 'presentation-preview-key';
export const PresentationConfigPreviewKey = 'presentation-config-preview-key';
export const CareerPlansPreviewKey = 'career-plans-preview-key';
export const ColorSchemaPreviewKey = 'color-scheme-preview-key';
export const PlanOrganizationPreviewKey = 'plan-organisation-preview-key';

export const PreviewKeys = [
  PresentationPreviewKey,
  PresentationConfigPreviewKey,
  CareerPlansPreviewKey,
  ColorSchemaPreviewKey,
  PlanOrganizationPreviewKey,
];
