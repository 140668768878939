import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportInfoComponent } from './support-info.component';
import { APIService } from '@shared/services';

@NgModule({
  declarations: [SupportInfoComponent],
  imports: [CommonModule],
  exports: [SupportInfoComponent],
  providers: [APIService],
})
export class SupportInfoModule {}
