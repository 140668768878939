import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, IconModule, ModalModule } from '@assurance/bootstrap';
import { FormControlsModule } from '@se/common';

import { SalesStoriesInputsComponent } from './sales-stories-inputs.component';

const components = [SalesStoriesInputsComponent];

@NgModule({
  declarations: components,
  imports: [CommonModule, ModalModule, ButtonModule, ReactiveFormsModule, FormControlsModule, IconModule],
  exports: components,
})
export class SalesStoriesInputsModule {}
