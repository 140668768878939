<div class="aapp-login-container">
  <div class="container">
    <div class="row justify-content-center">
      <h3 class="title col-12">Reset Password</h3>
      <div class="col-lg-8 col-sm-12">
        <um-reset-password-form
          [verificationKey]="verificationKey"
          (submitForm)="onResetSuccess($event)"
        ></um-reset-password-form>
      </div>
    </div>
  </div>
</div>
