import { Component, Input, OnChanges } from '@angular/core';

import { cloneDeep, find } from 'lodash-es';

import { Client } from './client-section.model';
import { ClientService } from './client-section.service';
import { CareerPlan } from '@core/model';

@Component({
  selector: 'ensight-client-section',
  styleUrls: ['client-section.scss'],
  templateUrl: 'client-section.html',
})
export class ClientSectionComponent implements OnChanges {
  @Input() firstPlan: any;
  @Input() plans: CareerPlan[];

  @Input() selectedId: number;

  public clients: Client[] = [];
  public selectedPlan: CareerPlan;

  constructor(private clientService: ClientService) {}

  ngOnChanges(): void {
    this.setSelectedPlan();
    this.clients = this.selectedId
      ? this.clientService.getClientsData(cloneDeep(this.selectedPlan))
      : this.clientService.getClientsData(cloneDeep(this.firstPlan));
  }

  private setSelectedPlan(): void {
    this.selectedPlan = find(this.plans, {
      id: this.selectedId,
    });
  }
}
