<div class="content-container" (mouseup)="stopPin.emit()">
  <ng-container *ngFor="let item of data" [class.total]="item.isTotal">
    <div class="content" *ngIf="!item.hideMetric" [style.font-size]="chartOptions?.chart?.chartFontSizeToolTipLabel">
      <span class="title">
        {{ item.title$ | async }}
      </span>
      <span *ngIf="item.isPercentYAxis" class="value" [style.color]="item.color" [style.font-size]="chartOptions?.chart?.chartFontSizeToolTipNumber">
        {{ item.y | planPersentage: 2:item.noValueLabel:item.isCustomMetric}}
      </span>
        <span *ngIf="!item.isPercentYAxis" class="value" [style.color]="item.color" [style.font-size]="chartOptions?.chart?.chartFontSizeToolTipNumber">
        {{ item.y | planCurrency: 'USD':0:item.noValueLabel }}
      </span>
    </div>
  </ng-container>
</div>
