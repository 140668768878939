import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DragulaModule, DragulaService } from 'ng2-dragula';

import { FormControlsModule } from '@se/common';

import { SpreadsheetSettingsModalComponent } from './settings-modal/spreadsheetsettings-modal.component';
import { TogglesModule } from '@shared/components';

@NgModule({
  declarations: [SpreadsheetSettingsModalComponent],
  imports: [
    CommonModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule,
    TabsModule,
    DragulaModule,
    TogglesModule,
    FormControlsModule,
  ],
  exports: [SpreadsheetSettingsModalComponent],
  providers: [DragulaService],
})
export class SpreadsheetSettingsModule {}
