import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found.component';
import { ButtonsModule } from '@se/common';

const components = [PageNotFoundComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, ButtonsModule],
})
export class PageNotFoundModule {}
