import { Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { APIService, Global, NavbarPagesAdapterService } from '@shared/services';
import {
  AdditionalNavBarPages,
  CompileParams,
  CustomPageValues,
  NavbarCustomPage,
  NavbarCustomPagesData,
  PageConfig,
} from '@shared/models';
import {
  getSalesConceptDependentPagesLoadingSuccess,
  setNavbarCustomPages,
} from '../../../../components/presentation/setup/setup.actions';
import { AppState } from '../../../../reducers';
import { CustomPageService } from '../../../../components/presentation/setup/custom-page/custom-page.service';

@Injectable()
export class NavbarPagesService {
  constructor(
    private apiService: APIService,
    private store: Store<AppState>,
    private global: Global,
    private navbarPagesAdapter: NavbarPagesAdapterService,
    private customPageService: CustomPageService
  ) {}

  getNavbarCustomPages(
    configs: PageConfig[],
    dependentPagesConfig,
    endPages: string[],
    salesConceptsIds?: string[]
  ): Observable<[NavbarCustomPagesData, CustomPageValues[]]> {
    return forkJoin([
      this.getNavbarConceptsAndDependentPages(configs, dependentPagesConfig, salesConceptsIds),
      this.getNavbarEndPages(endPages),
    ]).pipe(
      tap((data: [NavbarCustomPagesData, CustomPageValues[]]) => {
        const [salesConceptsAndDependentPages, endPages] = data;

        this.store.dispatch(getSalesConceptDependentPagesLoadingSuccess());
        this.store.dispatch(setNavbarCustomPages({ payload: { ...salesConceptsAndDependentPages, endPages } }));
      })
    );
  }

  getNavbarConceptsAndDependentPages(
    configs: PageConfig[],
    dependentPagesConfig,
    salesConceptsUiIds: string[],
    isPdf?: boolean
  ): Observable<NavbarCustomPagesData> {
    const data = {
      customPagesByUiId: this.navbarPagesAdapter.getSalesConceptsInfo(salesConceptsUiIds, dependentPagesConfig),
    };

    const dataWithInternalId = this.navbarPagesAdapter.getSalesConceptsInfo(
      salesConceptsUiIds,
      dependentPagesConfig,
      true
    );

    const source = data.customPagesByUiId.length
      ? this.apiService.getNavbarSalesConceptsAndDependentPages(
          data,
          this.global.isSharedPresentation(),
          this.global.getPresentation.id
        )
      : of([]);

    return source.pipe(
      map((data: NavbarCustomPage[]) => this.navbarPagesAdapter.mapInternalIdToPage(data, configs, dataWithInternalId)),
      switchMap((data: NavbarCustomPage[]) => this.getCompiledDependentPages(data, isPdf)),
      map((data: AdditionalNavBarPages) => this.navbarPagesAdapter.separatePages(data)),
      tap((data: NavbarCustomPagesData) => this.store.dispatch(setNavbarCustomPages({ payload: data })))
    );
  }

  private getNavbarEndPages(endPages: string[]): Observable<Partial<Omit<CustomPageValues, 'dependentPages'>>[]> {
    const endPagesByCarrierCode = this.global.getCurrentCarrierPlans
      .map(el => el.configjson.metadata.carrier_code)
      .filter(val => val);

    const params = { customPagesByUiId: endPages.map(uiId => ({ uiId })), endPagesByCarrierCode };

    return this.apiService
      .getNavbarEndPages(params, this.global.isSharedPresentation(), this.global.getPresentation.id)
      .pipe(map((data: NavbarCustomPage[]) => this.navbarPagesAdapter.adaptNavbarEndPages(data)));
  }

  private getCompiledDependentPages(pages: NavbarCustomPage[], isPdf?: boolean): Observable<AdditionalNavBarPages> {
    const params = isPdf ? [] : this.navbarPagesAdapter.getSalesConceptsIds(pages);
    const source = params.map((page: CompileParams) => this.customPageService.getSalesConcepts([page], false, false));

    const dependentPagesOfConceptWithParentUiId = pages.map((page: NavbarCustomPage) => {
      return {
        ...page,
        dependentPages:
          page?.dependentPages?.map((item: NavbarCustomPage) => {
            const found = page.customFields.dependentPages.find(dp => dp.uiId === item.uiId);

            return {
              ...item,
              customFields: {
                ...item.customFields,
                order: found?.order,
              },
              parentUiId: page.config.uiId,
            };
          }) ?? [],
      };
    });

    return params.length
      ? forkJoin(source).pipe(
          map(data => data.map(item => item.response[0].response[0])),
          map((data: CustomPageValues[]) => {
            return {
              fullData: dependentPagesOfConceptWithParentUiId,
              pages: this.navbarPagesAdapter.getFilteredDependentPages(pages, data),
            };
          })
        )
      : of({ fullData: dependentPagesOfConceptWithParentUiId, pages: dependentPagesOfConceptWithParentUiId });
  }
}
