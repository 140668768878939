import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { dropTicket } from '@core/constant';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DropTicketModalService {
  constructor(private http: HttpClient) {}

  public sendDropTicket(params: any): Observable<any> {
    return this.http.post(dropTicket, params).pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    return throwError(`${error.status} - ${error.statusText}`);
  }
}
