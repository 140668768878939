import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseType } from '@core/model';
import * as apiConstant from '@core/constant';
import { CustomPageChanges, CustomPageHistory } from '../../models';
import { ExportedCustomPage } from '@shared/models';

@Injectable()
export class CustomPageHistoryApiService {
  constructor(private http: HttpClient) {}

  getCustomPageHistory(customPageUUID: string, params): Observable<CustomPageHistory> {
    return this.http
      .get<ResponseType<CustomPageHistory>>(`${apiConstant.customPageHistory}/${customPageUUID}`, {
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(map((response: ResponseType<CustomPageHistory>) => response.data));
  }

  getCustomPageHistoryRecord(customPageUUID: string, recordId: string): Observable<CustomPageChanges> {
    return this.http
      .get<ResponseType<CustomPageChanges>>(`${apiConstant.customPageHistory}/${customPageUUID}/${recordId}`)
      .pipe(map((response: ResponseType<CustomPageChanges>) => response.data));
  }

  revertCustomPageHistoryRecord(recordId: string, pageNameId: string): Observable<void> {
    return this.http.post<void>(`${apiConstant.customPageHistory}/${recordId}/${pageNameId}/revert`, {});
  }

  exportRevisionCustomPage(customPageUUID: string, record: string): Observable<ExportedCustomPage> {
    return this.http
      .get<ResponseType<ExportedCustomPage>>(`${apiConstant.customPageHistory}/${customPageUUID}/${record}/revision`)
      .pipe(map((response: ResponseType<ExportedCustomPage>) => response.data));
  }
}
