import { SortDirections, TableBodyColumn, TableHeaderColumn } from '@shared/models';
import { TimeFormat } from '@core/constant';

const TableHeaderColumns: TableHeaderColumn[] = [
  {
    key: 'icon',
    sortable: false,
    title: '',
    include: ['dependentpage', 'requiredpage'],
  },
  {
    sortable: false,
    key: 'expend',
    title: '',
    include: ['salesconcept'],
  },
  {
    sortable: true,
    key: 'name',
    title: 'Page Name',
    include: ['salesconcept', 'dependentpage', 'requiredpage'],
  },
  {
    sortable: true,
    key: 'label',
    title: 'Page Label',
    include: ['salesconcept', 'dependentpage', 'requiredpage'],
  },
  {
    sortable: true,
    key: 'salesConceptLabel',
    title: 'Sales Story Label',
    include: ['salesconcept'],
  },
  {
    sortable: false,
    key: 'usedInGroups',
    title: 'Used in Groups',
    include: ['salesconcept', 'requiredpage'],
  },
  {
    sortable: false,
    key: 'createdBy',
    title: 'Created By',
    include: ['salesconcept', 'dependentpage', 'requiredpage'],
  },
  {
    sortable: true,
    key: 'createdAt',
    title: 'Created',
    include: ['salesconcept', 'dependentpage', 'requiredpage'],
  },
  {
    sortable: true,
    key: 'updatedAt',
    title: 'Updated',
    include: ['salesconcept', 'dependentpage', 'requiredpage'],
  },
  {
    sortable: false,
    key: 'menu',
    title: '',
    include: ['salesconcept', 'dependentpage', 'requiredpage'],
  },
];

const TableBodyColumns: TableBodyColumn[] = [
  {
    key: 'icon',
    isTruncateText: false,
    defaultValue: '',
    include: ['dependentpage', 'requiredpage'],
  },
  {
    key: 'pageIndex',
    isTruncateText: false,
    include: ['dependentTable'],
  },
  {
    key: 'expend',
    isTruncateText: false,
    defaultValue: '',
    include: ['salesconcept'],
  },
  {
    key: 'name',
    isTruncateText: false,
    defaultValue: '-',
    include: ['salesconcept', 'dependentpage', 'requiredpage', 'dependentTable'],
  },
  {
    key: 'label',
    isTruncateText: false,
    defaultValue: '-',
    include: ['salesconcept', 'dependentpage', 'requiredpage', 'dependentTable'],
  },
  {
    key: 'salesConceptLabel',
    isTruncateText: false,
    defaultValue: '-',
    include: ['salesconcept', 'dependentTable'],
  },
  {
    key: 'usedInGroups',
    isTruncateText: false,
    defaultValue: '-',
    include: ['salesconcept', 'requiredpage', 'dependentTable'],
  },
  {
    key: 'createdBy',
    isTruncateText: false,
    defaultValue: '-',
    include: ['salesconcept', 'dependentpage', 'requiredpage', 'dependentTable'],
  },
  {
    key: 'createdAt',
    isTruncateText: false,
    defaultValue: '',
    formatDate: TimeFormat.Mdyyyyhmma,
    include: ['salesconcept', 'dependentpage', 'requiredpage', 'dependentTable'],
  },
  {
    key: 'updatedAt',
    isTruncateText: false,
    defaultValue: '',
    formatDate: TimeFormat.Mdyyyyhmma,
    include: ['salesconcept', 'dependentpage', 'requiredpage', 'dependentTable'],
  },
  {
    key: 'menu',
    isTruncateText: false,
    defaultValue: '',
    include: ['salesconcept', 'dependentpage', 'requiredpage', 'dependentTable'],
  },
];

export const TableExpendedDetailsColumns: TableBodyColumn[] = [
  {
    key: 'expendedDetails',
    colSpan: 0,
    include: ['salesconcept'],
  },
];

export const CustomPageTableColumnStyle = {
  icon: {
    width: '5px',
    paddingLeft: '8px',
    paddingRight: '0',
    textAlign: 'center',
    header: {},
    body: { color: '#00A7F7' },
  },
  pageIndex: {
    minWidth: '20px',
    width: '20px',
    paddingLeft: '8px',
    paddingRight: '5px',
    textAlign: 'center',
    header: {},
    body: {},
  },
  expend: {
    minWidth: '20px',
    width: '20px',
    paddingLeft: '8px',
    paddingRight: '0',
    textAlign: 'center',
    header: {},
    body: {},
  },
  name: { minWidth: '160px', width: '250px', header: {}, body: { color: '#00A7F7' } },
  label: { minWidth: '160px', width: '250px', header: {}, body: {} },
  salesConceptLabel: { minWidth: '160px', width: '250px', header: {}, body: {} },
  usedInGroups: { minWidth: '50px', width: '70px', header: {}, body: {} },
  createdBy: { minWidth: '160px', width: '250px', header: {}, body: {} },
  updatedAt: { minWidth: '140px', width: '200px', textAlign: 'right', paddingRight: '20px', header: {}, body: {} },
  createdAt: { minWidth: '140px', width: '200px', textAlign: 'right', paddingRight: '20px', header: {}, body: {} },
  menu: { width: '10px', textAlign: 'center', padding: 0, margin: 0, header: {}, body: {} },
};

export const DefaultCustomPagePaginationRequestParams = {
  PAGE: 1,
};

const getListHeaderColumns = (): TableHeaderColumn[] => {
  return TableHeaderColumns.map((column: TableHeaderColumn) => {
    const style = { ...CustomPageTableColumnStyle[column.key] };
    const onlyHeader: Record<string, string> = { ...(style.header as Record<string, string>) };

    delete style.header;
    delete style.body;

    return {
      ...column,
      style: {
        ...style,
        ...onlyHeader,
      },
    };
  });
};

const geListBodyColumns = (): TableBodyColumn[] => {
  return TableBodyColumns.map((column: TableBodyColumn) => {
    const style = { ...CustomPageTableColumnStyle[column.key] };
    const onlyBody: Record<string, string> = { ...(style.body as Record<string, string>) };

    delete style.header;
    delete style.body;

    return {
      ...column,
      style: {
        ...style,
        ...onlyBody,
      },
    };
  });
};

export const CustomPageTableHeaderColumns: TableHeaderColumn[] = getListHeaderColumns();
export const CustomPageTableBodyColumns: TableBodyColumn[] = geListBodyColumns();
export const CustomPageListSearchValueKey = 'custom-page-list-search-value';
export const CustomPageListLabelValueKey = 'custom-page-list-label-value';

export const DefaultOrderByRequestParams = {
  ORDER_BY: 'updatedAt',
  DIRECTION: SortDirections.DESC,
};
