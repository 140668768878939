import { Injectable } from '@angular/core';

type PrefillFields = Record<string, { value: string; readOnly?: boolean }>;
type ZenDeskWebWidget = (type: 'webWidget', action: string, args?: PrefillFields | unknown) => void;

@Injectable({
  providedIn: 'root',
})
export class ZenDeskWidgetService {
  get zE(): ZenDeskWebWidget {
    return (window as any).zE;
  }

  prefill(fields: PrefillFields): void {
    this.zE('webWidget', 'prefill', fields);
  }

  action(action: string): void {
    this.zE('webWidget', action);
  }
}
