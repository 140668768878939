import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ImageInfo } from '@shared/models';

@Component({
  selector: 'ep-images-preview',
  templateUrl: './images-preview.component.html',
  styleUrls: ['./images-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagesPreviewComponent {
  @Input() imagesList: ImageInfo[];
  @Input() hideDelete = false;
  @Output() selectImage = new EventEmitter();
  @Output() deleteImage = new EventEmitter();
  @Output() loadedImage = new EventEmitter();

  onToggleImage(event: ImageInfo): void {
    this.selectImage.emit(event);
  }

  onDeleteImage(event: ImageInfo): void {
    this.deleteImage.emit(event);
  }

  onImageLoad(event: ImageInfo): void {
    if (!event.loading) return;

    this.loadedImage.emit(event);
  }
}
