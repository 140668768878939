import { Injectable, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { debounceTime, first, switchMap, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';

import { ChartVisualization } from '@core/class/ChartVisualization.class';

import { SetupService } from '../../../components/presentation/setup/setup.service';

import { AppState } from '../../../reducers';
import { ChartOptionsService, ChartDataService, PdfExportService } from '@core/service';
import { SelectedPlanOptions, BasePageType, SelectedPlansValues } from '@shared/models';
import { getPresentationData, getPresentationPlans } from '../../../components/presentation/presentation.selectors';
import { GAService, Global } from '@shared/services';
import { ChartAriaToggleData, ChartData, ChartOptions, ChartType, Metric, PinValues } from '@core/model';
import { CUSTOM_CHART_OPTIONS } from '@shared/components/chart/chart.constants';
import { ChartTypes } from '@core/enums';
import { getPresentationPageConfig } from 'src/app/components/presentation/redux/configs/selectors';

@Injectable()
export class MetricBaseVisualization extends ChartVisualization implements OnInit, OnDestroy, BasePageType {
  @ViewChild('container') container: ElementRef;

  protected chartType: ChartType;
  protected customChartOptions = CUSTOM_CHART_OPTIONS;
  protected useArea = false;
  selectedId: number;
  relatedMetrics: Metric[];
  chartData: ChartData[];
  chartOptions: ChartOptions;
  selectedPlansOptions: SelectedPlanOptions[] = [];
  chartAriaToggleData: ChartAriaToggleData[];
  selectedPlansOptionsSub: Subscription;
  private listenersAttached = false;
  private pinMoveSubject = new Subject<PinValues[]>();

  constructor(
    public global: Global,
    public router: Router,
    public setupService: SetupService,
    public presentationViewService: PdfExportService,
    public store: Store<AppState>,
    public chartOptionsService: ChartOptionsService,
    public chartDataService: ChartDataService,
    public cdRef: ChangeDetectorRef,
    public gaService: GAService
  ) {
    super(
      global,
      router,
      setupService,
      presentationViewService,
      store,
      chartOptionsService,
      chartDataService,
      cdRef,
      gaService
    );
  }

  // TODO: need to change!!!!
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  public ngOnInit() {
    // this.setupService.setSelectedPageId(this.configKey);
    combineLatest([
      this.setupService.getAgencyRules(),
      this.store.select(getPresentationData),
      this.store.select(getPresentationPlans),
      this.initConfigs(),
      this.setColorScheme(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(args => this.initData(args));

    if (!this.listenersAttached) {
      this.attachVisualizationListeners();
      this.listenersAttached = true;
    }

    this.setupPinMoveDebounce();
  }

  onPinMove(data: PinValues[]) {
    this.pinMoveSubject.next(data);
  }

  protected initConfigs() {
    return super.getConfigs().pipe(
      switchMap(config => {
        this.config = config;

        return this.getGraphValue$.pipe(first());
      })
    );
  }

  public initChart(relatedMetrics?: Metric[]): void {
    this.store
      .select(getPresentationPageConfig(this.configKey, this.pdfConfig))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pageConfig => {
        this.updateChartWithConfig(pageConfig, relatedMetrics);

        if (this.selectedPlansOptionsSub) {
          this.selectedPlansOptionsSub.unsubscribe();
        }

        this.selectedPlansOptionsSub = this.getSelectedPlansOptions().subscribe(selectedPlansOptions => {
          this.selectedPlansOptions = selectedPlansOptions;
          this.cdRef.markForCheck();
        });
      });
  }

  protected updateChartWithConfig(pageConfig: any, relatedMetrics?: Metric[]): void {
    const graphValue = this.setupService.getXAxisSource(pageConfig, this.graphValue);
    const options = {
      xAxisSource: graphValue,
      yKey: this.selectedMetric?.yAxisTitle || this.global.getTitleByType(this.selectedMetric?.title),
      isPercentYAxis: this.selectedMetric?.isPercentYAxis,
      pageID: this.configKey,
      pinValue:
        this.setupService.getConfigTipValue(pageConfig) ??
        this.setupService.getDefaultPinValue(pageConfig, this.activePlans, graphValue),
      type: this.chartType,
      isPdf: this.PDFPrint,
    };
    const customOptions = this.PDFPrint ? this.setupService.generateChartConfigForPDF() : this.customChartOptions;
    this.chartOptions = this.setChartOptions(options, customOptions);

    if (this.activePlans.length) {
      const { data, xMinApplied, xMaxApplied, yMinApplied, yMaxApplied } = this.setChartData({
        plans: this.activePlans,
        selectedMetrics: relatedMetrics || this.relatedMetrics,
        keyX: graphValue,
        xMin: pageConfig.config.chartConfig.xMin || this.setupService.getMinAgeValue(pageConfig),
        xMax:
          pageConfig.config.chartConfig.xMax ||
          this.setupService.getMaxAgeValue(pageConfig, this.agencyRules.maxAge, this.pageID, graphValue),
        yMin: this.config[0]?.config?.bottomIrr,
        yMax: this.config[0]?.config?.topIrr,
        area: this.useArea,
        type: ChartTypes.comboChart,
        metricBase: true,
      });
      this.xMinApplied = xMinApplied;
      this.xMaxApplied = xMaxApplied;
      this.yMinApplied = yMinApplied;
      this.yMaxApplied = yMaxApplied;
      this.chartData = data;
    }
  }

  protected initData([rules, presentation, plans, graphValue, colors]): void {
    this.colorScheme = colors;
    this.graphValue = graphValue;
    this.agencyRules = rules;
    this.presentation = presentation;
    this.setDisclosures();
    this.useArea = this.getChartArea();

    if (this.config[1]) {
      this.setPlans(plans);
      this.initLogic();
      this.global.pageLoading = false;
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (this.visualizationReadyEvent) {
      this.visualizationReadyEvent.unsubscribe();
    }

    if (this.selectedPlansOptionsSub) this.selectedPlansOptionsSub.unsubscribe();
  }

  public initPage(): void {
    this.setSelectedMetric();
    this.setActivePlans();
    this.initChart();
    this.setMinMaxConfigs();
  }

  public handleLimitation(): void {
    this.hideChart = true;
    this.initPage();
    this.hideChart = false;
  }

  public handlePinValues(pinValue: PinValues[]): SelectedPlansValues[] {
    return pinValue.map(pinValue => {
      const selectedMetricType = this.relatedMetrics.find(item => item.key === pinValue.metricId);

      return {
        visualizationUiId: this.configKey,
        value:
          this.yMinApplied || this.yMaxApplied
            ? this.setupService.getSelectedPlanValue(
                pinValue.x,
                this.setupService.getXAxisSource(this.config[1], this.graphValue),
                selectedMetricType.key,
                this.activePlans[0]
              )
            : !_.isNil(pinValue.y) && pinValue.y.toString(),
        planId: pinValue.metricId,
        additionalLabel: selectedMetricType && selectedMetricType.title ? selectedMetricType.title : '',
        show: !pinValue.disable,
        color: pinValue.color,
        isShownInfo: selectedMetricType && selectedMetricType.isShownInfo,
      };
    });
  }

  protected mergeRelatedMetricsConfigs(relatedMetrics: Metric[]): Metric[] {
    const activePlans = this.config[1]?.config.activePlans;

    return relatedMetrics.map((metric: Metric) => {
      const plan = _.find(activePlans, {
        metricId: metric.configMetricKey || metric.key,
      });

      return {
        ...metric,
        disable: !plan?.isShown,
        isShownInfo: plan?.isShownInfo,
      };
    });
  }

  public onChartIsReady(data: PinValues[] | boolean) {
    if (this.PDFPrint) {
      setTimeout(this.setSelectedPlans.bind(this, data));
    }

    this.handleMouseUpEvent();
  }

  protected setData(relatedMetrics?: Metric[]): void {
    if (!this.activePlans.length) return;

    this.store
      .select(getPresentationPageConfig(this.configKey, this.pdfConfig))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(config => {
        const { data, xMinApplied, xMaxApplied, yMinApplied, yMaxApplied } = this.setChartData({
          plans: this.activePlans,
          selectedMetrics: relatedMetrics || this.relatedMetrics,
          keyX: this.setupService.getXAxisSource(this.config[1], this.graphValue),
          xMin: config.config.chartConfig.xMin || this.setupService.getMinAgeValue(this.config[1]),
          xMax:
            config.config.chartConfig.xMax ||
            this.setupService.getMaxAgeValue(this.config[1], this.agencyRules.maxAge, this.pageID, this.graphValue),
          yMin: _.get(this.config[0], 'config.bottomIrr'),
          yMax: _.get(this.config[0], 'config.topIrr'),
          area: this.useArea,
          type: ChartTypes.comboChart,
          metricBase: true,
        });
        this.xMinApplied = xMinApplied;
        this.xMaxApplied = xMaxApplied;
        this.yMinApplied = yMinApplied;
        this.yMaxApplied = yMaxApplied;
        this.chartData = data;
      });
  }

  public emitReadyEvent(show: boolean): void {
    if (!this.visualizationReadyEvent.closed) {
      this.visualizationReadyEvent.emit({ id: this.configKey, show });
      this.visualizationReadyEvent.complete();
    }
  }

  public onSelect(metric: Metric): void {
    this.hideChart = true;
    this.selectedMetric = metric;
    this.setupService.setSelectedMetricConfig(metric.key, this.config);
    this.setRelatedMetrics();
    this.initChart();
    this.hideChart = false;
  }

  public setChartAriaToggleData(): void {
    this.chartAriaToggleData = [
      {
        checkedClass: 'checked-item',
        key: 'Lines',
        label: 'Lines',
        startState: () => !this.getChartArea(),
        uncheckedClass: 'unchecked-item',
      },
      {
        checkedClass: 'checked-item',
        key: 'Solid',
        label: 'Solid background',
        startState: () => this.getChartArea(),
        uncheckedClass: 'unchecked-item',
      },
    ];
  }

  public getChartArea(): boolean {
    return _.get(this.config, '[1].config.chartConfig.area');
  }

  protected getSelectedPlansOptions(): Observable<SelectedPlanOptions[]> {
    throw new Error('Method not implemented.');
  }

  protected setRelatedMetrics() {
    throw new Error('Method not implemented.');
  }

  protected setActivePlans() {
    throw new Error('Method not implemented.');
  }

  protected setSelectedMetric() {
    throw new Error('Method not implemented.');
  }

  protected initLogic() {
    throw new Error('Method not implemented.');
  }

  protected setPlans(plans) {
    if (plans) {
      throw new Error('Method not implemented.');
    }
  }

  protected setSelectedPlans(pinValue: PinValues[] | boolean): void {
    if (pinValue) {
      throw new Error('Method not implemented.');
    }
  }

  private setupPinMoveDebounce() {
    this.pinMoveSubject.pipe(debounceTime(250), takeUntil(this.unsubscribe$)).subscribe((data: PinValues[]) => {
      this.setSelectedPlans(data);
      this.setupService.setInteractiveGuidelineConfig(data[0].x, this.config, this.pageID);
    });
  }
}
