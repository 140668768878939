export const SAVE_LOCKED_MSG = 'This presentation is locked. To save changes, unlock it in "Presentation Settings".';

export const CHANGE_LOCKED_MSG =
  'To make changes to this presentation, unlock it under the "Presentation Settings" menu.';

export const DEFAULT_DISCLOSURE_TEXT =
  'Information regarding the products described herein is believed to be accurate at the time of ' +
  'publication but is not guaranteed. Clients should verify the accuracy of guaranteed and non-guaranteed ' +
  'illustration values by reviewing a current compliant illustration with their insurance agent prior to making an ' +
  'insurance policy decision. Ensight and its subsidiaries do not provide tax, legal advice or policy recommendations.' +
  ' Clients are encouraged to consult with their legal and tax professionals prior to entering into insurance transactions.' +
  ' All product names, brands and logos are the property of their respective owners.';

export const getLockedByDistributionMsg = (createdByName: string) =>
  `This template was provided to you by ${createdByName}. To change this template please contact Customer Success at the email or phone number at the top of the home screen`;

export const getLockedPresentationHeader = (isDistribution?: boolean) =>
  isDistribution ? 'Template Locked' : 'Presentation Locked';

export const MENU_PAGES_IDS = {
  targetPremium: 100,
  coverLetter: 99,
  spreadsheet: 11,
  charges: 14,
  custom_visualization: 13,
  retirementShortfall: 7,
  lifeProducts: 15,
};

export const PAGE_TYPE_FIELD_BY_PERMISSION = new Map([
  ['sales_concepts', 'isSalesConcept'],
  ['single_policy', 'isSinglePolicy'],
]);

export const GRAPH_VALUES = {
  eoy_age: 'age',
  policy_year: 'year',
} as any;

export const EXCLUDED_CONFIGS = ['spreadsheet', 'cover', 'retirement_shortfall'];

export const METRICS_TO_HIDE = ['guaranteed_policy_value', 'non_guaranteed_policy_value'];

export const PRODUCT_TABLE_UIID = 'life_products';

export const PAGES_WITHOUT_PRODUCT_TABLE = ['spreadsheet', 'retirement_shortfall', 'charges', 'cover'];

export const PRODUCT_TYPE_DISCLOSURE_RULE_MAP = {
  other: 'lifeDisclosure',
  4: 'VULDisclosure',
  10: 'viaDisclosure',
  57: 'viaDisclosure',
  9: 'fiaDisclosure',
  58: 'fiaDisclosure',
};

export const DISCLOSURE_FIELD_OTHER = 'other';

export const GA_V2 = 'V2';

export const GA_NOT_SET = '(not set)';
