<p>{{ labels?.tabs }}</p>

<se-button-group
  *ngIf="isDynamicMetrics"
  [ngClass]="{ 'btn-group-infinity': isPieDonutBarChart }"
  [buttons]="configurationButtons"
  size="medium"
  (onSelectButton)="onSelectConfiguration($event)"
></se-button-group>

<ng-container *ngIf="questionsData$ | async as questionsData">
  <ng-container *ngFor="let qs of questionsData; let i = index">
    <se-dynamic-form
      *ngIf="i === activeConfigurationKey"
      [questionsData]="qs"
      [formId]="i"
      [params]="{}"
    ></se-dynamic-form>
  </ng-container>
</ng-container>

<label *ngIf="labels?.hint" data-at-id="hint-label">
  {{ labels.hint }}
</label>

<div class="buttons-wrap" *ngIf="isPieDonutBarChart && isDynamicMetrics">
  <se-button class="secondary" (click)="addNewMetric()">Add Metric</se-button>
  <se-button class="delete" [disabled]="configurationButtons.length === 1" (click)="deleteMetric()">Delete Metric</se-button>
</div>
