import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToDate',
})
export class ConvertToDatePipe implements PipeTransform {
  constructor() {}

  transform(value: string, addDay?: boolean): Date {
    const date = new Date(value);

    return addDay ? new Date(date.setDate(date.getDate() + 1)) : date;
  }
}
