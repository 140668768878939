import { Inject, Injectable } from '@angular/core';

import * as _ from 'lodash-es';
import { Store } from '@ngrx/store';

import { UserService } from '@assurance/um-services';
import { AlertService } from '@se/common';

import { Global } from '@shared/services/index';
import { StyleBlock, StyleItem, UiTheme } from '@core/model';
import { LogoTypes, NEW_BRANDING_ITEMS_DEPENDENCY } from '../components/style-editor/style-editor.constants';
import { AppState } from '../../../reducers';
import { colorSchemeLoadSuccess } from '../../../components/presentation/setup/setup.actions';
import { DOCUMENT_TOKEN } from '@core/constant';
import { StyleSchemaService } from '@core/service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StyleEditorService {
  private collapsedState = new BehaviorSubject<boolean>(this.checkClassApplied());
  collapsedState$ = this.collapsedState.asObservable();

  constructor(
    private global: Global,
    private userService: UserService,
    private alertService: AlertService,
    private store: Store<AppState>,
    private styleSchemaService: StyleSchemaService,
    @Inject(DOCUMENT_TOKEN) private document: Document
  ) {}

  private checkClassApplied(): boolean {
    return d3.select('.common-container').classed('collapsed-style-editor');
  }

  collapsedView(isCollapsed: boolean): void {
    d3.selectAll('.common-container').classed('collapsed-style-editor', !isCollapsed);
    this.collapsedState.next(!isCollapsed);
  }

  highlightElements(className: string): void {
    const elements = d3.selectAll(`.${className}`);

    if (elements[0].length) {
      this.cleanHighlighters();
      elements[0].map((element: any) => {
        const position = this.getElementPosition(element);
        this.appendHighlighter(position);
      });
    } else {
      this.showAlert('neutral', 'This elements are on another page.');
    }
  }

  restoreStyleItem(item: StyleItem, uiTheme: UiTheme): void {
    if (item) {
      const defaultItem = this.findItemByClassName(item.className, uiTheme.sections);

      item.value = defaultItem.value;
      item.changed = false;
    }
  }

  getItemByClassName(theme: UiTheme, item: StyleItem): StyleItem | undefined {
    let target: StyleItem;
    const isCommonSection: boolean = this.isCommonSection(theme.sections, item.className);
    const isValid: boolean = isCommonSection
      ? _.keys(NEW_BRANDING_ITEMS_DEPENDENCY).includes(item.className)
      : _.values(NEW_BRANDING_ITEMS_DEPENDENCY).includes(item.title);

    if (isValid) {
      target = isCommonSection
        ? this.findItemByTitle(NEW_BRANDING_ITEMS_DEPENDENCY[item.className], theme.sections)
        : this.findItemByClassName(
            _.findLastKey(NEW_BRANDING_ITEMS_DEPENDENCY, i => i.indexOf(item.title) !== -1),
            theme.sections
          );
    }

    return target;
  }

  getTheme(theme: UiTheme): UiTheme {
    const clone = _.cloneDeep(theme);
    delete clone.id;
    delete clone.title;
    delete clone.date;
    delete clone.updateDate;
    delete clone.userId;
    delete clone.agencyId;

    return clone;
  }

  appendStylesInHtml(theme: UiTheme = this.styleSchemaService.getDefaultUiTheme): void {
    this.store.dispatch(
      colorSchemeLoadSuccess({
        payload: this.getChartColorScheme(theme.sections),
      })
    );
    let css = '';
    const id = 'a-app-current-style';
    const style = this.document.createElement('style');

    if (theme) {
      theme.sections.forEach(section => {
        section.items.forEach(item => {
          item.keys.forEach(key => {
            if (item.valueType === 'color') {
              css += `.${item.className}{ ${key}: ${item.value} !important;}`;
            }

            if (item.valueType === 'image') {
              css += `.${item.className}{ ${key}: url(${item.value}) !important;}`;
            }

            if (item.valueType === 'text') {
              (this.global as any)[item.keys[0]] = item.value;
            }
          });
        });
      });
    }

    this.removeStyledFromHtml(id);
    style.id = id;
    style.innerHTML = css;
    this.document.body.appendChild(style);
  }

  getBase64(file: any): Promise<any> {
    return new Promise(resolve => {
      const reader = new FileReader();
      let binaryString: any;
      reader.onloadend = () => {
        binaryString = reader.result;
        resolve(binaryString);
      };
      reader.readAsDataURL(file);
    });
  }

  isFileTypeValid(file: File): boolean {
    const types: string[] = _.values(LogoTypes);

    return file && types.indexOf(file.type) !== -1;
  }

  prepareReqData(param: { uiTheme: UiTheme }): UiTheme {
    const theme: UiTheme = param.uiTheme;
    theme.agencyId = this.userService.organization.id;
    theme.logo = this.getThemeLogo(theme);
    delete theme.default;

    return theme;
  }

  mergeStyles(target: UiTheme, defaultStyle: UiTheme): void {
    const sections: StyleBlock[] = [];

    defaultStyle.sections.forEach((section: StyleBlock) => {
      const newSection: StyleBlock = {
        items: [],
        sectionKey: section.sectionKey,
        title: section.title,
      };

      section.items.forEach((item: StyleItem) => {
        const targetItem = this.findItemByClassName(item.className, target.sections);
        newSection.items.push({
          className: item.className,
          keys: item.keys,
          title: item.title,
          value: targetItem ? targetItem.value : item.value,
          valueType: item.valueType,
        });
      });

      sections.push(newSection);
    });

    target.sections = sections;
  }

  hasSectionKeys(theme: UiTheme): boolean {
    return theme.sections.every(section => !!section.sectionKey);
  }

  populateMainThemes(results: UiTheme[]): void {
    if (!results) {
      return;
    }

    const style = results[0] || _.cloneDeep(this.styleSchemaService.getDefaultUiTheme);

    this.global.setIsDefaultTheme = style ? style.default : true;
    this.global.currentTheme = style;
    this.appendStylesInHtml(this.global.currentTheme);
  }

  showAlert(type: 'neutral' | 'error', body: string): void {
    this.alertService.openAlert({
      type,
      body,
      autoClose: 5000,
    });
  }

  private isCommonSection(sections: StyleBlock[], className: string): boolean {
    let sectionKey: string;

    sections.forEach(section => {
      section.items.forEach(item => {
        if (item.className === className) {
          sectionKey = section.sectionKey;
        }
      });
    });

    return sectionKey === 'common_elements_color_scheme';
  }

  private findItemByClassName(className: string, sections: StyleBlock[]): StyleItem {
    let targetItem: StyleItem;

    sections.forEach(section => {
      section.items.forEach(item => {
        if (item.className === className) {
          targetItem = item;
        }
      });
    });

    return targetItem;
  }

  private findItemByTitle(title: string, sections: StyleBlock[]): StyleItem {
    let searchedItem: StyleItem;
    sections.forEach(section => {
      section.items.forEach(item => {
        if (
          section.sectionKey !== 'common_elements_color_scheme' &&
          item.title === title &&
          (title !== 'Background color' || section.sectionKey === 'company_header')
        ) {
          searchedItem = item;
        }
      });
    });

    return searchedItem;
  }

  private getChartColorScheme(themeSections: StyleBlock[]) {
    const section: StyleBlock = _.find(themeSections, {
      sectionKey: 'chart_color_scheme',
    });
    const lines: StyleItem[] = _.filter(section.items, (item: StyleItem) => item.valueType === 'line');

    return lines.map((line: StyleItem) => ({ color: line.value }));
  }

  private getElementPosition(element: any): Record<string, number> {
    let el = element;
    let xPos = 0;
    let yPos = 0;

    while (el) {
      if (el.tagName === 'BODY') {
        const xScroll = el.scrollLeft || this.document.documentElement.scrollLeft;
        const yScroll = el.scrollTop || this.document.documentElement.scrollTop;

        xPos += el.offsetLeft - xScroll + el.clientLeft;
        yPos += el.offsetTop - yScroll + el.clientTop;
      } else {
        xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPos += el.offsetTop - el.scrollTop + el.clientTop;
      }

      el = el.offsetParent;
    }

    return {
      height: element.clientHeight || element.parentNode.clientHeight,
      width: element.clientWidth || element.parentNode.clientWidth,
      x: xPos,
      y: yPos,
    };
  }

  private cleanHighlighters(): void {
    d3.selectAll('.highliter').remove();
  }

  private appendHighlighter(position: Record<string, number>): void {
    d3.select('body')
      .append('div')
      .classed('highliter', true)
      .style('width', `${position.width}px`)
      .style('height', `${position.height}px`)
      .style('top', `${position.y}px`)
      .style('left', `${position.x}px`)
      .call(el => {
        setTimeout(() => {
          el.remove();
        }, 3000);
      });
  }

  private removeStyledFromHtml(id: string): void {
    const elem = this.document.getElementById(id);

    if (elem) {
      this.document.body.removeChild(elem);
    }
  }

  private getThemeLogo(theme: UiTheme): string {
    const header: StyleBlock = _.find(theme.sections, { title: 'Company header' });

    return _.get(_.find(header.items, { title: 'Logo image' }), 'value');
  }
}
