import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { withLatestFrom, switchMap, catchError, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';

import { AppState } from '../../../reducers';
import * as PlaceholdersWizardActions from './placeholders-wizard.actions';
import { getPersistImagesList, placeholdersMetadata } from './placeholders-wizard.selectors';
import { IMAGE_STATE } from '../constants';
import { APIService, ImageHandlerService } from '@shared/services';
import { CustomHttpParams } from '@core/model';

@Injectable()
export class PlaceholdersWizardEffects {
  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaceholdersWizardActions.setInsertTypeStates),
      withLatestFrom(this.store.pipe(select(placeholdersMetadata)), this.store.pipe(select(getPersistImagesList))),
      switchMap(([{ id, activePageId }, placeholders, persistImagesList]) => {
        if (activePageId !== IMAGE_STATE.id) return EMPTY;

        const placeholder = placeholders.find(placeholder => placeholder.id === id);

        if (!placeholder) {
          return of(
            PlaceholdersWizardActions.setImagesList({
              imagesData: persistImagesList[id],
            })
          );
        }

        const selectedImage = placeholder.selectedImage;

        const imagesData = this.imageHandlerService.getImageObjects(placeholder, selectedImage);

        return of(
          PlaceholdersWizardActions.setImagesList({
            imagesData: persistImagesList[id] ?? imagesData,
          })
        );
      }),
      catchError(err => {
        console.error(err);

        return of(err);
      })
    )
  );

  loadChooseCustomPagesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaceholdersWizardActions.loadChooseCustomPagesList),
      switchMap((queryParams: { queryParams: CustomHttpParams }) => {
        return this.apiService.getPagesList(queryParams.queryParams);
      }),
      switchMap(customPages => {
        const userIds: number[] = customPages.data.data.map(res => res.userId);

        return this.apiService.getUsersFullName({ ids: userIds }).pipe(
          map(({ data }) => {
            customPages.data.data.forEach((dataRow: any) => {
              const response = data.data.find(item => item.userId === dataRow.userId);
              dataRow.createdBy = response && response.fullName;
            });

            return customPages;
          })
        );
      }),
      map(customPages =>
        PlaceholdersWizardActions.loadChooseCustomPagesListSuccess({
          customPagesList: customPages.data.data,
          customPagesCount: customPages.data.count,
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiService: APIService,
    private imageHandlerService: ImageHandlerService
  ) {}
}
