import { createAction, props } from '@ngrx/store';

import { PresentationSettingsActiveTab } from './enums';

export const setPresentationSettingsOpen = createAction(
  '[Modals - Presentation Settings] Open',
  props<{ open: boolean }>()
);

export const setPresentationSettingsActiveTab = createAction(
  '[Modals - Presentation Settings] Active Tab',
  props<{ activeTab: PresentationSettingsActiveTab }>()
);
