import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PdfLoadingMessageComponent } from './pdf-loading-message.component';
import { SharedSocketService } from '@shared/services';

@NgModule({
  declarations: [PdfLoadingMessageComponent],
  exports: [PdfLoadingMessageComponent],
  imports: [CommonModule],
  providers: [SharedSocketService],
})
export class PdfLoadingMessageModule {}
