<div *ngIf="imagesList?.length" class="image-container">
  <div *ngFor="let image of imagesList" class="image-item">
    <se-circle-loader
      *ngIf="image.loading"
      type="circle-scale"
    ></se-circle-loader>
    <img
      [hidden]="image.loading"
      (load)="onImageLoad(image)"
      [src]="image.url | safeResource: 'url'"
    />
    <div [hidden]="image.loading" class="controls">
      <se-radio-button
        name="imageSelector"
        [checked]="image.selected"
        (onSelect)="onToggleImage(image)"
      ></se-radio-button>
      <div
        *ngIf="!hideDelete"
        (click)="onDeleteImage(image)"
        class="ensight-icon-trash"
      ></div>
    </div>
  </div>
</div>
