import { createSelector } from '@ngrx/store';
import { AppState } from '../../reducers';
import { GlobalState } from './global.reducer';

export const getGlobalReducer = (state: AppState): GlobalState => state.global;

export const getCoverLetter = createSelector(getGlobalReducer, (state: GlobalState) => state.coverLetter.data);

export const getDependentPermissions = createSelector(
  getGlobalReducer,
  (state: GlobalState) => state.dependentPermissions
);

export const getCoverLetterData = createSelector(getGlobalReducer, (state: GlobalState) => state.coverLetter.data);

export const isCoverLandscapeOrientation = createSelector(
  getGlobalReducer,
  (state: GlobalState) => state.coverLetterOrientation.landscape
);

export const getPdfGeneration = createSelector(getGlobalReducer, (state: GlobalState) => state.pdfGeneration);

export const getCoverLetterLoading = createSelector(
  getGlobalReducer,
  (state: GlobalState) => state.coverLetter.loading
);

export const getLoading = createSelector(getGlobalReducer, (state: GlobalState) => state.loading);

export const getCoverLetterLoaded = createSelector(getGlobalReducer, (state: GlobalState) => {
  return state.coverLetter.loaded;
});

export const getProfile = createSelector(getGlobalReducer, (state: GlobalState) => state.profile);

export const getProfileGroupPermissions = createSelector(getProfile, state => state.groupPermissions);

export const getProfileGroupRules = createSelector(getProfile, state => state.groupRules);

export const getOrganizationsSwitch = createSelector(getGlobalReducer, state => state.organizationsSwitch);

export const getOrganizationsSwitchData = createSelector(getOrganizationsSwitch, state => state.data);

export const getOrganizationsSwitchDataPending = createSelector(getOrganizationsSwitch, state => state.pending);

export const getOrganizationsSwitchByProfile = createSelector(getOrganizationsSwitch, state => state.getByProfile);
