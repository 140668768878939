import {
  CUSTOM_TABLE_COLUMNS_STATE,
  API_CALL_RESPONSE_STATE,
  CHART_METRICS_STATE,
  TAB_AREA_STATE,
} from './placeholders-wizard.constants';

export const FIELDS_BY_STATE_ID = {
  [CUSTOM_TABLE_COLUMNS_STATE.id]: {
    allTabsField: 'columns',
    innerFields: ['header', 'body', 'hideFormula'],
    tabsNumberField: 'columnsNumber',
  },
  [TAB_AREA_STATE.id]: {
    allTabsField: 'tabs',
    innerFields: ['HTMLForTabs'],
    tabsNumberField: 'tabsNumber',
  },
  [API_CALL_RESPONSE_STATE.id]: {
    allTabsField: 'results',
    innerFields: ['responsePath', 'destination'],
    tabsNumberField: 'apiResultsNumber',
  },
  [CHART_METRICS_STATE.id]: {
    allTabsField: 'metrics',
    innerFields: [
      'metricKey',
      'metricKeyLabels',
      'metricKeyData',
      'metricTitle',
      'chartType',
      'stepLineType',
      'color',
      'background',
      'interpolate',
      'productIndex',
      'dashedMetric',
      'dashLength',
      'dashInterval',
      'stackedMetric',
      'stackedGroup',
      'metricTitleForPieOrDonutChart',
    ],
    optionalDatalistFields: ['chartType', 'stepLineType', 'interpolate', 'background'],
    tabsNumber: 5,
  },
};

export const LABELS_BY_STATE_ID = {
  [CUSTOM_TABLE_COLUMNS_STATE.id]: {
    tabs: 'Columns:',
  },
  [API_CALL_RESPONSE_STATE.id]: {
    tabs: 'Parsing Response',
  },
  [TAB_AREA_STATE.id]: {
    tabs: 'HTML for Tabs:',
  },
};
