import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { TablePaginationOptions, TableRequestParams } from '@shared/models';

export abstract class TableRequestParamsService {
  protected defaultPaginationParams: TableRequestParams = null;
  protected defaultSearchParams: TableRequestParams = null;
  protected defaultOrderByParams: TableRequestParams = null;

  protected paginationRequestParams: BehaviorSubject<TableRequestParams>;
  protected searchRequestParams: BehaviorSubject<TableRequestParams>;
  protected orderByRequestParams: BehaviorSubject<TableRequestParams>;

  protected constructor(pagination: TableRequestParams, search: TableRequestParams, orderBy: TableRequestParams) {
    this.defaultPaginationParams = pagination;
    this.defaultSearchParams = search;
    this.defaultOrderByParams = orderBy;

    this.paginationRequestParams = new BehaviorSubject<TableRequestParams>(this.defaultPaginationParams);
    this.searchRequestParams = new BehaviorSubject<TableRequestParams>(this.defaultSearchParams);
    this.orderByRequestParams = new BehaviorSubject<TableRequestParams>(this.defaultOrderByParams);
  }

  setTableRequestParams(params: TablePaginationOptions): void {
    this.paginationRequestParams.next({ ...this.paginationRequestParams.getValue(), PAGE: params.currentPage });
  }

  setSearchRequestParams(value: string): void {
    this.paginationRequestParams.next({ ...this.paginationRequestParams.getValue(), PAGE: 1 });
    this.searchRequestParams.next(value ? { SEARCH_VALUE: value } : {});
  }

  setOderByRequestParams(params: TableRequestParams): void {
    this.orderByRequestParams.next(params);
  }

  getPaginationParams(): TableRequestParams {
    return this.paginationRequestParams.getValue();
  }

  getOrderByParams(): TableRequestParams {
    return this.orderByRequestParams.getValue();
  }

  getSearchParams(): TableRequestParams {
    return this.searchRequestParams.getValue();
  }

  resetRequestParams(): void {
    this.paginationRequestParams.next(this.defaultPaginationParams);
    this.searchRequestParams.next(null);
    this.orderByRequestParams.next(this.defaultOrderByParams);
  }

  watchForRequestParams(): Observable<TableRequestParams> {
    return combineLatest([
      this.paginationRequestParams.asObservable(),
      this.orderByRequestParams.asObservable(),
      this.searchRequestParams.asObservable(),
    ]).pipe(
      debounceTime(500),
      map(([tableParams, orderBy, searchData]: TableRequestParams[]) => ({
        ...tableParams,
        ...orderBy,
        ...searchData,
      }))
    );
  }
}
