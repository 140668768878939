<div class="modal-body-wrapper" [ngSwitch]="placeholder?.insertType">
  <ng-container *ngSwitchCase="insertTypeIds.text">
    <div class="control-container">
      <se-textarea
        [formControl]="valueControl"
        name="value"
        [height]="70"
        resize="none"
      ></se-textarea>
      <span
        class="error-message"
        *ngFor="let message of getErrorMessages(valueControl.errors)"
      >
        {{ message }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="insertTypeIds.variable">
    <div class="control-container">
      <se-form-input
        [inputPattern]="inputPattern"
        [formControl]="valueControl"
      ></se-form-input>
      <span
        class="error-message"
        *ngFor="let message of getErrorMessages(valueControl.errors)"
      >
        {{ message }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="insertTypeIds.image">
    <ep-images-preview
      [imagesList]="imagesList"
      [hideDelete]="true"
      (selectImage)="onSelectImage($event)"
      (loadedImage)="onLoadedImage($event)"
    ></ep-images-preview>
  </ng-container>
</div>

<div class="btn-block">
  <ac-button class="secondary" (click)="cancel()">Cancel</ac-button>
  <ac-button
    [disabled]="
      !valueControl?.valid && placeholder?.insertType !== insertTypeIds.image
    "
    [loading]="loading"
    (click)="update()"
  >
    Update
  </ac-button>
</div>
