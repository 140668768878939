<table *ngIf="isReady">
  <tr class="table-header-row" [ngStyle]="rowHeaderStyle">
    <ng-container *ngFor="let headerColumn of tableHeaderColumn;">
      <th
        *ngIf="headerColumn.sortable"
        epSort
        [sortable]="headerColumn.key"
        [currentSortable]="orderByParams.ORDER_BY"
        [currentDirection]="orderByParams.DIRECTION"
        (sortBy)="sort($event)"
        [ngStyle]="headerColumn.style"
        class="cell header-cell sortable {{ headerColumn.key }}">
        <div class="wrap-sort">
          <span class="title">{{ headerColumn.title }}</span>
          <span class="sort-icon"></span>
        </div>
      </th>

      <th [ngStyle]="headerColumn.style" *ngIf="!headerColumn.sortable" class="cell header-cell {{ headerColumn.key }}">
        <div class="wrap-sort">
          <span class="title">{{ headerColumn.title }}</span>
        </div>
      </th>
    </ng-container>
  </tr>
  <ng-container *ngFor="let record of data; let i = index; trackBy: trackBy">
    <tr class="table-body-row" (click)="rowBodyClick(i, record)" [ngClass]="{'row-clicked': record['isExpended']}" [ngStyle]="rowBodyStyle">
      <ng-container *ngFor="let column of tableColumn">
        <td class="cell body-cell" [ngStyle]="column.style">
          <ng-container *ngTemplateOutlet="customColumns[column.key] ? customColumns[column.key] : rowColumn; context: { value: record[column.key], column: column, index: i, record: record }"></ng-container>
        </td>
      </ng-container>
    </tr>
    <!-- TODO: need to improve -->
    <tr class="table-body-row detail-row" *ngIf="record['isExpended']" [ngStyle]="rowBodyStyle">
      <ng-container *ngFor="let column of expendedDetailColumns">
        <ng-container *ngIf="customColumns[column.key]">
          <ng-container *ngTemplateOutlet="customColumns[column.key]; context: { value: record[column.key], column: column, index: i, record: record }"></ng-container>
        </ng-container>
      </ng-container>
    </tr>
  </ng-container>
</table>

<ng-template #rowColumn let-value="value" let-column="column">
  <div class="wrap-content" *ngIf="column.isTruncateText">
    <span class="truncate-text">
      {{ value | default: column.defaultValue || '' }}
    </span>
  </div>

  <ng-container *ngIf="!column.isTruncateText && !column.isBadge">
    {{ column.formatDate ? (value | date: column.formatDate) :  (value | default: column.defaultValue || '') }}
  </ng-container>
</ng-template>
