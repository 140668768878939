import { createAction, props } from '@ngrx/store';
import { XAxisSourceType } from '@core/model';

export const updateSelectedPlanId = createAction(
  '[Configs - Visualization] Update Selected Plan Static Id',
  props<{ uiId: string; selectedPlanId: number; disableSave?: boolean }>()
);

export const updateChartConfigXMax = createAction(
  '[Configs - Visualization] Update Chart Config xMax',
  props<{ uiId: string; xMax: number; disableSave?: boolean }>()
);

export const updateChartConfigXMin = createAction(
  '[Configs - Visualization] Update Chart Config xMin',
  props<{ uiId: string; xMin: number; disableSave?: boolean }>()
);

export const updateSelectedMetricKey = createAction(
  '[Configs - Visualization] Update Selected Metric Key',
  props<{ uiId: string; selectedMetricKey: string; disableSave?: boolean }>()
);

export const updateChartConfigXAxisSource = createAction(
  '[Configs - Visualization] Update Chart Config xAxisSource',
  props<{
    uiId?: string;
    xAxisSource: XAxisSourceType;
    disableSave?: boolean;
  }>()
);
