import { Injectable } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

import { MappedCustomPage, MappedEditTextCustomPage } from '../../models';
import { CustomPageVersion } from '@shared/models';

@Injectable()
export class CustomPageEditTextAdapterService {
  constructor(private sanitizer: DomSanitizer) {}

  adaptCustomPageForEditText(data: MappedCustomPage): MappedEditTextCustomPage {
    const { customPage, customPageVersions } = data;
    const version = customPageVersions.versions.find((version: CustomPageVersion) => version.pageId === customPage._id);
    const modifiedHtmlBody = this.wrapCompileStringIntoSpan(customPage.customFields.htmlBody, /{{(.*?)}}/);

    return {
      customPageId: customPage._id,
      htmlBody: this.sanitizer.bypassSecurityTrustHtml(modifiedHtmlBody),
      customPageName: customPage?.name,
      versionName: version?.versionName,
    };
  }

  removeSpanFromHtml(htmlBody: string): string {
    const regExp = new RegExp('<span class="compile-text-placeholder">(.*?)</span>', 'gi');

    // @ts-ignore
    return htmlBody.replace(regExp, (s: string, content: string) => content);
  }

  private wrapCompileStringIntoSpan(htmlBody: string, match: RegExp): string {
    const regExp = new RegExp(match, 'gi');
    const div = document.createElement('div');

    div.innerHTML = htmlBody;

    // @ts-ignore
    return div.outerHTML.replace(/([^<>]+)(?=<[^>]+>)/g, (s: string, content: string) => {
      return content.replace(regExp, (str: string) => {
        return '<span class="compile-text-placeholder">' + str + '</span>';
      });
    });
  }
}
