export enum FormFields {
  title = 'title',
  text = 'text',
  signature = 'signature',
  footer = 'footer',
  body = 'body',
  textBody = 'textBody',
}

export const enum ActionTypes {
  CoverLetterTemplatesLoadSuccess = '[Cover sheet modal] Cover Letter Templates Load Success',
  CoverLetterTemplatesLoadFailure = '[Cover sheet modal] Cover Letter Templates Load Failure',
  CoverLetterTemplatesLoadPending = '[Cover sheet modal] Cover Letter Templates Load Pending',
  CoverLetterRequiredTemplatesLoadSuccess = '[Cover sheet modal] Cover Letter Required Templates Load Success',
  CoverLetterRequiredTemplatesLoadFailure = '[Cover sheet modal] Cover Letter Required Templates Load Failure',
  CoverLetterRequiredTemplatesLoadPending = '[Cover sheet modal] Cover Letter Required Templates Load Pending',
  CoverLetterTemplatesReset = '[Cover sheet modal] Templates State Reset',
}
