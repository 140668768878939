import { CUSTOM_METRIC_FORMULA_STATE, CUSTOM_METRIC_STATE } from './placeholders-wizard.constants';

export const FormulaEditorOptions = {
  lineNumbers: true,
  lineWrapping: true,
  autoCloseBrackets: true,
  foldGutter: true,
  gutters: ['CodeMirror-lint-markers'],
  matchBrackets: true,
  mode: 'javascript',
  extraKeys: { 'Ctrl-Space': 'autocomplete' },
  tabMode: 'indent',
  indentWithTabs: false,
  styleActiveLine: true,
  line: true,
  lint: { esversion: '11' },
};

export const CompilationEditorOptions = {
  ...FormulaEditorOptions,
  readOnly: true,
  json: true,
  placeholder: 'Click "Compile" to see preview',
};

export const CustomMetricButtons = [
  {
    label: 'Custom Metric',
    key: CUSTOM_METRIC_STATE.id,
    classes: 'secondary',
    active: true,
  },
  {
    label: 'Formula',
    key: CUSTOM_METRIC_FORMULA_STATE.id,
    classes: 'secondary',
    active: false,
  },
];
