import { ChangeDetectorRef, Directive } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { merge, get } from 'lodash-es';
import { first } from 'rxjs/operators';

import { getChartColorScheme } from '../../../components/presentation/setup/setup.selectors';
import { Y_DISCLOSURE_TEXT } from '../../../components/presentation/setup/setup.constant';
import { AppState } from '../../../reducers';
import { SetupService } from '../../../components/presentation/setup/setup.service';
import { Visualization } from '@core/class/Visualization.class';
import { ChartOptionsService, ChartDataService, PdfExportService } from '@core/service';
import * as setupConstant from '../../../components/presentation/setup/setup.constant';
import * as ConfigsActions from '../../../components/presentation/redux/configs/actions';
import * as PresentationSettingsActions from '../../../components/modals/redux/presentation-settings.actions';
import { GAService, Global } from '@shared/services';
import { ColorScheme } from '@shared/models';
import { ChartDataCreationParams, ChartType, XAxisSourceType } from '@core/model';

@Directive()
export abstract class ChartVisualization extends Visualization {
  public xMinApplied = false;
  public xMaxApplied = false;
  public yMinApplied = false;
  public yMaxApplied = false;
  protected useArea: boolean;
  public colorScheme: ColorScheme[];

  constructor(
    public global: Global,
    public router: Router,
    public setupService: SetupService,
    public presentationViewService: PdfExportService,
    public store: Store<AppState>,
    public chartOptionsService: ChartOptionsService,
    public chartDataService: ChartDataService,
    public cdRef: ChangeDetectorRef,
    public gaService: GAService
  ) {
    super(global, router, setupService, presentationViewService, store, cdRef, gaService);
  }

  setChartOptions(
    {
      type,
      isPdf = false,
      xAxisSource,
      yKey,
      isPercentYAxis = false,
      pageID,
      pinValue,
    }: {
      type: ChartType;
      isPdf: boolean;
      xAxisSource: XAxisSourceType;
      yKey: string;
      isPercentYAxis: boolean;
      pageID: string;
      pinValue: number;
    },
    customOptions = {}
  ) {
    const options = this.chartOptionsService.getOptions({
      xAxisSource,
      yKey,
      isPercentYAxis,
      pageID,
      pinValue,
      type,
      isPdf,
    });

    return merge(options, customOptions);
  }

  setChartData(params: ChartDataCreationParams) {
    params.chartType = params.type;

    return this.chartDataService.getChartData(params);
  }

  public setChartArea({ state }) {
    this.store.dispatch(
      ConfigsActions.configsPageAreaValue({
        area: state,
        uiId: this.pageID,
      })
    );
    this.useArea = state;
    this.setData();
  }

  protected setData() {
    throw new Error('Method not implemented.');
  }

  public setColorScheme() {
    this.colorScheme = this.setupService.getDefaultScheme(setupConstant.chart.colorScheme);

    return this.store.select(getChartColorScheme(this.configKey)).pipe(first());
  }

  public columnsEvent(event): void {
    this.store.dispatch(PresentationSettingsActions.setPresentationSettingsActiveTab(event));
    this.store.dispatch(PresentationSettingsActions.setPresentationSettingsOpen({ open: true }));
  }

  getChartYAxisDisclosureText() {
    return this.selectedMetric?.isPercentYAxis
      ? `${Y_DISCLOSURE_TEXT[0]}${
          this.yMinApplied ? get(this.config[0], 'config.bottomIrr') + Y_DISCLOSURE_TEXT[1] : ''
        }${this.yMinApplied && this.yMaxApplied ? Y_DISCLOSURE_TEXT[2] : ''}${
          this.yMaxApplied ? get(this.config[0], 'config.topIrr') + Y_DISCLOSURE_TEXT[3] : ''
        }.`
      : '';
  }

  onPinChange(): void {
    this.gaService.sendPinChangedEvent();
  }
}
