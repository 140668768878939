import { Metric } from '@core/model';

import { cloneDeep } from 'lodash-es';

export const ANNUITY_TITLE_FIELD = 'annuity';
export const VUL_TITLE_FIELD = 'vul';

export const MetricsNotForModifying = [
  'total_charges_nsv',
  'total_charges_db',
  'total_charges_yearly',
  'policy_charges_db',
  'coi_charges_db',
  'asset_charges_db',
  'policy_charges_nsv',
  'coi_charges_nsv',
  'asset_charges_nsv',
];

export const ChargesMetricsRelations: Record<string, Metric[]> = {
  total_charges_nsv: [
    {
      isIRR: false,
      name: 'Asset Charges',
      key: 'asset_charges_nsv',
      configMetricKey: 'asset_charges',
      title: 'as % of NSV + Cumulative Income',
      type: 'Charges',
      isPercentYAxis: true,
    },
    {
      isIRR: false,
      name: 'Cost of Insurance',
      key: 'coi_charges_nsv',
      configMetricKey: 'coi_charges',
      title: 'as % of NSV + Cumulative Income',
      type: 'Charges',
      isPercentYAxis: true,
    },
    {
      isIRR: false,
      name: 'Policy Charges',
      key: 'policy_charges_nsv',
      configMetricKey: 'policy_charges',
      title: 'as % of NSV + Cumulative Income',
      type: 'Charges',
      isPercentYAxis: true,
    },
    {
      isIRR: false,
      name: 'Total Charges',
      key: 'total_charges_nsv',
      configMetricKey: 'total_charges',
      title: 'as % of NSV + Cumulative Income',
      type: 'Charges',
      isPercentYAxis: true,
    },
  ],
  total_charges_db: [
    {
      isIRR: false,
      name: 'Asset Charges',
      key: 'asset_charges_db',
      configMetricKey: 'asset_charges',
      title: 'as % of DB + Cumulative Income',
      type: 'Charges',
      isPercentYAxis: true,
    },
    {
      isIRR: false,
      name: 'Cost of Insurance',
      key: 'coi_charges_db',
      configMetricKey: 'coi_charges',
      title: 'as % of DB + Cumulative Income',
      type: 'Charges',
      isPercentYAxis: true,
    },
    {
      isIRR: false,
      name: 'Policy Charges',
      key: 'policy_charges_db',
      configMetricKey: 'policy_charges',
      title: 'as % of DB + Cumulative Income',
      type: 'Charges',
      isPercentYAxis: true,
    },
    {
      isIRR: false,
      name: 'Total Charges',
      key: 'total_charges_db',
      configMetricKey: 'total_charges',
      title: 'as % of DB + Cumulative Income',
      type: 'Charges',
      isPercentYAxis: true,
    },
  ],
  total_charges_yearly: [
    {
      isIRR: false,
      name: 'Asset Charges',
      key: 'asset_charges_yearly',
      configMetricKey: 'asset_charges',
      title: '',
      type: 'Charges',
    },
    {
      isIRR: false,
      name: 'Cost of Insurance',
      key: 'coi_charges_yearly',
      configMetricKey: 'coi_charges',
      title: '',
      type: 'Charges',
    },
    {
      isIRR: false,
      name: 'Policy Charges',
      key: 'policy_charges_yearly',
      configMetricKey: 'policy_charges',
      title: '',
      type: 'Charges',
    },
    {
      isIRR: false,
      name: 'Total Charges',
      key: 'total_charges_yearly',
      configMetricKey: 'total_charges',
      title: '',
      type: 'Charges',
    },
  ],
};

export const ChargesMetricsList: Metric[] = [
  {
    isIRR: true,
    name: 'Asset Charges',
    key: 'asset_charges_nsv',
    title: 'as % of NSV + Cumulative Income',
    yAxisTitle: 'Asset Charges as % of NSV + Cumulative Income',
    isPercentYAxis: true,
  },
  {
    isIRR: true,
    name: 'Cost of Insurance',
    key: 'coi_charges_nsv',
    title: 'as % of NSV + Cumulative Income',
    yAxisTitle: 'Cost of Insurance as % of NSV + Cumulative Income',
    isPercentYAxis: true,
  },
  {
    isIRR: true,
    name: 'Policy Charges',
    key: 'policy_charges_nsv',
    title: 'as % of NSV + Cumulative Income',
    yAxisTitle: 'Policy Charges as % of NSV + Cumulative Income',
    isPercentYAxis: true,
  },
  {
    isIRR: true,
    name: 'Asset Charges',
    key: 'asset_charges_db',
    title: 'as % of DB + Cumulative Income',
    yAxisTitle: 'Asset Charges as % of DB + Cumulative Income',
    isPercentYAxis: true,
  },
  {
    isIRR: true,
    name: 'Cost of Insurance',
    key: 'coi_charges_db',
    title: 'as % of DB + Cumulative Income',
    yAxisTitle: 'Cost of Insurance as % of DB + Cumulative Income',
    isPercentYAxis: true,
  },
  {
    isIRR: true,
    name: 'Policy Charges',
    key: 'policy_charges_db',
    title: 'as % of DB + Cumulative Income',
    yAxisTitle: 'Policy Charges as % of DB + Cumulative Income',
    isPercentYAxis: true,
  },
  {
    isIRR: true,
    name: 'Yearly Avg Charges',
    key: 'total_charges_nsv',
    title: 'as % of NSV',
    type: 'Charges',
    yAxisTitle: 'Single View Charges as % of NSV + Cumulative Income',
    isPercentYAxis: true,
  },
  {
    isIRR: true,
    name: 'Yearly Avg Charges',
    key: 'total_charges_db',
    title: 'as % of DB',
    type: 'Charges',
    yAxisTitle: 'Single View Charges as % of DB + Cumulative Income',
    isPercentYAxis: true,
  },
  {
    isIRR: false,
    name: 'Absolute',
    key: 'total_charges_yearly',
    title: 'Charges',
    type: 'Charges',
    yAxisTitle: 'Yearly Average Charges',
    isPercentYAxis: false,
  },
];

export const RetirementShortfallMetrics: Record<string, Metric[]> = cloneDeep({
  on: [
    {
      isIRR: false,
      name: 'Social Security',
      key: 'social_security_post_tax',
      configMetricKey: 'social_security_post_tax',
      title: 'After-Tax Amount',
      type: 'Retirement',
    },
    {
      isIRR: false,
      name: 'Retirement Assets Distribution',
      key: 'retirement_distribution_post_tax_policy_on',
      configMetricKey: 'retirement_distribution_post_tax_policy',
      title: 'After-Tax Income from Assets',
      type: 'Retirement',
    },
    {
      isIRR: false,
      name: null,
      key: 'life_insurance_distribution_tax_free_policy_on',
      configMetricKey: 'life_insurance_distribution_tax_free_policy',
      title: 'Tax-Free Income from Policy',
      type: 'Retirement',
    },
    {
      isIRR: false,
      name: 'Retirement Shortfall',
      key: 'shortfall_policy_on',
      configMetricKey: 'shortfall_policy',
      title: 'Remaining Funds Needed',
      type: 'Retirement',
    },
    {
      isIRR: false,
      name: 'Total Retirement Goal',
      key: 'retirement_need_post_tax',
      configMetricKey: 'retirement_need_post_tax',
      title: 'After-Tax Income Need',
      type: 'Retirement',
    },
  ],
  off: [
    {
      isIRR: false,
      name: 'Social Security',
      key: 'social_security_post_tax',
      configMetricKey: 'social_security_post_tax',
      title: 'After-Tax Amount',
      type: 'Retirement',
    },
    {
      isIRR: false,
      name: 'Retirement Assets Distribution',
      key: 'retirement_distribution_post_tax_policy_off',
      configMetricKey: 'retirement_distribution_post_tax_policy',
      title: 'After-Tax Income from Assets',
      type: 'Retirement',
    },
    {
      isIRR: false,
      name: null,
      key: 'life_insurance_distribution_tax_free_policy_off',
      configMetricKey: 'life_insurance_distribution_tax_free_policy',
      title: 'Tax-Free Income from Policy',
      type: 'Retirement',
    },
    {
      isIRR: false,
      name: 'Retirement Shortfall',
      key: 'shortfall_policy_off',
      configMetricKey: 'shortfall_policy',
      title: 'Remaining Funds Needed',
      type: 'Retirement',
    },
    {
      isIRR: false,
      name: 'Total Retirement Goal',
      key: 'retirement_need_post_tax',
      configMetricKey: 'retirement_need_post_tax',
      title: 'After-Tax Income Need',
      type: 'Retirement',
    },
  ],
});

export const SALES_CONCEPT_METRICS = [
  ...ChargesMetricsList,
  ...RetirementShortfallMetrics.on,
  ...RetirementShortfallMetrics.off,
  ...ChargesMetricsRelations.total_charges_nsv,
  ...ChargesMetricsRelations.total_charges_db,
  ...ChargesMetricsRelations.total_charges_yearly,
];
