import { Action, on, createReducer } from '@ngrx/store';

import * as Actions from './actions';
import { SidebarItem } from '../interfaces';

export interface State {
  data: SidebarItem[];
}

export const initialState: State = {
  data: [],
};

const sidebarReducer = createReducer(
  initialState,
  on(Actions.setData, (state, { data }) => ({
    ...state,
    data,
  })),
  on(Actions.setActive, (state, { id }) => ({
    ...state,
    data: state.data.map(item => ({
      ...item,
      active: item.id === id,
      children: (item.children || []).map(child => ({
        ...child,
        active: child.id === id,
      })),
    })),
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return sidebarReducer(state, action);
}
