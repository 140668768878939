import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { ModalConfig, ModalRef } from '@assurance/bootstrap';

import { CopyCustomPage } from '@shared/models';

@Component({
  selector: 'ensight-custom-page-copy-modal',
  templateUrl: './copy-modal.component.html',
  styleUrls: ['./copy-modal.component.scss'],
})
export class CopyModalComponent implements OnInit {
  formValue: FormControl;
  isLoading = false;
  isInsertReuseEnabled = false;

  constructor(private modal: ModalRef, private config: ModalConfig<CopyCustomPage>) {}

  ngOnInit() {
    this.formValue = new FormControl(this.config.data.name, [Validators.required, Validators.maxLength(100)]);
  }

  onCopyCustomPage(): void {
    this.isLoading = true;
    this.modal.close({
      customPageUUID: this.config.data.customPageUUID,
      name: this.formValue.value,
      reuseInserts: this.isInsertReuseEnabled,
    });
  }

  onInsertReuseToggle(): void {
    this.isInsertReuseEnabled = true;
  }

  close(): void {
    this.modal.close(null);
  }
}
