import { Injectable } from '@angular/core';

import { TableRequestParamsService } from '@shared/services';
import { DefaultCustomPagePaginationRequestParams, DefaultOrderByRequestParams } from '../../constants';

@Injectable()
export class CustomPageListService extends TableRequestParamsService {
  constructor() {
    super(DefaultCustomPagePaginationRequestParams, null, DefaultOrderByRequestParams);
  }
}
