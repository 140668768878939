import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { finalize, map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserService, AuthService, IAMService, OrganizationSwitch, Organization } from '@assurance/um-services';
import { Portals } from '@assurance/um-services';

import { Global, PreviewCompiledPageService, StyleEditorService } from '@shared/services';
import { AppState } from '../../../../reducers';
import { globalStateReset } from '@ngrx-app/global.actions';
import { BaseMenuItems, MainMenuItems } from './user-menu.constants';
import { getOrganizationsSwitchData } from '@ngrx-app/global.selectors';
import { MenuItemsType, UserMenuItem } from './user-menu.model';
import { LocalStorageService } from '@core/service';
import { WINDOW_TOKEN } from '@core/constant';
import { userPilotStorageKey } from '@shared/constants';

@UntilDestroy()
@Component({
  selector: 'ensight-user-menu',
  styleUrls: ['user-menu.scss'],
  templateUrl: './user-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnInit {
  userName: string;
  menuItems: UserMenuItem[][];
  isPopupOpen: boolean;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private userService: UserService,
    private global: Global,
    private styleEditorService: StyleEditorService,
    private router: Router,
    private iamService: IAMService,
    private localStorage: LocalStorageService,
    private previewCompiledPageService: PreviewCompiledPageService,
    @Inject(WINDOW_TOKEN) private window: Window
  ) {}

  ngOnInit(): void {
    this.userName = `${this.userService.user.firstName} ${this.userService.user.lastName}`;
    this.watchForOrganizationsSwitchData();
  }

  onToggleMenu(event: boolean): void {
    this.isPopupOpen = event;
  }

  onSelectItem(event: UserMenuItem): void {
    switch (event.type) {
      case MenuItemsType.eiq:
        this.openEIQ();
        break;
      case MenuItemsType.logout:
        this.logout();
        break;
      default:
        this.router.navigate([event.route]);
    }
  }

  private watchForOrganizationsSwitchData(): void {
    this.store
      .pipe(
        untilDestroyed(this),
        select(getOrganizationsSwitchData),
        map((response: OrganizationSwitch<Organization>) => this.prepareMenuItems(response))
      )
      .subscribe((items: UserMenuItem[][]) => (this.menuItems = items));
  }

  private logout(): void {
    this.authService
      .logout()
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.previewCompiledPageService.clear();
          this.localStorage.setData(userPilotStorageKey, false);
        }),
        finalize(() => {
          this.cleanGlobalCurrentTheme();
          this.styleEditorService.collapsedView(true);
          this.router.navigate(['/login']);
          this.store.dispatch(globalStateReset());
        })
      )
      .subscribe();
  }

  private openEIQ(): void {
    const eiqUrl = this.localStorage.getNotJSONData('eiqUrlHost');

    if (eiqUrl) {
      this.window.open(eiqUrl, '_blank');
    }
  }

  private cleanGlobalCurrentTheme(): void {
    this.global.currentTheme = null;
    this.styleEditorService.appendStylesInHtml();
  }

  private prepareMainMenuItemsByPermission(): UserMenuItem[] {
    return MainMenuItems.filter((item: UserMenuItem) => {
      if (item.type === MenuItemsType.um) {
        return (
          this.iamService.hasPortalAccess(Portals.ADM) && this.iamService.hasGroupAccess(item.groupWithPortalPermission)
        );
      }

      if (item.userPermission) {
        return this.iamService.hasUserAccess(item.userPermission);
      }

      if (item.groupPermission) {
        return this.iamService.hasGroupAccess(item.groupPermission);
      }

      if (item.isAssuranceAdmin) {
        return this.userService.isAssuranceOrganization;
      }

      return true;
    });
  }

  private prepareBaseMenuItems(isOrganizationSwitchAvailable: boolean): UserMenuItem[] {
    return BaseMenuItems.filter((item: UserMenuItem) => {
      return item.type === MenuItemsType.changeOrganization ? isOrganizationSwitchAvailable : true;
    });
  }

  private prepareMenuItems(res: OrganizationSwitch<Organization>): UserMenuItem[][] {
    const mainItems = this.prepareMainMenuItemsByPermission();
    const baseItems = this.prepareBaseMenuItems(res?.data?.length > 1 || res?.data[0]?.dataSources?.length > 1);

    return [mainItems, baseItems];
  }
}
