import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';

import { DefaultHistoryOrderByRequestParam, DefaultHistoryPaginationRequestParams } from '../../constants';
import { TableRequestParamsService, TimeService } from '@shared/services';
import { TimeFormat } from '@core/constant';
import { LocalStorageService } from '@core/service';
import { CustomPageChanges } from '../../models';
import { CustomPageHistoryApiService } from '../custom-page-history-api/custom-page-history-api.service';
import { map } from 'rxjs/operators';
import { ExportedCustomPage } from '@shared/models';

@Injectable()
export class CustomPageHistoryService extends TableRequestParamsService {
  constructor(
    private localStorageService: LocalStorageService,
    private timeService: TimeService,
    private customPageHistoryApiService: CustomPageHistoryApiService
  ) {
    super(DefaultHistoryPaginationRequestParams, null, DefaultHistoryOrderByRequestParam);
  }

  revertCustomPageHistory(record: CustomPageChanges): Observable<void> {
    return this.customPageHistoryApiService.revertCustomPageHistoryRecord(record.pageUUID, record.pageNameId);
  }

  downloadRecord(record: CustomPageChanges): void {
    const jsonString = JSON.stringify(record, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const fileName = this.constructFileName(record, 'List of changes');

    saveAs(blob, fileName);
  }

  exportRevisionCustomPage(record: CustomPageChanges): Observable<void> {
    return this.customPageHistoryApiService.exportRevisionCustomPage(record.pageUUID, record.pageNameId).pipe(
      map((response: ExportedCustomPage) => {
        const fileName = this.constructFileName(record, 'Revision');
        saveAs(new Blob([JSON.stringify(response)]), fileName);
      })
    );
  }

  private constructFileName(selectedRecord: CustomPageChanges, title: string): string {
    const env = this.localStorageService.getNotJSONData('appEnv');
    const timestamp = this.timeService.getFormattedDate(new Date(), TimeFormat.YYYYMMDDhhmmss);
    const revisionDatetime = this.timeService.getFormattedDate(selectedRecord.updated, TimeFormat.YYYYMMDDhhmmss);

    return `${env} - ${title} - ${revisionDatetime} - [${selectedRecord.pageName}] - ${timestamp}.json`;
  }
}
