import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgUploaderModule } from 'ngx-uploader';
import { DragulaModule, DragulaService } from 'ng2-dragula';

import { ButtonsModule, FormControlsModule } from '@se/common';
import { LoaderModule } from '@assurance/bootstrap';

import { NavbarComponent } from './navbar.component';
import { NavbarService } from './navbar.service';
import { NavbarPagesAdapterService, NavbarPagesService } from '@shared/services';
import { PdfLoadingMessageModule, TogglesModule } from '@shared/components';
import { DirectivesModule } from '@shared/directives/directives.module';
import { NoteInputModule } from '@shared/components/note-input/note-input.module';
import { SharedModalsModule } from '@shared/components/modal/shared-modals.module';
import { ModalProviderService } from 'src/app/modules/custom-pages/services';

@NgModule({
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
  imports: [
    CommonModule,
    DragulaModule,
    RouterModule,
    FormsModule,
    NgUploaderModule,
    SharedModalsModule,
    TogglesModule,
    PdfLoadingMessageModule,
    ButtonsModule,
    LoaderModule,
    ReactiveFormsModule,
    FormControlsModule,
    NoteInputModule,
    DirectivesModule,
  ],
  providers: [NavbarService, DragulaService, NavbarPagesService, NavbarPagesAdapterService, ModalProviderService],
})
export class NavbarModule {}
