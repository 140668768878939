export const CUSTOM_PAGES_TYPES = {
  requiredpage: 'requiredpage',
  dependentpage: 'dependentpage',
  salesconcept: 'salesconcept',
  endpage: 'endpage',
};

export const REQUIRED_PAGES_QUERY_PARAMS = {
  types: ['requiredpage'],
};

export const PAGE_TYPES: Record<string, string> = {
  requiredpage: 'Required Page',
  dependentpage: 'Dependent Page',
  salesconcept: 'Sales Concept',
};
