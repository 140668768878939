import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { AppState } from '../../../../../reducers';
import { IMAGE_STATE } from '../../../constants';
import { configurationForm, getImagesList } from '../../../redux';
import { UPLOAD_CONFIG_VALIDATIONS } from '@shared/constants';
import { ImageInfo } from '@shared/models';

@Component({
  selector: 'ep-set-image',
  templateUrl: './set-image.component.html',
  styleUrls: ['./set-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetImageComponent implements OnInit {
  @Input() id: string;
  @Output() uploadImage = new EventEmitter();
  @Output() deleteImage = new EventEmitter<ImageInfo>();
  @Output() selectImage = new EventEmitter<ImageInfo>();
  @Output() loadedImage = new EventEmitter<ImageInfo>();
  readonly stateId = IMAGE_STATE.id;
  // TODO: need to change interface
  readonly logoUploadConfig: any = {
    type: 'image',
    fileExtension: 'image/*',
    image: {
      title: 'Upload Image',
      addBtnLabel: 'Upload',
    },
    validations: UPLOAD_CONFIG_VALIDATIONS,
  };
  questionsData$: Observable<{ data: any }>;
  imagesList$ = this.store.pipe(select(getImagesList));

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.questionsData$ = this.store.pipe(
      select(
        configurationForm({
          id: this.id,
          stateId: this.stateId,
        })
      ),
      filter(item => !!item),
      first()
    ) as Observable<{ data: any }>;
  }

  addImage(event: { file: File }): void {
    this.uploadImage.emit({
      name: `${Date.now()} - ${event.file.name}`,
      type: event.file.type,
      url: URL.createObjectURL(event.file),
    });
  }

  onSelectImage(image: ImageInfo): void {
    this.selectImage.emit(image);
  }

  onDeleteImage(image: ImageInfo): void {
    this.deleteImage.emit(image);
  }

  onLoadedImage(image: ImageInfo): void {
    this.loadedImage.emit(image);
  }
}
