import { createReducer, on, Action } from '@ngrx/store';

import { Params } from '../import-illustration.models';
import {
  getSelectedPresentationProducts,
  setImportParams,
  setPendingExportsData,
  setSelectedPresentation,
  setSelectedPresentationProducts,
} from './import-illustration.actions';
import { TableDataItem, PendingExportsData } from '@shared/models';
import { CareerPlan } from '@core/model';

export interface State {
  params: Params;
  pendingExportsData: PendingExportsData[];
  selectedPresentation: TableDataItem;
  selectedPresentationId: number;
  selectedPresentationCarrierPlans: CareerPlan[];
}

export const initialState: State = {
  params: {
    transactionUUID: null,
    caseId: null,
    casePresentationsCount: null,
    thirdParty: false,
  },
  pendingExportsData: [],
  selectedPresentation: null,
  selectedPresentationId: null,
  selectedPresentationCarrierPlans: null,
};

const importIllustrationReducer = createReducer(
  initialState,
  on(setImportParams, (state, { params }) => ({
    ...state,
    params: {
      ...state.params,
      ...params,
    },
  })),
  on(setPendingExportsData, (state, { pendingExportsData }) => ({
    ...state,
    pendingExportsData: pendingExportsData,
  })),
  on(setSelectedPresentation, (state, { selectedPresentation }) => ({
    ...state,
    selectedPresentation: selectedPresentation,
  })),
  on(getSelectedPresentationProducts, (state, { selectedPresentationId }) => ({
    ...state,
    selectedPresentationId,
  })),
  on(setSelectedPresentationProducts, (state, { selectedPresentationCarrierPlans }) => ({
    ...state,
    selectedPresentationCarrierPlans,
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return importIllustrationReducer(state, action);
}
