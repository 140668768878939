import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule, ModalModule, InputModule, LoaderModule } from '@assurance/bootstrap';

// import { StyleEditorModule } from '../../../style-editor/style.editor.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { DistributedModalComponent } from './distributed-modal.component';
import { DistributedModalService } from './distributed-modal.service';

@NgModule({
  exports: [DistributedModalComponent],
  declarations: [DistributedModalComponent],
  imports: [CommonModule, DirectivesModule, ButtonModule, ModalModule, InputModule, LoaderModule],
  providers: [DistributedModalService],
})
export class DistributedModalModule {}
