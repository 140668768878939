import { Injectable } from '@angular/core';

import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { concatMap, first, map, switchMap, tap } from 'rxjs/operators';
import { get, orderBy } from 'lodash-es';
import { Store } from '@ngrx/store';

import { UserService } from '@assurance/um-services';
import { Rule } from '@assurance/um-services/interfaces/groups';

import { APIService, Global, PreviewCompiledPageService } from '@shared/services';
import {
  CareerPlan,
  ConfigPlanOrganisation,
  DropdownIndex,
  ExtendedPlaceholderMetadata,
  Placeholder,
  Presentation,
  PresentationConfig,
  PresentationConfigs,
  ResponseType,
  TimeDate,
  XAxisSourceType,
} from '@core/model';
import {
  ColorScheme,
  CompilePageOptions,
  CustomPage,
  CustomPageInsert,
  DataLimitsSource,
  Insert,
  ShortfallFields,
} from '@shared/models';
import {
  ADDITIONAL_DATA_INSERT_TYPES,
  CALCULATED_SETTINGS,
  COMPILE_SETTINGS,
  PrecompilePaths,
  RETIREMENT_SHORTFALL_SETTINGS_FIELDS,
} from '@shared/constants';
import { ChartsInjectService, LocalStorageService } from '@core/service';
// TODO: it changed once path to this items will change
import { getChartColorScheme } from '../../../../components/presentation/setup/setup.selectors';
import { AppState } from '../../../../reducers';
import { InsertContentDirective } from '@shared/pipes/insert-content.pipe';
import { CustomPageAdapterService } from '../custom-page-adapter/custom-page-adapter.service';
import { CustomPageApiService } from '../custom-page-api/custom-page-api.service';
import { INSERT_TYPE } from '@core/enums';
import * as PlaceholdersWizardSelectors from '../../redux/placeholders-wizard.selectors';
import { dynamicFormValue } from '../../redux/placeholders-wizard.selectors';
import { CompiledCustomMetric } from '../../models';

@Injectable()
export class CustomPagePreviewService extends PreviewCompiledPageService {
  constructor(
    private apiService: APIService,
    private userService: UserService,
    protected global: Global,
    private chartsInjectService: ChartsInjectService,
    private customPageAdapterService: CustomPageAdapterService,
    private store: Store<AppState>,
    protected localStorage: LocalStorageService,
    private customPageApiService: CustomPageApiService
  ) {
    super(localStorage, global);

    if (this.getPlansConfig) {
      this.global.setPlansConfig = this.getPlansConfig;
      this.global.setPresentation = this.getPresentation;
    }
  }

  getCompiledPage(
    presentationId: number,
    addedDate: TimeDate,
    customPage: CustomPage,
    inserts?: Partial<Insert>[]
  ): Observable<CustomPage> {
    return combineLatest([
      this.getCareerPlans(presentationId),
      this.getPresentationConfig(presentationId),
      this.store.select(getChartColorScheme(null)),
    ]).pipe(
      map((data: [CareerPlan[], PresentationConfigs, ColorScheme[]]) => {
        const [plans, config, colorScheme] = data;
        this.setConfigs = config;
        this.setColorScheme = colorScheme;
        this.setPlans = plans;

        const options = this.getCompileOptions(customPage, addedDate, inserts);

        this.chartsInjectService.setXAxisSourceForPreview(options.compileData.globalConfig.config.xAxisSource);

        return options;
      }),
      switchMap((options: CompilePageOptions) => this.getPage(customPage._id, options, presentationId))
    );
  }

  compileCustomMetric(presentationId: number, addedDate: TimeDate, page: CustomPage): Observable<CompiledCustomMetric> {
    return this.prepareInserts().pipe(
      switchMap((inserts: Partial<Insert>[]) => this.getCompiledPage(presentationId, addedDate, page, inserts)),
      switchMap((page: CustomPage) => this.getAdaptedCustomMetricCompilation(page))
    );
  }

  compilePreviewInserts(presentationId: number, addedDate: TimeDate, page: CustomPage): Observable<CustomPage> {
    return this.prepareInserts().pipe(
      switchMap((inserts: Partial<Insert>[]) => this.getCompiledPage(presentationId, addedDate, page, inserts))
    );
  }

  getCompiledPageBySelectedPresentation(
    customPage: CustomPage,
    addedDate: TimeDate,
    presentationId: number
  ): Observable<CustomPage> {
    const config$ = this.getConfigs
      ? of(this.getConfigs)
      : this.getPresentationConfig(presentationId).pipe(tap(config => (this.setConfigs = config)));

    const careerPlans$ = this.getPlans
      ? of(this.getPlans)
      : this.getCareerPlans(presentationId).pipe(tap(plans => (this.setPlans = plans)));

    return combineLatest([config$, careerPlans$]).pipe(
      switchMap(() => {
        const options = this.getCompileOptions(customPage, addedDate);
        this.chartsInjectService.setXAxisSourceForPreview(options.compileData.globalConfig.config.xAxisSource);

        return this.getPage(customPage._id, options, presentationId);
      })
    );
  }

  getPresentationData(presentationId: number): Observable<Presentation> {
    return this.apiService
      .getPresentationConfig(presentationId)
      .pipe(map((data: ResponseType<Presentation>) => data.data));
  }

  setInserts(
    customPage: CustomPage,
    insertContentRef: InsertContentDirective,
    inserts: CustomPageInsert[],
    uiId: string,
    isPDFPrint: boolean
  ): ExtendedPlaceholderMetadata[] {
    this.setPlansForPreview(customPage);

    return this.chartsInjectService.getInsertsMetadata(
      insertContentRef,
      inserts,
      uiId,
      null,
      this.getColorScheme,
      [],
      this.getConfigsDataLimits(),
      isPDFPrint
    );
  }

  private prepareInserts(): Observable<Partial<Insert>[]> {
    return combineLatest([
      this.store.select(PlaceholdersWizardSelectors.placeholdersMetadata),
      this.store.select(dynamicFormValue),
    ]).pipe(
      first(),
      map((data: [Placeholder[], Record<string, any>]) =>
        this.customPageAdapterService.adaptInsertsForCompileMetric(data)
      )
    );
  }

  private getAdaptedCustomMetricCompilation(page: CustomPage): Observable<CompiledCustomMetric> {
    return this.store.select(dynamicFormValue).pipe(
      first(),
      map((formValue: Record<string, any>) => {
        return this.customPageAdapterService.adaptCustomMetricCompilation(page, formValue.placeholderKey);
      })
    );
  }

  private setPlansForPreview(customPage: CustomPage): void {
    const min = customPage.customFields.productsMin;

    const plans = !min || min > this.getPlans.length ? this.getPlans : this.getPlans.slice(0, Number(min));

    this.chartsInjectService.setPlansForPreview(plans as any, this.gatherInsertsCalculatedData(customPage));
  }
  private getCareerPlans(presentationId: number): Observable<CareerPlan[]> {
    return this.apiService.getCareerPlans(presentationId).pipe(
      map((data: ResponseType<CareerPlan[]>) => orderBy(data.data, 'order')),
      concatMap((plans: CareerPlan[]) => {
        let organisationKey;

        if (plans && plans[0]) {
          organisationKey = plans[0].app;
        }

        return forkJoin([of(plans), this.getCarrierPlansConfig(organisationKey)]);
      }),
      map((data: [CareerPlan[], ConfigPlanOrganisation]) => data[0])
    );
  }
  private getCarrierPlansConfig(organisationKey: string): Observable<ConfigPlanOrganisation> {
    return this.apiService.getCarrierPlansConfig(organisationKey).pipe(
      map((data: ResponseType<ConfigPlanOrganisation>) => data.data),
      tap((data: ConfigPlanOrganisation) => {
        this.setPlansConfig = data;
        this.global.setPlansConfig = data;
      })
    );
  }
  private getPage(uiId: string, options: CompilePageOptions, presentationId: number): Observable<CustomPage> {
    return this.customPageApiService.getCompiledPreviewPage(uiId, options, presentationId).pipe(
      map((data: CustomPage) => {
        return {
          ...data,
          inserts: this.customPageAdapterService.adaptCompiledInserts(data?.inserts || []) as any, //CustomPageInsert[]
        };
      })
    );
  }

  private getPresentationConfig(presentationId: number): Observable<PresentationConfigs> {
    return this.apiService
      .getSetupPageConfig(presentationId)
      .pipe(map((data: ResponseType<PresentationConfigs>) => data.data));
  }

  private getCompileOptions(
    customPage: CustomPage,
    addedDate?: TimeDate,
    inserts?: Partial<Insert>[]
  ): CompilePageOptions {
    const variables = this.getVariables(inserts || customPage.inserts);
    const dropdowns = this.getDropdowns(customPage.inserts);
    const productSelector = this.getProductSelectors(customPage.inserts);
    const tabs = this.getTabInsert(customPage.inserts);

    const compilationOption: CompilePageOptions = {
      compileData: {
        variables,
        dropdowns,
        selectedDropdownIndex: this.getDropdownIndexes(customPage.inserts),
        productSelector,
        tabs,
        selectedProductIndex: productSelector?.selected_product,
        presentation: this.getPresentation,
        assumptions: this.getAssumptionsInfo(),
        profile: this.getProfileInfo(),
        globalConfig: this.getGlobalConfig(),
        dropdownOptions: this.getDropDownOptionsForPrecompile(customPage.inserts),
      },
      compileSettings: {
        paths: COMPILE_SETTINGS,
        calculatedPaths: CALCULATED_SETTINGS,
        additionalDataInsertTypes: ADDITIONAL_DATA_INSERT_TYPES,
        precompilePaths: PrecompilePaths,
      },
      addedDate,
    };

    if (inserts && inserts.length) {
      compilationOption.inserts = inserts;
    }

    return compilationOption;
  }

  private getProfileInfo(): Record<string, Record<string, string>> {
    return {
      user: {
        firstName: this.userService.user.firstName,
        lastName: this.userService.user.lastName,
      },
      organization: {
        name: this.userService.organization && this.userService.organization.name,
      },
    };
  }

  private getAssumptionsInfo(): ShortfallFields {
    const rules = this.userService.groupRules.reduce((acc: any, item: Rule) => {
      return {
        ...acc,
        [item.ruleKey]: item.ruleValue,
      };
    }, {});

    const retirementConfig = this.getConfigs.configs.find(
      (item: PresentationConfig) => item.config.uiId === 'retirement_shortfall'
    );

    if (retirementConfig?.config.shortfallSettings?.retirementAge) {
      return retirementConfig.config.shortfallSettings;
    }

    return RETIREMENT_SHORTFALL_SETTINGS_FIELDS.reduce((acc: ShortfallFields, ruleKey: string) => {
      return {
        ...acc,
        [ruleKey]: rules[ruleKey],
      };
    }, {} as ShortfallFields);
  }

  private getGlobalConfig(): PresentationConfig {
    const globalConfig = this.getConfigs.configs[0];

    if (!globalConfig) {
      return {} as PresentationConfig;
    }

    return {
      ...globalConfig,
      config: {
        ...globalConfig.config,
        xAxisSource: this.getGraphValue(),
      },
    };
  }

  private getGraphValue(): XAxisSourceType {
    const graphValues = this.getConfigs.configs
      .map((item: PresentationConfig) => item?.config?.chartConfig?.xAxisSource as XAxisSourceType)
      .filter((item: XAxisSourceType) => item);

    return graphValues.filter(
      (val: XAxisSourceType, index: number, values: XAxisSourceType[]) => values.indexOf(val) === index
    )[0];
  }

  private getConfigsDataLimits(): DataLimitsSource {
    const rules = this.getAssumptionsInfo();

    return {
      topIrr: get(this.getConfigs.configs, '[0].config.topIrr'),
      bottomIrr: get(this.getConfigs.configs, '[0].config.bottomIrr'),
      maxAgeDisplay: get(this.getConfigs.configs, '[0].config.maxAgeDisplay') || get(rules, 'maxAge') || null,
      minAgeDisplay: get(this.getConfigs.configs, '[0].config.minAgeDisplay'),
      xAxisSource: this.getGraphValue(),
    };
  }

  private gatherInsertsCalculatedData(customPage: CustomPage): Record<string, number[]>[] {
    return customPage?.inserts?.reduce((res, insert: Insert) => {
      if (insert.config.calculable && Array.isArray(insert.calculatedData)) {
        const calculatedRes = [...res];

        insert.calculatedData.forEach((calculatedData: any, index: number) => {
          calculatedData.forEach(item => {
            if (item) {
              const placeholderKey = item.placeholderKey;
              const data = item.data;
              placeholderKey &&
                data &&
                (calculatedRes[index] = {
                  ...(calculatedRes[index] ?? {}),
                  [placeholderKey]: data,
                });
            }
          });
        });

        return calculatedRes;
      }

      return res;
    }, []);
  }

  private getVariables(inserts: Partial<Insert>[]): Record<string, number> {
    const filteredVariables = inserts.filter(i => !i.metadata.isInlineEditable);

    return this.getCompileOptionsItemByType<number>(filteredVariables, INSERT_TYPE.variable, 'placeholderDefaultValue');
  }

  private getDropdowns(inserts: Insert[]): Record<string, string> {
    return this.getCompileOptionsItemByType(inserts, INSERT_TYPE.dropdown, 'placeholderSelectedValue');
  }

  private getDropdownIndexes(inserts: Insert[]): DropdownIndex {
    const result = {};

    inserts.forEach(insert => {
      if (insert.metadata.insertType === INSERT_TYPE.dropdown) {
        const index = insert.metadata?.dropdownOptions.findIndex(
          option => option.value === insert.metadata.placeholderSelectedValue
        );
        const value = insert.metadata.placeholderSelectedValue;

        result[insert.metadata.placeholderKey] = value?.startsWith('{{') && value?.endsWith('}}') ? value : index;
      }
    });

    return result;
  }

  private getProductSelectors(inserts: Insert[]): Record<string, number> {
    return this.getCompileOptionsItemByType(inserts, INSERT_TYPE.productSelector, 'placeholderSelectedProduct');
  }

  private getTabInsert(inserts: Insert[]): Record<string, number> {
    return this.getCompileOptionsItemByType(inserts, INSERT_TYPE.tab, 'placeholderSelectedTab');
  }

  private getCompileOptionsItemByType<T = string>(
    inserts: Partial<Insert>[],
    type: string,
    prop: string
  ): Partial<Record<string, T>> {
    return inserts
      .filter((insert: Partial<Insert>) => insert.metadata.insertType === type)
      .reduce((acc: Partial<Record<string, T>>, insert: Partial<Insert>) => {
        let value = insert.metadata[prop];
        const type = insert.metadata.insertType;

        const isFoundValueFormula = typeof value === 'string' && value.startsWith('{{') && value.endsWith('}}');

        if (type === INSERT_TYPE.productSelector || type === INSERT_TYPE.variable) {
          value = isFoundValueFormula ? value : Number(value) || 0;
        }

        return {
          ...acc,
          [insert.metadata.placeholderKey]: value,
        };
      }, {});
  }

  private getDropDownOptionsForPrecompile(inserts: Insert[]): Record<string, (string | number)[]> {
    return (inserts || [])
      .filter(insert => insert.metadata.insertType === INSERT_TYPE.dropdown)
      .reduce((acc: Record<string, (string | number)[]>, insert: Insert) => {
        acc[insert.metadata.placeholderKey] = insert.metadata.dropdownOptions.map(option => option.value);

        return acc;
      }, {});
  }
}
