import { Component, Inject, OnInit } from '@angular/core';

import { WINDOW_TOKEN } from '@core/constant';

@Component({
  selector: 'ensight-browser-message',
  templateUrl: './browser-message.component.html',
  styleUrls: ['./browser-message.component.scss'],
})
export class BrowserMessageComponent implements OnInit {
  showMessage = false;

  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  ngOnInit(): void {
    // TODO: we have utils detectIE, maybe it would be better to use
    this.detectIE();
  }

  private detectIE(): void {
    const agent = this.window.navigator.userAgent;

    if (agent.includes('MSIE ') || agent.includes('Trident/')) {
      this.showMessage = true;
    }
  }
}
