import { CoverSheetPlaceholder } from '../cover-sheet-modal.interfaces';

export const CoverSheetPlaceholders: CoverSheetPlaceholder[] = [
  {
    text: 'Client',
    value: 'client',
    subMenu: [
      {
        text: 'Client Name',
        value: 'client_name',
        dataFrom: 'product',
      },
      {
        text: 'Client Age',
        value: 'age',
        dataFrom: 'product',
      },
      {
        text: '2nd Client Name',
        value: 'client_name_2',
        dataFrom: 'product',
      },
      {
        text: '2nd Client Age',
        value: 'age_2',
        dataFrom: 'product',
      },
      {
        text: 'Risk Class',
        value: 'risk_class',
        dataFrom: 'product',
      },
      {
        text: 'State',
        value: 'residence_state',
        dataFrom: 'product',
      },
    ],
  },
  {
    text: 'Agent',
    value: 'agent',
    subMenu: [
      {
        text: 'Agent First Name',
        value: 'agent_first_name',
        dataFrom: 'product',
      },
      {
        text: 'Agent Last Name',
        value: 'agent_last_name',
        dataFrom: 'product',
      },
      {
        text: 'Agent Phone',
        value: 'agent_phone',
        dataFrom: 'product',
      },
      {
        text: 'Agent Address',
        value: 'agent_address_1',
        dataFrom: 'product',
      },
      {
        text: 'Agent Zip',
        value: 'agent_zip_code',
        dataFrom: 'product',
      },
      {
        text: 'Agent City',
        value: 'agent_city',
        dataFrom: 'product',
      },
      {
        text: 'Agent State',
        value: 'agent_state',
        dataFrom: 'product',
      },
      {
        text: 'Agent Company',
        value: 'agent_company',
        dataFrom: 'product',
      },
      {
        text: 'Agent Email',
        value: 'agent_email',
        dataFrom: 'product',
      },
      {
        text: 'Agent License',
        value: 'agent_license',
        dataFrom: 'product',
      },
    ],
  },
  {
    text: 'Product',
    value: 'product',
    subMenu: [
      {
        text: 'Product Name',
        value: 'product_name',
        dataFrom: 'product',
      },
      {
        text: 'Product Type',
        value: 'product_type',
        dataFrom: 'product',
      },
      {
        text: 'Carrier',
        value: 'company_name',
        dataFrom: 'product',
      },
      {
        text: 'Initial Death Benefit',
        value: 'initial_death_benefit', // formatted in $X,XXX,XXX format
        dataFrom: 'product',
        formatted: 'true',
      },
      {
        text: 'Initial Annual Premium',
        value: 'initial_annual_premium', // formatted in $X,XXX,XXX format
        dataFrom: 'product',
        formatted: 'true',
      },
    ],
  },
  {
    text: 'Presentation',
    value: 'presentation',
    subMenu: [
      {
        text: 'Presentation Name',
        value: 'name',
        dataFrom: 'presentation',
      },
      {
        text: 'Created On',
        value: 'formattedDate',
        dataFrom: 'presentation',
      },
      {
        text: 'Created By',
        value: 'createdByName',
        dataFrom: 'presentation',
      },
    ],
  },
];
