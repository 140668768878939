<div class="template-holder" *ngIf="(coverSheet || coverSheetLoaded) && coverSheetTemplates && coverSheetTemplates.length" #templateRef>
  <span *ngIf="selectedTemplateUid !== 'text'" [innerHTML]="innerHTML"></span>
  <div *ngIf="selectedTemplateUid === 'text'">
    <div>
      <ng-template *ngIf="!PDFPrint" [ngTemplateOutlet]="header"></ng-template>
      <section [innerHTML]="htmlBody" class="coversheet-text-body"></section>
    </div>
  </div>
</div>

<div
  *ngIf="(selectedTemplateUid === 'allianz' || selectedTemplateUid === 'allianzretirement') && !landscape"
  class="resize-button-holder print-elem-hide"
  (click)="toggleZoom()"
>
  <ac-icon
    acSuffix
    icon="{{ fitPage ? 'zoomout' : 'zoomin' }}"
    color="#fff"
    [width]="22"
    [height]="22"
  ></ac-icon>
</div>

<ng-template #header>
  <div class="template-header">
    <div class="a-app-logo-img"></div>
  </div>
</ng-template>
