import { ValidatorFn, FormArray } from '@angular/forms';

import { CUSTOM_PAGES_TYPES } from '@shared/constants';

function calculateSelected(formArray: FormArray): number {
  return formArray.controls.map(control => !!control.value).reduce((prev, next) => (next ? +prev + +next : prev), 0);
}

export function minSelectedCheckboxes(min = 1): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (formArray: FormArray): { minChecked: true } | null => {
    const totalSelected = calculateSelected(formArray);

    return totalSelected >= min ? null : { minChecked: true };
  };
}

export function maxSelectedCheckboxes(max = 1): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (formArray: FormArray): { maxChecked: true } | null => {
    const totalSelected = calculateSelected(formArray);

    return totalSelected <= max ? null : { maxChecked: true };
  };
}

export function selectedCheckboxes(selectedPageType: string): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (formArray: FormArray): { carrierUnChecked: true } | null => {
    if (selectedPageType !== CUSTOM_PAGES_TYPES.salesconcept) return null;

    const totalSelected = calculateSelected(formArray);

    return totalSelected > 0 ? null : { carrierUnChecked: true };
  };
}
