<div
  bsModal
  #confirmAgreementChildModal="bs-modal"
  [config]="{ backdrop: 'static' }"
  (onHide)="cancel()"
  class="{{ windowClass }} modal fade aapp-modal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  *ngIf="modalInitComplete"
>
  <div class="modal-container modal-lg a-app-confirm-info-modal-color">
    <div class="modal-content">
      <div class="modal-header aapp-modal-header">
        <h3 class="modal-title">Assurance Service Agreement</h3>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="cancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <um-confirm-agreement
        (output)="onAgreementOutput($event)"
      ></um-confirm-agreement>
    </div>
  </div>
</div>
