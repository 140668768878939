import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';
import { PlansGridComponent } from './plans-grid.component';
// import { StyleEditorModule } from '../../../style-editor/style.editor.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ButtonModule } from '@assurance/bootstrap';
import { PlansGridService } from './plans.grid.service';
import { NoteInputModule } from '@shared/components';
import { CommonSeModule } from '@se/common';

@NgModule({
  declarations: [PlansGridComponent],
  exports: [PlansGridComponent],
  imports: [
    AssurencePipesModule,
    CommonModule,
    TooltipModule.forRoot(),
    // StyleEditorModule,
    DirectivesModule,
    ButtonModule,
    NoteInputModule,
    CommonSeModule,
  ],
  providers: [PlansGridService],
})
export class PlansGridModule {}
