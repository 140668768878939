import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { IAMService } from '@assurance/um-services';
import { PermissionKeyEnum } from '@core/enums';

@Injectable()
export class PolicyInForceRecordsGuard implements CanActivate {
  constructor(private iamService: IAMService, private router: Router) {}

  canActivate(): boolean {
    return this.canAccess();
  }

  private canAccess(): boolean {
    if (this.iamService.hasUserAccess(PermissionKeyEnum.view_policy_inforce_records)) {
      return true;
    } else {
      this.router.navigate(['home']);

      return false;
    }
  }
}
