import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UmNodeAPIService } from '@assurance/um-services';
import { LocalStorageService } from '@core/service';
import { Store } from '@ngrx/store';
import { AlertService } from '@se/common';

import { AppState } from '../reducers';
import { organizationsSwitch } from '@ngrx-app/global.actions';

@UntilDestroy()
@Component({
  styles: [
    `
      .heading {
        text-align: center;
        margin-top: 2em;
      }
    `,
  ],
  template: `
    <h2 class="heading">Authentication in progress...</h2>
    <div class="spinner-circle">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
    </div>
  `,
})
export class WinFlexRedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private umNodeAPIService: UmNodeAPIService,
    private store: Store<AppState>,
    private alertService: AlertService,
    private localStorage: LocalStorageService
  ) {}

  public ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe(({ id }) => {
      const transactionUUID = this.route.snapshot.queryParams.transactionUUID;

      if (id) {
        this.umNodeAPIService.postGenerateUMToken(id, this.localStorage.getNotJSONData('referrer')).subscribe(
          response => {
            if (response.bearer && response.status === 200) {
              setTimeout(() => {
                if (transactionUUID) {
                  this.store.dispatch(
                    organizationsSwitch({
                      queryParams: { transactionUUID },
                    })
                  );
                } else {
                  this.store.dispatch(organizationsSwitch({}));
                }
              }, 500);
            } else if (response.orgUuid && response.status === 202) {
              const queryParams = {
                orgUuid: response.orgUuid,
              };

              if (transactionUUID) {
                Object.assign(queryParams, { transactionUUID });
              }

              this.router.navigate(['login'], { queryParams });
            } else {
              this.alertService.openAlert({
                type: 'warning',
                body: `Caution! Sorry we can't process your request.`,
                autoClose: 5000,
              });
              this.router.navigate(['login']);
            }
          },
          () => this.router.navigate(['login'])
        );
      } else {
        this.router.navigate(['login']);
      }
    });
  }
}
