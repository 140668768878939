import { CoverLetterTemplates } from './cover-sheet-modal.interfaces';
import * as actions from './cover-sheet-modal.actions';
import { ActionTypes } from './cover-sheet-modal.enum';

export interface CoverSheetModalState {
  coverLetterTemplates: CoverLetterTemplates;
  coverLetterRequiredTemplates: CoverLetterTemplates;
}

export const initialState: CoverSheetModalState = {
  coverLetterTemplates: {
    data: [],
    loading: false,
    loaded: false,
    error: false,
  },
  coverLetterRequiredTemplates: {
    data: [],
    loading: false,
    loaded: false,
    error: false,
  },
};

export function reducer(state = initialState, action: actions.ActionsUnion): CoverSheetModalState {
  switch (action.type) {
    case ActionTypes.CoverLetterTemplatesLoadFailure: {
      return {
        ...state,
        coverLetterTemplates: {
          ...state.coverLetterTemplates,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    }
    case ActionTypes.CoverLetterTemplatesLoadPending: {
      return {
        ...state,
        coverLetterTemplates: {
          ...state.coverLetterTemplates,
          loading: true,
          loaded: false,
        },
      };
    }

    case ActionTypes.CoverLetterTemplatesLoadSuccess: {
      return {
        ...state,
        coverLetterTemplates: {
          ...state.coverLetterTemplates,
          data: action.payload,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    }

    case ActionTypes.CoverLetterRequiredTemplatesLoadFailure: {
      return {
        ...state,
        coverLetterRequiredTemplates: {
          ...state.coverLetterRequiredTemplates,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    }
    case ActionTypes.CoverLetterRequiredTemplatesLoadPending: {
      return {
        ...state,
        coverLetterRequiredTemplates: {
          ...state.coverLetterRequiredTemplates,
          loading: true,
          loaded: false,
        },
      };
    }

    case ActionTypes.CoverLetterRequiredTemplatesLoadSuccess: {
      return {
        ...state,
        coverLetterRequiredTemplates: {
          ...state.coverLetterRequiredTemplates,
          data: action.payload,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    }

    default: {
      return state;
    }
  }
}
