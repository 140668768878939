import { GlobalConfig, PageConfig } from '@shared/models';
import { State } from '../../presentation.reducer';

export function updateMainConfigField<T extends GlobalConfig & PageConfig>(
  configs: T[],
  values: Record<string, unknown>
): T[] {
  return configs.map((item, index) =>
    index
      ? item
      : {
          ...item,
          config: {
            ...item.config,
            ...values,
          },
        }
  );
}

export function updateMainConfig(state: State, values: Record<string, unknown>): State {
  return {
    ...state,
    configs: {
      ...state.configs,
      data: updateMainConfigField(state.configs.data, values),
    },
  };
}
