import { Action } from '@ngrx/store';

import { ActionTypes } from './cover-sheet-modal.enum';

export class CoverLetterTemplatesLoadSuccess implements Action {
  readonly type = ActionTypes.CoverLetterTemplatesLoadSuccess;
  constructor(public payload: any) {}
}

export class CoverLetterTemplatesLoadPending implements Action {
  readonly type = ActionTypes.CoverLetterTemplatesLoadPending;
}

export class CoverLetterTemplatesLoadFailure implements Action {
  readonly type = ActionTypes.CoverLetterTemplatesLoadFailure;
  constructor(public error: any) {}
}

export class CoverLetterRequiredTemplatesLoadSuccess implements Action {
  readonly type = ActionTypes.CoverLetterRequiredTemplatesLoadSuccess;
  constructor(public payload: any) {}
}

export class CoverLetterRequiredTemplatesLoadPending implements Action {
  readonly type = ActionTypes.CoverLetterRequiredTemplatesLoadPending;
}

export class CoverLetterRequiredTemplatesLoadFailure implements Action {
  readonly type = ActionTypes.CoverLetterRequiredTemplatesLoadFailure;
  constructor(public error: any) {}
}

export class CoverLetterTemplatesReset implements Action {
  readonly type = ActionTypes.CoverLetterTemplatesReset;
}

export type ActionsUnion =
  | CoverLetterTemplatesLoadSuccess
  | CoverLetterTemplatesLoadPending
  | CoverLetterTemplatesLoadFailure
  | CoverLetterRequiredTemplatesLoadSuccess
  | CoverLetterRequiredTemplatesLoadPending
  | CoverLetterRequiredTemplatesLoadFailure
  | CoverLetterTemplatesReset;
