import { INSERT_TYPE } from '@core/enums';

// export const INSERT_TYPES: Placeholder.RadioOption[] = [
export const INSERT_TYPES = [
  {
    checked: false,
    label: 'Chart',
    type: INSERT_TYPE.chart,
  },
  {
    checked: false,
    label: 'Text',
    type: INSERT_TYPE.text,
  },
  {
    checked: false,
    label: 'Variable',
    type: INSERT_TYPE.variable,
  },
  {
    checked: false,
    label: 'Custom Metric',
    type: INSERT_TYPE.customMetric,
  },
  {
    checked: false,
    label: 'Image',
    type: INSERT_TYPE.image,
  },
  {
    checked: false,
    label: 'Products Description',
    type: INSERT_TYPE.productsDescription,
  },
  {
    checked: false,
    label: 'Custom Table',
    type: INSERT_TYPE.customTable,
  },
  {
    checked: false,
    label: 'Button',
    type: INSERT_TYPE.button,
  },
  {
    checked: false,
    label: 'API Call',
    type: INSERT_TYPE.apiCall,
  },
  {
    checked: false,
    label: 'From Other Pages',
    type: INSERT_TYPE.fromOtherPage,
  },
  {
    checked: false,
    label: 'Dropdown',
    type: INSERT_TYPE.dropdown,
  },
  {
    checked: false,
    label: 'Product Selector',
    type: INSERT_TYPE.productSelector,
  },
  {
    checked: false,
    label: 'Tab',
    type: INSERT_TYPE.tab,
  },
];

export const DEFAULT_CUSTOM_TABLE_PRODUCT = 0;
