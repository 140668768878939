import { HttpParams } from '@angular/common/http';

import { CustomHttpParams } from '@core/model';

export const getSearchQuery = (searchObject: CustomHttpParams): HttpParams => {
  let paramsList = new HttpParams();

  for (const param in searchObject) {
    if (searchObject.hasOwnProperty(param)) {
      paramsList = paramsList.set(param, `${searchObject[param]}`);
    }
  }

  return paramsList;
};
