export const pendingExports = `/AssuranceAPI/pending-exports`;
export const creatingPresentationId = -1;
export const defaultPlanType = 'OTHER';
export const planTypes: string[] = [defaultPlanType, 'VUL', 'ANNUITY', 'LTC', 'INFORCE'];

export const importLabelByApp = {
  'pacific-life-annuity': 'Pacific Life',
  SE: 'EIQ',
  allianz: 'Allianz',
  'allianz-annuity': 'Allianz',
};
