import { FormFields } from './cover-sheet-modal.enum';

export const coverSheetModalSize = {
  width: '110vh',
  height: '75vh',
  widthForSalesConceptsModal: '130vh',
};

export const coverSheetInputsLength = {
  [FormFields.title]: 100,
  [FormFields.text]: 500,
  [FormFields.signature]: 100,
  [FormFields.footer]: 200,
};

export const TEMPLATES_IDS = {
  allianz: 'allianz',
  allianzretirement: 'allianzretirement',
  text: 'text',
  lowcolor: 'lowcolor',
};

export const coverSheetTemplatesQueryParams = {
  labels: ['coverletter'],
};

export const coverSheetRequiredTemplatesQueryParams = {
  labels: ['required'],
};

export const ALLIANZ_UIIDS = [TEMPLATES_IDS.allianz, TEMPLATES_IDS.allianzretirement];
