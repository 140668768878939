import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash-es';

@Pipe({
  name: 'setTemplateSrc',
})
export class TemplateSrcPipe implements PipeTransform {
  transform(uiId: string, fileNames: string[]) {
    return find(fileNames, (fileName: string) => fileName.includes(uiId));
  }
}
