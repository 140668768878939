import { Injectable } from '@angular/core';

import { TinyEditorService } from '@shared/services';
import { WizardModalResponse } from '@shared/models';
import {
  ANCHOR_CLASS,
  CUSTOM_PAGES_TYPES,
  INSERT_PLACEHOLDER_SELECTOR,
  LINK_INTERPOLATION,
  PLACEHOLDER_MARKUP_BY_TYPE,
} from '@shared/constants';
import { InsertType } from '@core/model';

@Injectable()
export class CustomPageSettingsService {
  removePlaceholder(id: string, htmlBody: string): string {
    const removePlaceholderFunc = item => {
      const styleId = item.getAttribute('data-style-id');

      if (styleId) {
        const styleElement = item.parentNode.querySelector(`style[data-style-id="${styleId}"]`);
        styleElement?.remove();
      }

      item.remove();
    };

    return this.managePlaceholders(id, htmlBody, removePlaceholderFunc);
  }

  replaceTextContent(id: string, htmlBody: string, values: WizardModalResponse, insertType: InsertType): string {
    return this.managePlaceholders(id, htmlBody, this.renderPlaceholder.bind(this, insertType, values));
  }

  generateSelectorForStyles(styleId: string, style = ''): string {
    return `.${INSERT_PLACEHOLDER_SELECTOR}[data-style-id="${styleId}"] {${style}}`;
  }

  addAttributesToAnchors(htmlBody: string): string {
    const div = document.createElement('div');

    div.innerHTML = htmlBody;

    const links = div.querySelectorAll('a');

    links.forEach(link => {
      const isContainsInterpolation = decodeURI(link.href).includes(LINK_INTERPOLATION);
      const isLinkContainsClass = link.classList.contains(ANCHOR_CLASS);

      if (isContainsInterpolation && !isLinkContainsClass) {
        link.setAttribute('class', ANCHOR_CLASS);
      }
    });

    return div.innerHTML;
  }

  isNotIncludeEndPage(labels: string[]): boolean {
    return !labels.includes(CUSTOM_PAGES_TYPES.requiredpage);
  }

  private renderPlaceholder(insertType: InsertType, values: WizardModalResponse, item: HTMLElement): void {
    const parser = new DOMParser();
    const styleId = item.getAttribute('data-style-id');
    const content = TinyEditorService.extendMetadata(PLACEHOLDER_MARKUP_BY_TYPE[insertType], values, styleId);

    const contentBody = parser.parseFromString(content, 'text/html').body;

    if (styleId) {
      const styleElement = contentBody.querySelector(`style[data-style-id="${styleId}"]`);
      styleElement?.remove();
    }

    item.replaceWith(contentBody);
  }

  private managePlaceholders(id: string, htmlBody: string, method: (elem: HTMLElement) => void): string {
    const div = document.createElement('div');
    // TODO: need to change interface
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    div.innerHTML = htmlBody;
    const insertList = div.querySelectorAll(`.${INSERT_PLACEHOLDER_SELECTOR}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    insertList.forEach((item: HTMLElement) => {
      const idElem = item.querySelector('.id');

      if (idElem?.textContent === id) {
        method(item);
      }
    });
    const html = div.innerHTML;
    div.remove();

    return html;
  }
}
