<div *ngIf="savePageLoading" class="spinner-holder">
  <ac-loader></ac-loader>
</div>

<section class="content a-app-main-container-background-color" [ngClass]="{ loader: loading, hidden: !loading }"></section>

<div class="container-content">
  <ensight-custom-page-header
    [title]="customPageName"
    [showSaveBtn]="true"
    [disableSaveBtn]="false"
    (onClose)="leavePage()"
    (onSave)="savePage()"
  ></ensight-custom-page-header>

  <div class="container-version-name" *ngIf="versionName">
    <p>{{ versionName }}</p>
  </div>

  <div class="container-info-hint" *ngIf="isShowHint">
    <div class="hint-info">
      <div class="info">
        <span class="icon-info"></span>
        <p>Select part of text and hit the “Edit” button in order to change your text</p>
      </div>
      <button (click)="hideHint()" class="btn btn-hide-hint">
        <i class="ensight-icon-se-close"></i>
      </button>
    </div>
  </div>

  <section *ngIf="htmlBody" #templateRef [innerHTML]="htmlBody" class="custom-page-body"></section>
</div>

<div class="modal-container" *ngIf="showModal"
     [style.left.px]="modalPosition.left"
     [style.top.px]="modalPosition.bottom">
  <p class="title">Change selected text below:</p>

  <div class="container-textarea">
    <textarea class="selected-text" autofocus [formControl]="textControl"></textarea>
  </div>

  <div class="container-btns">
    <!-- button set edited text and close modal -->
    <ac-button (click)="setText(textControl.value)" [disabled]="!textControl.dirty" class="secondary bnt-edit-text" size="sm">
      <span class="fa fa-check"></span>
    </ac-button>
    <!-- button set selected text and close modal -->
    <ac-button (click)="setText(selectedText)" class="secondary bnt-edit-text" size="sm">
      <i class="ensight-icon-se-close"></i>
    </ac-button>
  </div>
</div>
