/* tslint:disable */
/* eslint-disable */
export const ga = {
  init4v: (gaV4Code: string) => {
    const scriptTag = document.createElement('script');
    const scriptScrUrl = new URL('https://www.googletagmanager.com/gtag/js');
    scriptScrUrl.searchParams.append('id', gaV4Code);

    scriptTag.setAttribute('async', '');
    scriptTag.setAttribute(
      'src',
      scriptScrUrl.toString()
    );

    (window as any).dataLayer = (window as any).dataLayer || [];

    document.head.appendChild(scriptTag);
    function gtag(){dataLayer.push(arguments)};
    (window as any).gtag = gtag;
    // @ts-ignore
    gtag('js', new Date());
    // @ts-ignore
    gtag('config', gaV4Code);
  }
};

/* tslint:tslint:enable */
