import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '@assurance/bootstrap';
import { CommonSeModule } from '@se/common';

import { ImagesPreviewModule } from '@shared/components';
import { PlaceholdersEditingModalComponent } from './placeholders-editing-modal.component';

const components = [PlaceholdersEditingModalComponent];

@NgModule({
  declarations: components,
  exports: components,
  imports: [CommonModule, ButtonModule, ReactiveFormsModule, CommonSeModule, ImagesPreviewModule],
})
export class PlaceholdersEditingModalModule {}
